import React from "react";
import { Modal } from "react-bootstrap";

function WhyChooseUsModal({ show, handleShow, chooseTitle }) {
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="text-center">
                      <h3 className="fw-bold py-4">Why Choose US</h3>
                      <img
                        src="assets/img/Whychoose_us.png"
                        alt=""
                        className="chooseImg"
                      />
                    </div>
                    {chooseTitle === "Sports venues at fingertips" ? (
                      <>
                        <h5 className="pt-4">{chooseTitle}</h5>
                        <p className="text-justify">
                          Heard anything better than a selection of sports
                          venues that work with your schedule?
                          <br /> Game Hunt recognises the difficulties that
                          sports enthusiasts encounter when it comes to locating
                          sports venues and how time-consuming it may be! With
                          many filter options to help you find a desirable
                          sports venue near you from the comfort of your own
                          home! Not only that, but we also offer incredible
                          discounts on exclusive venues, allowing you to book
                          the venue at the best possible price. Maximize your
                          playtime while minimizing booking hassles! Tap "BOOK A
                          VENUE" to discover the realm of sporting venues around
                          you.
                        </p>
                      </>
                    ) : chooseTitle ===
                      "Assertive Coaching camps for persistence." ? (
                      <>
                        <h5 className="pt-4">{chooseTitle}</h5>
                        <p className="text-justify">
                          It may be difficult to locate quality coaching
                          academies in your area. As a result, we have coaching
                          centres listed in meticulous detail on our platform.
                          This will allow you to compare other centres to find
                          the best club based on your preferences and book a
                          free trial session. Gamehunt offers video courses that
                          are organized into modules and are accessible 24X7,
                          allowing you to learn with the best specialists in the
                          country. The video lectures in the app are both
                          interesting and instructive. GameHunt experts
                          understand the importance of personalized learning
                          routes; each student may pick a different path based
                          on their needs and chosen teaching method. To stay
                          focused on their game, players are assisted with the
                          experience of handpicked coaches and trainers from
                          throughout the country during the whole learning
                          process. Experts not only provide individual
                          instruction to students, but they also contribute
                          content to a complete video archive that students may
                          access at anytime from anywhere.
                        </p>
                      </>
                    ) : chooseTitle === "The GAMEHUNT ACADEMY" ? (
                      <>
                        <h5 className="pt-4">{chooseTitle}</h5>
                        <p className="text-justify">
                          The Gamehunt Academy is a one-stop shop for schools
                          and students seeking sports and life skills. Given
                          that schools' areas of expertise are in education and
                          academics, most schools struggle to bridge the gap
                          between academics and extracurricular activities.
                          Young athletes have up until now relied on the
                          athletic departments of their schools to provide them
                          with the coaches they needed to get ready for state or
                          national competitions. By procuring the greatest
                          coaches, setting up playgrounds and courts, sending
                          kids to competitions, we assist schools in finding
                          solutions to all of these issues under one roof. The
                          Gamehunt Academy also believes that it is critical to
                          begin teaching life coaching to children at a young
                          age. The Gamehunt strives to present superior life
                          skills training programmes through our extensive
                          network of qualified life coaches who are
                          professionals in working with students to build traits
                          that may catalyse their advancement and give a jump
                          start to their sports career. It's simple to tie up
                          with us! And schools can afford to use all these
                          different services and amenities.
                        </p>
                      </>
                    ) : chooseTitle === "Events & Tournaments" ? (
                      <>
                        <h5 className="pt-4">{chooseTitle}</h5>
                        <p className="text-justify">
                          The Gamehunt is d edicated to providing numerous
                          opportunities for our young athletes to develop and
                          showcase their skills at a competitive level. We
                          organise major sporting events, tournaments, and
                          championship leagues for a variety of sports,
                          including football, cricket, field hockey, basketball,
                          volleyball, tennis, and swimming. We provide
                          unrivalled facilities for these events in order to
                          provide the best experience for young athletes. It is
                          unavoidable for players to compete with the best of
                          the best in order to hone their skills and advance in
                          their respective sports. We bring together teams from
                          all over the city to provide a level playing field for
                          the teams and a thrilling experience for the
                          spectators! The Gamehunt also recognizes and rewards
                          the winners and runner-ups with trophies, medals, and
                          exclusive Gamehunt scholarships to help athletes
                          advance in the sports community.
                        </p>
                      </>
                    ) : null}
                  </div>
                  <div className="text-center" onClick={handleShow}>
                    <button className="btn StatusBtn RedBtn text-white">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WhyChooseUsModal;
