import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";
import HomeHeader from "../Components/HomeHeader";
const token = localStorage.getItem("token");

function CommonLayout() {
  return (
    <>
      {token ? <HomeHeader className="loginAfter" /> : <HomeHeader />}
      <Outlet />
      <Footer />
    </>
  );
}

export default CommonLayout;
