import React from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AddBlockedUserReducer } from "../../features/slice";

function BlockedUserModal({ show, handleShow, id, getdetail, isBlocked }) {
  const dispatch = useDispatch();
  const initialValues = {
    reason: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      reason: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const payload = {
        reason: values.reason,
        userId: id,
      };

      dispatch(AddBlockedUserReducer(payload)).then((data) => {
        if (data?.payload?.code === 1) {
          toast.success(data.payload.message);
          handleShow();
          getdetail();
        } else {
          toast.error(data.payload.message);
        }
      });
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <form className="row" onSubmit={formik.handleSubmit}>
                  <div className="col-sm-12 col-md-12 col-lg-12 px-5">
                    <div className="text-center">
                      <h3 className="fw-bold py-4">
                        {isBlocked === true ? "Unblock User" : "Block User"}
                      </h3>
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="bg-white rad border-0 p-3 w-100"
                        rows="7"
                        placeholder="Reason here.."
                        name="reason"
                        value={formik.values.reason}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.errors.reason && formik.touched.reason && (
                        <div className="text-red"> {formik.errors.reason}</div>
                      )}
                    </div>
                    <div className="mb-4 text-center">
                      <button type="submit" className="btn btn-submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BlockedUserModal;
