import React from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import WeekStrip from "react-weekstrip-daypicker";
import moment from "moment";
import Paginations from "../../../Components/Paginations";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import {
  VenueDashboardReducer,
  VenueUpcomingEventBookingListReducer,
} from "../../CoachVenueslice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

function Dashboard() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const detail = useSelector((state) => state.CoachVenueMain.VenueDashboar);
  const Venuecompleted_appointments = useSelector(
    (state) => state.CoachVenueMain.Venuecompleted_appointments
  );
  const VenuetotalEarning = useSelector(
    (state) => state.CoachVenueMain.VenuetotalEarning
  );
  const upcoming_appointments = useSelector(
    (state) => state.CoachVenueMain.VenueUpcomingEventBooking
  );
  const Venuetotal_appointments = useSelector(
    (state) => state.CoachVenueMain.Venuetotal_appointments
  );
  const Venueupcoming_appointments = useSelector(
    (state) => state.CoachVenueMain.Venueupcoming_appointments
  );

  const [earning_type, setearning_type] = useState("1");
  const [SelectDate, setSelectDate] = useState();

  const [changedate, setChangeDate] = useState(new Date());

  //console.log(changedate);

  const handlePage = (value) => {
    setPage(value);
  };

  //console.log(detail);
  const getdetail = useCallback(() => {
    dispatch(
      VenueDashboardReducer({
        date: moment(SelectDate).format("YYYY-MM-DD"),
        earning_type: earning_type,
      })
    );
  }, [dispatch, earning_type, SelectDate]);

  const UpcomingAppointmentGetList = useCallback(() => {
    dispatch(
      VenueUpcomingEventBookingListReducer({
        page: page,
        date: moment(changedate, "DD/MM/YYYY").format("DD/MM/YYYY"),
        limit: 10,
      })
    );
  }, [dispatch, changedate, page]);

  useEffect(() => {
    getdetail();
  }, [getdetail]);

  useEffect(() => {
    UpcomingAppointmentGetList();
    setSelectDate(moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"));
  }, [UpcomingAppointmentGetList]);

  const BarData = {
    labels:
      earning_type === "1"
        ? detail?.map((item) => moment(item.date, "YYYY-MM-DD").format("ddd"))
        : earning_type === "2"
        ? detail?.map((item) => {
            return `${moment(item.date, "YYYY-MM-DD")
              .startOf("week")
              .format("DD MMM")}-${moment(item.date, "YYYY-MM-DD")
              .endOf("week")
              .format("DD MMM")} `;
          })
        : earning_type === "3"
        ? detail?.map((item) =>
            moment(item.date, "YYYY-MM-DD").format("MMM YY")
          )
        : "",
    datasets: [
      {
        data: detail?.map((item) => item?.earning_amount),
        fill: true,
        backgroundColor: ["#f2bcbf"],
        borderColor: "#df0510",
        borderRadius: 10,
        borderWidth: 5,
        label: "₹",
        pointStyle: "#df0510",
        pointRadius: 5,
        pointBorderColor: "#df0510",
        //data: [5000, 10000, 15000, 20000, 11000, 17000],
      },
    ],
  };
  return (
    <>
      <main id="mainbody">
        <section>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 class="my-3 fw-bold">Dashboard</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                    <div class="paymentBox dueBox d-block p-2">
                      <div class="text-end">
                        <img src="assets/img/Christmas_Date.png" alt="" />
                      </div>
                      <div>
                        <h6 class="mb-3">Total Booking</h6>
                        <h3 class="mb-0">{Venuetotal_appointments || "0"}</h3>
                      </div>
                      <img
                        src="assets/img/Christmas_Date_graph.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                    <div class="paymentBox recivedBox d-block p-2">
                      <div class="text-end">
                        <img src="assets/img/calendar_person.png" alt="" />
                      </div>
                      <div>
                        <h6 class="mb-3">Upcoming Bookings</h6>
                        <h3 class="mb-0">
                          {Venueupcoming_appointments || "0"}
                        </h3>
                      </div>
                      <img
                        src="assets/img/calendar_person_graph.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                    <div class="paymentBox recivedBox d-block p-2">
                      <div class="text-end">
                        <img src="assets/img/calendar_completed.png" alt="" />
                      </div>
                      <div>
                        <h6 class="mb-3">Completed Bookings</h6>
                        <h3 class="mb-0">
                          {Venuecompleted_appointments || "0"}
                        </h3>
                      </div>
                      <img
                        src="assets/img/calendar_completed_graph.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="bg-white rad p-3">
                      <div class="d-flex align-items-center justify-content-between gap-3">
                        <span>
                          <b>Total earnings</b>{" "}
                          <h3 class="mb-0">
                            <i class="fa fa-inr"></i>{" "}
                            {VenuetotalEarning
                              ? parseFloat(VenuetotalEarning).toFixed(2)
                              : "0"}
                          </h3>
                        </span>
                        <span class="d-flex align-items-center gap-1 border nowrap p-2 rad">
                          <span>Earning By: </span>
                          <select
                            class="form-select border-0 fw-bold"
                            onClick={(e) => {
                              setearning_type(e.target.value);
                            }}
                          >
                            <option value="1">Daily</option>
                            <option value="2">Weekly</option>
                            <option value="3">Monthly</option>
                          </select>
                        </span>
                      </div>
                      <Line
                        data={BarData}
                        plugin={ChartDataLabels}
                        options={{
                          plugins: {
                            layout: {
                              padding: {
                                left: 10,
                                right: 25,
                                top: 25,
                                bottom: 0,
                              },
                            },
                            title: {
                              display: false,
                            },
                            legend: {
                              display: false,
                            },
                          },
                          maintainAspectRatio: true,
                          responsive: true,

                          scales: {
                            x: {
                              display: true,
                              ticks: {
                                color: "#000",
                              },
                              time: {
                                unit: "date",
                              },
                              grid: {
                                display: false,
                                drawBorder: false,
                              },
                            },
                            y: {
                              display: true,

                              ticks: {
                                padding: 10,
                                //color: "#000",
                              },
                              grid: {
                                display: true,
                                drawOnChartArea: true,
                                drawTicks: true,

                                drawBorder: true,
                                borderDash: [5],
                                zeroLineBorderDash: [5],
                              },
                            },
                          },
                          Tooltip: {
                            backgroundColor: "#fff",
                            bodyFontColor: "#000",
                            bodyFontStyle: "bold",
                            bodyFontSize: 20,
                            titleMarginBottom: 10,
                            titleFontColor: "#000",
                            titleFontSize: 25,
                            borderColor: "#fff",
                            borderWidth: 1,
                            xPadding: 15,
                            yPadding: 15,
                            displayColors: false,
                            intersect: false,
                            mode: "index",
                            caretPadding: 10,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <div class="FormBoxShadow mb-5 p-3 rad shadow-none">
                  <h5 class="mb-4 text-center">Upcoming Booking</h5>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <WeekStrip
                        //datesWhitelist={datesWhitelist}
                        // date={changedate}
                        onWeekChange={(weeks) => {
                          let weakDate = weeks.format("DD/MM/YYYY");
                          console.log(
                            weeks.format("DD/MM/YYYY"),
                            "weeksChange"
                          );
                          setChangeDate(weakDate);
                        }}
                        onChange={(value) => {
                          let PartuDate = value.format("DD/MM/YYYY");
                          //console.log(value.format("DD/MM/YYYY"));
                          setChangeDate(PartuDate);
                        }}
                        initDay={SelectDate}
                        minDate={new Date()}
                      />
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <h5>
                        {moment(changedate, "DD/MM/YYYY").format("dddd")}{" "}
                        {moment(changedate, "DD/MM/YYYY").format("DD")}
                      </h5>
                      <div class="text-end">
                        <Link to="/venue/bookings" class="theme-color fw-bold">
                          See All
                        </Link>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      {upcoming_appointments?.eventbooking?.length > 0
                        ? upcoming_appointments?.eventbooking?.map(
                            (item, i) => (
                              <div class="HairStylishAppoint">
                                <div class="imgNameTtl">
                                  <span>
                                    <img
                                      src={
                                        item?.createdBy?.picture?.includes(
                                          "http"
                                        )
                                          ? item?.createdBy?.picture
                                          : "assets/img/employe.png"
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span>
                                    <h6 class="mb-0">
                                      {item?.createdBy?.firstName
                                        ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                        : "NA"}
                                    </h6>
                                    <p class="mb-0">
                                      Sport:<b> {item?.sport || "NA"}</b>
                                    </p>
                                    <p class="mb-0">
                                      {moment(
                                        item?.slot?.[0]?.timeSlotStart,
                                        "hh:mm"
                                      ).format("hh:mm A")}{" "}
                                      -{" "}
                                      {moment(
                                        item?.slot?.[0]?.timeSlotEnd,
                                        "hh:mm"
                                      ).format("hh:mm A")}
                                    </p>
                                  </span>
                                </div>
                                <span class="theme-color fw-bold">
                                  <i class="fa fa-inr"></i>{" "}
                                  {parseFloat(item?.total).toFixed(2) || "0"}
                                  /-
                                </span>
                              </div>
                            )
                          )
                        : "No Data Found"}
                    </div>
                    <div className="text-center">
                      {upcoming_appointments?.eventbooking?.length > 0 && (
                        <Paginations
                          handlePage={handlePage}
                          page={page}
                          upcomingBottom="upcomingBottom"
                          total={
                            upcoming_appointments?.total_eventbooking &&
                            upcoming_appointments?.total_eventbooking
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Dashboard;
