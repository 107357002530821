import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CourtsAndTrufStatusApi } from "../../../api/CoachVenuesetting";
import CourtTurfDetailModal from "../../../Components/CourtTurfDetailModal";
import Paginations from "../../../Components/Paginations";
import { CourtsAndTrufReducer } from "../../CoachVenueslice";
import AddCourtTruf from "./AddCourtTruf";
import AddSlot from "./AddSlot";
import CopySlotModal from "./CopySlotModal";
import EditCourtTurf from "./EditCourtTurf";
import EditSlot from "./EditSlot";

function CourtsTrufs() {
  const dispatch = useDispatch();
  const [tabName, setTabNeme] = useState("active");
  const [status, setStatus] = useState("1");
  const [page, setPage] = useState(1);
  const [modalName, setModalName] = useState("");
  const [copyModalName, setCopyModalName] = useState("");
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [copyData, setCopyData] = useState(false);
  const [initialData, setinitialData] = useState();
  const [id, setId] = useState("");

  const handleShow = () => {
    setShow(!show);
  };
  const handlePage = (value) => {
    setPage(value);
  };
  const onClose = () => {
    setVisible(false);
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const list = useSelector((state) => state.CoachVenueMain.CourtsTrufsList);
  const total = useSelector(
    (state) => state.CoachVenueMain.total_CourtsTrufsList
  );

  const getCourtTrufList = useCallback(async () => {
    dispatch(
      CourtsAndTrufReducer({
        isActive: status,
        page: page,
        limit: 10,
      })
    );
  }, [dispatch, page, status]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCourtTrufList();
    }
    return () => {
      mounted = false;
    };
  }, [getCourtTrufList]);

  const UpdateStatus = (data) => {
    CourtsAndTrufStatusApi(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getCourtTrufList();
      } else {
        toast?.error(res?.message);
      }
    });
  };
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center position-relative">
                <h3 className="my-3 fw-bold">Court/Turfs</h3>
                <span>
                  <img
                    src="assets/img/addBtn.png"
                    alt=""
                    className="add-new"
                    onClick={() => {
                      setModalName("AddCourtTruf");
                      showDrawer();
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <ul
                  className="nav nav-pills mb-3 justify-content-center bookingTabs courtTrufTabs tabsnew"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setStatus("1");
                        setTabNeme("active");
                      }}
                    >
                      active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setStatus("0");
                        setTabNeme("inactive");
                      }}
                    >
                      inactive
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="tab-content mt-4" id="pills-tabContent">
                  {tabName === "active" ? (
                    <div
                      className="tab-pane fade show active"
                      id="pills-active"
                      role="tabpanel"
                      aria-labelledby="pills-active-tab"
                    >
                      <div className="table-responsive">
                        <table className="table storetable">
                          <thead className="">
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">court/turf number</th>
                              <th scope="col">slot cost</th>
                              <th scope="col">sports</th>
                              <th scope="col">min. capacity</th>
                              <th scope="col">max. capacity</th>
                              <th scope="col" className="text-center">
                                action
                              </th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td className="CurtTurfImgeTable">
                                    <img
                                      src={
                                        item?.image[0]?.image?.includes("http")
                                          ? item?.image[0]?.image
                                          : "assets/img/dummyImage.png"
                                      }
                                      alt=""
                                      className="CurtTurfImgeTable rounded-3"
                                    />
                                  </td>
                                  <td>{item?.name || "NA"}</td>
                                  <td>
                                    <i className="fa fa-inr"></i>{" "}
                                    {item?.costPerSlot || "NA"}
                                  </td>
                                  <td>{item?.sport || "NA"}</td>
                                  <td>{item?.minCapacity || "0"}</td>
                                  <td>{item?.maxCapacity || "0"}</td>
                                  <td>
                                    <div className="d-flex justify-content-center action">
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setModalName("viewDetail");
                                          setId(item?._id);
                                          handleShow();
                                        }}
                                      >
                                        <img src="assets/img/view.png" alt="" />
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setModalName("EditCourtTruf");
                                          setId(item?._id);
                                          showDrawer();
                                        }}
                                      >
                                        <img src="assets/img/edit.png" alt="" />
                                      </button>
                                      <button className="btn p-0">
                                        <input
                                          type="checkbox"
                                          className="toggle"
                                          checked={item?.isActive === true}
                                          id={`courtTruf-${item?._id}`}
                                          onChange={() => {
                                            UpdateStatus({
                                              courtandtrufId: item?._id,
                                            });
                                          }}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan="11">No Records Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="text-center">
                        {list?.length > 0 && (
                          <Paginations
                            handlePage={handlePage}
                            page={page}
                            total={total && total}
                          />
                        )}
                      </div>
                    </div>
                  ) : tabName === "inactive" ? (
                    <div>
                      <div className="table-responsive">
                        <table className="table storetable">
                          <thead className="">
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">court/turf number</th>
                              <th scope="col">slot cost</th>
                              <th scope="col">sports</th>
                              <th scope="col">min. capacity</th>
                              <th scope="col">max. capacity</th>
                              <th scope="col" className="text-center">
                                action
                              </th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td className="CurtTurfImgeTable">
                                    <img
                                      src={
                                        item?.image[0]?.image?.includes("http")
                                          ? item?.image[0]?.image
                                          : "assets/img/dummyImage.png"
                                      }
                                      alt=""
                                      className="CurtTurfImgeTable rounded-3"
                                    />
                                  </td>
                                  <td>{item?.name || "NA"}</td>
                                  <td>
                                    <i className="fa fa-inr"></i>{" "}
                                    {item?.costPerSlot || "NA"}
                                  </td>
                                  <td>{item?.sport || "NA"}</td>
                                  <td>{item?.minCapacity || "0"}</td>
                                  <td>{item?.maxCapacity || "0"}</td>
                                  <td>
                                    <div className="d-flex justify-content-center action">
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setModalName("viewDetail");
                                          setId(item?._id);
                                          handleShow();
                                        }}
                                      >
                                        <img src="assets/img/view.png" alt="" />
                                      </button>
                                      <button className="btn p-0">
                                        <input
                                          type="checkbox"
                                          className="toggle"
                                          checked={item?.isActive === true}
                                          id={`courtTruf-${item?._id}`}
                                          onChange={() => {
                                            UpdateStatus({
                                              courtandtrufId: item?._id,
                                            });
                                          }}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan="11">No Records Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="text-center">
                        {list?.length > 0 && (
                          <Paginations
                            handlePage={handlePage}
                            page={page}
                            total={total && total}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {visible && modalName === "AddCourtTruf" && (
        <AddCourtTruf
          onClose={onClose}
          visible={visible}
          getlist={getCourtTrufList}
          setModalName={setModalName}
          showDrawer={showDrawer}
          setinitialData={setinitialData}
        />
      )}
      {visible && modalName === "AddSlot" && (
        <AddSlot
          onClose={onClose}
          visible={visible}
          getlist={getCourtTrufList}
          setModalName={setModalName}
          showDrawer={showDrawer}
          show={show}
          handleShow={handleShow}
          setCopyModalName={setCopyModalName}
          copyData={copyData}
          setCopyData={setCopyData}
          initialData={initialData}
        />
      )}
      {show && copyModalName === "copySlot" && (
        <CopySlotModal
          show={show}
          handleShow={handleShow}
          setCopyData={setCopyData}
        />
      )}
      {show && modalName === "viewDetail" && id && (
        <CourtTurfDetailModal show={show} handleShow={handleShow} id={id} />
      )}
      {visible && modalName === "EditCourtTruf" && id && (
        <EditCourtTurf
          onClose={onClose}
          visible={visible}
          getlist={getCourtTrufList}
          setModalName={setModalName}
          showDrawer={showDrawer}
          setinitialData={setinitialData}
          id={id}
        />
      )}
      {visible && modalName === "EditSlot" && (
        <EditSlot
          onClose={onClose}
          visible={visible}
          getlist={getCourtTrufList}
          setModalName={setModalName}
          showDrawer={showDrawer}
          show={show}
          handleShow={handleShow}
          setCopyModalName={setCopyModalName}
          copyData={copyData}
          setCopyData={setCopyData}
          initialData={initialData}
          id={id}
        />
      )}
    </>
  );
}

export default CourtsTrufs;
