import React, { useState } from "react";
import { useCallback } from "react";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import ForgotPasswordModal from "../../Components/Modals/ForgotPasswordModal";
import JoinAnExistingEventModal from "../../Components/Modals/JoinAnExistingEventModal";
import LoginModal from "../../Components/Modals/LoginModal";
import LoginWithEmail from "../../Components/Modals/LoginWithEmail";
import OTPVerification from "../../Components/Modals/OTPVerification";
import ResetPassword from "../../Components/Modals/ResetPassword";
import SignUpModal from "../../Components/Modals/SignUpModal";
import SignUpWithMobileModal from "../../Components/Modals/SignUpWithMobileModal";
import {
  CreatedEventSlider,
  DetailImageCarouselsettings,
  mapApiKey,
} from "../../Utils";
import {
  ChangeouterLogin,
  ChangeurlId,
  MyFavouriteVenueDetailReducer,
  MyFavouriteVenueDetailWithTokenReducer,
} from "../slice";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { UpdateFavVenueAPI } from "../../api/setting";
import { toast } from "react-toastify";

function VenueDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [createdEventId, setCreatedEventId] = useState("");
  const [AvailableSport, setAvailableSport] = useState("");
  const [minCapacity, setminCapacity] = useState("");

  const handleShow = () => {
    setShow(!show);
  };
  const loginType = localStorage.getItem("loginType");
  const loginDetail = useSelector((state) => state.main.userDetail);
  const outerLogin = useSelector((state) => state.main.outerLogin);
  const urlId = useSelector((state) => state.main.urlId);

  const liveLat = useSelector((state) => state.main.liveCoords?.lat);
  const liveLan = useSelector((state) => state.main.liveCoords?.lng);

  const detail = useSelector((state) => state.main.VenueDetail);
  const token = localStorage.getItem("token");
  const detailSport = localStorage.getItem("detailSport");

  const [newPrice, setNewPrice] = useState("0");

  const getVenueDetail = useCallback(async () => {
    if (token) {
      dispatch(
        MyFavouriteVenueDetailWithTokenReducer({
          venueId: id,
          sport: detailSport,
          longitude: liveLan,
          latitude: liveLat,
        })
      );
    } else {
      dispatch(
        MyFavouriteVenueDetailReducer({
          venueId: id,
          sport: detailSport,
          longitude: liveLan,
          latitude: liveLat,
        })
      );
    }
  }, [dispatch, id, token, detailSport, liveLan, liveLat]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getVenueDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getVenueDetail]);

  useEffect(() => {
    setAvailableSport(detail?.sport);
  }, [detail?.sport]);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (detail) {
      setMarkerPosition({
        lat: Number(detail?.latitude),
        lng: Number(detail?.longitude),
      });
    }
  }, [detail]);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap defaultZoom={15} defaultCenter={props?.markerPosition}>
        <Marker position={props?.markerPosition} />
      </GoogleMap>
    ))
  );

  const UpdateFav = (data) => {
    //console.log(data);
    UpdateFavVenueAPI(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getVenueDetail();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody" className="pt-0">
        <section className="bg-white py-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4 position-relative">
                <span
                  onClick={() => navigate(-1)}
                  className="theme-color backIconPosi"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </span>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 mb-2 position-relative">
                <Slider {...DetailImageCarouselsettings}>
                  {detail?.image?.map((item, i) => (
                    <div key={i}>
                      <img
                        src={
                          item?.image?.includes("http")
                            ? item?.image
                            : "assets/img/dummyImage.png"
                        }
                        className="img-fluid Detailgounds"
                        alt=""
                      />
                    </div>
                  ))}
                </Slider>

                <img
                  src={
                    detail?.isFevVenue === true
                      ? "assets/img/Favoriteselected.png"
                      : "assets/img/Favoriteunselected.png"
                  }
                  className={
                    token ? "Favoriteselected cursor-pointer" : "d-none"
                  }
                  alt=""
                  onClick={() => {
                    UpdateFav({
                      venueId: detail?._id,
                      isFevVenue: detail?.isFevVenue === true ? false : true,
                    });
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                <div className={markerPosition?.lat ? "mapView" : "NomapView"}>
                  {markerPosition?.lat ? (
                    <MapWithAMarker
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      markerPosition={markerPosition}
                    />
                  ) : (
                    <div className="NoMapFound">No Map Found</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8 position-relative">
                <div className="detailTitle">
                  <span>
                    <h5 className="mb-0 text-capitalize">
                      {detail?.name || "NA"}
                    </h5>
                    <p className="mb-0">
                      {detail?.address ? `${detail?.address} ` : "NA"}
                    </p>
                  </span>
                  <span>
                    <div className="text-end mb-2">
                      <a
                        href={`https://www.google.com/maps/place/${detail?.address}`}
                        target="_blank"
                        className="clubKMS box-shadow"
                      >
                        {detail?.dist?.calculated
                          ? parseFloat(detail?.dist?.calculated).toFixed(2)
                          : "0"}{" "}
                        Km
                      </a>
                    </div>
                    <span className="fw-bold">Starting From : </span>
                    <span className="theme-color fw-bold">
                      <i className="fa fa-inr"></i> {detail?.slotCost}
                    </span>{" "}
                    / Slot{" "}
                    <Link
                      to={`/venues-listing/review/${id}`}
                      className="votesRatingBg text-white"
                    >
                      {detail?.rating
                        ? parseFloat(detail?.rating).toFixed(1)
                        : "0"}{" "}
                      <img src="assets/img/star.png" alt="" /> (
                      {detail?.review || "0"}{" "}
                      {detail?.review <= 1 ? "vote" : "votes"})
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row pt-4">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h5>Available Sports</h5>
            </div>
            {detail?.sports?.map((item, i) => (
              <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3" key={i}>
                <div
                  className={
                    AvailableSport?.toLowerCase() === item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : "AvailableSportsName"
                  }
                  onClick={() => {
                    setAvailableSport(item?.sport);
                    localStorage.setItem("detailSport", item?.sport);
                  }}
                >
                  <img
                    src={
                      item?.sport === "cricket"
                        ? "assets/img/cricket_ball_icon.png"
                        : item?.sport === "football"
                        ? "assets/img/Footall.png"
                        : item?.sport === "basketball"
                        ? "assets/img/Basketball.png"
                        : item?.sport === "table tennis"
                        ? "assets/img/table_tennis.png"
                        : item?.sport === "badminton"
                        ? "assets/img/badminton.png"
                        : item?.sport === "swimming"
                        ? "assets/img/swimming.png"
                        : item?.sport === "lawn tennis"
                        ? "assets/img/lawntennis.png"
                        : null
                    }
                    alt=""
                    className="unselectImg"
                  />{" "}
                  <img
                    src={
                      item?.sport === "cricket"
                        ? "assets/img/cricket_ball_icon_white.png"
                        : item?.sport === "football"
                        ? "assets/img/Footall_white.png"
                        : item?.sport === "basketball"
                        ? "assets/img/Basketball_white.png"
                        : item?.sport === "table tennis"
                        ? "assets/img/table_tennis_white.png"
                        : item?.sport === "badminton"
                        ? "assets/img/badminton_white.png"
                        : item?.sport === "swimming"
                        ? "assets/img/swimming_white.png"
                        : item?.sport === "lawn tennis"
                        ? "assets/img/lawntennis_white.png"
                        : null
                    }
                    alt=""
                    className="selectImg"
                  />{" "}
                  {item?.sport || "NA"}
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="row pt-2">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h5>Amenities</h5>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
              <ul className="AmenitiesList">
                {detail?.amenities?.map((item, i) => (
                  <li
                    className={item?.amenities ? "text-capitalize" : "d-none"}
                    key={i}
                  >
                    <img
                      src={
                        item?.amenities === "cctv"
                          ? "assets/img/cctv.png"
                          : item?.amenities === "parking"
                          ? "assets/img/parking.png"
                          : item?.amenities === "air-conditioned"
                          ? "assets/img/air_conditioning.png"
                          : item?.amenities === "changing rooms"
                          ? "assets/img/ChangingRoom.png"
                          : item?.amenities === "washroom"
                          ? "assets/img/washroom.png"
                          : item?.amenities === "first aid"
                          ? "assets/img/firstaid_healtn.png"
                          : null
                      }
                      alt=""
                      width="16px"
                    />{" "}
                    {item?.amenities ? item?.amenities : ""}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <hr />
          <div className="row pt-2">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h5>Venue Rules</h5>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <div
                className="rulePTage"
                dangerouslySetInnerHTML={{ __html: detail?.rules }}
              ></div>
            </div>
          </div>
          <hr />
          <div className="row pt-2">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h5>Already Created Event</h5>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              {detail?.alreadyCreatedEvent?.length > 0 ? (
                <Slider {...CreatedEventSlider}>
                  {detail?.alreadyCreatedEvent?.map((item, i) => (
                    <div key={i}>
                      <div className="AlreadyEvent">
                        <div className="AlreadyEventBox">
                          <img
                            src={
                              detail?.image[0]?.image?.includes("http")
                                ? detail?.image[0]?.image
                                : "assets/img/Event1.png"
                            }
                            alt=""
                            className="eventImg"
                          />
                          <Link
                            to={`/already-created-event-details/${item?._id}`}
                            className="text-dark"
                          >
                            <h6 className="mb-0 nameAddressEllip">
                              {detail?.name}
                            </h6>
                            <p className="mb-0 nameAddressEllip">
                              {detail?.address ? `${detail?.address}` : "NA"}
                            </p>
                          </Link>
                          <span className="position-relative Sidetag">
                            <img src="assets/img/Sidetag.png" alt="" />
                            <span
                              className={
                                item?.fullBooking === true
                                  ? "SidetagText fullBooking"
                                  : "SidetagText"
                              }
                            >
                              {item?.fullBooking === true
                                ? "Full Booked"
                                : `Booked User: ${item?.addPeople}/${item?.max}`}
                            </span>
                          </span>
                        </div>
                        <div className="eventCreateDetail">
                          <div className="row px-1 align-items-center">
                            <div className="col-sm-12 col-md-6 col-lg-4 eventCreater vercalLIne">
                              <img
                                src={
                                  item?.createdBy?.picture?.includes("http")
                                    ? item?.createdBy?.picture
                                    : "assets/img/employe.png"
                                }
                                className="rounded-circle eventCreaterIcon"
                                alt=""
                              />
                              <span>
                                <p className="mb-0">Created by</p>
                                <p className="mb-0 fw-bold">
                                  {item?.createdBy?.firstName
                                    ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                    : "NA"}
                                </p>
                              </span>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 vercalLIne">
                              <span>
                                <p className="mb-0">Available Sports</p>
                                <p className="mb-0 fw-bold">{item?.sport}</p>
                              </span>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                              <span>
                                <p className="mb-0">Date & Time</p>
                                <p className="mb-0 fw-bold">
                                  {moment(item?.date).format("ll")}{" "}
                                </p>
                                <p className="mb-0 fw-bold">
                                  {moment(
                                    item?.slot[0]?.timeSlotStart,
                                    "hh:mm"
                                  ).format("hh:mm a")}{" "}
                                  -{" "}
                                  {moment(
                                    item?.slot.slice(-1)[0]?.timeSlotEnd,
                                    "hh:mm"
                                  ).format("hh:mm a")}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="paymentJoin">
                          <span>
                            <p className="mb-0">Payment required</p>
                            <p className="mb-0 theme-color fw-bold">
                              <i className="fa fa-inr"></i>{" "}
                              {parseFloat(item?.userPayment).toFixed(2) || "0"}
                              /-
                            </p>
                          </span>

                          {loginType === "user" ? (
                            <button
                              className={
                                item?.fullBooking === true ||
                                item?.max === 1 ||
                                item?.max === item?.addPeople
                                  ? "d-none"
                                  : "joinUsBtn"
                              }
                              onClick={() => {
                                setCreatedEventId(item?._id);
                                setminCapacity(item?.min);
                                setModalName("JoinAnExistingEvent");
                                handleShow();
                              }}
                            >
                              Join Us
                            </button>
                          ) : (
                            <button
                              className={
                                item?.fullBooking === true ||
                                item?.max === 1 ||
                                item?.max === item?.addPeople
                                  ? "d-none"
                                  : "joinUsBtn"
                              }
                              onClick={() => {
                                dispatch(ChangeouterLogin(true));
                                dispatch(
                                  ChangeurlId(`/venues-listing/detail/${id}`)
                                );
                                setModalName("LoginModal");
                                handleShow();
                              }}
                            >
                              Join Us
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="text-center">No Event Found</div>
              )}
            </div>
          </div>

          <div className="mt-5">
            <div className="view-btns">
              {token ? (
                <Link
                  to={`/venues-listing/detail/${id}/create-new-event`}
                  className="btn-view btn-accept"
                >
                  Create a new event
                </Link>
              ) : (
                <span
                  onClick={() => {
                    dispatch(ChangeouterLogin(true));
                    dispatch(
                      ChangeurlId(
                        `/venues-listing/detail/${id}/create-new-event`
                      )
                    );
                    setModalName("LoginModal");
                    handleShow();
                  }}
                  className="btn-view btn-accept cursor-pointer"
                >
                  Create a new event
                </span>
              )}

              {token ? (
                <Link
                  to={`/venues-listing/detail/${id}/full-booking`}
                  className="btn-view btn-reject"
                >
                  Full Booking
                </Link>
              ) : (
                <span
                  onClick={() => {
                    dispatch(ChangeouterLogin(true));
                    dispatch(
                      ChangeurlId(`/venues-listing/detail/${id}/full-booking`)
                    );
                    setModalName("LoginModal");
                    handleShow();
                  }}
                  className="btn-view btn-reject cursor-pointer"
                >
                  Full Booking
                </span>
              )}
            </div>
          </div>
        </div>
      </main>
      {modalName === "JoinAnExistingEvent" && createdEventId && (
        <JoinAnExistingEventModal
          show={show}
          handleShow={handleShow}
          createdEventId={createdEventId}
          minCapacity={minCapacity}
        />
      )}
      {modalName === "LoginModal" && (
        <LoginModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "LoginWithEmail" && (
        <LoginWithEmail
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "SignUpModal" && (
        <SignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpWithMobile" && (
        <SignUpWithMobileModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ForgotPassword" && (
        <ForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "OTPVerification" && (
        <OTPVerification
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "ResetPassword" && (
        <ResetPassword
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
    </>
  );
}

export default VenueDetail;
