import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import HomeHeader from "../../Components/HomeHeader";
import {
  Carouselsettings,
  CustomerSaying,
  CustomerSayingMultiItem,
  eventNews,
  eventNewsMultiItem,
  onlineSession,
  onlineSessionMultiItem,
  settings,
  settingsMuiltiItems,
  whyChooseUs,
} from "../../Utils";
import { Link, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import LoginModal from "../../Components/Modals/LoginModal";
import LoginWithEmail from "../../Components/Modals/LoginWithEmail";
import SignUpModal from "../../Components/Modals/SignUpModal";
import SignUpWithMobileModal from "../../Components/Modals/SignUpWithMobileModal";
import ForgotPasswordModal from "../../Components/Modals/ForgotPasswordModal";
import OTPVerification from "../../Components/Modals/OTPVerification";
import ResetPassword from "../../Components/Modals/ResetPassword";
import { useCallback } from "react";
import moment from "moment";
import {
  ChangeouterLogin,
  ChangeurlId,
  HomePageDataCoachesReducer,
  HomePageDataCoachesReducerWithToken,
  HomePageDataReducer,
  HomePageDataReducerWithToken,
  HomePageDataVenueReducer,
  HomePageDataVenueReducerWithToken,
} from "../slice";
import WhyChooseUsModal from "../../Components/WhyChooseUsModal";
import { UpdateFavCoachAPI, UpdateFavVenueAPI } from "../../api/setting";
import { toast } from "react-toastify";
import JoinAnExistingEventModal from "../../Components/Modals/JoinAnExistingEventModal";
import Loader from "../../Components/Loader";

function Home() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [chooseTitle, setChooseTitle] = useState("");
  const navigate = useNavigate();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [sport, setSport] = useState("");
  const [professionals, setProfessionals] = useState("");
  const [offerEvent, setOfferEvent] = useState("offers");
  const [filterCoach, setFilterCoach] = useState("");
  const [filterVenue, setFilterVenue] = useState("");
  const [minCapacity, setminCapacity] = useState("");
  const [loading, setLoading] = useState(false);

  const loginDetail = useSelector((state) => state.main.userDetail);
  const token = localStorage.getItem("token");
  const loginType = localStorage.getItem("loginType");
  const liveLat = useSelector((state) => state.main.liveCoords?.lat);
  const liveLan = useSelector((state) => state.main.liveCoords?.lng);
  const [createdEventId, setCreatedEventId] = useState("");
  const outerLogin = useSelector((state) => state.main.outerLogin);
  const urlId = useSelector((state) => state.main.urlId);

  const HomePageData = useSelector((state) => state.main.HomePageData);
  const HomePageDataCoach = useSelector(
    (state) => state.main.HomePageDataCoach
  );
  const HomePageDataVenue = useSelector(
    (state) => state.main.HomePageDataVenue
  );
  const vanuesNearMeListHome = useSelector(
    (state) => state.main.HomesliderList
  );

  const handleShow = () => {
    setShow(!show);
  };

  const getHomePage = useCallback(async () => {
    if (loginType === "user") {
      setLoading(true);
      dispatch(
        HomePageDataReducerWithToken({
          longitude: liveLan,
          latitude: liveLat,
        })
      ).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
      dispatch(
        HomePageDataReducer({
          longitude: liveLan,
          latitude: liveLat,
        })
      ).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, liveLan, liveLat, loginType]);

  const getHomePageChoach = useCallback(async () => {
    if (loginType === "user") {
      setLoading(true);
      dispatch(
        HomePageDataCoachesReducerWithToken({
          coachSport: professionals,
          longitude: liveLan,
          latitude: liveLat,
          isfev: filterCoach,
        })
      ).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
      dispatch(
        HomePageDataCoachesReducer({
          coachSport: professionals,
          longitude: liveLan,
          latitude: liveLat,
          isfev: filterCoach,
        })
      ).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, professionals, loginType, liveLan, liveLat, filterCoach]);

  const getHomePageVenue = useCallback(async () => {
    if (loginType === "user") {
      dispatch(
        HomePageDataVenueReducerWithToken({
          venueSport: sport,
          longitude: liveLan,
          latitude: liveLat,
          isFev: filterVenue,
        })
      );
    } else {
      dispatch(
        HomePageDataVenueReducer({
          venueSport: sport,
          longitude: liveLan,
          latitude: liveLat,
          isFev: filterVenue,
        })
      );
    }
  }, [dispatch, sport, loginType, liveLan, liveLat, filterVenue]);

  const UpdateFavCoach = (data) => {
    //console.log(data);
    UpdateFavCoachAPI(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getHomePageChoach();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  const UpdateFavVenue = (data) => {
    //console.log(data);
    UpdateFavVenueAPI(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getHomePageVenue();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  useEffect(() => {
    getHomePage();
  }, [getHomePage]);

  useEffect(() => {
    getHomePageChoach();
  }, [getHomePageChoach]);

  useEffect(() => {
    getHomePageVenue();
  }, [getHomePageVenue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SettingsDotsMuilti = {
    ...settingsMuiltiItems,
    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>;
    },
    beforeChange: (prev, next) => {
      // here to detect slide change
      //this.setState({ currentSlideIndex: next });
      setCurrentSlideIndex(next);
    },
    customPaging: (pagi, i) => {
      const style = {
        width: 10,
        height: 10,
        backgroundColor: "#ddd",
        display: "block",
        borderRadius: "50%",
      };
      const activeStyle = {
        width: 10,
        height: 10,
        backgroundColor: "#eb0909",
        display: "block",
        borderRadius: "50%",
      };
      return (
        <a
          className="slick-dot"
          style={pagi === currentSlideIndex ? activeStyle : style}
        />
      );
    },
  };

  return (
    <>
      <div className="bgImageset" id="home">
        <HomeHeader handleShow={handleShow} setModalName={setModalName} />

        <header>
          <Slider {...Carouselsettings}>
            {HomePageData?.webBanners?.map((item, i) => (
              <div key={i}>
                <img
                  src={
                    item?.image?.includes("http")
                      ? item?.image
                      : "assets/img/dummyImage.jpg"
                  }
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            ))}
            {/* <div>
              <img
                src="assets/img/FirstBanner.jpg"
                className="img-fluid w-100"
                alt=""
              />
            </div>
            <div>
              <img
                src="assets/img/UserHomeBanner2.png"
                className="img-fluid w-100"
                alt=""
              />
            </div>
            <div>
              <img
                src="assets/img/UserHomeBanner.png"
                className="img-fluid w-100"
                alt=""
              />
            </div> */}
          </Slider>
        </header>
      </div>

      <section className="mt-4 pt-4" id="ourprograms">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3 className="sec-title">Training by Professionals</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3  position-relative">
              <ul
                className="nav nav-pills mb-3 justify-content-start justify-content-md-center tabsnew lineAlign"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      professionals === "cricket"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setProfessionals(
                        professionals === ""
                          ? "cricket"
                          : professionals === "football"
                          ? "cricket"
                          : professionals === "basketball"
                          ? "cricket"
                          : professionals === "table tennis"
                          ? "cricket"
                          : professionals === "badminton"
                          ? "cricket"
                          :professionals === "swimming"
                          ? "cricket"
                          : professionals === "lawn tennis"
                          ?  "cricket" : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        professionals === "cricket"
                          ? "assets/img/cricket_ball_icon_red.png"
                          : "assets/img/cricket_ball_icon.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    cricket
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      professionals === "football"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setProfessionals(
                        professionals === "cricket"
                          ? "football"
                          : professionals === ""
                          ? "football"
                          : professionals === "basketball"
                          ? "football"
                          : professionals === "table tennis"
                          ? "football"
                          : professionals === "badminton"
                          ? "football"
                          : professionals === "swimming"
                          ? "football"
                          : professionals === "lawn tennis"
                          ?  "football" :""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        professionals === "football"
                          ? "assets/img/Footall_red.png"
                          : "assets/img/Footall.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    football
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      professionals === "swimming"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setProfessionals(
                        professionals === "cricket"
                          ? "swimming"
                          : professionals === "football"
                          ? "swimming"
                          : professionals === ""
                          ? "swimming"
                          : professionals === "table tennis"
                          ? "swimming"
                          : professionals === "badminton"
                          ? "swimming"
                          :professionals === "basketball"
                          ? "swimming"
                          : professionals === "lawn tennis"
                          ? "swimming"
                          :  ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        professionals === "swimming"
                          ? "assets/img/swimming_red.png"
                          : "assets/img/swimming.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    swimming
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      professionals === "lawn tennis"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setProfessionals(
                        professionals === "cricket"
                          ? "lawn tennis"
                          : professionals === "football"
                          ? "lawn tennis"
                          : professionals === "basketball"
                          ? "lawn tennis"
                          : professionals === ""
                          ? "lawn tennis"
                          : professionals === "badminton"
                          ? "lawn tennis"
                          :  professionals === "swimming"
                          ? "lawn tennis"
                          : professionals === "table tennis"
                          ? "lawn tennis"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        professionals === "lawn tennis"
                        ? "assets/img/lawntennis_red.png"
                        : "assets/img/lawntennis.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    lawn tennis
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={
                      professionals === "basketball"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setProfessionals(
                        professionals === "cricket"
                          ? "basketball"
                          : professionals === "football"
                          ? "basketball"
                          : professionals === ""
                          ? "basketball"
                          : professionals === "table tennis"
                          ? "basketball"
                          : professionals === "badminton"
                          ? "basketball"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        professionals === "basketball"
                          ? "assets/img/Basketball_red.png"
                          : "assets/img/Basketball.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    basketball
                  </button>
                </li> */}
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={
                      professionals === "table tennis"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setProfessionals(
                        professionals === "cricket"
                          ? "table tennis"
                          : professionals === "football"
                          ? "table tennis"
                          : professionals === "basketball"
                          ? "table tennis"
                          : professionals === ""
                          ? "table tennis"
                          : professionals === "badminton"
                          ? "table tennis"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        professionals === "table tennis"
                          ? "assets/img/table_tennis_red.png"
                          : "assets/img/table_tennis.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    table Tennis
                  </button>
                </li> */}
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      professionals === "badminton"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setProfessionals(
                        professionals === "cricket"
                          ? "badminton"
                          : professionals === "football"
                          ? "badminton"
                          : professionals === "basketball"
                          ? "badminton"
                          : professionals === "table tennis"
                          ? "badminton"
                          : professionals === ""
                          ? "badminton"
                          : professionals === "swimming"
                          ? "badminton"
                          : professionals === "lawn tennis"
                          ? "badminton"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        professionals === "badminton"
                          ? "assets/img/badminton_red.png"
                          : "assets/img/badminton.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    badminton
                  </button>
                </li>
              </ul>
              <div
                className={loginType === "user" ? "HomefilterDrop" : "d-none"}
              >
                <div
                  className={
                    filterCoach === ""
                      ? "position-relative filterDrop"
                      : "position-relative filterDrop FilterFill"
                  }
                >
                  <img
                    src={
                      filterCoach === ""
                        ? "assets/img/filter.png"
                        : "assets/img/filter_white.png"
                    }
                    alt=""
                  />

                  <span
                    className={
                      filterCoach === ""
                        ? "filterText"
                        : "filterText text-white"
                    }
                  >
                    Filter
                  </span>

                  <div className="filterDropSelct">
                    <span className="me-2 fw-bold">Filter By:</span>
                    <select
                      onChange={(e) => {
                        setFilterCoach(e.target.value);
                      }}
                      value={filterCoach}
                    >
                      <option value="">Select</option>
                      <option value="1">My favourite</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div id="dynamicDots" className="position-relative">
                  {professionals === "" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.sports?.[0]?.coverImage?.includes(
                                            "http"
                                          )
                                            ? item?.sports?.[0]?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports?.[0]?._id
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.sports?.[0]?.coverImage?.includes(
                                            "http"
                                          )
                                            ? item?.sports?.[0]?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}

                                  <div className="venueBoxIcons">
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </div>
                                  <img
                                    src={
                                      item?.isFev === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavCoach({
                                        coachId: item?._id,
                                        isfev:
                                          item?.isFev === true ? false : true,
                                      });
                                    }}
                                  />
                                </div>

                                <div className="text-start mt-3">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark ellips1Line w-100 mb-3">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport || "NA";
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports?.[0]?._id
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark ellips1Line w-100 mb-3">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport || "NA";
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.sports?.[0]?.coverImage?.includes(
                                            "http"
                                          )
                                            ? item?.sports?.[0]?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports[0]?._id
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.sports?.[0]?.coverImage?.includes(
                                            "http"
                                          )
                                            ? item?.sports?.[0]?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                  <div className="venueBoxIcons">
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </div>
                                  <img
                                    src={
                                      item?.isFev === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavCoach({
                                        coachId: item?._id,
                                        isfev:
                                          item?.isFev === true ? false : true,
                                      });
                                    }}
                                  />
                                </div>

                                <div className="text-start mt-3">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark ellips1Line w-100 mb-3">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport || "NA";
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports?.[0]?._id
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark ellips1Line w-100 mb-3">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport || "NA";
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : professionals === "cricket" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.coverImage?.includes("http")
                                            ? item?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.coverImage?.includes("http")
                                            ? item?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}

                                  <div className="venueBoxIcons">
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </div>
                                  <img
                                    src={
                                      item?.isFev === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavCoach({
                                        coachId: item?._id,
                                        isfev:
                                          item?.isFev === true ? false : true,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="text-start mt-3"></div>
                                {loginType === "venue" ||
                                loginType === "coach" ? (
                                  <span
                                    className="text-dark cursor-pointer"
                                    onClick={() => {
                                      setModalName("LoginModal");
                                      handleShow();
                                    }}
                                  >
                                    <h5
                                      className="ellips1Line text-dark w-100 fw-bold"
                                      title={
                                        item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name
                                      }
                                    >
                                      {item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name}
                                    </h5>
                                    <p className="text-dark">
                                      {item?.sport || "NA"}
                                    </p>
                                  </span>
                                ) : (
                                  <Link
                                    to={`/coaching/detail/${item._id}`}
                                    className="text-dark"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "detailSport",
                                        item?.sportId
                                      );
                                    }}
                                  >
                                    <h5
                                      className="ellips1Line text-dark w-100 fw-bold"
                                      title={
                                        item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name
                                      }
                                    >
                                      {item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name}
                                    </h5>
                                    <p className="text-dark">
                                      {item?.sport || "NA"}
                                    </p>
                                  </Link>
                                )}
                                <div className="eventCreater">
                                  <img
                                    src={
                                      item?.image?.includes("http")
                                        ? item?.image
                                        : "assets/img/employe.png"
                                    }
                                    className="rounded-circle eventCreaterIcon"
                                    alt=""
                                  />
                                  <span>
                                    <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                      {item?.name || "NA"}
                                    </p>
                                  </span>
                                </div>
                                <p
                                  className="mb-0 ellips1Line w-100 text-dark"
                                  title={item?.address}
                                >
                                  {item?.address || "NA"}
                                </p>
                                <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                  <a
                                    href={`https://www.google.com/maps/place/${item?.address}`}
                                    target="_blank"
                                    className="clubKMS box-shadow"
                                  >
                                    <img
                                      src="assets/img/map_pins_icon.png"
                                      alt=""
                                      className="map_pins_icon"
                                    />
                                    {item?.dist?.calculated
                                      ? parseFloat(
                                          item?.dist?.calculated
                                        ).toFixed(2)
                                      : "0"}{" "}
                                    Km
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.coverImage?.includes("http")
                                            ? item?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.coverImage?.includes("http")
                                            ? item?.coverImage
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                  <div className="venueBoxIcons">
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </div>
                                  <img
                                    src={
                                      item?.isFev === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavCoach({
                                        coachId: item?._id,
                                        isfev:
                                          item?.isFev === true ? false : true,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="text-start mt-3"></div>
                                {loginType === "venue" ||
                                loginType === "coach" ? (
                                  <span
                                    className="text-dark cursor-pointer"
                                    onClick={() => {
                                      setModalName("LoginModal");
                                      handleShow();
                                    }}
                                  >
                                    <h5
                                      className="ellips1Line text-dark w-100 fw-bold"
                                      title={
                                        item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name
                                      }
                                    >
                                      {item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name}
                                    </h5>
                                    <p className="text-dark">
                                      {item?.sport || "NA"}
                                    </p>
                                  </span>
                                ) : (
                                  <Link
                                    to={`/coaching/detail/${item._id}`}
                                    className="text-dark"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "detailSport",
                                        item?.sportId
                                      );
                                    }}
                                  >
                                    <h5
                                      className="ellips1Line text-dark w-100 fw-bold"
                                      title={
                                        item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name
                                      }
                                    >
                                      {item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name}
                                    </h5>
                                    <p className="text-dark">
                                      {item?.sport || "NA"}
                                    </p>
                                  </Link>
                                )}
                                <div className="eventCreater">
                                  <img
                                    src={
                                      item?.image?.includes("http")
                                        ? item?.image
                                        : "assets/img/employe.png"
                                    }
                                    className="rounded-circle eventCreaterIcon"
                                    alt=""
                                  />
                                  <span>
                                    <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                      {item?.name || "NA"}
                                    </p>
                                  </span>
                                </div>
                                <p
                                  className="mb-0 ellips1Line w-100 text-dark"
                                  title={item?.address}
                                >
                                  {item?.address || "NA"}
                                </p>
                                <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                  <a
                                    href={`https://www.google.com/maps/place/${item?.address}`}
                                    target="_blank"
                                    className="clubKMS box-shadow"
                                  >
                                    <img
                                      src="assets/img/map_pins_icon.png"
                                      alt=""
                                      className="map_pins_icon"
                                    />
                                    {item?.dist?.calculated
                                      ? parseFloat(
                                          item?.dist?.calculated
                                        ).toFixed(2)
                                      : "0"}{" "}
                                    Km
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : professionals === "football" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : professionals === "basketball" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : professionals === "table tennis" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : professionals === "badminton" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : professionals === "swimming" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) :professionals === "lawn tennis" ? (
                    HomePageDataCoach?.length > 0 ? (
                      HomePageDataCoach?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataCoach?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/coaching/detail/${item._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sportId
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.coverImage?.includes("http")
                                              ? item?.coverImage
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <div className="venueBoxIcons">
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </div>
                                    <img
                                      src={
                                        item?.isFev === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavCoach({
                                          coachId: item?._id,
                                          isfev:
                                            item?.isFev === true ? false : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3"></div>
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/coaching/detail/${item._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sportId
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line text-dark w-100 fw-bold"
                                        title={
                                          item?.coachingCenterName
                                            ? item?.coachingCenterName
                                            : item?.name
                                        }
                                      >
                                        {item?.coachingCenterName
                                          ? item?.coachingCenterName
                                          : item?.name}
                                      </h5>
                                      <p className="text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <div className="eventCreater">
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/employe.png"
                                      }
                                      className="rounded-circle eventCreaterIcon"
                                      alt=""
                                    />
                                    <span>
                                      <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                        {item?.name || "NA"}
                                      </p>
                                    </span>
                                  </div>
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) :null}
                  <Link to="/coaching" className="viewAll theme-color">
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-4 pt-4" id="ourprograms">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3 className="sec-title">Venues near me</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 position-relative">
              <ul
                className="nav nav-pills mb-3 justify-content-start justify-content-md-center tabsnew lineAlign"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      sport === "cricket"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setSport(
                        sport === ""
                          ? "cricket"
                          : sport === "football"
                          ? "cricket"
                          : sport === "basketball"
                          ? "cricket"
                          : sport === "table tennis"
                          ? "cricket"
                          : sport === "badminton"
                          ? "cricket"
                          : sport === "swimming"
                          ? "cricket"
                          : sport === "lawn tennis"
                          ? "cricket"
                          :  ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        sport === "cricket"
                          ? "assets/img/cricket_ball_icon_red.png"
                          : "assets/img/cricket_ball_icon.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    cricket
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      sport === "football"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "football"
                          : sport === ""
                          ? "football"
                          : sport === "basketball"
                          ? "football"
                          : sport === "table tennis"
                          ? "football"
                          : sport === "badminton"
                          ? "football"
                          :  sport === "swimming"
                          ? "football"
                          : sport === "lawn tennis"
                          ? "football"
                          :""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        sport === "football"
                          ? "assets/img/Footall_red.png"
                          : "assets/img/Footall.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    football
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      sport === "swimming"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "swimming"
                          : sport === "football"
                          ? "swimming"
                          : sport === ""
                          ? "swimming"
                          : sport === "table tennis"
                          ? "swimming"
                          : sport === "badminton"
                          ? "swimming"
                          : sport === "basketball"
                          ? "swimming"
                          : sport === "lawn tennis"
                          ? "swimming"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        sport === "swimming"
                          ? "assets/img/swimming_red.png"
                          : "assets/img/swimming.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    swimming
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      sport === "lawn tennis"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "lawn tennis"
                          : sport === "football"
                          ? "lawn tennis"
                          : sport === "basketball"
                          ? "lawn tennis"
                          : sport === ""
                          ? "lawn tennis"
                          : sport === "badminton"
                          ? "lawn tennis"
                          : sport === "swimming"
                          ? "lawn tennis"
                          : sport === "table tennis"
                          ? "lawn tennis"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        sport === "lawn tennis"
                          ? "assets/img/lawntennis_red.png"
                          : "assets/img/lawntennis.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    lawn tennis
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={
                      sport === "basketball"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "basketball"
                          : sport === "football"
                          ? "basketball"
                          : sport === ""
                          ? "basketball"
                          : sport === "table tennis"
                          ? "basketball"
                          : sport === "badminton"
                          ? "basketball"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        sport === "basketball"
                          ? "assets/img/Basketball_red.png"
                          : "assets/img/Basketball.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    basketball
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      sport === "table tennis"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "table tennis"
                          : sport === "football"
                          ? "table tennis"
                          : sport === "basketball"
                          ? "table tennis"
                          : sport === ""
                          ? "table tennis"
                          : sport === "badminton"
                          ? "table tennis"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        sport === "table tennis"
                          ? "assets/img/table_tennis_red.png"
                          : "assets/img/table_tennis.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    table Tennis
                  </button>
                </li> */}
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      sport === "badminton"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "badminton"
                          : sport === "football"
                          ? "badminton"
                          : sport === "basketball"
                          ? "badminton"
                          : sport === "table tennis"
                          ? "badminton"
                          : sport === ""
                          ? "badminton"
                          : sport === "swimming"
                          ? "badminton"
                          : sport === "lawn tennis"
                          ? "badminton"
                          : ""
                      );
                      setCurrentSlideIndex(0);
                    }}
                  >
                    <img
                      src={
                        sport === "badminton"
                          ? "assets/img/badminton_red.png"
                          : "assets/img/badminton.png"
                      }
                      alt=""
                      className="sportTabImg"
                    />
                    badminton
                  </button>
                </li>
              </ul>
              <div
                className={loginType === "user" ? "HomefilterDrop" : "d-none"}
              >
                <div
                  className={
                    filterVenue === ""
                      ? "position-relative filterDrop"
                      : "position-relative filterDrop FilterFill"
                  }
                >
                  <img
                    src={
                      filterVenue === ""
                        ? "assets/img/filter.png"
                        : "assets/img/filter_white.png"
                    }
                    alt=""
                  />
                  <span
                    className={
                      filterVenue === ""
                        ? "filterText"
                        : "filterText text-white"
                    }
                  >
                    Filter
                  </span>
                  <div className="filterDropSelct">
                    <span className="me-2 fw-bold">Filter By:</span>
                    <select
                      onChange={(e) => {
                        setFilterVenue(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="1">My favourite</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div id="dynamicDots" className="position-relative">
                  {sport === "" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark  cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image?.[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image?.[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports?.[0]?.sport
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image?.[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image?.[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}

                                  <Link
                                    to={`/venues-listing/review/${item?._id}`}
                                    className="venueBoxIcons"
                                  >
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </Link>
                                  <img
                                    src={
                                      item?.isFevVenue === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavVenue({
                                        venueId: item?._id,
                                        isFevVenue:
                                          item?.isFevVenue === true
                                            ? false
                                            : true,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="text-start mt-3">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark  cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark ellips1Line w-100">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport;
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports?.[0]?.sport
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark ellips1Line w-100">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport;
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </Link>
                                  )}

                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark  cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image?.[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image?.[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports?.[0]?.sport
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image?.[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image?.[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}

                                  <Link
                                    to={`/venues-listing/review/${item?._id}`}
                                    className="venueBoxIcons"
                                  >
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </Link>
                                  <img
                                    src={
                                      item?.isFevVenue === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavVenue({
                                        venueId: item?._id,
                                        isFevVenue:
                                          item?.isFevVenue === true
                                            ? false
                                            : true,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="text-start mt-3">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark  cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport;
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sports?.[0]?.sport
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport;
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </Link>
                                  )}

                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : sport === "cricket" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sport
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}

                                  <Link
                                    to={`/venues-listing/review/${item?._id}`}
                                    className="venueBoxIcons"
                                  >
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </Link>
                                  <img
                                    src={
                                      item?.isFevVenue === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavVenue({
                                        venueId: item?._id,
                                        isFevVenue:
                                          item?.isFevVenue === true
                                            ? false
                                            : true,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="text-start mt-3">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport;
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sport
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue?.map((item, i) => (
                            <div key={i}>
                              <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                <div className="position-relative">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sport
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.image[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="img-fluid tabImage"
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                  <Link
                                    to={`/venues-listing/review/${item?._id}`}
                                    className="venueBoxIcons"
                                  >
                                    <span className="votesRatingBg">
                                      {item?.rating
                                        ? parseFloat(item?.rating).toFixed(1)
                                        : "0"}{" "}
                                      <img
                                        src="assets/img/star.png"
                                        alt=""
                                        className="start"
                                      />
                                    </span>{" "}
                                    {item?.review || "0"}{" "}
                                    {item?.review <= 1 ? "vote" : "votes"}
                                  </Link>
                                  <img
                                    src={
                                      item?.isFevVenue === true
                                        ? "assets/img/Favoriteselected.png"
                                        : "assets/img/Favoriteunselected.png"
                                    }
                                    alt=""
                                    className={
                                      loginType === "user"
                                        ? "homeFav"
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      UpdateFavVenue({
                                        venueId: item?._id,
                                        isFevVenue:
                                          item?.isFevVenue === true
                                            ? false
                                            : true,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="text-start mt-3">
                                  {loginType === "venue" ||
                                  loginType === "coach" ? (
                                    <span
                                      className="text-dark cursor-pointer"
                                      onClick={() => {
                                        setModalName("LoginModal");
                                        handleShow();
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark">
                                        {item?.sports?.length > 0
                                          ? item?.sports
                                              ?.map((spt) => {
                                                return spt?.sport;
                                              })
                                              ?.join(", ")
                                          : "NA"}
                                      </p>
                                    </span>
                                  ) : (
                                    <Link
                                      to={`/venues-listing/detail/${item?._id}`}
                                      className="text-dark"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "detailSport",
                                          item?.sport
                                        );
                                      }}
                                    >
                                      <h5
                                        className="ellips1Line w-100 fw-bold text-dark"
                                        title={item?.name || "NA"}
                                      >
                                        {item?.name || "NA"}
                                      </h5>
                                      <p className="mb-0 text-dark">
                                        {item?.sport || "NA"}
                                      </p>
                                    </Link>
                                  )}
                                  <p
                                    className="mb-0 ellips1Line w-100 text-dark"
                                    title={item?.address}
                                  >
                                    {item?.address || "NA"}
                                  </p>
                                  <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                    <a
                                      href={`https://www.google.com/maps/place/${item?.address}`}
                                      target="_blank"
                                      className="clubKMS box-shadow"
                                    >
                                      <img
                                        src="assets/img/map_pins_icon.png"
                                        alt=""
                                        className="map_pins_icon"
                                      />
                                      {item?.dist?.calculated
                                        ? parseFloat(
                                            item?.dist?.calculated
                                          ).toFixed(2)
                                        : "0"}{" "}
                                      Km
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : sport === "football" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : sport === "basketball" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : sport === "table tennis" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : sport === "badminton" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : sport === "swimming" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : sport === "lawn tennis" ? (
                    HomePageDataVenue?.length > 0 ? (
                      HomePageDataVenue?.length <= 2 ? (
                        <Slider {...settings}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...SettingsDotsMuilti}>
                          {HomePageDataVenue?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="bg-white p-2 text-capitalize programs-colles vanuesNearMeBox">
                                  <div className="position-relative">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.image[0]?.image?.includes(
                                              "http"
                                            )
                                              ? item?.image[0]?.image
                                              : "assets/img/dummyImage.png"
                                          }
                                          className="img-fluid tabImage"
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                    <Link
                                      to={`/venues-listing/review/${item?._id}`}
                                      className="venueBoxIcons"
                                    >
                                      <span className="votesRatingBg">
                                        {item?.rating
                                          ? parseFloat(item?.rating).toFixed(1)
                                          : "0"}{" "}
                                        <img
                                          src="assets/img/star.png"
                                          alt=""
                                          className="start"
                                        />
                                      </span>{" "}
                                      {item?.review || "0"}{" "}
                                      {item?.review <= 1 ? "vote" : "votes"}
                                    </Link>
                                    <img
                                      src={
                                        item?.isFevVenue === true
                                          ? "assets/img/Favoriteselected.png"
                                          : "assets/img/Favoriteunselected.png"
                                      }
                                      alt=""
                                      className={
                                        loginType === "user"
                                          ? "homeFav"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateFavVenue({
                                          venueId: item?._id,
                                          isFevVenue:
                                            item?.isFevVenue === true
                                              ? false
                                              : true,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="text-start mt-3">
                                    {loginType === "venue" ||
                                    loginType === "coach" ? (
                                      <span
                                        className="text-dark cursor-pointer"
                                        onClick={() => {
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sports?.length > 0
                                            ? item?.sports
                                                ?.map((spt) => {
                                                  return spt?.sport;
                                                })
                                                ?.join(", ")
                                            : "NA"}
                                        </p>
                                      </span>
                                    ) : (
                                      <Link
                                        to={`/venues-listing/detail/${item?._id}`}
                                        className="text-dark"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "detailSport",
                                            item?.sport
                                          );
                                        }}
                                      >
                                        <h5
                                          className="ellips1Line w-100 fw-bold text-dark"
                                          title={item?.name || "NA"}
                                        >
                                          {item?.name || "NA"}
                                        </h5>
                                        <p className="mb-0 text-dark">
                                          {item?.sport || "NA"}
                                        </p>
                                      </Link>
                                    )}
                                    <p
                                      className="mb-0 ellips1Line w-100 text-dark"
                                      title={item?.address}
                                    >
                                      {item?.address || "NA"}
                                    </p>
                                    <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.address}`}
                                        target="_blank"
                                        className="clubKMS box-shadow"
                                      >
                                        <img
                                          src="assets/img/map_pins_icon.png"
                                          alt=""
                                          className="map_pins_icon"
                                        />
                                        {item?.dist?.calculated
                                          ? parseFloat(
                                              item?.dist?.calculated
                                            ).toFixed(2)
                                          : "0"}{" "}
                                        Km
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      <div className="text-center">No Venue Found</div>
                    )
                  ) : null}
                  <Link to="/venues-listing" className="viewAll theme-color">
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-4" id="Offerevents">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3 className="sec-title">Offers and Sport Events</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <ul
                className="nav nav-pills mb-3 justify-content-center tabsnew lineAlign"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      offerEvent === "offers"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setOfferEvent("offers");
                    }}
                  >
                    offers
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      offerEvent === "events"
                        ? "nav-link active text-capitalize d-grid"
                        : "nav-link text-capitalize d-grid"
                    }
                    type="button"
                    onClick={() => {
                      setOfferEvent("events");
                    }}
                  >
                    events
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div id="dynamicDots" className="position-relative">
                  {offerEvent === "offers" ? (
                    HomePageData?.offers?.length > 0 ? (
                      HomePageData?.offers?.length <= 2 ? (
                        <Slider {...eventNews}>
                          {/* <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/OfferNew.jpeg"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/offerImage1.png"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/offerImage2.png"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/offerImage3.png"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div> */}
                          {HomePageData?.offers?.map((item, i) => (
                            <div key={i}>
                              <div className="">
                                <div className="eventGridFlex">
                                  <img
                                    src={
                                      item?.image.includes("http")
                                        ? item?.image
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid eventTabImg"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <Slider {...eventNewsMultiItem}>
                          {/* <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/OfferNew.jpeg"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/offerImage1.png"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/offerImage2.png"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="">
                              <div className="eventGridFlex">
                                <img
                                  src="assets/img/offerImage3.png"
                                  className="img-fluid eventTabImg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div> */}
                          {HomePageData?.offers?.map((item, i) => (
                            <div key={i}>
                              <div className="">
                                <div className="eventGridFlex">
                                  <img
                                    src={
                                      item?.image?.includes("http")
                                        ? item?.image
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid eventTabImg"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      )
                    ) : (
                      "No Offer Found"
                    )
                  ) : offerEvent === "events" ? (
                    HomePageData?.eventBooking?.length > 0 ? (
                      HomePageData?.eventBooking?.length <= 2 ? (
                        <Slider {...eventNews}>
                          {HomePageData?.eventBooking?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="AlreadyEvent">
                                  <div className="AlreadyEventBox">
                                    <img
                                      src={
                                        item?.venue?.image[0]?.image?.includes(
                                          "http"
                                        )
                                          ? item?.venue?.image[0]?.image
                                          : "assets/img/dummyImage.png"
                                      }
                                      alt=""
                                      className="eventImg"
                                    />

                                    <Link
                                      to={`/already-created-event-details/${item?._id}`}
                                      className="text-dark"
                                    >
                                      <h6 className="mb-0 nameAddressEllip">
                                        {item?.venue?.name || "NA"}
                                      </h6>
                                      <p className="mb-0 nameAddressEllip">
                                        {item?.venue?.address
                                          ? `${item?.venue?.address}`
                                          : "NA"}
                                      </p>
                                    </Link>
                                    <span className="position-relative Sidetag">
                                      <img
                                        src="assets/img/Sidetag.png"
                                        alt=""
                                      />
                                      <span
                                        className={
                                          item?.fullBooking === true
                                            ? "SidetagText fullBooking"
                                            : "SidetagText"
                                        }
                                      >
                                        {item?.fullBooking === true
                                          ? "Full Booked"
                                          : `Booked User: ${item?.addPeople}/${item?.max}`}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="eventCreateDetail">
                                    <div className="row px-1 align-items-center">
                                      <div className="col-sm-12 col-md-6 col-lg-4 eventCreater vercalLIne">
                                        <img
                                          src={
                                            item?.createdBy?.picture?.includes(
                                              "http"
                                            )
                                              ? item?.createdBy?.picture
                                              : "assets/img/employe.png"
                                          }
                                          className="rounded-circle eventCreaterIcon"
                                          alt=""
                                        />
                                        <span>
                                          <p className="mb-0">Created by</p>
                                          <p className="mb-0 fw-bold">
                                            {item?.createdBy?.firstName
                                              ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                              : "NA"}
                                          </p>
                                        </span>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4 vercalLIne">
                                        <span>
                                          <p className="mb-0">
                                            Available Sports
                                          </p>
                                          <p className="mb-0 fw-bold">
                                            {item?.sport}
                                          </p>
                                        </span>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <span>
                                          <p className="mb-0">Date & Time</p>
                                          <p className="mb-0 fw-bold">
                                            {moment(item?.date).format("ll")}{" "}
                                          </p>
                                          <p className="mb-0 fw-bold">
                                            {moment(
                                              item?.slot[0]?.timeSlotStart,
                                              "hh:mm"
                                            ).format("hh:mm a")}{" "}
                                            -{" "}
                                            {moment(
                                              item?.slot.slice(-1)[0]
                                                ?.timeSlotEnd,
                                              "hh:mm"
                                            ).format("hh:mm a")}
                                          </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="paymentJoin">
                                    <span>
                                      <p className="mb-0">Payment required</p>
                                      <p className="mb-0 theme-color fw-bold">
                                        <i className="fa fa-inr"></i>{" "}
                                        {parseFloat(item?.userPayment).toFixed(
                                          2
                                        ) || "0"}
                                        /-
                                      </p>
                                    </span>
                                    {loginType === "user" ? (
                                      <button
                                        className={
                                          item?.fullBooking === true ||
                                          item?.max === 1 ||
                                          item?.max === item?.addPeople
                                            ? "d-none"
                                            : "joinUsBtn"
                                        }
                                        onClick={() => {
                                          setCreatedEventId(item?._id);
                                          setminCapacity(item?.min);
                                          setModalName("JoinAnExistingEvent");
                                          handleShow();
                                        }}
                                      >
                                        Join Us
                                      </button>
                                    ) : (
                                      <button
                                        className={
                                          item?.fullBooking === true ||
                                          item?.max === 1 ||
                                          item?.max === item?.addPeople
                                            ? "d-none"
                                            : "joinUsBtn"
                                        }
                                        onClick={() => {
                                          dispatch(ChangeouterLogin(true));
                                          dispatch(
                                            ChangeurlId(
                                              `/already-created-event-details/${item?._id}`
                                            )
                                          );
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        Join Us
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      ) : (
                        <Slider {...eventNewsMultiItem}>
                          {HomePageData?.eventBooking?.map((item, i) => {
                            return (
                              <div key={i}>
                                <div className="AlreadyEvent">
                                  <div className="AlreadyEventBox">
                                    <img
                                      src={
                                        item?.venue?.image[0]?.image?.includes(
                                          "http"
                                        )
                                          ? item?.venue?.image[0]?.image
                                          : "assets/img/dummyImage.png"
                                      }
                                      alt=""
                                      className="eventImg"
                                    />

                                    <Link
                                      to={`/already-created-event-details/${item?._id}`}
                                      className="text-dark"
                                    >
                                      <h6 className="mb-0 nameAddressEllip">
                                        {item?.venue?.name || "NA"}
                                      </h6>
                                      <p className="mb-0 nameAddressEllip">
                                        {item?.venue?.address
                                          ? `${item?.venue?.address}`
                                          : "NA"}
                                      </p>
                                    </Link>
                                    <span className="position-relative Sidetag">
                                      <img
                                        src="assets/img/Sidetag.png"
                                        alt=""
                                      />
                                      <span
                                        className={
                                          item?.fullBooking === true
                                            ? "SidetagText fullBooking"
                                            : "SidetagText"
                                        }
                                      >
                                        {item?.fullBooking === true
                                          ? "Full Booked"
                                          : `Booked User: ${item?.addPeople}/${item?.max}`}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="eventCreateDetail">
                                    <div className="row px-1 align-items-center">
                                      <div className="col-sm-12 col-md-6 col-lg-4 eventCreater vercalLIne">
                                        <img
                                          src={
                                            item?.createdBy?.picture?.includes(
                                              "http"
                                            )
                                              ? item?.createdBy?.picture
                                              : "assets/img/employe.png"
                                          }
                                          className="rounded-circle eventCreaterIcon"
                                          alt=""
                                        />
                                        <span>
                                          <p className="mb-0">Created by</p>
                                          <p className="mb-0 fw-bold">
                                            {item?.createdBy?.firstName
                                              ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                              : "NA"}
                                          </p>
                                        </span>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4 vercalLIne">
                                        <span>
                                          <p className="mb-0">
                                            Available Sports
                                          </p>
                                          <p className="mb-0 fw-bold">
                                            {item?.sport}
                                          </p>
                                        </span>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <span>
                                          <p className="mb-0">Date & Time</p>
                                          <p className="mb-0 fw-bold">
                                            {moment(item?.date).format("ll")}{" "}
                                          </p>
                                          <p className="mb-0 fw-bold">
                                            {moment(
                                              item?.slot[0]?.timeSlotStart,
                                              "hh:mm"
                                            ).format("hh:mm a")}{" "}
                                            -{" "}
                                            {moment(
                                              item?.slot.slice(-1)[0]
                                                ?.timeSlotEnd,
                                              "hh:mm"
                                            ).format("hh:mm a")}
                                          </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="paymentJoin">
                                    <span>
                                      <p className="mb-0">Payment required</p>
                                      <p className="mb-0 theme-color fw-bold">
                                        <i className="fa fa-inr"></i>{" "}
                                        {parseFloat(item?.userPayment).toFixed(
                                          2
                                        ) || "0"}
                                        /-
                                      </p>
                                    </span>
                                    {loginType === "user" ? (
                                      <button
                                        className={
                                          item?.fullBooking === true ||
                                          item?.max === 1 ||
                                          item?.max === item?.addPeople
                                            ? "d-none"
                                            : "joinUsBtn"
                                        }
                                        onClick={() => {
                                          setCreatedEventId(item?._id);
                                          setminCapacity(item?.min);
                                          setModalName("JoinAnExistingEvent");
                                          handleShow();
                                        }}
                                      >
                                        Join Us
                                      </button>
                                    ) : (
                                      <button
                                        className={
                                          item?.fullBooking === true ||
                                          item?.max === 1 ||
                                          item?.max === item?.addPeople
                                            ? "d-none"
                                            : "joinUsBtn"
                                        }
                                        onClick={() => {
                                          dispatch(ChangeouterLogin(true));
                                          dispatch(
                                            ChangeurlId(
                                              `/already-created-event-details/${item?._id}`
                                            )
                                          );
                                          setModalName("LoginModal");
                                          handleShow();
                                        }}
                                      >
                                        Join Us
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )
                    ) : (
                      "No Event Found"
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="WhyChooseUsBg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-5 text-center">
              <h3 className="sec-title text-white">Why Choose Us</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-4 DesktopViewUs">
              <Slider {...whyChooseUs}>
                <div>
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle("Sports venues at fingertips");
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon1.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          Sports venues at fingertips
                        </h5>
                        <small className="ps-3">
                          Heard anything better than a selection of sports
                          venues that work with your schedule?
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle(
                        "Assertive Coaching camps for persistence."
                      );
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon2.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          Assertive Coaching camps for persistence.
                        </h5>
                        <small className="ps-3">
                          It may be difficult to locate quality coaching
                          academies in your area. As a result,
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle("The GAMEHUNT ACADEMY");
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon3.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          The GAMEHUNT ACADEMY
                        </h5>
                        <small className="ps-3">
                          The Gamehunt Academy is a one-stop shop for schools
                          and students seeking sports and life skills.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle("Events & Tournaments");
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon4.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          Events & Tournaments
                        </h5>
                        <small className="ps-3">
                          The Gamehunt is d edicated to providing numerous
                          opportunities for our young athletes to develop and
                          showcase their skills at a competitive level.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mobirlViewUs">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle("Sports venues at fingertips");
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon1.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          Sports venues at fingertips
                        </h5>
                        <small className="ps-3">
                          Heard anything better than a selection of sports
                          venues that work with your schedule?
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle(
                        "Assertive Coaching camps for persistence."
                      );
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon2.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          Assertive Coaching camps for persistence.
                        </h5>
                        <small className="ps-3">
                          It may be difficult to locate quality coaching
                          academies in your area. As a result,
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle("The GAMEHUNT ACADEMY");
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon3.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          The GAMEHUNT ACADEMY
                        </h5>
                        <small className="ps-3">
                          The Gamehunt Academy is a one-stop shop for schools
                          and students seeking sports and life skills.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-3">
                  <div
                    className="text-center cursor-pointer whyChooseBox"
                    onClick={() => {
                      setChooseTitle("Events & Tournaments");
                      handleShow();
                    }}
                  >
                    <div className="eventGridFlex">
                      <img
                        src="assets/img/whychoose_icon4.png"
                        className="img-fluid ChooseUsIcon"
                        alt=""
                      />
                      <div className="text-white mt-4">
                        <h5 className="text-capitalize">
                          Events & Tournaments
                        </h5>
                        <small className="ps-3">
                          The Gamehunt is dedicated to providing numerous
                          opportunities for our young athletes to develop and
                          showcase their skills at a competitive level.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-4 pt-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-2 mb-md-5 text-center">
              <h3 className="sec-title">Online Session</h3>
            </div>
          </div>
          <div className="row">
            {loading ? (
              <Loader colorChange="colorChange" />
            ) : (
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                {HomePageData?.onlineSession?.length > 0 ? (
                  HomePageData?.onlineSession?.length <= 2 ? (
                    <Slider {...onlineSession}>
                      {HomePageData?.onlineSession?.map((item, i) => (
                        <div key={i}>
                          <a
                            href={
                              item?.link?.includes("http") ? item?.link : "#"
                            }
                            target="_blank"
                            className="text-dark"
                          >
                            <div className="">
                              <div className="eventGridFlex position-relative">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/dummyImage.png"
                                  }
                                  className="img-fluid sessionImage"
                                  alt=""
                                />
                                <img
                                  src="assets/img/video_youtube_icon.png"
                                  className="img-fluid video_youtube_icon"
                                  alt=""
                                />
                              </div>
                              <h5 className="text-capitalize mt-3">
                                {item?.name || "NA"}
                              </h5>
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <Slider {...onlineSessionMultiItem}>
                      {HomePageData?.onlineSession?.map((item, i) => (
                        <div key={i}>
                          <div className="">
                            <div className="eventGridFlex position-relative">
                              {item?.link?.includes("youtube.com/embed") ? (
                                <iframe
                                  src={item?.link}
                                  title="Use this three-letter word to Stop Trash-Talking to yourself! - Coach Amreen"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              ) : (
                                <>
                                  <a
                                    href={
                                      item?.link?.includes("http")
                                        ? item?.link
                                        : "#"
                                    }
                                    target="_blank"
                                    className="text-dark"
                                  >
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/dummyImage.png"
                                      }
                                      className="img-fluid sessionImage"
                                      alt=""
                                    />
                                    <img
                                      src="assets/img/video_youtube_icon.png"
                                      className="img-fluid video_youtube_icon"
                                      alt=""
                                    />
                                  </a>
                                </>
                              )}
                            </div>
                            {/* <h5 className="text-capitalize mt-3">
                              {item?.name || "NA"}
                            </h5> */}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  )
                ) : (
                  <div className="text-center">No Sessoin Found</div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="mt-4 pt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 mb-5">
              <h3 className="sec-title Whatour">
                What our customer are saying
                <p className="quetePng">
                  <img src="assets/img/quete.png" className="mt-3" alt="" />
                </p>
              </h3>
            </div>
            <div className="col-sm-12 col-md-9 col-lg-9 mb-0 mb-md-5 pe-0 quetePngMobileFlex">
              <img
                src="assets/img/quete.png"
                className="img-fluid quetePngMobile"
                alt=""
              />
              <div className="customersayingBg">
                {vanuesNearMeListHome?.length > 0 ? (
                  vanuesNearMeListHome?.length <= 2 ? (
                    <Slider {...CustomerSaying}>
                      {vanuesNearMeListHome?.map((item, i) => (
                        <div key={i}>
                          <a
                            href={item?.url}
                            target="_blank"
                            className="text-dark"
                          >
                            <div className="">
                              <div className="eventGridFlex">
                                <video>
                                  <source
                                    src="https://www.youtube.com/watch?v=u_dbhrN_1Xw"
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                              <h5 className="text-capitalize">
                                basketball coaching
                              </h5>
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <Slider {...CustomerSayingMultiItem}>
                      <div>
                        <div className="customerSaingBox">
                          <div className="eventGridFlex">
                            <img
                              src="assets/img/employe.png"
                              className="img-fluid coachImgCenetr"
                              alt=""
                            />
                          </div>
                          <h5 className="text-uppercase mt-1">
                            Arpit Narvekar
                          </h5>
                          {/* <h6 className="text-capitalize mt-1">@dsfefewf</h6> */}
                          <p className="mb-0 mb-md-2">
                            <img
                              src="assets/img/quete_red.png"
                              width="20px"
                              alt=""
                            />
                          </p>
                          <p>
                            A step in the right direction, a much needed
                            platform with a lot of transparency and great
                            expertise to get our children ready to become the
                            next big Athletes and sports person.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="customerSaingBox">
                          <div className="eventGridFlex">
                            <img
                              src="assets/img/employe.png"
                              className="img-fluid coachImgCenetr"
                              alt=""
                            />
                          </div>
                          <h5 className="text-uppercase mt-1">Sandeep Patil</h5>
                          {/* <h6 className="text-capitalize mt-1">@dsfefewf</h6> */}
                          <p className="mb-0 mb-md-2">
                            <img
                              src="assets/img/quete_red.png"
                              width="20px"
                              alt=""
                            />
                          </p>
                          <p>
                            There was a need for such a platform that helps my
                            child grow physically and makes him mentally strong.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="customerSaingBox">
                          <div className="eventGridFlex">
                            <img
                              src="assets/img/employe.png"
                              className="img-fluid coachImgCenetr"
                              alt=""
                            />
                          </div>
                          <h5 className="text-uppercase mt-1">Tamrika Tyagi</h5>
                          {/* <h6 className="text-capitalize mt-1">@dsfefewf</h6> */}
                          <p className="mb-0 mb-md-2">
                            <img
                              src="assets/img/quete_red.png"
                              width="20px"
                              alt=""
                            />
                          </p>
                          <p>
                            Gamehunt Academy has taken away the worry of our
                            child's security when he goes for his training
                            outside school, very reliable and trustworthy.
                          </p>
                        </div>
                      </div>
                      {/* {vanuesNearMeListHome?.map((item, i) => (
                        <div key={i}>
                          <div className="customerSaingBox">
                            <div className="eventGridFlex">
                              <img
                                src="assets/img/employe.png"
                                className="img-fluid coachImgCenetr"
                                alt=""
                              />
                            </div>
                            <h5 className="text-uppercase mt-1">Arpit Narvekar</h5>
                            {/* <h6 className="text-capitalize mt-1">@dsfefewf</h6> */}
                      {/* <p className="mb-0 mb-md-2">
                              <img
                                src="assets/img/quete_red.png"
                                width="20px"
                                alt=""
                              />
                            </p>
                            <p>
                            A step in the right direction, a much needed
                                platform with a lot of transparency and great
                                expertise to get our children ready to become
                                the next big Athletes and sports person.
                            </p>
                          </div>
                        </div>
                      ))}  */}
                    </Slider>
                  )
                ) : (
                  "No Data Found"
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="CounterBG">
        <div className="container">
          <div className="">
            <div className="counterGrid text-center align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <img src="assets/img/Countusers.png" alt="" className="" />
                <div className="gapFlex">
                  <h2 className="mb-0 fw-bold">
                    <CountUp
                      start={0}
                      //end={HomePageData?.user}
                      end={2500}
                      delay={5}
                      enableScrollSpy
                      scrollSpyDelay={1000}
                    />
                  </h2>
                  <h6>
                    <span className="numOfCounter">Users</span>
                  </h6>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <img src="assets/img/Countvenues.png" alt="" className="" />
                <div className="gapFlex">
                  <h2 className="mb-0 fw-bold">
                    <CountUp
                      start={0}
                      //end={HomePageData?.venue}
                      end={489}
                      delay={5}
                      enableScrollSpy
                      scrollSpyDelay={1000}
                    />
                  </h2>
                  <h6>
                    <span className="numOfCounter">Venues</span>
                  </h6>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <img src="assets/img/Countcoaches.png" alt="" className="" />
                <div className="gapFlex">
                  <h2 className="mb-0 fw-bold">
                    <CountUp
                      start={0}
                      //end={HomePageData?.coaches}
                      end={412}
                      delay={5}
                      enableScrollSpy
                      scrollSpyDelay={1000}
                    />
                  </h2>
                  <h6>
                    <span className="numOfCounter">Coaches </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalName === "LoginModal" && (
        <LoginModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "LoginWithEmail" && (
        <LoginWithEmail
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "SignUpModal" && (
        <SignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpWithMobile" && (
        <SignUpWithMobileModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ForgotPassword" && (
        <ForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "OTPVerification" && (
        <OTPVerification
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "ResetPassword" && (
        <ResetPassword
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {chooseTitle && (
        <WhyChooseUsModal
          show={show}
          handleShow={handleShow}
          chooseTitle={chooseTitle}
        />
      )}
      {modalName === "JoinAnExistingEvent" && createdEventId && (
        <JoinAnExistingEventModal
          show={show}
          handleShow={handleShow}
          createdEventId={createdEventId}
          minCapacity={minCapacity}
        />
      )}
    </>
  );
}

export default Home;
