import React from "react";

function CoachTermsServices() {
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <img
                  src="assets/img/terms&condition.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <h3>Terms & Conditions</h3>
                <p>
                  Every references to "you", “user/s” or "your", as applicable,
                  indicates the person/s that accesses, uses, and/or
                  participates in the GAMEHUNT Platform in any manner of
                  whatsoever.
                </p>
                <p>
                  The Website and App of GAMEHUNT, either directly or via
                  licencees assigned by us, are jointly or severally referred to
                  as the "Platform".
                </p>
                <p>
                  The absolute Owner and Operator of this website is GAMEHUNT.
                  Any kind of use of this Website by you will ever mean your
                  irrefutable Agreement/Consent to the Terms of use stated
                  herein. GAMEHUNT urges you to please read these Terms of use
                  carefully preceding the use of this Website.
                </p>
                <p>
                  All the Terms and Conditions regulating the use of the
                  services available on the platform of GAMEHUNT constitute a
                  legally binding Agreement between GAMEHUNT and the USER. The
                  Terms and Conditions are deemed to Include Privacy Policy and
                  Cookie Policy, and are collectively known as the “Terms”. By
                  using the website of GAMEHUNT, you consent to the processing
                  of your data in accordance with these policies. As you use
                  this website, you forthwith agree to be bound by these terms.
                </p>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <p>
                  GAMEHUNT reserves the right to amend/alter/modify these terms
                  of use wholly or any part thereof from time to time and any
                  changes thereof will be duly posted on the website/s. Your
                  continued use of the website/s will ever constitute your
                  acceptance of, and agreement to, any changes. Specific
                  services that GAMEHUNT offers, or makes available to you, may
                  also be governed by additional or alternative terms and
                  conditions with us, or other third parties.
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>
                  YOU AGREE TO BE BOUND BY ALL THE TERMS & CONDITIONS APPEARING
                  HEREINAFTER BELOW:
                </h3>
                <ul>
                  <li>
                    <h5>OTHER WEBSITES LINKS APPEARING ON GAMEHUNT WEBSITE</h5>
                    <p>
                      GAMEHUNT website contains a number of links to other
                      websites and online resources that are not owned or
                      controlled by GAMEHUNT. GAMEHUNT has no control over, and
                      therefore does not takes any responsibility for, the
                      content or general practices of any of these third party
                      sites and/or services. Therefore, it is vehemently advised
                      you to read the entire terms and conditions and privacy
                      policy of any site that you visit as a result of following
                      a link that is posted on the website of GAMEHUNT.
                    </p>
                  </li>
                  <li>
                    <h5>AMENDMENTS/ALTERATIONS TO THIS AGREEMENT</h5>
                    <p>
                      GAMEHUNT reserves the right to modify/alter these terms of
                      service at any time. This is done by posting and drawing
                      attention to the updated terms on the site. Your decision
                      to continue to visit and make use of the site after such
                      changes constitutes your absolute acceptance of the new
                      terms of service.
                    </p>
                    <p>
                      Therefore, you are always urged by GAMEHUNT to check and
                      review this Agreement for such changes on an occasional
                      basis. If you do not agree to any provisions of this
                      agreement or any changes made to this agreement, you are
                      asked and advised to do not use or continue to access the
                      GAMEHUNT website.
                    </p>
                  </li>
                  <li>
                    <h5>CONDUCT OF USER</h5>
                    <p>
                      The website shall be used by the User for lawful purposes
                      only. User shall not post or transmit through website any
                      material which violates or infringes in any way upon the
                      rights of others, which is unlawful, otherwise
                      objectionable, which encourages conduct that would
                      constitute a criminal offence, give rise to civil
                      liability. Website contains copyrighted material,
                      trademarks and other proprietary information, including,
                      but not limited to text, software, photos, videos,
                      graphics, music and sound, and the entire contents of the
                      website are copyrighted as a collective work under the
                      trademark and copyright laws of India. GAMEHUNT owns a
                      copyright in the selection, coordination, arrangement and
                      enhancement of such content, as well as in the content
                      original to it. User shall not modify, publish, transmit,
                      participate in the transfer or sale, create derivative
                      works, or in any way exploit, any of the content, in whole
                      or in part. User shall not upload, post or otherwise make
                      available on website any material protected by copyright,
                      trademark or other proprietary right without the express
                      permission of the owner of the copyright, trademark or
                      other proprietary right and the burden of determining that
                      any material is not protected by copyright rests with
                      user.
                    </p>
                  </li>
                  <li>
                    <h5>SERVICES OFFERED</h5>
                    <p>
                      Offered services by GAMEHUNT may contain email services,
                      chat areas, forums, personal profile pages, photos, and/or
                      other message or communication facilities designed to
                      enable user to communicate with others, user agrees to use
                      the communication services only to post, send and receive
                      messages and material that are proper and when applicable,
                      related to the particular communication service. While
                      using the communication services, user agrees that user
                      will not use the communication services for purpose which
                      violate the legal rights of others; download any file
                      posted by another user of a communication service that
                      user knows, or reasonably should know, cannot be legally
                      reproduced, displayed, performed, and/or distributed.
                      GAMEHUNT reserves the right to review materials posted to
                      the communication services and to remove any material in
                      its sole discretion and further to terminate user’s access
                      to any or all of the communication services at any time,
                      without notice, for any reason whatsoever. User are
                      advised to use caution when giving out any personally
                      identifiable information in any communication services.
                      GAMEHUNT does not control or endorse the content, messages
                      or information found in any communication services, and
                      therefore, GAMEHUNT specifically disclaims any liability
                      with regard to the communication services and any actions
                      resulting from user’s participation in any communication
                      services. Managers and hosts are not authorized GAMEHUNT
                      spokespersons, and their views do not necessarily reflect
                      those of GAMEHUNT.
                    </p>
                  </li>
                  <li>
                    <h5>
                      Apart from the above GAMEHUNT at its sole discretion will:
                    </h5>
                    <ul>
                      <li>
                        Understanding the Users' requirements, provide users
                        with customer support and accordingly respond to User's
                        queries
                      </li>
                      <li>Diagnose technical faults;</li>
                      <li>
                        Allow Users to participate in any features offered
                        through the Services;
                      </li>
                      <li>
                        Affiliate marketing and analytics for monetization and
                        processing of statistics for advertising;
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>PAYMENTS</h5>
                    <ul>
                      <li>
                        Any payments made through the GAMEHUNT Platform will be
                        non-refundable in all manners of whatsoever
                      </li>
                      <li>
                        In case at the time of the User availing any products
                        services, events etc., where the GAMEHUNT amends, alters
                        or withdraws any product, service, venue etc., and the
                        same is not updated on the platform, then GAMEHUNT shall
                        make the replacement of such products, services, events
                        etc., with an equivalent offering;
                      </li>
                      <li>
                        All the credentials provided to You from GAMEHUNT shall
                        be kept confidential by You at all times and same shall
                        be never disclosed to any third party except to
                        GAMEHUNT, however GAMEHUNT reserves the right to disable
                        any user credential at its sole discretion at any point
                        of time if the user fails to comply with the terms of
                        GAMEHUNT.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>AREA LIMITS OF SERVICES PROVIDED BY GAMEHUNT</h5>
                    <ul>
                      <li>
                        Our Platform can be used to avail services within
                        particular cities of India as selected by GAMEHUNT;
                      </li>
                      <li>
                        Such services shall be provided to the users who are
                        registered with GAMEHUNT;
                      </li>
                      <li>
                        In case the GAMEHUNT platform is used by users from out
                        of India then the same shall be at entire risk of such
                        users and further we make no representation that the
                        GAMEHUNT platform can be availed or suitable for use of
                        beyond selected areas of GAMEHUNT;
                      </li>
                      <li>
                        Any user choose to access GAMEHUNT from other then the
                        areas as selected by GAMEHUNT then the consequences
                        arising thereof shall be absolutely liable upon the user
                        and it shall be the responsibility of user to comply
                        with the applicable laws & regulations thereof and that
                        GAMEHUNT do not takes any liability of whatsoever nature
                        for the same.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>FURTHER COVENANTS BY USER</h3>
                <ul>
                  <li>
                    <h5>That the user covenants with GAMEHUNT that:</h5>
                    <ul>
                      <li>
                        Our Services does not include the provision of a
                        computer or mobile device or other necessary equipment
                        to access it and You is also aware that to access our
                        Platform, internet connectivity and telecommunication
                        links are absolutely necessary and all such costs of
                        computer or mobile device or other necessary equipment
                        as well as internet connectivity and telecommunication
                        charges shall be absolutely borne by you and GAMEHUNT is
                        at all not responsible for same in any manner.
                      </li>
                      <li>
                        You also agreed, confirmed and granted all permissions
                        to receive promotional/transactional SMS and e-mails
                        from GAMEHUNT or allied partners.
                      </li>
                      <li>
                        Whatever information submitted by You including
                        registration information, is truthful, lawful and
                        accurate and that you agree to maintain the accuracy of
                        such information;
                      </li>
                      <li>
                        Use of the GAMEHUNT Platform by You shall be solely for
                        your personal use and you shall not authorize others to
                        use your account, including your profile or email
                        address and that you are solely responsible for all
                        content published or displayed through your account,
                        including any email messages, and your interactions with
                        other Users and you shall abide by all applicable local,
                        state, national and foreign laws, treaties and
                        regulations, including those related to data privacy,
                        international communications and the transmission of
                        technical or personal data.
                      </li>
                      <li>
                        Every necessary licenses, consents, permissions and
                        rights are owned by you and there is no need for any
                        payment or permission or authorization required from any
                        other party or entity to use, distribute or otherwise
                        exploit in all manners permitted by these Terms of Use
                        and Privacy Policy, all trademarks, copyrights, patents,
                        trade secrets, privacy and publicity rights and / or
                        other proprietary rights contained in any content that
                        you submit, post, upload, distribute or otherwise
                        transmit or make available.
                      </li>
                      <li>
                        Further You indemnify GAMEHUNT and/or any of its
                        directors, shareholders, officers and representatives
                        from any cost, damage, liability or other consequence of
                        any of the actions of the Users of the GAMEHUNT and
                        specifically waive any claims that you may have in this
                        behalf under any applicable laws of India. Without
                        prejudice to this, you agree that the limitations and
                        disclaimers set out in these Terms of Use will also
                        protect the officers, employees, agents, subsidiaries,
                        successors, assigns and sub-contractors of GAMEHUNT.
                      </li>
                      <li>
                        Despite of all its reasonable efforts, GAMEHUNT cannot
                        take responsibility or control the information provided
                        by other Users which is made available on the GAMEHUNT
                        Platform. In any case you may find other User's
                        information to be offensive, harmful, inconsistent,
                        inaccurate, or deceptive then in such case you are
                        suggested to use caution and practice safe trading when
                        using the GAMEHUNT Platform.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>SECURITY AND ACCURACY PROVIDED BY GAMEHUNT</h3>
                <ul>
                  <li>
                    We ever work hard to make the GAMEHUNT Platform available
                    24/7. However, we do not undertake that access to the
                    GAMEHUNT Platform will be uninterrupted, timely, error free,
                    free of viruses or other harmful components or that such
                    defects will be corrected;
                  </li>
                  <li>
                    We do not represent or confirm that the information
                    available on the GAMEHUNT Platform will be correct, accurate
                    or otherwise reliable;
                  </li>
                  <li>
                    We do not assure that the GAMEHUNT Platform will be
                    compatible with all hardware and software which you may use.
                    We shall not be liable for damage to, or viruses or other
                    code that may affect, any equipment (including but not
                    limited to your mobile device), software, data or other
                    property as a result of your download, installation, access
                    to or use of the GAMEHUNT Platform or your obtaining any
                    material from, or as a result of using the GAMEHUNT
                    Platform. We shall also not be liable for the actions of
                    third parties.
                  </li>
                </ul>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>ADEQUATE USE OF THE WEBSITE</h3>
                <p>
                  GAMEHUNT website hosts a blog and a public forum, both of
                  which are equipped with commenting facilities. When you are
                  invited to share your opinions and questions, they must not be
                  used to distribute spam messages, post commercial
                  advertisements, or spread links to malicious or dangerous
                  websites. GAMEHUNT retains all the right to moderate any
                  comment or written content submitted to the GAMEHUNT website
                  and further to remove any content deemed to have violated the
                  GAMEHUNT policies.
                </p>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>AGREED BY YOU TO NOT TO:</h3>
                <ul>
                  <li>
                    Transmit any material designed to interrupt, damage, destroy
                    or limit the functionality of GAMEHUNT websites;
                  </li>
                  <li>
                    Use any automated software/s to view the websites without
                    consent of GAMEHUNT and to only access the websites
                    manually;
                  </li>
                  <li>
                    Use the websites other than for your own personal use;
                  </li>
                  <li>
                    Copy the data of GAMEHUNT for any purposes of whatsoever;
                  </li>
                  <li>
                    Use the websites in any manner that is illegal, immoral or
                    harmful to GAMEHUNT (including to commit or facilitate the
                    commitment of any fraud against GAMEHUNT);
                  </li>
                  <li>
                    Use the websites in breach of any policy or other notice on
                    the websites;
                  </li>
                  <li>
                    Remove or alter any copyright notices that appear on the
                    websites;
                  </li>
                  <li>
                    Publish any material that may encourage a breach of any
                    relevant laws or regulations;
                  </li>
                  <li>
                    Interfere with any other user’s enjoyment of the websites;
                  </li>
                  <li>
                    Transmit materials protected by copyright without the
                    permission of the owner;
                  </li>
                  <li>
                    <p className="mb-0">
                      Conduct yourself in an offensive or abusive manner whilst
                      using the websites.
                    </p>
                    <p>
                      GAMEHUNT is entitled to suspend the services and/or
                      terminate these terms of use without notice to you if you
                      commit a breach of these terms of use, and in particular
                      these restrictions on use, which in our reasonable opinion
                      is serious enough to merit immediate termination.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>PERSONAL INFORMATION SHARED BY GAMEHUNT</h3>
                <p>
                  We do not sell, trade, or rent user’s Information to others.
                  We may share generic aggregated demographic information
                  regarding visitors and users with our business partners,
                  trusted affiliates and advertisers for the purposes outlined
                  above. However, Personal Identification Information may be
                  shared if required, with (a) our subsidiaries or affiliates
                  (b) a buyer or successor in the event of a merger,
                  divestiture, restructuring, reorganization, dissolution or
                  other sale or transfer of some or all of GAMEHUNT assets,
                  whether as a going concern or as a part of bankruptcy,
                  liquidation or similar proceeding, in which Information of
                  Users held by GAMEHUNT are among the assets transferred.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CoachTermsServices;
