import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ContactUsMessageModal({ show, handleShow }) {
  const navigate = useNavigate();
  const ContactUsSendData = useSelector(
    (state) => state.main.ContactUsSendData
  );
  //console.log(ContactUsSendData);
  return (
    <Modal show={show} onHide={handleShow} centered size="lg">
      <Modal.Body className="p-5">
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            handleShow();
            navigate("/", {
              replace: true,
            });
          }}
        >
          <img src="assets/img/Close.png" alt="" className="safaryImg" />
        </button>
        <div>
          <h5 className="text-capitalize">
            Hi,
            {/* <i>{ContactUsSendData?.name}</i> */}
          </h5>
          <p>Your message is received by our team, we will contact you soon!</p>
          <p className="mb-0">Thanks,</p>
          <p className="mb-0">Gamehunt Support</p>
          <p className="mb-0">
            <a href="mailto:play@thegamehunt.com" style={{ color: "#0d6efd" }}>
              play@thegamehunt.com
            </a>
          </p>
        </div>
        <div
          className="text-center mt-5"
          onClick={() => {
            handleShow();
            navigate("/", {
              replace: true,
            });
          }}
        >
          <button className="btn StatusBtn RedBtn text-white">Close</button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ContactUsMessageModal;
