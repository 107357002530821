import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Loader from "../../../Components/Loader";
import {
  CoachAddBankDetailReducer,
  CoachBankDetailReducer,
  CoachEditBankDetailReducer,
} from "../../CoachVenueslice";

function CoachAccountInfo() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detail = useSelector((state) => state.CoachVenueMain.CoachBankDetail);

  const getDetail = useCallback(async () => {
    dispatch(
      CoachBankDetailReducer({
        // contactusId: id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  const initialValues = {
    accountNo: detail?.accountNumber ? detail?.accountNumber : "",
    userName: detail?.name ? detail?.name : "",
    bankName: detail?.bankName ? detail?.bankName : "",
    IFSCCode: detail?.ifscCode ? detail?.ifscCode : "",
    accountType: detail?.account_type ? detail?.account_type : "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      accountNo: Yup.string().required("Required"),
      userName: Yup.string().required("Required"),
      bankName: Yup.string().required("Required"),
      IFSCCode: Yup.string().required("Required"),
      accountType: Yup.string().required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      setLoading(true);

      if (detail?._id) {
        dispatch(
          CoachEditBankDetailReducer({
            name: values.userName,
            ifscCode: values.IFSCCode.toLocaleUpperCase(),
            accountNumber: values.accountNo,
            bankName: values.bankName,
            account_type: values.accountType,
            UserBankInfoId: detail?._id,
          })
        ).then((data) => {
          setLoading(false);
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            getDetail();
            navigate("/", {
              replace: true,
            });
          } else {
            setLoading(false);
            toast.error(data.payload.message);
          }
        });
      } else {
        dispatch(
          CoachAddBankDetailReducer({
            name: values.userName,
            ifscCode: values.IFSCCode.toLocaleUpperCase(),
            accountNumber: values.accountNo,
            bankName: values.bankName,
            account_type: values.accountType,
          })
        ).then((data) => {
          setLoading(false);
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            getDetail();
            navigate("/", {
              replace: true,
            });
          } else {
            setLoading(false);
            toast.error(data.payload.message);
          }
        });
      }
    },
  });

  return (
    <main id="mainbody" className="pt-5">
      <section className="container">
        <form className="form-style formCavas" onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center position-relative">
              <h3 className="my-3 fw-bold">Bank Detail</h3>
            </div>
            <div className="col-sm-12 col-md-12 mb-4 position-relative">
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control p-3"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    name="userName"
                  />
                  {/* <img
                    src="assets/img/sports.png"
                    className="input-img"
                    alt=""
                  /> */}
                </div>
              </div>
              {formik.errors.userName && formik.touched.userName && (
                <div className="text-red"> {formik.errors.userName}</div>
              )}
            </div>
            <div className="col-sm-12 col-md-12 mb-4 position-relative">
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control p-3"
                    placeholder="Bank Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bankName}
                    name="bankName"
                  />
                  {/* <img
                    src="assets/img/bankName.png"
                    className="input-img"
                    alt=""
                  /> */}
                </div>
              </div>
              {formik.errors.bankName && formik.touched.bankName && (
                <div className="text-red"> {formik.errors.bankName}</div>
              )}
            </div>

            <div className="col-sm-12 col-md-12 mb-4 position-relative">
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="number"
                    className="form-control p-3"
                    placeholder="Account No"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountNo}
                    name="accountNo"
                    rows="5"
                  />
                  {/* <img
                    src="assets/img/accountNo.png"
                    className="input-img"
                    alt=""
                  /> */}
                </div>
              </div>
              {formik.errors.accountNo && formik.touched.accountNo && (
                <div className="text-red"> {formik.errors.accountNo}</div>
              )}
            </div>
            <div className="col-sm-12 col-md-12 mb-4 position-relative">
              <div className="form-group">
                <div className="input-container">
                  <select
                    type="text"
                    className="form-control p-3 form-select"
                    placeholder="Account Type"
                    name="accountType"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountType}
                  >
                    <option value="">Account Type</option>
                    <option value="saving">Saving Account</option>
                    <option value="current">Current Account</option>
                  </select>
                </div>
              </div>
              {formik.errors.accountType && formik.touched.accountType && (
                <div className="text-red"> {formik.errors.accountType}</div>
              )}
            </div>
            <div className="col-sm-12 col-md-12 mb-4 position-relative">
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control p-3"
                    placeholder="IFSC Code"
                    name="IFSCCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.IFSCCode}
                  />
                  {/* <img
                    src="assets/img/IFSCCode.png"
                    className="input-img"
                    alt=""
                  /> */}
                </div>
              </div>
              {formik.errors.IFSCCode && formik.touched.IFSCCode && (
                <div className="text-red"> {formik.errors.IFSCCode}</div>
              )}
            </div>

            <div className="col-sm-12 col-md-12 text-center">
              {detail?._id ? (
                <button
                  type="submit"
                  className="btn btn-submit w-50"
                  disabled={loading ? "disabled" : ""}
                >
                  {loading ? <Loader /> : "Update"}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-submit w-50"
                  disabled={loading ? "disabled" : ""}
                >
                  {loading ? <Loader /> : "Add"}
                </button>
              )}
            </div>
          </div>
        </form>
      </section>
    </main>
  );
}

export default CoachAccountInfo;
