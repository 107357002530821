import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, isObject } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CoachSignUpReducer } from "../../features/CoachVenueslice";

function CoachSignUpModal({ show, handleShow, setModalName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="xl">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div className="col-sm-12 col-md-12 col-lg-6 bgSignUpImgCoach">
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-lg-5">
                    <div className="form-style mt-3 mt-lg-5 px-4">
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                          firstName: "",
                          countryCode: "+91",
                          mobile: "",
                          city: "",
                          street: "",
                          state: "",
                          country: "",
                          pinCode: "",
                          doorNo: "",
                          confirmPassword: "",
                          acceptTermsCondition: false,
                        }}
                        validationSchema={Yup.object({
                          firstName: Yup.string()
                            .max(50, "Name is very long")
                            .required("Required"),
                          mobile: Yup.string()
                            .required("required")
                            .matches(phoneRegExp, "Phone number is not valid")
                            .min(10, "Mobile Number should be only 10 digits")
                            .max(10, "Mobile Number should be only 10 digits"),
                          email: Yup.string()
                            .email("email must be a valid format")
                            .required("Required"),
                          //street: Yup.string().required("Required"),
                          //doorNo: Yup.string().required("Required"),
                          city: Yup.string().required("Required"),
                          state: Yup.string().required("Required"),
                          country: Yup.string().required("Required"),
                          pinCode: Yup.string().required("Required"),
                          password: Yup.string().required("Required"),
                          confirmPassword: Yup.string()
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            )
                            .required("Required"),
                          acceptTermsCondition: Yup.boolean().oneOf(
                            [true],
                            "Required"
                          ),
                        })}
                        onSubmit={(values, action) => {
                          setLoading(true);
                          dispatch(
                            CoachSignUpReducer({
                              name: values.firstName,
                              countryCode: values.countryCode,
                              mobile: values.mobile,
                              email: values.email,
                              password: values.password,
                              city: values.city,
                              state: values.state,
                              country: values.country,
                              zipcode: values.pinCode,
                              doorNo: values.doorNo,
                              street: values.street,
                            })
                          ).then(async (data) => {
                            //console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 2) {
                              toast.success(data?.payload?.message);
                              await localStorage.setItem("email", values.email);
                              await localStorage.setItem(
                                "password",
                                values.password
                              );
                              // await localStorage.setItem("loginType", "coach");
                              await localStorage.setItem(
                                "OTPVerify",
                                "SignUpOTPVerifyEmailCoach"
                              );

                              setModalName("CoachVenueOTPVerification");
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFormikState,
                          setFieldValue,
                        }) => (
                          <form
                            className=""
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleSubmit();
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-12 text-center mb-4">
                                <h2 className="mdl-ttl">sign up</h2>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="input-container">
                                  <input
                                    type="text"
                                    className="form-control ps-5"
                                    placeholder="Full Name"
                                    name="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                  />
                                  <img
                                    src="assets/img/userBlack.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                                {errors.firstName && touched.firstName && (
                                  <div className="text-red">
                                    {errors.firstName}
                                  </div>
                                )}
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="input-container">
                                  <PhoneInput
                                    country="in"
                                    inputProps={{
                                      name: "mobile",
                                    }}
                                    enableSearch
                                    value={
                                      values.mobile
                                        ? values.countryCode + values.mobile
                                        : undefined
                                    }
                                    onChange={(
                                      phone,
                                      country,
                                      e,
                                      formattedValue
                                    ) => {
                                      setCountryData({
                                        formattedValue,
                                        country,
                                      });
                                      if (phone.length > 0) {
                                        const raw = phone.slice(
                                          country?.dialCode?.length
                                        );
                                        setFieldValue("mobile", raw);

                                        setFieldValue(
                                          "countryCode",
                                          formattedValue?.split(" ")[0]
                                        );
                                        //   handleGetCustomerName(
                                        //     setFieldValue,
                                        //     raw,
                                        //     formattedValue?.split(" ")[0]
                                        //   );
                                      } else {
                                        setFieldValue("mobile", "");

                                        setFieldValue("countryCode", "");
                                        //handleGetCustomerName(setFieldValue, null, null);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    placeholder="Primary Mobile Number"
                                    containerclassName="form-control signupClass"
                                    inputclassName="phoneInputClass"
                                  />
                                  <img
                                    src="assets/img/mobile.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                                {errors.mobile && touched.mobile && (
                                  <div className="text-red">
                                    {errors.mobile}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type="email"
                                      className="form-control ps-5"
                                      placeholder="Email Address"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    <img
                                      src="assets/img/email.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.email && touched.email && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <h5>Coaching Centre Address</h5>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="Door No."
                                      name="doorNo"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.doorNo}
                                    />
                                    <img
                                      src="assets/img/doorno.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.doorNo && touched.doorNo && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.doorNo}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-6 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="Street"
                                      name="street"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.street}
                                    />
                                    <img
                                      src="assets/img/street.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.street && touched.street && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.street}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="City"
                                      name="city"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.city}
                                    />
                                    <img
                                      src="assets/img/city.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.city && touched.city && (
                                  <div className="text-red"> {errors.city}</div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="State"
                                      name="state"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.state}
                                    />
                                    <img
                                      src="assets/img/State.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.state && touched.state && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.state}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-6 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="Country"
                                      name="country"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.country}
                                    />
                                    <img
                                      src="assets/img/Country.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.country && touched.country && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.country}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-6 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="form-control ps-5"
                                      placeholder="Pin Code"
                                      name="pinCode"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.pinCode}
                                    />
                                    <img
                                      src="assets/img/pincode.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.pinCode && touched.pinCode && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.pinCode}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type={values.toggle ? "text" : "password"}
                                      name="password"
                                      className="form-control ps-5"
                                      placeholder="Password*"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />
                                    <img
                                      src="assets/img/password.png"
                                      className="input-img"
                                      alt=""
                                    />
                                    <span
                                      className={
                                        values.toggle
                                          ? "pass-hide field-icon toggle-password cursor-pointer"
                                          : "pass-view field-icon toggle-password cursor-pointer"
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFormikState((prevState) => {
                                          return {
                                            ...prevState,
                                            values: {
                                              ...prevState.values,
                                              toggle: !prevState.values.toggle,
                                            },
                                          };
                                        });
                                      }}
                                    ></span>
                                  </div>
                                  {errors.password && touched.password && (
                                    <div className="text-red">
                                      {" "}
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type={
                                        values.toggle2 ? "text" : "password"
                                      }
                                      name="confirmPassword"
                                      className="form-control ps-5"
                                      placeholder="Confirm Password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.confirmPassword}
                                    />
                                    <img
                                      src="assets/img/password.png"
                                      className="input-img"
                                      alt=""
                                    />
                                    <span
                                      className={
                                        values.toggle2
                                          ? "pass-hide field-icon toggle-password cursor-pointer"
                                          : "pass-view field-icon toggle-password cursor-pointer"
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFormikState((prevState) => {
                                          return {
                                            ...prevState,
                                            values: {
                                              ...prevState.values,
                                              toggle2:
                                                !prevState.values.toggle2,
                                            },
                                          };
                                        });
                                      }}
                                    ></span>
                                  </div>
                                  {errors.confirmPassword &&
                                    touched.confirmPassword && (
                                      <div className="text-red">
                                        {" "}
                                        {errors.confirmPassword}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="input-container">
                                  <div className="">
                                    <input
                                      className="styled-checkbox"
                                      id="Iaccept"
                                      type="checkbox"
                                      name="acceptTermsCondition"
                                      onChange={handleChange}
                                      onBlur={handleBlur("radio")}
                                      checked={
                                        values.acceptTermsCondition === true
                                      }
                                    />
                                    <label htmlFor="Iaccept" className="w-100">
                                      I accept to the{" "}
                                      <Link
                                        to="/terms-conditions"
                                        className="theme-color"
                                        target="_blank"
                                      >
                                        Terms & Conditions
                                      </Link>{" "}
                                    </label>
                                  </div>
                                  {errors.acceptTermsCondition &&
                                    touched.acceptTermsCondition && (
                                      <div className="text-red">
                                        {" "}
                                        {errors.acceptTermsCondition}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn btn-submit mt-0 w-75"
                                >
                                  {loading ? <Loader /> : "Sign up"}
                                </button>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                                Already have an account ?{" "}
                                <span
                                  className="theme-color cursor-pointer fw-bold"
                                  onClick={() => {
                                    setModalName("VenueLoginModal");
                                    localStorage.setItem(
                                      "loginImg",
                                      "coachLogin"
                                    );
                                  }}
                                >
                                  Login
                                </span>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CoachSignUpModal;
