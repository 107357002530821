import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { CoachVenueResetPasswordReducer } from "../../features/CoachVenueslice";

function CoachVenueResetPassword({ show, handleShow }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loginImg = localStorage.getItem("loginImg");
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div
                    className={
                      loginImg === "venueLogin"
                        ? "col-sm-12 col-md-12 col-lg-6 bgloginImgvenue pb-1"
                        : loginImg === "coachLogin"
                        ? "col-sm-12 col-md-12 col-lg-6 bgloginImgCoach pb-1"
                        : "col-sm-12 col-md-12 col-lg-6 bgloginImgvenue pb-1"
                    }
                  >
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-lg-5">
                    <div className="form-style mt-md-5 px-2">
                      <Formik
                        initialValues={{
                          password: "",
                          confirmPassword: "",
                          toggle: false,
                          toggle1: false,
                        }}
                        validationSchema={Yup.object({
                          password: Yup.string().required("Required"),
                          confirmPassword: Yup.string()
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            )
                            .required("Required"),
                        })}
                        onSubmit={(values, { isSubmitting, resetForm }) => {
                          setLoading(true);
                          dispatch(
                            CoachVenueResetPasswordReducer({
                              password: values.password,
                              user_type:
                                loginImg === "coachLogin"
                                  ? "coach"
                                  : "venueowner",
                            })
                          ).then(async (data) => {
                            //console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 1) {
                              toast.success(data?.payload?.message);
                              await localStorage.clear();
                              handleShow();
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          setFormikState,
                        }) => (
                          <form className="row" onSubmit={handleSubmit}>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                              <h2 className="mdl-ttl">reset password</h2>
                              <p>Please reset your password here</p>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                              <div className="">
                                <img
                                  src="assets/img/Password_graphic.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 mb-5">
                              <div className="form-group">
                                <div className="input-container">
                                  <input
                                    type={values.toggle ? "text" : "password"}
                                    name="password"
                                    className="form-control ps-5"
                                    placeholder="Set New Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                  />
                                  <img
                                    src="assets/img/password.png"
                                    className="input-img"
                                    alt=""
                                  />
                                  <span
                                    className={
                                      values.toggle
                                        ? "pass-hide field-icon toggle-password cursor-pointer"
                                        : "pass-view field-icon toggle-password cursor-pointer"
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setFormikState((prevState) => {
                                        return {
                                          ...prevState,
                                          values: {
                                            ...prevState.values,
                                            toggle: !prevState.values.toggle,
                                          },
                                        };
                                      });
                                    }}
                                  ></span>
                                </div>
                                {errors.password && touched.password && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                              <div className="form-group">
                                <div className="input-container">
                                  <input
                                    type={values.toggle2 ? "text" : "password"}
                                    name="confirmPassword"
                                    className="form-control ps-5"
                                    placeholder="Set Confirm New Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.confirmPassword}
                                  />
                                  <img
                                    src="assets/img/password.png"
                                    className="input-img"
                                    alt=""
                                  />
                                  <span
                                    className={
                                      values.toggle2
                                        ? "pass-hide field-icon toggle-password cursor-pointer"
                                        : "pass-view field-icon toggle-password cursor-pointer"
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setFormikState((prevState) => {
                                        return {
                                          ...prevState,
                                          values: {
                                            ...prevState.values,
                                            toggle2: !prevState.values.toggle2,
                                          },
                                        };
                                      });
                                    }}
                                  ></span>
                                </div>
                                {errors.confirmPassword &&
                                  touched.confirmPassword && (
                                    <div className="text-red">
                                      {" "}
                                      {errors.confirmPassword}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center">
                              <button type="submit" className="btn btn-submit">
                                {loading ? <Loader /> : "Done"}
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CoachVenueResetPassword;
