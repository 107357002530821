import React, { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h4>ABOUT GAMEHUNT</h4>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mb-3">
                <img
                  src="assets/img/about1.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-9 mb-3">
                <p>
                  Gamehunt aspires to be your go-to resource for all your
                  sporting activities. The ideal sports venues, vetted and
                  experienced coaches, coaching camps and numerous sporting
                  events in your vicinity, and much more at the most affordable
                  prices, are just a click away. We orchestrate competitive
                  tournaments for kids to help them perfect their skills. We
                  also organize a variety of sports activities in corporate
                  settings for hardworking individuals to unwind after a long
                  day. We encourage people to make new friends, organize events,
                  share new experiences, and, most importantly, find athletic
                  locations and activities on Gamehunt. So, no more excuses, put
                  on your sneakers because the game is about to begin.
                </p>
                <p>
                  When managing work and life in an urban area, it might be
                  difficult to find places to play. We recognize these concerns
                  and attempt to alleviate them by scouting the best
                  locationsfor you within your purviewin only a few clicks! We
                  make certain, that your attention is focused on the game and
                  not on the arrangement!
                </p>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-9 mb-3">
                <p>
                  Gamehunt not only selects the best place for you, but it also
                  links players with coaches & academies online for outstanding
                  performances. Travelling to coaching clubs may be
                  time-consuming, so we make it easier by putting the coaches
                  online and providing each student with a personalized path via
                  online lessons. By bringing the expertise of hand-picked
                  coaches and trainers from around the country to your doorstep,
                  the whole learning experience is designed to allow players to
                  focus on their game. By facilitating sports participation, we
                  hope to inspire young individuals to embrace sports as a way
                  of life.
                </p>
                <p>
                  The Gamehunt also helps academic organisations like schools
                  close the gap between academics and sports and fitness. We
                  comprehend how schools put an emphasis on academics and are
                  unable to give kids the resources to develop in sports.
                  Through collaborations and affiliations with schools through
                  The Gamehunt Academy, we provide great resources including
                  coaches and trainers, training courses, easy access to nearby
                  grounds and courts, as well as unwavering support. We will not
                  only provide services, but will also allow schools and parents
                  to keep track of their child's schedule and progress on our
                  platform simply by entering their child's login id. We strive
                  to provide all-around assistance to the youngsters so that
                  they can attain their full potential.
                </p>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mb-3">
                <img
                  src="assets/img/about2.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h4>WHY CHOOSE US?</h4>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h6>Sports venues at fingertips</h6>
                <p>
                  Heard anything better than a selection of sports venues that
                  work with your schedule?
                </p>
                <p>
                  Game Hunt recognises the difficulties that sports enthusiasts
                  encounter when it comes to locating sports venues and how
                  time-consuming it may be! With many filter options to help you
                  find a desirable sports venue near you from the comfort of
                  your own home! Not only that, but we also offer incredible
                  discounts on exclusive venues, allowing you to book the venue
                  at the best possible price. Maximize your playtime while
                  minimizing booking hassles! Tap "BOOK A VENUE" to discover the
                  realm of sporting venues around you.
                </p>
                <h6>Assertive Coaching camps for persistence.</h6>
                <p>
                  It may be difficult to locate quality coaching academies in
                  your area. As a result, we have coaching centres listed in
                  meticulous detail on our platform. This will allow you to
                  compare other centres to find the best club based on your
                  preferences and book a free trial session. Gamehunt offers
                  video courses that are organized into modules and are
                  accessible 24X7, allowing you to learn with the best
                  specialists in the country. The video lectures in the app are
                  both interesting and instructive. GameHunt experts understand
                  the importance of personalized learning routes; each student
                  may pick a different path based on their needs and chosen
                  teaching method. To stay focused on their game, players are
                  assisted with the experience of handpicked coaches and
                  trainers from throughout the country during the whole learning
                  process. Experts not only provide individual instruction to
                  students, but they also contribute content to a complete video
                  archive that students may access at anytime from anywhere.
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h4>The GAMEHUNT ACADEMY</h4>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <p>
                  The Gamehunt Academy is a one-stop shop for schools and
                  students seeking sports and life skills. Given that schools'
                  areas of expertise are in education and academics, most
                  schools struggle to bridge the gap between academics and
                  extracurricular activities. Young athletes have up until now
                  relied on the athletic departments of their schools to provide
                  them with the coaches they needed to get ready for state or
                  national competitions. By procuring the greatest coaches,
                  setting up playgrounds and courts, sending kids to
                  competitions, we assist schools in finding solutions to all of
                  these issues under one roof. The Gamehunt Academy also
                  believes that it is critical to begin teaching life coaching
                  to children at a young age. The Gamehunt strives to present
                  superior life skills training programmes through our extensive
                  network of qualified life coaches who are professionals in
                  working with students to build traits that may catalyse their
                  advancement and give a jump start to their sports career. It's
                  simple to tie up with us! And schools can afford to use all
                  these different services and amenities.
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 ">
                <h4>Events & Tournaments</h4>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <p>
                  The Gamehunt is dedicated to providing numerous opportunities
                  for our young athletes to develop and showcase their skills at
                  a competitive level. We organise major sporting events,
                  tournaments, and championship leagues for a variety of sports,
                  including football, cricket, field hockey, basketball,
                  volleyball, tennis, and swimming. We provide unrivalled
                  facilities for these events in order to provide the best
                  experience for young athletes. It is unavoidable for players
                  to compete with the best of the best in order to hone their
                  skills and advance in their respective sports. We bring
                  together teams from all over the city to provide a level
                  playing field for the teams and a thrilling experience for the
                  spectators! The Gamehunt also recognizes and rewards the
                  winners and runner-ups with trophies, medals, and exclusive
                  Gamehunt scholarships to help athletes advance in the sports
                  community.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AboutUs;
