import React, { useState, useEffect, useRef } from "react";
import { mapApiKey } from "../Utils";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    //console.log("ready");
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function getLatLngFromAddress(place, updateLatLong, latLong) {
  var address = place;
  var geocoder = new window.google.maps.Geocoder();

  geocoder.geocode({ address: address }, function (results, status) {
    if (status === window.google.maps.GeocoderStatus.OK) {
      updateLatLong({
        ...latLong,
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      });
    } else {
      console.log(
        "Geocode was not successful for the following reason: " + status
      );
    }
  });
}

function handleScriptLoad(
  updateQuery,
  autoCompleteRef,
  updateLatLong,
  latLong
) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(regions)"], componentRestrictions: { country: "in" } }
  );
  console.log(autoComplete);
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, updateLatLong, latLong)
  );
}

async function handlePlaceSelect(updateQuery, updateLatLong, latLong) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  //console.log(query)
  getLatLngFromAddress(query, updateLatLong, latLong);
  updateQuery(query);
}

function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const [latLong, setLatLong] = useState(props.latLong);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, setLatLong, latLong)
    );

    return () => {};
  }, [latLong]);

  useEffect(() => {
    props.onChange(query, latLong);
  }, [query, latLong]);

  useEffect(() => {
    setQuery(props.value);
  }, []);

  return (
    <div className="">
      <input
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Current Location"
        value={props.value}
        className="form-control CurrentLocation"
        name={props.name}
        onBlur={props.onBlur}
      />
    </div>
  );
}

export default SearchLocationInput;
