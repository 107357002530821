import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { SendContactUsReducer } from "../slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContactUsMessageModal from "../../Components/Modals/ContactUsMessageModal";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [countryData, setCountryData] = useState();
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");

  const handleShow = () => {
    setShow(!show);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const initialValues = {
    name: "",
    email: "",
    countryCode: "+91",
    mobile: "",
    message: "",
  };

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h4>Contact Us</h4>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                    email: Yup.string()
                      .email("email must be a valid format")
                      .required("Required"),
                    countryCode: Yup.string().required("Required"),
                    mobile: Yup.string()
                      .required("required")
                      .matches(phoneRegExp, "Phone number is not valid")
                      .min(10, "Mobile Number should be only 10 digits")
                      .max(10, "Mobile Number should be only 10 digits"),
                    message: Yup.string().required("Required"),
                  })}
                  enableReinitialize
                  onSubmit={(values, { isSubmitting, resetForm }) => {
                    //console.log(values);

                    const payload = {
                      name: values.name,
                      email: values.email,
                      contryCode: values.countryCode,
                      mobile: values.mobile,
                      message: values.message,
                    };
                    dispatch(SendContactUsReducer(payload)).then((data) => {
                      if (data?.payload?.code === 1) {
                        toast.success(data.payload.message);
                        setModalName("ContactUsMessageModal");
                        handleShow();
                        // navigate("/", {
                        //   replace: true,
                        // });
                      } else {
                        toast.error(data.payload.message);
                      }
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFormikState,
                    setFieldValue,
                  }) => (
                    <form
                      className="form-style formCavas"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-12 mb-4">
                          <h5>Get in touch</h5>
                        </div>
                        <div className="col-sm-12 col-md-12 mb-4">
                          <div className="form-group">
                            <label className="mb-2 fw-bold">Your Name</label>
                            <div className="input-container">
                              <input
                                type="text"
                                className="form-control ps-5"
                                placeholder="Enter Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                name="name"
                              />
                              <img
                                src="assets/img/userName.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.name && touched.name && (
                            <div className="text-red">{errors.name}</div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-12 mb-4">
                          <div className="form-group">
                            <label className="mb-2 fw-bold">Email</label>
                            <div className="input-container">
                              <input
                                type="text"
                                className="form-control ps-5"
                                placeholder="Enter your email address.."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                name="email"
                              />
                              <img
                                src="assets/img/Email-Address.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.email && touched.email && (
                            <div className="text-red">{errors.email}</div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-12 mb-4">
                          <div className="form-group">
                            <label className="mb-2 fw-bold">Phone Number</label>
                            <div className="input-container">
                              <PhoneInput
                                country="in"
                                inputProps={{
                                  name: "mobile",
                                }}
                                enableSearch
                                value={
                                  values.countryCode
                                    ? values.countryCode + values.mobile
                                    : undefined
                                }
                                onChange={(
                                  phone,
                                  country,
                                  e,
                                  formattedValue
                                ) => {
                                  setCountryData({ formattedValue, country });
                                  if (phone.length > 0) {
                                    const raw = phone.slice(
                                      country?.dialCode?.length
                                    );
                                    setFieldValue("mobile", raw);

                                    setFieldValue(
                                      "countryCode",
                                      formattedValue?.split(" ")[0]
                                    );
                                    //   handleGetCustomerName(
                                    //     setFieldValue,
                                    //     raw,
                                    //     formattedValue?.split(" ")[0]
                                    //   );
                                  } else {
                                    setFieldValue("mobile", "");

                                    setFieldValue("countryCode", "");
                                    //handleGetCustomerName(setFieldValue, null, null);
                                  }
                                }}
                                onBlur={handleBlur}
                                placeholder="Primary Mobile Number"
                                containerclassName="form-control signupClass"
                                inputclassName="phoneInputClass"
                              />
                              <img
                                src="assets/img/mobile.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.mobile && touched.mobile && (
                            <div className="text-red"> {errors.mobile}</div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-12 mb-4">
                          <div className="form-group">
                            <label className="mb-2 fw-bold">Message</label>
                            <div className="input-container">
                              <textarea
                                type="text"
                                className="textarea-control ps-5 py-3"
                                placeholder="Text Here..."
                                rows={7}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                name="message"
                              ></textarea>
                              <img
                                src="assets/img/message_icon.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.message && touched.message && (
                            <div className="text-red">{errors.message}</div>
                          )}
                        </div>

                        <div className="col-sm-12 col-md-12 text-center">
                          <button type="submit" className="btn btn-submit w-50">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <img
                  src="assets/img/Contact.png"
                  alt=""
                  className="img-fluid w-100"
                />
                <ul className="contactList p-0 m-0">
                  <li>
                    <div className="contactIcon">
                      <img src="assets/img/locationmap.png" alt="" />
                    </div>
                    1004, 10th Floor, Ruby Crescent, Above Axis Bank, Ashok
                    Nagar, Kandivali East. Mumbai 400101
                  </li>
                  <li>
                    <div className="contactIcon">
                      <img src="assets/img/forwaded_call_icon.png" alt="" />
                    </div>
                    <a href="tel:+919167048112">+91 9167048112</a> /{" "}
                    <a href="tel:9819499479">9819499479</a>
                  </li>
                  <li>
                    <div className="contactIcon">
                      <img src="assets/img/e-mailletter.png" alt="" />
                    </div>
                    <a href="mailto:play@thegamehunt.com">
                      play@thegamehunt.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      {modalName === "ContactUsMessageModal" && (
        <ContactUsMessageModal show={show} handleShow={handleShow} />
      )}
    </>
  );
}

export default ContactUs;
