import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { ForgotPasswordReducer } from "../../features/slice";

function ForgotPasswordModal({ show, handleShow, setModalName }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="xl">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div className="col-sm-12 col-md-12 col-lg-6 bgloginImgUser">
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-lg-5">
                    <div className="form-style mt-lg-5 px-4">
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={Yup.object({
                          email: Yup.string()
                            .email("email must be a valid format")
                            .required("Required"),
                        })}
                        onSubmit={(values, action) => {
                          //console.log(values);
                          setLoading(true);
                          dispatch(
                            ForgotPasswordReducer({
                              email: values.email,
                              user_type: "normaluser",
                            })
                          ).then(async (data) => {
                            //console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 1) {
                              toast.success(data?.payload?.message);
                              await localStorage.setItem(
                                "email",
                                data?.payload?.data?.user?.email
                              );
                              await localStorage.setItem(
                                "OTPVerify",
                                "ForgotOTPVerify"
                              );
                              setModalName("OTPVerification");
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFormikState,
                          setFieldValue,
                        }) => (
                          <form className="" onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                                <h2 className="mdl-ttl">forgot password</h2>
                                <p>
                                  In order to retrieve your password, please
                                  enter registered email id
                                </p>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                                <div className="">
                                  <img
                                    src="assets/img/Forgot.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 mb-5">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      className="form-control ps-5"
                                      placeholder="Email Address"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    <img
                                      src="assets/img/email.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.email && touched.email && (
                                  <div className="text-red">{errors.email}</div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 text-center mb-5">
                                <button
                                  type="submit"
                                  className="btn btn-submit"
                                >
                                  {loading ? <Loader /> : "Submit"}
                                </button>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                                Not register Yet ?{" "}
                                <span
                                  className="theme-color cursor-pointer fw-bold"
                                  onClick={() => {
                                    setModalName("SignUpModal");
                                  }}
                                >
                                  Create an Account
                                </span>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPasswordModal;
