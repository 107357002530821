import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GooglePlace from "../../Components/GooglePlace";
import { Buffer } from "buffer";
import { config, mapApiKey } from "../../Utils";
import { SportListReducer, UpdateUserProfileReducer } from "../slice";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import Geocode from "react-geocode";
import { UserDetailReducer } from "./../slice";
import Loader from "../../Components/Loader";
import Autocomplete from "react-google-autocomplete";
import { useRef } from "react";
window.Buffer = Buffer;

function EditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countryData, setCountryData] = useState();
  const [loading, setLoading] = useState(false);
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [address, setaddress] = useState("");
  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");
  const [imageName, setimageName] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [area, setarea] = useState("");
  const [street, setstreet] = useState("");
  const [dob, setdob] = useState("");
  const autocompleteRef = useRef();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const loginDetail = useSelector((state) => state.main.userDetail);
  //console.log(loginDetail);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const initialValues = {
    imageName: imageName ? imageName : "",
    firstName: firstName ? firstName : "",
    lastName: lastName ? lastName : "",
    email: email ? email : "",
    mobile: mobile ? mobile : "",
    countryCode: countryData ? countryData : "",
    doorNo: doorNo ? doorNo : "",
    area: area ? area : "",
    street: street ? street : "",
    city: city ? city : "",
    state: state ? state : "",
    pincode: pincode ? pincode : "",
    address: address,
    latitude: lat,
    longitude: long,
    gender: loginDetail?.gender ? loginDetail?.gender : "",
    boi: loginDetail?.bio ? loginDetail?.bio : "",
    dob: dob ? dob : "",
    SportsSlotArr:
      loginDetail?.Sport?.length > 0
        ? loginDetail?.Sport?.map((item) => {
            return {
              sport: item?.sport,
              skills: item?.skills,
            };
          })
        : [
            {
              sport: "",
              skills: "",
            },
          ],
  };

  const handleTimeSlot = (e, ind, name, values, setFormikState) => {
    //setTimeSlotIndex(ind);
    const arr = [...values.SportsSlotArr];

    if (name) {
      const { value } = e.target;
      arr[ind] = {
        ...arr[ind],
        [name]: value,
      };
    } else {
      arr[ind] = {
        ...arr[ind],
        [name]: e,
      };
    }

    //console.log(arr);
    setFormikState((prev) => {
      return {
        ...prev,
        values: {
          ...prev.values,
          SportsSlotArr: arr,
        },
      };
    });
  };

  const Sportlist = useSelector((state) => state.main.SportList);

  const getSportList = useCallback(async () => {
    dispatch(
      SportListReducer({
        program: "Sport & Fitness",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getSportList();
    }
    return () => {
      mounted = false;
    };
  }, [getSportList]);

  const getUserDetail = useCallback(async () => {
    dispatch(UserDetailReducer({})).then((data) => {
      if (data?.payload?.code === 1) {
        setimageName(data?.payload?.data?.normaluser?.picture);
        setfirstName(data?.payload?.data?.normaluser?.firstName);
        setlastName(data?.payload?.data?.normaluser?.lastName);
        setemail(data?.payload?.data?.normaluser?.email);
        setmobile(data?.payload?.data?.normaluser?.mobile);
        setCountryData(data?.payload?.data?.normaluser?.countryCode);
        setdoorNo(data?.payload?.data?.normaluser?.door);
        setarea(data?.payload?.data?.normaluser?.area);
        setstreet(data?.payload?.data?.normaluser?.street);
        setdob(data?.payload?.data?.normaluser?.dob);
        setpincode(data?.payload?.data?.normaluser?.zipcode);
        setstate(data?.payload?.data?.normaluser?.state);
        setcity(data?.payload?.data?.normaluser?.city);
        setaddress(data?.payload?.data?.normaluser?.address);
        setlat(data?.payload?.data?.normaluser?.location?.[0]?.latitude);
        setlong(data?.payload?.data?.normaluser?.location?.[0]?.longitude);
      } else {
        setpincode("");
        setstate("");
        setcity("");
        setaddress("");
        setlat("");
        setlong("");
      }
    });
  }, [dispatch]);
  useEffect(() => {
    getUserDetail();
  }, [getUserDetail]);

  if (lat) {
    Geocode.setApiKey(mapApiKey);
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        const address = response.results[0];
        //console.log(address);
        let city, state, country, postal_code;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postal_code =
                  response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        //console.log(city, state, country, postal_code);

        setpincode(postal_code);
        setstate(state);
        setcity(city);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  return (
    <>
      <div className="form-style mt-5  mx-width">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            // imageName: Yup.string().required("Required"),
            firstName: Yup.string().typeError("Required").required("Required"),
            lastName: Yup.string().typeError("Required").required("Required"),
            countryCode: Yup.string()
              .typeError("Required")
              .required("Required"),
            mobile: Yup.string()
              .required("required")
              .matches(phoneRegExp, "Phone number is not valid")
              .min(10, "Mobile Number should be only 10 digits")
              .max(10, "Mobile Number should be only 10 digits"),
            email: Yup.string()
              .email("email must be a valid format")
              .required("Required"),

            dob: Yup.string().typeError("Required").required("Required"),
            address: Yup.string().typeError("Required").required("Required"),
            gender: Yup.string().typeError("Required").required("Required"),
          })}
          enableReinitialize
          onSubmit={(values) => {
            setLoading(true);
            const arr = values.SportsSlotArr?.map((item) => {
              return {
                ...item,
              };
            });
            const payload = {
              picture: values.imageName,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              countryCode: values.countryCode,
              mobile: values.mobile,
              door: values.doorNo,
              area: values.area,
              street: values.street,
              city: values.city,
              state: values.state,
              zipcode: values.pincode,
              address: values.address,
              location: JSON.stringify([
                {
                  latitude: values.latitude,
                  longitude: values.longitude,
                },
              ]),

              gender: values.gender,
              bio: values.boi,
              dob: moment(values.dob, "GTM").format("DD/MM/YYYY"),
              Sport: JSON.stringify(arr),
            };

            const dataWithOutEmailMobile = {
              picture: values.imageName,
              firstName: values.firstName,
              lastName: values.lastName,
              door: values.doorNo,
              area: values.area,
              street: values.street,
              city: values.city,
              state: values.state,
              zipcode: values.pincode,
              address: values.address,
              location: JSON.stringify([
                {
                  latitude: values.latitude,
                  longitude: values.longitude,
                },
              ]),

              gender: values.gender,
              bio: values.boi,
              dob: moment(values.dob, "GTM").format("DD/MM/YYYY"),
              Sport: JSON.stringify(arr),
            };
            const { email, ...rest1 } = payload;
            const { countryCode, mobile, ...rest2 } = payload;
            // console.log("email", rest1);
            // console.log("mobile", rest2);
            const dataToSend =
              loginDetail?.email && loginDetail?.mobile
                ? dataWithOutEmailMobile
                : loginDetail?.mobile
                ? rest2
                : loginDetail?.email
                ? rest1
                : payload;
            //console.log(dataToSend);

            if (values.latitude === undefined || values.latitude === "") {
              toast.error("Please Check Current Location");
            } else {
              dispatch(UpdateUserProfileReducer(dataToSend)).then(
                async (data) => {
                  setLoading(false);
                  if (data?.payload?.code === 1) {
                    toast.success(data.payload.message);

                    navigate("/", {
                      replace: true,
                    });
                  } else {
                    setLoading(false);
                    toast.error(data.payload.message);
                  }
                }
              );
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFormikState,
          }) => (
            <form className="row" onSubmit={handleSubmit}>
              <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                <h2 className="mdl-ttl">Set Profile</h2>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 text-center">
                <div className="upload-btn-wrapper">
                  <span className="btn shadow">
                    <img
                      src={
                        values.imageName && values.imageName?.includes("http")
                          ? values.imageName
                          : "assets/img/employe.png"
                      }
                      alt=""
                    />

                    <div className="camera-icon">
                      <i className="fa fa-camera"></i>
                    </div>
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    name="imageName"
                    onChange={(event) => {
                      setIsActive(true);
                      S3FileUpload.uploadFile(event.target.files[0], config)
                        .then((data) => {
                          // setFormikState((prevState) => {
                          //   return {
                          //     ...prevState,
                          //     values: {
                          //       ...prevState.values,
                          //       imageName: data.location,
                          //     },
                          //   };
                          // });
                          setimageName(data.location);
                          setTime(100);
                          setIsActive(true);
                          setTimeout(() => {
                            setTime(0);
                            setIsActive(false);
                          }, 3000);
                          //console.log(data.location);
                        })
                        .catch((err) => console.error(err));
                    }}
                  />
                </div>
                {time > 0 && isActive && (
                  <Progress type="line" width={100} percent={time} />
                )}
                {errors.imageName && touched.imageName && (
                  <div className="text-red"> {errors.imageName}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                <h5>Personal Info</h5>
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="First name"
                    name="firstName"
                    value={values.firstName}
                    onChange={(e) => {
                      setfirstName(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                  <img
                    src="assets/img/user_icon.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.firstName && touched.firstName && (
                  <div className="text-red"> {errors.firstName}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="Last name"
                    name="lastName"
                    value={values.lastName}
                    onChange={(e) => {
                      setlastName(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                  <img
                    src="assets/img/user_icon.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.lastName && touched.lastName && (
                  <div className="text-red"> {errors.lastName}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <div className="input-container">
                  <input
                    type="email"
                    className="form-control ps-5"
                    placeholder="Email Address"
                    name="email"
                    value={values.email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    onBlur={handleBlur}
                    disabled={loginDetail?.email ? "disabled" : ""}
                  />
                  <img
                    src="assets/img/email.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.email && touched.email && (
                  <div className="text-red"> {errors.email}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                <div className="input-container">
                  <PhoneInput
                    country="in"
                    inputProps={{
                      name: "mobile",
                    }}
                    enableSearch
                    value={
                      values.countryCode
                        ? values.countryCode + values.mobile
                        : undefined
                    }
                    onChange={(phone, country, e, formattedValue) => {
                      setCountryData({ formattedValue, country });
                      if (phone.length > 0) {
                        const raw = phone.slice(country?.dialCode?.length);
                        setmobile(raw);

                        setCountryData(formattedValue?.split(" ")[0]);
                      } else {
                        setmobile("");

                        setCountryData("");
                      }
                    }}
                    onBlur={handleBlur}
                    placeholder="Mobile Number"
                    containerclassName="form-control signupClass"
                    inputclassName="phoneInputClass"
                    disabled={loginDetail?.mobile ? "disabled" : ""}
                  />
                  {/* <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="Mobile No"
                  /> */}
                  <img
                    src="assets/img/mobile.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.mobile && touched.mobile && (
                  <div className="text-red"> {errors.mobile}</div>
                )}
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                <div className="form-group">
                  <div className="input-container">
                    <DatePicker
                      containerClassName=""
                      value={values.dob}
                      name="dob"
                      onChange={(e) => {
                        setdob(e.toDate());
                      }}
                      placeholder="Date of Birth"
                      format="DD/MM/YYYY"
                      maxDate={new Date()}
                      onBlur={handleBlur}
                    />
                    <img
                      src="assets/img/calendar_date.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {errors.dob && touched.dob && (
                  <div className="text-red"> {errors.dob}</div>
                )}
              </div>

              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2 mt-5">
                <h5>Address</h5>
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="Door No"
                    name="doorNo"
                    value={values.doorNo}
                    onChange={(e) => {
                      setdoorNo(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                  <img
                    src="assets/img/doorno.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.doorNo && touched.doorNo && (
                  <div className="text-red"> {errors.doorNo}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="Area"
                    name="area"
                    value={values.area}
                    onChange={(e) => {
                      setarea(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                  <img src="assets/img/area.png" className="input-img" alt="" />
                </div>
                {errors.area && touched.area && (
                  <div className="text-red"> {errors.area}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="Street"
                    name="street"
                    value={values.street}
                    onChange={(e) => {
                      setstreet(e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                  <img
                    src="assets/img/street.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.street && touched.street && (
                  <div className="text-red"> {errors.street}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="City"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <img src="assets/img/city.png" className="input-img" alt="" />
                </div>
                {errors.city && touched.city && (
                  <div className="text-red"> {errors.city}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control ps-5"
                    placeholder="State"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <img
                    src="assets/img/address.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.state && touched.state && (
                  <div className="text-red"> {errors.state}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="input-container">
                  <input
                    type="number"
                    className="form-control ps-5"
                    placeholder="Pincode"
                    name="pincode"
                    value={values.pincode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <img
                    src="assets/img/Bar-code.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.pincode && touched.pincode && (
                  <div className="text-red"> {errors.pincode}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="input-container profile-loca">
                  {/* <GooglePlace
                    name="address"
                    value={address}
                    latLong={{
                      lat: lat,
                      lng: long,
                    }}
                    onBlur={handleBlur}
                    onChange={(val, latLong) => {
                      if (val) {
                        setaddress(val);
                        setlat(latLong.lat);
                        setlong(latLong.lng);
                      } else {
                        setaddress("");
                        setlat("");
                        setlong("");
                      }
                    }}
                  /> */}
                  <Autocomplete
                    apiKey={mapApiKey}
                    name="address"
                    value={address}
                    ref={autocompleteRef}
                    className="form-control ps-5"
                    placeholder="Current Location"
                    onBlur={handleBlur}
                    onChange={() => {
                      // console.log(autocompleteRef);
                      setaddress(autocompleteRef.current.value);
                      setlat("");
                      setlong("");
                    }}
                    onPlaceSelected={(place, inputRef, autocompleteRef) => {
                      // console.log(place, inputRef, autocompleteRef);
                      if (place) {
                        let lat = place.geometry.location.lat();
                        let lng = place.geometry.location.lng();
                        let address = place.formatted_address;

                        setaddress(address);
                        setlat(lat);
                        setlong(lng);
                      } else {
                        setaddress("");
                        setlat("");
                        setlong("");
                      }
                    }}
                    options={{
                      types: ["(regions)"],
                      componentRestrictions: { country: "in" },
                    }}
                  />
                  <img
                    src="assets/img/location.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                {errors.address && touched.address && (
                  <div className="text-red"> {errors.address}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2 mt-5">
                <h5>Gender</h5>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2 RadioMoboileFull">
                <ul className="slotTimeList custom-radio ps-0">
                  <li>
                    <div className="radio-item h-100">
                      <input
                        type="radio"
                        value="male"
                        name="gender"
                        id="male"
                        onChange={handleChange("gender")}
                        onBlur={handleBlur("radio")}
                        checked={values.gender === "male"}
                      />
                      <label className="label-icon setGender" htmlFor="male">
                        <div>
                          <img
                            src="assets/img/male_black.png"
                            alt=""
                            className="unselect"
                          />
                          <img
                            src="assets/img/male_white.png"
                            alt=""
                            className="select"
                          />
                        </div>
                        Male
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="radio-item h-100">
                      <input
                        type="radio"
                        value="female"
                        name="gender"
                        id="female"
                        onChange={handleChange("gender")}
                        onBlur={handleBlur("radio")}
                        checked={values.gender === "female"}
                      />
                      <label className="label-icon setGender" htmlFor="female">
                        <div>
                          <img
                            src="assets/img/Female_black.png"
                            alt=""
                            className="unselect"
                          />
                          <img
                            src="assets/img/Female_white.png"
                            alt=""
                            className="select"
                          />
                        </div>
                        Female
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="radio-item h-100">
                      <input
                        type="radio"
                        value="Not to Specify"
                        name="gender"
                        id="other"
                        onChange={handleChange("gender")}
                        onBlur={handleBlur("radio")}
                        checked={values.gender === "Not to Specify"}
                      />
                      <label className="label-icon setGender" htmlFor="other">
                        <div>
                          <img
                            src="assets/img/social-gender-agender_black.png"
                            alt=""
                            className="unselect"
                          />
                          <img
                            src="assets/img/social-gender-agender_white.png"
                            alt=""
                            className="select"
                          />
                        </div>
                        Not to Specify
                      </label>
                    </div>
                  </li>
                </ul>
                {errors.gender && touched.gender && (
                  <div className="text-red"> {errors.gender}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                <textarea
                  className="bg-white border-0 w-100 p-3"
                  placeholder="Bio"
                  rows="7"
                  name="boi"
                  value={values.boi}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                {errors.boi && touched.boi && (
                  <div className="text-red"> {errors.boi}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className="row">
                  {values?.SportsSlotArr?.map((item, i) => {
                    return (
                      <div
                        className="element RadioMoboileFull form-group col-sm-12 col-md-12 col-lg-6"
                        key={i}
                      >
                        <div className="row">
                          <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-2">
                            <h4>Sports {i + 1}</h4>
                            <div
                              className="remove"
                              onClick={() => {
                                let arr = [...values?.SportsSlotArr];
                                arr.splice(i, 1);
                                setFormikState((prev) => {
                                  return {
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      SportsSlotArr: arr,
                                    },
                                  };
                                });
                              }}
                            >
                              <img src="assets/img/delete.png" alt="" />
                            </div>
                          </div>
                          <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                            <div className="input-container">
                              <select
                                className="form-control ps-5 form-select"
                                onChange={(e) => {
                                  handleTimeSlot(
                                    e,
                                    i,
                                    "sport",
                                    values,
                                    setFormikState
                                  );
                                }}
                                onBlur={handleBlur}
                                value={item?.sport}
                                name={`SportsSlotArr[i].sport`}
                              >
                                <option value="">Select</option>
                                {Sportlist?.map((item, i) => (
                                  <option value={item?.name} key={i}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <ul className="slotTimeList custom-radio ps-0">
                                <li>
                                  <div className="radio-item h-100">
                                    <input
                                      type="radio"
                                      value="beginner"
                                      name={`SportsSlotArr${i}.skills`}
                                      id={`SportsSlotArr${i}.Beginner`}
                                      checked={item?.skills === "beginner"}
                                      onChange={(e) => {
                                        handleTimeSlot(
                                          e,
                                          i,
                                          "skills",
                                          values,
                                          setFormikState
                                        );
                                      }}
                                    />
                                    <label
                                      className="label-icon setGender"
                                      htmlFor={`SportsSlotArr${i}.Beginner`}
                                    >
                                      <div>
                                        <img
                                          src="assets/img/Beginner_red.png"
                                          alt=""
                                          className="unselect"
                                        />
                                        <img
                                          src="assets/img/Beginner_white.png"
                                          alt=""
                                          className="select"
                                        />
                                      </div>
                                      Beginner
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="radio-item h-100">
                                    <input
                                      type="radio"
                                      value="intermediate"
                                      name={`SportsSlotArr${i}.skills`}
                                      id={`SportsSlotArr${i}.Intermediate`}
                                      checked={item?.skills === "intermediate"}
                                      onChange={(e) => {
                                        handleTimeSlot(
                                          e,
                                          i,
                                          "skills",
                                          values,
                                          setFormikState
                                        );
                                      }}
                                    />
                                    <label
                                      className="label-icon setGender"
                                      htmlFor={`SportsSlotArr${i}.Intermediate`}
                                    >
                                      <div>
                                        <img
                                          src="assets/img/Intermediate_red.png"
                                          alt=""
                                          className="unselect"
                                        />
                                        <img
                                          src="assets/img/intermediate_white.png"
                                          alt=""
                                          className="select"
                                        />
                                      </div>
                                      Intermediate
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="radio-item h-100">
                                    <input
                                      type="radio"
                                      value="professional"
                                      name={`SportsSlotArr${i}.skills`}
                                      id={`SportsSlotArr${i}.Professional`}
                                      checked={item?.skills === "professional"}
                                      onChange={(e) => {
                                        handleTimeSlot(
                                          e,
                                          i,
                                          "skills",
                                          values,
                                          setFormikState
                                        );
                                      }}
                                    />
                                    <label
                                      className="label-icon setGender"
                                      htmlFor={`SportsSlotArr${i}.Professional`}
                                    >
                                      <div>
                                        <img
                                          src="assets/img/Professional_red.png"
                                          alt=""
                                          className="unselect"
                                        />
                                        <img
                                          src="assets/img/Professional_white.png"
                                          alt=""
                                          className="select"
                                        />
                                      </div>
                                      Professional
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-end">
                <div
                  className="position-relative pw-3 AddtoList cursor-pointer"
                  onClick={() => {
                    //setTimeSlotIndex(timeSlotIndex + 1);
                    let arr = [...values?.SportsSlotArr];
                    arr.push({
                      sport: "",
                      skills: "",
                    });
                    setFormikState((prev) => {
                      //console.log(prev);
                      return {
                        ...prev,
                        values: {
                          ...prev.values,
                          SportsSlotArr: arr,
                        },
                      };
                    });
                    //console.log(arr);
                  }}
                >
                  <b>+ Add More</b>
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <button type="submit" className="btn btn-submit w-sm-25">
                  {loading ? <Loader /> : "Save Changes"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default EditProfile;
