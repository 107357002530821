import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  CoachDetailReducer,
  CoachNotificationCountReducer,
} from "../features/CoachVenueslice";

function CoachHeader({ className }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [showdropdown, setShowdropdown] = useState(false);
  const [DropMenu, setDropMenu] = useState(false);
  const total_CoachNotification = useSelector(
    (state) => state.CoachVenueMain.CoachNotificationCount
  );
  const handleDropMenu = () => {
    setDropMenu(!DropMenu);
  };

  const loginDetail = useSelector((state) => state.CoachVenueMain.CoachDetail);

  const getCoachDetail = useCallback(async () => {
    dispatch(CoachDetailReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCoachDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getCoachDetail]);

  async function logout(e) {
    await localStorage.clear();
    navigate("/");
  }
  const handleShowDrop = () => {
    setShowdropdown(!showdropdown);
  };
  const getList = useCallback(async () => {
    dispatch(CoachNotificationCountReducer({ status: "0" }));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getList();
    }
    return () => {
      mounted = false;
    };
  }, [getList]);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowdropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });
  return (
    <>
      <div className={`header-top-tab navbar-expand-lg ${className}`}>
        <div className="toggleButton">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              handleShowDrop();
            }}
          >
            <i className="fa fa-bars"></i>
            {/* <i className="menu-toggle-img ms-1"></i> */}
          </button>
        </div>
        <div className="text-center">
          <Link to="/">
            <img
              src="assets/img/logo.png"
              className="logo-icon1"
              alt="game hunt"
              title="game hunt"
            />
          </Link>
        </div>

        <div
          className={showdropdown ? "menurespo show" : "menurespo"}
          id="menuCollepse"
        >
          <ul className="navbar-nav m-auto py-lg-0 align-items-center gg-10">
            <li className="navbar-items">
              <Link
                to="/coach/manage-booking"
                className={
                  pathname.includes("manage-booking")
                    ? "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text active"
                    : "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text"
                }
                onClick={() => {
                  handleShowDrop();
                }}
              >
                <img
                  src="assets/img/ManageBookingMenu.png"
                  className="m-auto link-img"
                  alt=""
                />
                <img
                  src="assets/img/Managebookingmrnuactv.png"
                  className="m-auto link-img-actv"
                  alt=""
                />
                manage booking
              </Link>
            </li>
            <li className="navbar-items">
              <Link
                to="/coach/review-rating"
                className={
                  pathname.includes("review-rating")
                    ? "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text active"
                    : "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text"
                }
                onClick={() => {
                  handleShowDrop();
                }}
              >
                <img
                  src="assets/img/review-rating.png"
                  className="m-auto link-img"
                  alt=""
                />
                <img
                  src="assets/img/review-ratingactv.png"
                  className="m-auto link-img-actv"
                  alt=""
                />
                review & rating
              </Link>
            </li>
          </ul>
        </div>
        <div className="noti-flex">
          <Link
            to="/coach/notification"
            className="notificatin-drop cursor-pointer"
          >
            <img src="assets/img/notification.png" className="" alt="" />
            <div
              className={total_CoachNotification === 0 ? "d-none" : "numNoti"}
            >
              {total_CoachNotification >= 10 ? "9+" : total_CoachNotification}
            </div>
          </Link>
          <div className="profile-drop cursor-pointer position-relative">
            <div
              className="tabs-list shadow"
              onClick={() => {
                handleDropMenu();
                setShowdropdown(false);
              }}
            >
              <span>
                <img
                  src={
                    loginDetail?.image?.includes("http")
                      ? loginDetail?.image
                      : "assets/img/employe.png"
                  }
                  className="profile-img"
                  alt=""
                />
              </span>
              <span>
                <h5 className="mb-0">{loginDetail?.name || "Hello coach"}</h5>
                <p className="mb-0">coach</p>
              </span>
            </div>
            <div
              className={
                DropMenu
                  ? "profile-collapse notificatin-box coachProfileMenu"
                  : "profile-collapse hideDrop notificatin-box coachProfileMenu"
              }
            >
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/user_EditProfile.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  className={
                    pathname.includes("edit-personal-info")
                      ? "dropdown-item theme-color"
                      : "dropdown-item"
                  }
                  to="/coach/edit-personal-info"
                >
                  <h6>My Profile & Services</h6>
                </Link>
              </div>
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/wallet_icon.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  to="/coach/my-earning"
                  className={
                    pathname.includes("my-earning") ? "theme-color" : ""
                  }
                >
                  <h6>My Earnings</h6>
                </Link>
              </div>
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/account-info.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  to="/coach/account-info"
                  className={
                    pathname.includes("account-info") ? "theme-color" : ""
                  }
                >
                  <h6>account info</h6>
                </Link>
              </div>
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/user_review_icon.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  to="/coach/review-rating"
                  className={
                    pathname.includes("review-rating") ? "theme-color" : ""
                  }
                >
                  <h6>My Reviews</h6>
                </Link>
              </div>
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/iconpassword.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  className={
                    pathname.includes("change-password")
                      ? "dropdown-item theme-color"
                      : "dropdown-item"
                  }
                  to="/coach/change-password"
                >
                  <h6>Change Password</h6>
                </Link>
              </div>
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/use_MyAccount.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  className={
                    pathname.includes("terms-services")
                      ? "dropdown-item theme-color"
                      : "dropdown-item"
                  }
                  to="/coach/terms-services"
                >
                  <h6>Terms of Services</h6>
                </Link>
              </div>

              <div className="text-center mt-4">
                <span
                  className="fw-bold"
                  style={{ color: "#ff2f2f" }}
                  onClick={(e) => {
                    logout(e);
                  }}
                >
                  <img
                    src="assets/img/logout.png"
                    className=""
                    alt=""
                    width="14px"
                  />{" "}
                  Logout
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachHeader;
