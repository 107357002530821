import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  VenueOwnerBookingDetailReducer,
  VenuePutRatingReviewReducer,
} from "../features/CoachVenueslice";

function VenuePutRatingReviewModal({ show, handleShow, getList, id }) {
  const dispatch = useDispatch();

  const detail = useSelector(
    (state) => state.CoachVenueMain.VenueOwnerBookingDetail
  );

  const getDetail = useCallback(async () => {
    dispatch(
      VenueOwnerBookingDetailReducer({
        eventbookingId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getDetail]);

  const initialValues = {
    review: detail?.venueRating?.rating ? detail?.venueRating?.rating : "",
    feedback: detail?.venueRating?.review ? detail?.venueRating?.review : "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      review: Yup.string().typeError("Required").required("Required"),
      feedback: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const payload = {
        rating: values.review,
        review: values.feedback,
        eventbookingId: id,
      };

      dispatch(VenuePutRatingReviewReducer(payload)).then((data) => {
        if (data?.payload?.code === 1) {
          toast.success(data.payload.message);
          getList();
          handleShow();
        } else {
          toast.error(data.payload.message);
        }
      });
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <form className="row" onSubmit={formik.handleSubmit}>
                  <div className="col-sm-12 col-md-12 col-lg-12 px-5">
                    <div className="text-center">
                      <h3 className="fw-bold py-4">Rating & Reviews</h3>
                    </div>

                    <div className="mb-4">
                      <div className="ps-3 text-center reviews">
                        <Rating
                          onClick={(val) => {
                            formik.setFieldValue("review", val);
                          }}
                          ratingValue={formik.values.review * 20}
                          initialValue={formik.values.review}
                          //showTooltip
                          size={50}
                          iconsCount={5}
                          label
                          transition
                          fillColor="#e10c17"
                          emptyColor="gray"
                          className="foo" // Will remove the inline style if applied
                        />
                      </div>
                      {formik.errors.review && formik.touched.review && (
                        <div className="text-red"> {formik.errors.review}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="bg-white rad border-0 p-3 w-100"
                        rows="7"
                        placeholder="Feedback here.."
                        name="feedback"
                        value={formik.values.feedback}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.errors.feedback && formik.touched.feedback && (
                        <div className="text-red">
                          {" "}
                          {formik.errors.feedback}
                        </div>
                      )}
                    </div>
                    <div className="mb-4 text-center">
                      <button type="submit" className="btn btn-submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VenuePutRatingReviewModal;
