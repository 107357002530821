import React from "react";

function NewsEventDetail() {
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h4>
                  Singha Sports Academy -AITA Champion Series Tennis Tournament
                  2022
                </h4>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mb-3">
                <img
                  src="assets/img/about1.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-9 mb-3">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Nobis qui sapiente culpa sint assumenda iste repellendus
                  pariatur quia vel, quis recusandae ipsa illo beatae eos
                  placeat, ipsum minima. Asperiores, aperiam. Lorem ipsum dolor,
                  sit amet consectetur adipisicing elit. Nobis qui sapiente
                  culpa sint assumenda iste repellendus pariatur quia vel, quis
                  recusandae ipsa illo beatae eos placeat, ipsum minima.
                  Asperiores, aperiam. Lorem ipsum dolor, sit amet consectetur
                  adipisicing elit. Nobis qui sapiente culpa sint assumenda iste
                  repellendus pariatur quia vel, quis recusandae ipsa illo
                  beatae eos placeat, ipsum minima. Asperiores, aperiam.
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Nobis qui sapiente culpa sint assumenda iste repellendus
                  pariatur quia vel, quis recusandae ipsa illo beatae eos
                  placeat, ipsum minima. Asperiores, aperiam. Lorem ipsum dolor,
                  sit amet consectetur adipisicing elit. Nobis qui sapiente
                  culpa sint assumenda iste repellendus pariatur quia vel, quis
                  recusandae ipsa illo beatae eos placeat, ipsum minima.
                  Asperiores, aperiam. Lorem ipsum dolor, sit amet consectetur
                  adipisicing elit. Nobis qui sapiente culpa sint assumenda iste
                  repellendus pariatur quia vel, quis recusandae ipsa illo
                  beatae eos placeat, ipsum minima. Asperiores, aperiam.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default NewsEventDetail;
