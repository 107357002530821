import React, { useEffect } from "react";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h4>Privacy Policy</h4>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-4 mb-3">
                <img
                  src="assets/img/Privacy.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-sm-12 col-md-7 col-lg-8 mb-3">
                <h3>PRIVACY & COOKIES POLICY</h3>
                <p>
                  On several junctions while navigating the website of GAMEHUNT,
                  you may be asked to share your email address or other personal
                  identifying information with us. Your email address will only
                  be used to send you the GAMEHUNT newsletter and/or to alert
                  you to any information that you have specifically requested
                  you be notified about. GAMEHUNT may share your personal data
                  with other companies or authorized third parties who process
                  personal data for GAMEHUNT. This may include billing through
                  your network service provider or otherwise, providing services
                  including customer service, managing and analyzing consumer
                  data, credit checks, conducting research and managing
                  marketing and other such campaigns. GAMEHUNT may conduct joint
                  marketing and other communications with our partners.
                </p>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>
                  Collection Of Personal Information By GAMEHUNT: In relation to
                  the
                </h3>
                <p>use of the Platform/Website/App of GAMEHUNT:</p>
                <ol start="a" type="a">
                  <li>
                    For the purposes of creation of an Account on the Platform,
                    Users will be required to disclose information including
                    personal contact details. The type of Information collected
                    from a User varies based on the interaction with the
                    Platform.
                  </li>
                  <li>
                    During the creation of an Account for User, we will collect
                    information such as your name, telephone number, email
                    address, residential/commercial address and other
                    Information. In some situations, we will also require you to
                    disclose demographic information including gender, age,
                    education and other information relevant to the provision of
                    Services. We may also collect Information that you post in
                    your offer, profile, requirements, or feedback and any other
                    correspondence site on the Platform.
                  </li>
                  <li>
                    When you use our location-enabled services, we may collect
                    and process information about your mobile device's GPS
                    location (including the latitude, longitude or altitude) and
                    the time the information is recorded, to customize the
                    Services. We may associate your location data with your
                    device ID and other information we hold about you. You can
                    withdraw your consent for this feature by disabling the GPS
                    or any other location-tracking functions on your device.
                  </li>
                  <li>
                    We also reserve the right to monitor conversations between
                    two Users facilitated by GAMEHUNT through any mechanism on
                    the Platform. This information can be used to but not
                    limited to monitor and prohibit abuse, safeguard the rights
                    of the Users and resolve any disputed that may arise.
                  </li>
                  <li>
                    You may also register for an Account using your existing
                    Facebook, Google, Twitter or such other accounts and log-in
                    credentials (your "Third-Party Account/s"). As part of the
                    functionality of the Platform, you may link your account
                    with Third Party Accounts, by either: (i) providing your
                    Third Party Account login information to us through the
                    Platform; or (ii) allowing us to access your Third Party
                    Account, as is permitted under the applicable terms and
                    conditions that govern your use of each Third Party Account.
                    As part of the functionality of the Platform, you may link
                    your account with Third Party Account, by either: (i)
                    providing your Third Party Account login information to us
                    through the Platform; or (ii) allowing us to access your
                    Third Party Account, as is permitted under the applicable
                    terms and conditions that govern your use of each Third
                    Party Account. You represent that you are entitled to
                    disclose your Third Party Account login information to us
                    and/or grant us access to your Third Party Account
                    (including, but not limited to, for the purposes described
                    herein), without breach by you of any of the terms and
                    conditions that govern your use of the applicable Third
                    Party Account and without obligating us to pay any fees or
                    making us subject to any usage limitations imposed by the
                    applicable Third Party Site service provider.
                  </li>
                  <li>
                    If you decide to register by logging into a Third Party
                    Account via our Platform, we will obtain the Personal
                    Information you have provided f.to the applicable Third
                    Party service provider (such as your "real" name, email
                    address, profile picture, names of friends, names of groups
                    to which you belong, other information you make publicly
                    available via the applicable Third Party service provider
                    and/or other information you authorize us to access by
                    authorizing the Third Party service provider to provide such
                    information) from your Third Party Accounts and use that
                    information to create your account and profile page and you
                    will become a member. Depending on the Third Party Account
                    you choose and subject to the privacy settings that you have
                    set in such Third Party Account, you understand that by
                    granting us access to the Third Party Account, we will
                    access, make available and store (if applicable and as
                    permitted by the Third Party service provider and authorized
                    by you) the information in your Third Party Account so that
                    it is available on and through your account on the Platform.
                  </li>
                  <li>
                    For the purposes of creation of an Account on the Platform,
                    Users will be required to disclose certain SPDI (Sensitive
                    personal data or information) including credit card/debit
                    card detail for payment purposes, required by payment
                    gateways and financial institutions to consummate a
                    transaction. We do not gain any access or store any SPDI
                    related to financial transactions on our Platform; such SPDI
                    is availed directly by the payment gateway via an
                    integration with our Platform and we ensure to utilize
                    payment gateways of repute with industry accepted
                    credentials and security measures. The type of Information
                    collected from a User varies based on the interaction with
                    the Platform. GAMEHUNT undertakes to protect the data
                    provided by you in accordance with reasonable security
                    practices and procedures. GAMEHUNT undertakes not to
                    transfer or disclose your SPDI to a third party for any
                    purpose without your prior consent.
                  </li>
                  <li>
                    GAMEHUNT reserves the right to disclose your Information,
                    including, without limitation, your name, city, state,
                    telephone number, email address and activity history on the
                    Platform, to law enforcement agencies or other government
                    officials if we are required to do so by law, if the same is
                    required to respond to any court orders or other legal
                    process, which shall be without notice to you. At any point
                    of time, if required so, we will also disclose regulation or
                    any other governmental authority or otherwise in cooperation
                    with an investigation of a governmental authority.
                  </li>
                </ol>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <p>
                  <b>Cookies Policy:</b>Cookies are small pieces of information
                  saved by your browser. Cookies are used to record various
                  aspects of your visit and assist us to provide you with
                  uninterrupted service. They also allow ad networks to, among
                  other things, deliver targeted advertisements that they
                  believe will be of most interest to you. Cookies may be set in
                  your browser by us when you access the Platform or may be set
                  in when you visit Third Party websites.
                </p>
                <p>
                  This anonymous information is maintained distinctly and is not
                  linked to the Information that you submit to us. We use
                  cookies collected to:
                </p>
                <ul type="circle">
                  <li>Enable our security features;</li>
                  <li>Authenticate your login information;</li>
                  <li>
                    Improve and develop the features of the Website. Please feel
                    free to change your browser settings if you do not wish to
                    accept cookies. However, please note that changing your
                    browser setting may affect your experience on the Platform;
                  </li>
                  <li>Contextualize and show you advertising.</li>
                </ul>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>DISCLAIMER</h3>
                <p>
                  Every contents existing on the GAMEHUNT is edited, checked,
                  and verified for accuracy as much as it is possible to do so.
                  However, we do not guarantee either its accuracy or the safety
                  of any external links it might contain. GAMEHUNT, as well as
                  its owners, affiliates, and contributing authors shall
                  therefore not be held responsible for any problems or damage
                  that occurs as a result of making use of material contained on
                  our site.
                </p>
                <p>
                  Nothing in these terms of use excludes or limits our liability
                  for death or personal injury arising from our negligence, or
                  our fraud or fraudulent misrepresentation, or any other
                  liability that cannot be excluded or limited by the law of
                  land where the company is established.
                </p>
                <p>
                  To the extent permitted by law, we exclude all conditions,
                  warranties, representations or other terms which may apply to
                  our site or any content on it, whether express or implied.
                </p>
                <p>
                  <b>
                    We will not be liable to any user for any loss or damage,
                    whether in contract, tort (including negligence), breach of
                    statutory duty, or otherwise, even if foreseeable, arising
                    under or in connection with:
                  </b>
                </p>
                <ul>
                  <li>use of, or inability to use, our site; or</li>
                  <li>
                    use of or reliance on any content displayed on our site.
                  </li>
                </ul>
                <p>
                  <b>
                    If you are a business user, please note that in particular,
                    we will not be liable for:
                  </b>
                </p>
                <ul>
                  <li>loss of profits, sales, business, or revenue;</li>
                  <li>business interruption;</li>
                  <li>loss of anticipated savings;</li>
                  <li>
                    loss of business opportunity, goodwill or reputation; or
                  </li>
                  <li>any indirect or consequential loss or damage.</li>
                </ul>
                <p>
                  <b>
                    If you are a consumer user, please note that we only provide
                    our site for domestic and private use. you agree not to use
                    our site for any commercial or business purposes, and we
                    have no liability to you for any loss of profit, loss of
                    business, business interruption, or loss of business
                    opportunity.
                  </b>
                </p>
                <p>
                  We accept no responsibility for the content of websites linked
                  to on our site. such links should not be interpreted as
                  endorsement by us of those linked websites. We will not be
                  liable for any loss or damage that may arise from your use of
                  them.
                </p>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>COPYRIGHT</h3>
                <p>
                  All of the contents presented on the GAMEHUNT website is,
                  unless explicitly stated otherwise, subject to a copyright
                  held by GAMEHUNT. It is permissible to link to content from
                  this site as long as the original source is clearly stated,
                  but the wholesale reproduction or partial modification of
                  content is not permitted. Exceptions are granted only if you
                  receive prior written consent from GAMEHUNT.
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>GOVERNING LAW</h3>
                <p>
                  By accessing GAMEHUNT you agree that the laws prevailing in
                  India shall be the governing laws in all matters relating to
                  GAMEHUNT as well as these terms and conditions. Courts at
                  Mumbai, Maharashtra, India, alone shall have the exclusive
                  jurisdiction in all matters relating to GAMEHUNT and these
                  terms and conditions, irrespective of the territory and
                  jurisdiction of your access to GAMEHUNT.
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h3>CONTACT US</h3>
                <p>
                  If you have any queries about this Agreement, please feel free
                  to contact us at:
                </p>
                <p className="mb-0">
                  <b>M/S GAMEHUNT SPORTS INDIA PRIVATE LIMITED</b>
                </p>
                <p className="mb-0">
                  <b>Add</b>- 66/402, Evershine Millenium Paradise,
                </p>
                <p className="mb-0">Thakur Village, Kandivali East,Mumbai,</p>
                <p>Maharashtra, Pincode-400101, India.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default PrivacyPolicy;
