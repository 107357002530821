import React, { useCallback, useEffect, useState } from "react";
import { Drawer } from "antd";
import "antd/dist/antd.css";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import WeekStrip from "react-weekstrip-daypicker";
import {
  AllReadyCreatedEventdetailReducer,
  TimeSlotListReducer,
} from "../slice";
import moment from "moment";

function EditBooking({ onClose, visible, getlist, id }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [changedate, setChangeDate] = useState("");
  const [courtId, setCourtId] = useState();
  const [totalAmount, setTotalAmount] = useState("");
  const [peopleCount, setPeopleCount] = useState("");

  const detail = useSelector((state) => state.main.AllReadyCreatedEventdetail);

  const getAllReadyCreatedEvent = useCallback(async () => {
    dispatch(
      AllReadyCreatedEventdetailReducer({
        eventbookingId: id,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        setChangeDate(
          moment(res?.payload?.data?.date, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
        setCourtId(res?.payload?.data?.court?._id);
        setPeopleCount(res?.payload?.data?.addPeople);
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    getAllReadyCreatedEvent();
  }, [getAllReadyCreatedEvent]);

  const timeSlotlist = useSelector((state) => state.main.timeSlot);

  //const [timeSlotlist, setTimeSlotlist] = useState([]);

  const gettimeSlotList = useCallback(async () => {
    dispatch(
      TimeSlotListReducer({
        date: changedate,
        courtId: courtId,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        let arr = res?.payload?.data?.timeSlot;
        //setTimeSlotlist(arr);
      }
    });
  }, [dispatch, changedate, courtId]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      gettimeSlotList();
    }
    return () => {
      mounted = false;
    };
  }, [gettimeSlotList]);

  const handleAmount = (values, setFieldValue, arr, count, amount) => {
    if (arr?.length > 0) {
      let amp = 0;
      arr?.forEach((item) => {
        amp = item?.price * count + amp;
        //console.log(amp);
      });
      setFieldValue("totalAmount", amp - detail?.cost);
      //   setPeopleCount(count);
      setTotalAmount(amp - detail?.cost);
    } else {
      setFieldValue("totalAmount", 0);
    }
  };

  const initialValues = {
    costSlot: detail?.cost || 0,
    totalAmount: 0,
    peopleCount: peopleCount,
    slot: detail?.slot ? detail?.slot : [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      description: Yup.string().required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      console.log(values);
      setLoading(true);

      const payload = {
        sports: values.sports,
        maxCapacity_name: values.maxCapacity,
        minCapacity: values.minCapacity,
        description: values.description,
        courtTrufNumber: values.courtTrufNumber,
        rulesandregulations: values.rulesandregulations,
        picture: JSON.stringify([
          {
            image: values.imageName,
          },
          {
            image: values.imageName1,
          },
          {
            image: values.imageName2,
          },
        ]),
      };
      console.log(payload);
      // dispatch(
      //   AddBatchReducer(payload)
      // ).then((data) => {
      //   setLoading(false);
      //   if (data?.payload?.code === 1) {
      //     toast.success(data.payload.message);
      //     getlist();
      //   } else {
      //     setLoading(false);
      //     toast.error(data.payload.message);
      //   }
      // });
    },
  });

  return (
    <>
      <Drawer placement="right" onClose={onClose} visible={visible}>
        <div className="">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3 fw-bold">Edit Booking</h2>
          </div>
          <form className="form-style formCavas" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container bg-white p-2 raunded">
                    {changedate && (
                      <WeekStrip
                        //datesWhitelist={datesWhitelist}
                        //date={changedate}
                        onWeekChange={(weeks) => {
                          let weakDate = weeks.format("YYYY-MM-DD");
                          console.log(
                            weeks.format("DD/MM/YYYY"),
                            "weeksChange"
                          );
                          setChangeDate(weakDate);
                        }}
                        onChange={(value) => {
                          let PartuDate = value.format("YYYY-MM-DD");
                          console.log(value.format("DD/MM/YYYY"));
                          setChangeDate(PartuDate);
                        }}
                        initDay={changedate || undefined}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 mb-4">
                <div className="row custom-radio">
                  {timeSlotlist?.length > 0 ? (
                    timeSlotlist?.map((item, i) => (
                      <div
                        className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3"
                        key={i}
                      >
                        <div className="radio-item h-100">
                          <input
                            type="checkbox"
                            //value={timeSlotlist?.[i]}
                            checked={
                              formik.values?.slot?.findIndex((it) => {
                                return (
                                  it?.timeSlotStart === item?.timeSlotStart &&
                                  it?.timeSlotEnd === item?.timeSlotEnd &&
                                  it?.price === item?.price
                                );
                              }) !== -1
                            }
                            name="slot"
                            id={`timeslot${i}`}
                            onChange={(e) => {
                              let checked = e.target.checked;
                              let arr = [...formik.values.slot];
                              let index = arr?.indexOf(item);
                              if (index === -1 && checked) {
                                arr.push(item);
                              } else if (index !== -1 && !checked) {
                                arr.splice(index, 1);
                              }
                              formik.setFieldValue("slot", arr);
                              handleAmount(
                                formik.values,
                                formik.setFieldValue,
                                arr,
                                formik.values?.peopleCount,
                                formik.values?.totalAmount
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="label-icon timeSlotAdd"
                            htmlFor={`timeslot${i}`}
                          >
                            <p>
                              {moment(item?.timeSlotStart, "hh:mm:ss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(item?.timeSlotEnd, "hh:mm:ss").format(
                                "hh:mm A"
                              )}
                            </p>
                            <p>
                              <i className="fa fa-inr"></i> {item?.price || "0"}
                            </p>
                            <div className="PlusRemoveIcon"></div>
                          </label>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center mb-3">No Data Found</div>
                  )}
                  {formik.errors.slot && formik.touched.slot && (
                    <div className="text-red position-static">
                      {" "}
                      {formik.errors.slot}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-sm-12 col-md-12 text-center">
                <button
                  type="submit"
                  className="btn btn-submit w-50"
                  // onClose={onClose}
                  // onClick={() => {
                  //   setModalName("AddSlot");
                  //   showDrawer();
                  // }}
                >
                  {loading ? <Loader /> : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
}

export default EditBooking;
