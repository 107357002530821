import React from "react";
import FacebookLogin from "react-facebook-login";
import { useDispatch } from "react-redux";
import { SocialLoginReducer } from "../../features/slice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const FacebookSocialLogin = ({ handleShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appId = "2050419521662610";

  const responseFacebook = (res, status) => {
    //console.log("Login facebook", res);
    const name = res.name;
    const array = name.split(" ");
    localStorage.setItem("accessToken", res?.accessToken);
    const fab = localStorage.getItem("accessToken");

    if (res.accessToken === fab) {
      //console.log("close");
      dispatch(
        SocialLoginReducer({
          facebookId: res.id,
          firstName: array[0],
          lastName: array[1],
          email: res.email,
        })
      ).then(async (res) => {
        //console.log(res.data);
        if (res?.payload?.code === 1) {
          await localStorage.setItem("token", res?.payload?.data?.token);
          await localStorage.setItem("loginType", "user");
          toast.success(res?.payload?.message);
          navigate("/", {
            replace: true,
          });
          handleShow();
        }
      });
    } else {
      handleShow();
    }
  };
  const componentClicked = (data) => {
    console.log("data", data);
  };
  return (
    <div>
      <FacebookLogin
        appId={appId}
        autoLoad={false}
        fields="name,email,picture"
        onClick={componentClicked}
        callback={responseFacebook}
      />
    </div>
  );
};

export default FacebookSocialLogin;
