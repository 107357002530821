import axios from "axios";
import { toast } from "react-toastify";
var querystring = require("querystring");
const BASE_URL = "https://api.thegamehunt.com/api/";
//const BASE_URL = "http://13.233.104.251:8080/api/";

export const postFormDataNoToken = async (url = "", data = {}) => {
  try {
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post(BASE_URL + url, formData);

    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
};
export const postFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post(BASE_URL + url, formData, {
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
};
export const patchFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.patch(BASE_URL + url, formData, {
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
};
export const postRawData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.post(BASE_URL + url, data, {
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
};

export const getDataNoToken = async (url = "", data = {}) => {
  try {
    const response = await axios.get(BASE_URL + url, {
      params: data,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getDataforUrl = async (url = "", data = "") => {
  try {
    const response = await axios.get(BASE_URL + url + `/${data}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getDataParamsAndUrl = async (url = "", data = {}) => {
  try {
    const response = await axios.get(
      BASE_URL +
        url +
        `/${data.id}?fromDate=${data.fromDate}&toDate=${data.toDate}`
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url, {
      params: data,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getDataAndDownload = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios({
      url: BASE_URL + url, //your url
      method: "GET",
      responseType: "blob", // important
      params: data,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getDataStringify = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const response = await axios.get(BASE_URL + url, {
      params: data,

      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postDataURLIncoded = async (url = "", data = {}) => {
  try {
    //const token = await localStorage.getItem("token");
    const formData = new URLSearchParams();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    //console.log(data);
    const response = await axios.post(
      BASE_URL + url,
      querystring.stringify(data),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    //console.log(response);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const putFormData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.put(BASE_URL + url, formData, {
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteData = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.delete(BASE_URL + url, {
      headers: {
        token: token,
      },
      data: formData,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postFormDataChekoutPayment = async (url = "", data = {}) => {
  try {
    const token = await localStorage.getItem("token");

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    const response = await axios.post(url, formData, {
      headers: "Access-Control-Allow-Origin: *",
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return error.response.data;
  }
};
