import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { VenueOwnerEditProfileApi } from "../api/CoachVenuesetting";
import {
  VenueOwnerDetailReducer,
  VenueOwnerNotifiactionCountReducer,
} from "../features/CoachVenueslice";

function Header({ className }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [showdropdown, setShowdropdown] = useState(false);
  const [settingCollespe, setSettingCollespe] = useState(false);
  const [DropMenu, setDropMenu] = useState(false);

  const loginDetail = useSelector(
    (state) => state.CoachVenueMain.VenueOwnerDetail
  );
  const handleSettingCollespe = () => {
    setSettingCollespe(!settingCollespe);
  };
  const handleDropMenu = () => {
    setDropMenu(!DropMenu);
  };

  const getvenueDetail = useCallback(async () => {
    dispatch(VenueOwnerDetailReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getvenueDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getvenueDetail]);

  async function logout(e) {
    await localStorage.clear();
    navigate("/");
  }
  const handleShowDrop = () => {
    setShowdropdown(!showdropdown);
  };

  const UpdateStatus = (data) => {
    //console.log(data);
    VenueOwnerEditProfileApi(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getvenueDetail();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  const list = useSelector(
    (state) => state.CoachVenueMain.VenueOwnerNotifiactionCount
  );

  const getList = useCallback(async () => {
    dispatch(
      VenueOwnerNotifiactionCountReducer({
        status: "0",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getList();
    }
    return () => {
      mounted = false;
    };
  }, [getList]);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowdropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });

  return (
    <>
      <div className={`header-top-tab navbar-expand-lg ${className}`}>
        <div className="toggleButton">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              handleShowDrop();
            }}
          >
            <i className="fa fa-bars"></i>
            {/* <i className="menu-toggle-img ms-1"></i> */}
          </button>
        </div>
        <div className="text-center">
          <Link to="/">
            <img
              src="assets/img/logo.png"
              className="logo-icon1"
              alt="game hunt"
              title="game hunt"
            />
          </Link>
        </div>

        <div
          className={showdropdown ? "menurespo show" : "menurespo"}
          id="menuCollepse"
        >
          <ul className="navbar-nav m-auto py-lg-0 align-items-center gg-10">
            <li className="navbar-items">
              <Link
                to="/venue/dashboard"
                className={
                  pathname.includes("dashboard")
                    ? "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text active"
                    : "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text"
                }
                onClick={() => {
                  handleShowDrop();
                }}
              >
                <img
                  src="assets/img/dashboardMenu.png"
                  className="m-auto link-img"
                  alt=""
                />
                <img
                  src="assets/img/dashboardmrnuactv.png"
                  className="m-auto link-img-actv"
                  alt=""
                />
                dashboard
              </Link>
            </li>
            <li className="navbar-items">
              <Link
                to="/venue/bookings"
                className={
                  pathname.includes("booking")
                    ? "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text active"
                    : "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text"
                }
                onClick={() => {
                  handleShowDrop();
                }}
              >
                <img
                  src="assets/img/ManageBookingMenu.png"
                  className="m-auto link-img"
                  alt=""
                />
                <img
                  src="assets/img/Managebookingmrnuactv.png"
                  className="m-auto link-img-actv"
                  alt=""
                />
                bookings
              </Link>
            </li>
            <li className="navbar-items">
              <Link
                to="/venue/courts-turfs"
                className={
                  pathname.includes("courts")
                    ? "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text active"
                    : "d-grid align-items-center gg-10 text-capitalize text-decoration-none link-img-text"
                }
                onClick={() => {
                  handleShowDrop();
                }}
              >
                <img
                  src="assets/img/courts-trufs.png"
                  className="m-auto link-img"
                  alt=""
                />
                <img
                  src="assets/img/courts-trufsactv.png"
                  className="m-auto link-img-actv"
                  alt=""
                />
                courts/turfs
              </Link>
            </li>
          </ul>
        </div>
        <div className="noti-flex">
          <Link
            to="/venue/notification"
            className="notificatin-drop cursor-pointer"
          >
            <img src="assets/img/notification.png" className="" alt="" />
            <div
              className={list?.total_notification === 0 ? "d-none" : "numNoti"}
            >
              {list?.total_notification >= 10 ? "9+" : list?.total_notification}
            </div>
          </Link>
          <div className="profile-drop cursor-pointer position-relative">
            <div
              className="tabs-list shadow"
              onClick={() => {
                handleDropMenu();
                setShowdropdown(false);
              }}
            >
              <span>
                <img
                  src={
                    loginDetail?.picture?.includes("http")
                      ? loginDetail?.picture
                      : "assets/img/employe.png"
                  }
                  className="profile-img"
                  alt=""
                />
              </span>
              <span>
                <h5 className="mb-0">{loginDetail?.name || "venue owner"}</h5>
                <p className="mb-0">owner</p>
              </span>
            </div>
            <div
              className={
                DropMenu
                  ? "profile-collapse notificatin-box coachProfileMenu"
                  : "profile-collapse hideDrop notificatin-box coachProfileMenu"
              }
            >
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/register.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  to="/venue/venue-detail"
                  className={
                    pathname.includes("venue-detail") ? "theme-color" : ""
                  }
                >
                  <h6>General Detail</h6>
                </Link>
              </div>

              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/wallet_icon.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  to="/venue/my-earning"
                  className={
                    pathname.includes("my-earning") ? "theme-color" : ""
                  }
                >
                  <h6>My Earnings</h6>
                </Link>
              </div>
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/account-info.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  to="/venue/account-info"
                  className={
                    pathname.includes("account-info") ? "theme-color" : ""
                  }
                >
                  <h6>account info</h6>
                </Link>
              </div>
              <div
                className="text-start position-relative mb-3"
                onClick={() => {
                  handleDropMenu();
                }}
              >
                <img
                  src="assets/img/rate_review-drop.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <Link
                  to="/venue/review-ratings"
                  className={
                    pathname.includes("review-ratings") ? "theme-color" : ""
                  }
                >
                  <h6>Review and Ratings</h6>
                </Link>
              </div>
              <div className="text-start position-relative mb-3">
                <img
                  src="assets/img/settings_outline_icon.png"
                  alt=""
                  className="coachProfileMenuIcon"
                />
                <span
                  className=""
                  onClick={() => {
                    handleSettingCollespe();
                  }}
                >
                  <h6>Setting</h6>
                </span>
                <ul
                  className={
                    settingCollespe
                      ? "collapse ps-0 setting-nav list-style-none show"
                      : "collapse ps-0 setting-nav list-style-none"
                  }
                  id="collapseExample"
                >
                  <li
                    onClick={() => {
                      handleDropMenu();
                    }}
                  >
                    <Link
                      className={
                        pathname.includes("edit-profile")
                          ? "dropdown-item theme-color"
                          : "dropdown-item"
                      }
                      to="/venue/edit-profile"
                    >
                      Edit Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        pathname.includes("change-password")
                          ? "dropdown-item theme-color"
                          : "dropdown-item"
                      }
                      to="/venue/change-password"
                    >
                      Change Password
                    </Link>
                  </li>
                  <li className="d-flex align-items-center justify-content-between">
                    <span>Turn Notification</span>
                    <span className="btn p-0">
                      <input
                        type="checkbox"
                        className="toggle"
                        id=""
                        checked={
                          loginDetail?.notification_permission === "allow"
                        }
                        onChange={() => {
                          UpdateStatus({
                            notification_permission:
                              loginDetail?.notification_permission === "allow"
                                ? "deny"
                                : "allow",
                          });
                        }}
                      />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="text-center mt-4">
                <span
                  className="fw-bold"
                  style={{ color: "#ff2f2f" }}
                  onClick={(e) => {
                    logout(e);
                  }}
                >
                  <img
                    src="assets/img/logout.png"
                    className=""
                    alt=""
                    width="14px"
                  />{" "}
                  Logout
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
