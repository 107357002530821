import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { VenueBookingListReducer } from "../../CoachVenueslice";
import Paginations from "../../../Components/Paginations";
import CancelBookingModal from "../../../Components/Modals/CancelBookingModal";
import { venueOwenerBookingApproRejectApi } from "../../../api/CoachVenuesetting";
import VenueBookingDetail from "../../../Components/VenueBookingDetail";
import VenuePutRatingReviewModal from "../../../Components/VenuePutRatingReviewModal";

function Booking() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("upcoming");
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [eventStatus, setEventStatus] = useState("");
  const handlePage = (value) => {
    setPage(value);
  };
  const handleShow = () => {
    setShow(!show);
  };

  const list = useSelector((state) => state.CoachVenueMain.VenueBookingList);
  const total = useSelector(
    (state) => state.CoachVenueMain.total_VenueBookingList
  );

  const getVenueBokingList = useCallback(async () => {
    setLoading(true);
    dispatch(
      VenueBookingListReducer({
        progress: status,
        page: page,
        limit: 10,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, status]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getVenueBokingList();
    }
    return () => {
      mounted = false;
    };
  }, [getVenueBokingList]);

  const UpdateApprovReject = (data) => {
    //console.log(data);
    venueOwenerBookingApproRejectApi(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getVenueBokingList();
      } else {
        toast?.error(res?.message);
      }
    });
  };
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">Bookings</h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-start bookingTabs tabsnew gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active text-capitalize"
                      id="pills-upcoming-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-upcoming"
                      type="button"
                      role="tab"
                      aria-controls="pills-upcoming"
                      aria-selected="true"
                      onClick={() => {
                        setStatus("upcoming");
                      }}
                    >
                      upcoming
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link text-capitalize"
                      id="pills-current-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-current"
                      type="button"
                      role="tab"
                      aria-controls="pills-current"
                      aria-selected="false"
                      onClick={() => {
                        setStatus("completed");
                      }}
                    >
                      completed
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link text-capitalize"
                      id="pills-cancelled-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-cancelled"
                      type="button"
                      role="tab"
                      aria-controls="pills-cancelled"
                      aria-selected="false"
                      onClick={() => {
                        setStatus("cancelled");
                      }}
                    >
                      cancelled
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 venueBookingTbl">
                <div className="tab-content mt-4" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-upcoming"
                    role="tabpanel"
                    aria-labelledby="pills-upcoming-tab"
                  >
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">name</th>
                            <th scope="col">booking ID</th>
                            <th scope="col">type of booking</th>
                            <th scope="col">date</th>
                            <th scope="col">sports</th>
                            <th scope="col">booked with coach</th>
                            <th scope="col">court/turf</th>
                            <th scope="col">gender</th>
                            <th scope="col" className="noPeopleWidthTd">
                              no. of people booked the slot
                            </th>
                            <th scope="col" className="noPeopleWidthTd">
                              min no. of people required for the slot
                            </th>
                            <th scope="col"></th>
                            <th scope="col">action</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.createdBy?.picture?.includes("http")
                                        ? item?.createdBy?.picture
                                        : "assets/img/employe.png"
                                    }
                                    className="table-img"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  {item?.createdBy?.firstName
                                    ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                    : "NA"}
                                </td>
                                <td>{item?.bookingId || "NA"}</td>
                                <td>
                                  {item?.fullBooking === false
                                    ? item?.eventType
                                    : "Full Booking"}
                                </td>
                                <td>
                                  {moment(item?.date).format("ll")}
                                  <br />
                                  {/* {moment(
                                    item?.slot[0]?.timeSlotStart,
                                    "hh:mm:ss"
                                  ).format("hh:mm a")}{" "}
                                  -{" "}
                                  {moment(
                                    item?.slot[0]?.timeSlotEnd,
                                    "hh:mm:ss"
                                  ).format("hh:mm a")} */}
                                </td>
                                <td>{item?.sport || "NA"}</td>
                                <td>{item?.isCoach === true ? "yes" : "No"}</td>
                                <td>
                                  {item?.courts
                                    ?.map((item) => {
                                      return item?.name;
                                    })
                                    ?.join(", ")}
                                </td>
                                <td>{item?.gender || "NA"}</td>
                                <td>{item?.addPeople || "0"}</td>
                                <td>{item?.min || "0"}</td>

                                <td>
                                  <span
                                    className="StatusBtn cancelBtn"
                                    onClick={() => {
                                      setId(item?._id);
                                      setModalName("CancelBooking");
                                      handleShow();
                                    }}
                                  >
                                    cancel
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action">
                                    <button
                                      className="btn px-0"
                                      onClick={() => {
                                        setId(item?._id);
                                        setModalName("ViewBooking");
                                        setEventStatus(item?.progress);
                                        handleShow();
                                      }}
                                    >
                                      <img src="assets/img/view.png" alt="" />
                                    </button>
                                    <button
                                      className={
                                        item?.status === "0"
                                          ? "btn px-1"
                                          : "d-none"
                                      }
                                      onClick={() => {
                                        UpdateApprovReject({
                                          eventbookingId: item?._id,
                                          status: "1",
                                        });
                                      }}
                                    >
                                      <img
                                        src="assets/img/approve.png"
                                        alt=""
                                        width="18px"
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="13">No Records Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center">
                      {list?.length > 0 && (
                        <Paginations
                          handlePage={handlePage}
                          page={page}
                          total={total && total}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-current"
                    role="tabpanel"
                    aria-labelledby="pills-current-tab"
                  >
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">name</th>
                            <th scope="col">booking ID</th>
                            <th scope="col">type of booking</th>
                            <th scope="col">date</th>
                            <th scope="col">sports</th>
                            <th scope="col">court/turf</th>
                            <th scope="col" className="noPeopleWidthTd">
                              no. of people on the slot
                            </th>
                            <th scope="col">amount</th>
                            <th scope="col">status</th>
                            <th scope="col">give reviews</th>
                            <th scope="col">action</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.createdBy?.picture?.includes("http")
                                        ? item?.createdBy?.picture
                                        : "assets/img/employe.png"
                                    }
                                    className="table-img"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  {item?.createdBy?.firstName
                                    ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                    : "NA"}
                                </td>
                                <td>{item?.bookingId || "NA"}</td>
                                <td>
                                  {item?.fullBooking === false
                                    ? item?.eventType
                                    : "Full Booking"}
                                </td>
                                <td>
                                  {moment(item?.date).format("ll")} <br />
                                  {/* 11:00am - 01:00pm */}
                                </td>
                                <td>{item?.sport || "NA"}</td>
                                <td>
                                  {item?.courts
                                    ?.map((item) => {
                                      return item?.name;
                                    })
                                    ?.join(", ")}
                                </td>
                                <td>{item?.addPeople || "0"}</td>
                                <td>
                                  <i className="fa fa-inr"></i>
                                  {parseFloat(item?.total).toFixed(2) || "0"}
                                </td>
                                <td>
                                  <span className="StatusBtn approvedStatus">
                                    {item?.progress}
                                  </span>
                                </td>
                                <td>
                                  <img
                                    src="assets/img/rate_review.png"
                                    className="cursor-pointer"
                                    alt=""
                                    onClick={() => {
                                      setId(item?._id);
                                      setModalName("ReviewModal");
                                      handleShow();
                                    }}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action">
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        setId(item?._id);
                                        setModalName("ViewBooking");
                                        setEventStatus(item?.progress);
                                        handleShow();
                                      }}
                                    >
                                      <img src="assets/img/view.png" alt="" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="13">No Records Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center">
                      {list?.length > 0 && (
                        <Paginations
                          handlePage={handlePage}
                          page={page}
                          total={total && total}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-cancelled"
                    role="tabpanel"
                    aria-labelledby="pills-cancelled-tab"
                  >
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">name</th>
                            <th scope="col">booking ID</th>
                            <th scope="col">type of booking</th>
                            <th scope="col">date</th>
                            <th scope="col">sports</th>
                            <th scope="col">amount</th>
                            <th scope="col"></th>
                            <th scope="col">action</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.createdBy?.picture?.includes("http")
                                        ? item?.createdBy?.picture
                                        : "assets/img/employe.png"
                                    }
                                    className="table-img"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  {item?.createdBy?.firstName
                                    ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                    : "NA"}
                                </td>
                                <td>{item?.bookingId || "NA"}</td>
                                <td>
                                  {item?.fullBooking === false
                                    ? item?.eventType
                                    : "Full Booking"}
                                </td>
                                <td>
                                  {moment(item?.date).format("ll")} <br />
                                </td>
                                <td>{item?.sport || "NA"}</td>
                                <td>
                                  <i className="fa fa-inr"></i>{" "}
                                  {item?.total
                                    ? parseFloat(item?.total).toFixed(2)
                                    : "0"}
                                </td>
                                <td align="right">
                                  <span
                                    className={
                                      item?.refundStatus === "refunded"
                                        ? "StatusBtn approvedStatus"
                                        : "StatusBtn pendingStatus"
                                    }
                                  >
                                    {item?.refundStatus}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action">
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        setId(item?._id);
                                        setModalName("ViewBooking");
                                        setEventStatus(item?.refundStatus);
                                        handleShow();
                                      }}
                                    >
                                      <img src="assets/img/view.png" alt="" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="9">No Records Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center">
                      {list?.length > 0 && (
                        <Paginations
                          handlePage={handlePage}
                          page={page}
                          total={total && total}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {modalName === "CancelBooking" && id && (
        <CancelBookingModal
          handleShow={handleShow}
          show={show}
          getList={getVenueBokingList}
          id={id}
        />
      )}
      {modalName === "ViewBooking" && id && (
        <VenueBookingDetail
          handleShow={handleShow}
          show={show}
          getList={getVenueBokingList}
          id={id}
          eventStatus={eventStatus}
        />
      )}
      {modalName === "ReviewModal" && id && (
        <VenuePutRatingReviewModal
          show={show}
          handleShow={handleShow}
          id={id}
          getList={getVenueBokingList}
        />
      )}
    </>
  );
}

export default Booking;
