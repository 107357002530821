import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, isObject } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import {
  CoachVenueOTPVerificationReducer,
  CoachVenueResendOTPReducer,
} from "../../features/CoachVenueslice";

function CoachVenueOTPVerification({ show, handleShow, setModalName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginImg = localStorage.getItem("loginImg");
  const [loading, setLoading] = useState(false);
  const OTPVerify = localStorage.getItem("OTPVerify");
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, minutes]);

  const resendCode = () => {
    dispatch(
      CoachVenueResendOTPReducer({
        user_type: loginImg === "coachLogin" ? "coach" : "venueowner",
        otp_for:
          OTPVerify === "SignUpOTPVerifyEmailVenue"
            ? "signup_via_email"
            : OTPVerify === "SignUpOTPVerifyEmailCoach"
            ? "signup_via_email"
            : "forgot_password",
        email: email,
      })
    ).then((data) => {
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div
                    className={
                      loginImg === "venueLogin"
                        ? "col-sm-12 col-md-12 col-lg-6 bgloginImgvenue pb-1"
                        : loginImg === "coachLogin"
                        ? "col-sm-12 col-md-12 col-lg-6 bgloginImgCoach pb-1"
                        : "col-sm-12 col-md-12 col-lg-6 bgloginImgvenue pb-1"
                    }
                  >
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6  mt-lg-5">
                    <div className="form-style mt-md-5 px-4">
                      <Formik
                        initialValues={{ otp: "" }}
                        validationSchema={Yup.object({
                          otp: Yup.string()
                            .required("Required")
                            .matches(/^[0-9]+$/, "Must be only digits")
                            .min(6, "Kindly enter valid otp.")
                            .max(6, "Kindly enter valid otp."),
                        })}
                        onSubmit={(values, { isSubmitting, resetForm }) => {
                          setLoading(true);
                          dispatch(
                            CoachVenueOTPVerificationReducer({
                              code: values.otp,
                              user_type:
                                loginImg === "coachLogin"
                                  ? "coach"
                                  : "venueowner",
                              otp_for:
                                OTPVerify === "SignUpOTPVerifyEmailVenue"
                                  ? "signup_via_email"
                                  : OTPVerify === "SignUpOTPVerifyEmailCoach"
                                  ? "signup_via_email"
                                  : "forgot_password",
                              email: email,
                              password: password,
                            })
                          ).then(async (data) => {
                            //console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 1) {
                              await localStorage.setItem(
                                "token",
                                data?.payload?.data?.token
                              );
                              toast.success(data?.payload?.message);
                              await localStorage.removeItem("OTPVerify");
                              await localStorage.removeItem("email");
                              await localStorage.removeItem("password");

                              if (OTPVerify === "SignUpOTPVerifyEmailVenue") {
                                await localStorage.setItem(
                                  "loginType",
                                  "venue"
                                );
                                if (
                                  data?.payload?.data?.user?.profileSetup === 0
                                ) {
                                  navigate("/venue/edit-profile", {
                                    replace: true,
                                  });
                                  handleShow();
                                } else {
                                  navigate("/venue/dashboard", {
                                    replace: true,
                                  });
                                  handleShow();
                                }
                              } else if (
                                OTPVerify === "SignUpOTPVerifyEmailCoach"
                              ) {
                                await localStorage.setItem(
                                  "loginType",
                                  "coach"
                                );
                                if (
                                  data?.payload?.data?.user?.profileSetup === 0
                                ) {
                                  navigate("/coach/edit-personal-info", {
                                    replace: true,
                                  });
                                  handleShow();
                                } else {
                                  navigate("/coach/manage-booking", {
                                    replace: true,
                                  });
                                  handleShow();
                                }
                              } else {
                                setModalName("CoachVenueResetPassword");
                              }
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <form className="row" onSubmit={handleSubmit}>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                              <h2 className="mdl-ttl">OTP Verification</h2>
                              <p>
                                Please enter 6 digit code which has been send on
                                your registered email Id: <b>{email}</b>
                              </p>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                              <div className="">
                                <img
                                  src="assets/img/OTPVery.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <OtpInput
                                isInputNum={true}
                                containerStyle="passcode-wrapper"
                                value={values.otp}
                                onChange={(val) => {
                                  setFieldValue("otp", val);
                                }}
                                inputStyle={"otpInputs"}
                                numInputs={6}
                                separator={<span> </span>}
                                keyboardType={"numeric"}
                                shouldAutoFocus={true}
                              />
                              {errors.otp && touched.otp && (
                                <div className="text-red">{errors.otp}</div>
                              )}
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-3">
                              <p className="otp-timing">
                                {" "}
                                <span>
                                  {minutes === 0 && seconds === 0 ? null : (
                                    <span>
                                      {" "}
                                      0{minutes}:
                                      {seconds < 10 ? `0${seconds}` : seconds}
                                    </span>
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center">
                              <button type="submit" className="btn btn-submit">
                                {loading ? <Loader /> : "Verify"}
                              </button>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                              <span
                                className={
                                  seconds === 0
                                    ? "forgot-pass cursor-pointer p-0"
                                    : "forgot-pass d-none p-0"
                                }
                                onClick={() => {
                                  resendCode();
                                  setMinutes(2);
                                  setSeconds(0);
                                }}
                              >
                                Resend Code
                              </span>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CoachVenueOTPVerification;
