import React, { useState, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useDispatch } from "react-redux";
import { SocialLoginReducer } from "../../features/slice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const GoogleSocialLogin = ({ handleShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientID =
    "43437833627-lijt2kanu4t43q9p8ocb5hpjja4qgjtu.apps.googleusercontent.com";
  useEffect(() => {
    function start() {
      gapi?.client?.init({
        clientId: clientID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  const handleSuccess = (res) => {
    //console.log("Login success", res.profileObj);
    const name = res.profileObj.name;
    const array = name.split(" ");

    dispatch(
      SocialLoginReducer({
        googleId: res.profileObj.googleId,
        firstName: array[0],
        lastName: array[1],
        email: res.profileObj.email,
      })
    ).then(async (res) => {
      //console.log(res.data);
      if (res?.payload?.code === 1) {
        await localStorage.setItem("token", res?.payload?.data?.token);
        await localStorage.setItem("loginType", "user");
        toast.success(res?.payload?.message);
        navigate("/", {
          replace: true,
        });
        handleShow();
      }
    });
  };

  return (
    <>
      <GoogleLogin
        clientId={clientID}
        buttonText="Login with Google"
        onSuccess={handleSuccess}
        // onFailure={handleFailure}
        //onClick={handleClick}
        cookiePolicy={"single_host_origin"}
        className="GoogleLogingBtn"
      />
    </>
  );
};

export default GoogleSocialLogin;
