import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  AddAccountRazorApi,
  AddCourtsAndTrufApi,
  CoachAddBankDetailApi,
  CoachBankDetailApi,
  CoachDetailApi,
  CoachEarningApi,
  CoachEarningChartApi,
  CoachEditBankDetailApi,
  CoachEditProfileApi,
  CoacheRatingReviewApi,
  coachHelpAndSupportApi,
  CoachNotificationCountApi,
  CoachNotificationListApi,
  CoachPutReviewRatingApi,
  CoachSignUpApi,
  CoachVenueBookingDetailApi,
  CoachVenueBookingListApi,
  CoachVenueChangePasswordApi,
  CoachVenueForgotPasswordApi,
  CoachVenueLoginApi,
  CoachVenueOTPVerificationApi,
  CoachVenueResendOTPApi,
  CoachVenueResetPasswordApi,
  CourtsAndTrufDetailApi,
  CourtsAndTrufEditApi,
  CourtsAndTrufListApi,
  VenueAddBankDetailApi,
  VenueBankDetailApi,
  VenueBookingListApi,
  VenueDashboardApi,
  VenueEarningApi,
  VenueEditBankDetailApi,
  VenueHelpAndSupportApi,
  VenueOwnerBookingCancelApi,
  VenueOwnerBookingDetailApi,
  VenueOwnerDetailApi,
  VenueOwnerEditProfileApi,
  VenueOwnerNotifiactionCountApi,
  VenueOwnerNotifiactionListApi,
  VenueOwnerReviewRatingListApi,
  VenuePutRatingReviewApi,
  VenueSignUpApi,
  VenueUpcomingEventBookingListApi,
} from "../api/CoachVenuesetting";

const initialState = {
  token: "",
  VenueOwnerDetail: {},
  CoachDetail: {},
  CoachBookingList: [],
  total_CoachBookingList: 0,
  CoachBookingDetail: {},
  CoachReviewRating: [],
  total_CoachReviewRating: 0,
  coachtotalReview: 0,
  CoachtotalRating: 0,
  CoachNotification: [],
  total_CoachNotification: 0,
  CoachEarning: {},
  CoachrecivedAmount: 0,
  CoachtotalAmount: 0,
  CoachAmount: 0,
  CoachNotificationCount: 0,
  CoachEarningChart: {},
  CoachBankDetail: {},
  // venueowner===============================================================================
  VenueReviewRating: {},
  CourtsTrufsList: [],
  total_CourtsTrufsList: 0,
  CourtsTrufsDetail: {},
  VenueBookingList: [],
  total_VenueBookingList: 0,
  VenueOwnerBookingDetail: {},
  VenueDashboar: [],
  Venuecompleted_appointments: 0,
  VenuetotalEarning: 0,
  Venuetotal_appointments: 0,
  Venueupcoming_appointments: 0,
  VenueNotification: 0,
  VenueUpcomingEventBooking: {},
  VenueEarning: {},
  VenueOwnerNotifiactionCount: 0,
  VenueBankDetail: {},
};

export const CoachVenueLoginReducer = createAsyncThunk(
  "CoachVenueLoginReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueLoginApi(obj);
      //console.log(response);
      await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const CoachVenueForgotPasswordReducer = createAsyncThunk(
  "CoachVenueForgotPasswordReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueForgotPasswordApi(obj);
      //console.log(response);
      //await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachVenueOTPVerificationReducer = createAsyncThunk(
  "CoachVenueOTPVerificationReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueOTPVerificationApi(obj);
      //console.log(response);
      //await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachVenueResendOTPReducer = createAsyncThunk(
  "CoachVenueResendOTPReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueResendOTPApi(obj);
      //console.log(response);
      //await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachVenueResetPasswordReducer = createAsyncThunk(
  "CoachVenueResetPasswordReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueResetPasswordApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachVenueChangePasswordReducer = createAsyncThunk(
  "CoachVenueChangePasswordReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueChangePasswordApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueSignUpReducer = createAsyncThunk(
  "VenueSignUpReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueSignUpApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachSignUpReducer = createAsyncThunk(
  "CoachSignUpReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachSignUpApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueOwnerDetailReducer = createAsyncThunk(
  "VenueOwnerDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueOwnerDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachDetailReducer = createAsyncThunk(
  "CoachDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const CoachVenueBookingListReducer = createAsyncThunk(
  "CoachVenueBookingListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueBookingListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachVenueBookingDetailReducer = createAsyncThunk(
  "CoachVenueBookingDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachVenueBookingDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachPutReviewRatingReducer = createAsyncThunk(
  "CoachPutReviewRatingReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachPutReviewRatingApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachNotificationListReducer = createAsyncThunk(
  "CoachNotificationListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachNotificationListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachNotificationCountReducer = createAsyncThunk(
  "CoachNotificationCountReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachNotificationCountApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachEditProfileReducer = createAsyncThunk(
  "CoachEditProfileReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachEditProfileApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachEarningReducer = createAsyncThunk(
  "CoachEarningReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachEarningApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachEarningChartReducer = createAsyncThunk(
  "CoachEarningChartReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachEarningChartApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoacheRatingReviewReducer = createAsyncThunk(
  "CoacheRatingReviewReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoacheRatingReviewApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const coachHelpAndSupportReducer = createAsyncThunk(
  "coachHelpAndSupportReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await coachHelpAndSupportApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const CoachAddBankDetailReducer = createAsyncThunk(
  "CoachAddBankDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachAddBankDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachBankDetailReducer = createAsyncThunk(
  "CoachBankDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachBankDetailApi(obj);
      console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachEditBankDetailReducer = createAsyncThunk(
  "CoachEditBankDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachEditBankDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// Venue reducer ========================================================================
export const CourtsAndTrufReducer = createAsyncThunk(
  "CourtsAndTrufReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CourtsAndTrufListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const AddCourtsAndTrufReducer = createAsyncThunk(
  "AddCourtsAndTrufReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AddCourtsAndTrufApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueBookingListReducer = createAsyncThunk(
  "VenueBookingListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueBookingListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueDashboardReducer = createAsyncThunk(
  "VenueDashboardReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueDashboardApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueOwnerEditProfileReducer = createAsyncThunk(
  "VenueOwnerEditProfileReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueOwnerEditProfileApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueOwnerBookingDetailReducer = createAsyncThunk(
  "VenueOwnerBookingDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueOwnerBookingDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CourtsAndTrufDetailReducer = createAsyncThunk(
  "CourtsAndTrufDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CourtsAndTrufDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CourtsAndTrufEditReducer = createAsyncThunk(
  "CourtsAndTrufEditReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CourtsAndTrufEditApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueOwnerBookingCancelReducer = createAsyncThunk(
  "VenueOwnerBookingCancelReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueOwnerBookingCancelApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueOwnerReviewRatingListReducer = createAsyncThunk(
  "VenueOwnerReviewRatingListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueOwnerReviewRatingListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueOwnerNotifiactionListReducer = createAsyncThunk(
  "VenueOwnerNotifiactionListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueOwnerNotifiactionListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueOwnerNotifiactionCountReducer = createAsyncThunk(
  "VenueOwnerNotifiactionCountReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueOwnerNotifiactionCountApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueUpcomingEventBookingListReducer = createAsyncThunk(
  "VenueUpcomingEventBookingListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueUpcomingEventBookingListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenuePutRatingReviewReducer = createAsyncThunk(
  "VenuePutRatingReviewReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenuePutRatingReviewApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueEarningReducer = createAsyncThunk(
  "VenueEarningReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueEarningApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueHelpAndSupportReducer = createAsyncThunk(
  "VenueHelpAndSupportReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueHelpAndSupportApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueAddBankDetailReducer = createAsyncThunk(
  "VenueAddBankDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueAddBankDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueBankDetailReducer = createAsyncThunk(
  "VenueBankDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueBankDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VenueEditBankDetailReducer = createAsyncThunk(
  "VenueEditBankDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VenueEditBankDetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);


export const CoachVenueMainSlice = createSlice({
  name: "coachVenueMainSlice",
  initialState,
  reducers: {
    clearList: (state, action) => {
      state.ProgramsList = action?.payload;
    },
    ChangePrograms: (state, action) => {
      state.programs = action?.payload;
    },
  },
  extraReducers: {
    [CoachVenueLoginReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.token = action?.payload?.data?.token;
    },
    [VenueOwnerDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueOwnerDetail = action?.payload?.data?.venueowner;
    },
    [CoachDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachDetail = action?.payload?.data?.coaches;
    },
    [CoachVenueBookingListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachBookingList = action?.payload?.data?.coachingappointment;
      state.total_CoachBookingList =
        action?.payload?.data?.total_coachingappointment;
    },
    [CoachVenueBookingDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachBookingDetail = action?.payload?.data?.coachingappointment;
    },
    [CoachNotificationListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachNotification = action?.payload?.data?.notification;
      state.total_CoachNotification = action?.payload?.data?.total_notification;
    },
    [CoachNotificationCountReducer.fulfilled]: (state, action) => {
      state.CoachNotificationCount = action?.payload?.data?.total_notification;
    },
    [CoachEarningReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachEarning = action?.payload?.data?.myaccount;
      state.CoachtotalAmount = action?.payload?.data?.totalAmount;
      state.CoachrecivedAmount = action?.payload?.data?.recivedAmount;
      state.CoachAmount = action?.payload?.data?.amount;
    },
    [CoachEarningChartReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachEarningChart = action?.payload?.data;
    },
    [CoacheRatingReviewReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachReviewRating = action?.payload?.data?.coachingappointment;
      state.total_CoachReviewRating = action?.payload?.data?.total_coachingappointment;
      state.CoachtotalRating = action?.payload?.data?.totalRating;
      state.coachtotalReview = action?.payload?.data?.totalReview;
    },
    [CoachBankDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachBankDetail = action?.payload?.data;
    },
    // Venue reducer====================================================================================
    [CourtsAndTrufReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CourtsTrufsList = action?.payload?.data?.courtandtruf;
      state.total_CourtsTrufsList = action?.payload?.data?.total_courtandtruf;
    },
    [CourtsAndTrufDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CourtsTrufsDetail = action?.payload?.data;
    },
    [VenueBookingListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueBookingList = action?.payload?.data?.eventbooking;
      state.total_VenueBookingList = action?.payload?.data?.total_eventbooking;
    },
    [VenueOwnerBookingDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueOwnerBookingDetail = action?.payload?.data;
    },
    [VenueOwnerReviewRatingListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueReviewRating = action?.payload?.data;
    },
    [VenueOwnerNotifiactionListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueNotification = action?.payload?.data;
    },
    [VenueOwnerNotifiactionCountReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueOwnerNotifiactionCount = action?.payload?.data;
    },
    [VenueDashboardReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueDashboar = action?.payload?.data?.earnings;
      state.Venuecompleted_appointments =
        action?.payload?.data?.completed_appointments;
      state.VenuetotalEarning = action?.payload?.data?.totalEarning;
      state.Venuetotal_appointments = action?.payload?.data?.total_appointments;
      state.Venueupcoming_appointments =
        action?.payload?.data?.upcoming_appointments;
    },
    [VenueUpcomingEventBookingListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueUpcomingEventBooking = action?.payload?.data;
    },
    [VenueEarningReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueEarning = action?.payload?.data;
    },
    [VenueBankDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueBankDetail = action?.payload?.data;
    },
  },
});

export const { clearList, ChangePrograms } = CoachVenueMainSlice.actions;

// export const { togglesuccess } = MainSlice.actions;

export default CoachVenueMainSlice.reducer;
