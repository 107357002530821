import React, { useCallback } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  VenueOwnerBookingCancelReducer,
  VenueOwnerBookingDetailReducer,
} from "../../features/CoachVenueslice";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  AllReadyCreatedEventdetailReducer,
  BookingCancelReducer,
} from "../../features/slice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function CancelBookingModal({ show, handleShow, getList, id, forUser, navi }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [detailData, setDetailData] = useState();

  const detail = useSelector(
    (state) => state.CoachVenueMain.VenueOwnerBookingDetail
  );
  const detailUserBooking = useSelector(
    (state) => state.main.AllReadyCreatedEventdetail
  );

  console.log(detailData);

  const getDetail = useCallback(async () => {
    if (forUser === "userSide") {
      dispatch(
        AllReadyCreatedEventdetailReducer({
          eventbookingId: id,
        })
      );
    } else {
      dispatch(
        VenueOwnerBookingDetailReducer({
          eventbookingId: id,
        })
      );
    }
  }, [dispatch, id, forUser]);

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  useEffect(() => {
    if (forUser === "userSide") {
      setDetailData(detailUserBooking);
    } else {
      setDetailData(detail);
    }
  }, [forUser, detailUserBooking, detail]);

  const initialValues = {
    canceledReason: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      canceledReason: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const payload = {
        status: 2,
        canceledReason: values.canceledReason,
        eventbookingId: id,
      };

      if (forUser === "userSide") {
        dispatch(BookingCancelReducer(payload)).then((data) => {
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            if (navi === "BookingConfirmationThenCencel") {
              handleShow();
              navigate("/my-booking", {
                replace: true,
              });
            } else {
              getList();
              handleShow();
            }
          } else {
            toast.error(data.payload.message);
          }
        });
      } else {
        dispatch(VenueOwnerBookingCancelReducer(payload)).then((data) => {
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            getList();
            handleShow();
          } else {
            toast.error(data.payload.message);
          }
        });
      }
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <form className="row" onSubmit={formik.handleSubmit}>
                  <div className="col-sm-12 col-md-12 col-lg-12 px-5">
                    <div className="text-center">
                      <h3 className="fw-bold py-4">Reason for Cancel</h3>
                    </div>
                    <div className="mb-4">
                      <p className="mb-0">Booking ID</p>
                      <h6 className="text-uppercase">
                        {detailData?.bookingId || "NA"}
                      </h6>
                    </div>
                    <div className="mb-4">
                      <p className="mb-0">Booked by</p>
                      <h6 className="text-capitalize">
                        <img
                          src={
                            detailData?.createdBy?.picture?.includes("http")
                              ? detailData?.createdBy?.picture
                              : "assets/img/employe.png"
                          }
                          className="table-img"
                          alt=""
                        />{" "}
                        {detailData?.createdBy?.firstName
                          ? `${detailData?.createdBy?.firstName} ${detailData?.createdBy?.lastName}`
                          : "NA"}
                      </h6>
                    </div>
                    <div className="mb-4">
                      <p className="mb-0">Available Sports</p>
                      <h6 className="text-capitalize">
                        {detailData?.sport || "NA"}
                      </h6>
                    </div>
                    <div className="mb-4">
                      <p className="mb-0">Date</p>
                      <h6>{moment(detailData?.date).format("DD/MM/YYYY")}</h6>
                    </div>
                    <div
                      className={
                        detailData?.fullBooking === true ? "d-none" : "mb-4"
                      }
                    >
                      <p className="mb-0">Number of people booked</p>
                      <h6>
                        {forUser === "userSide"
                          ? detailData?.myAdded
                          : detailData?.addPeople}
                        /{detailData?.max}
                      </h6>
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="bg-white rad border-0 p-3 w-100"
                        rows="7"
                        placeholder="Comments.."
                        name="canceledReason"
                        value={formik.values.canceledReason}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.errors.canceledReason &&
                        formik.touched.canceledReason && (
                          <div className="text-red">
                            {" "}
                            {formik.errors.canceledReason}
                          </div>
                        )}
                    </div>
                    <div className="mb-4 text-center">
                      <button className="btn btn-submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CancelBookingModal;
