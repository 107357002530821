import moment from "moment";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CourtDetailReducer,
  CourtListReducer,
  MyFavouriteVenueDetailReducer,
  MyFavouriteVenueDetailWithTokenReducer,
  TimeSlotListReducer,
} from "../slice";
import FullBookingDetailModal from "../../Components/Modals/FullBookingDetailModal";
import { useRef } from "react";

function FullBoking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [date, setDate] = useState(new Date());
  const [modalName, setModalName] = useState("");
  const [show, setShow] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");

  const [courtId, setCourtId] = useState("");
  const [showAb, setShowAb] = useState(false);
  const Hhan = () => {
    setShowAb(!showAb);
  };

  const ref = useRef();
  const handleShow = () => {
    setShow(!show);
  };

  const timeSlotlist = useSelector((state) => state.main.timeSlot);

  const detail = useSelector((state) => state.main.VenueDetail);
  const token = localStorage.getItem("token");
  const detailSport = localStorage.getItem("detailSport");
  const [sport, setSport] = useState(detailSport);

  const getVenueDetail = useCallback(async () => {
    if (token) {
      dispatch(
        MyFavouriteVenueDetailWithTokenReducer({
          venueId: id,
          sport: sport,
        })
      );
    } else {
      dispatch(
        MyFavouriteVenueDetailReducer({
          venueId: id,
          sport: sport,
        })
      );
    }
  }, [dispatch, id, sport, token]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getVenueDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getVenueDetail]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Courtlist = useSelector((state) => state.main.CourtList);

  const getCourtList = useCallback(async () => {
    dispatch(
      CourtListReducer({
        isActive: 1,
        venueId: id,
        sport: sport,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        setCourtId(res?.payload?.data?.courtandtruf?.[0]?._id);
      }
    });
  }, [dispatch, id, sport]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCourtList();
    }
    return () => {
      mounted = false;
    };
  }, [getCourtList]);

  const CourtDetail = useSelector((state) => state.main.CourtDetail);

  const getCourtDetail = useCallback(async () => {
    if (courtId) {
      dispatch(
        CourtDetailReducer({
          courtandtrufId: courtId,
        })
      );
    }
  }, [dispatch, courtId]);

  useEffect(() => {
    getCourtDetail();
  }, [getCourtDetail]);

  const initialValues = {
    sport: sport,
    skill: "professional",
    gender: "all",
    court: courtId,
    slot: [],
    maxCapacity: CourtDetail?.maxCapacity || "1",
    minCapacity: CourtDetail?.minCapacity || "0",
    peopleCount: 0,
    totalAmount: 0,
    coachFee: 500,
    wantCoach: "no",
  };

  const handleAmount = (values, setFieldValue, arr, coachFee) => {
    console.log(coachFee);
    if (arr?.length > 0) {
      let amp = 0;
      arr?.forEach((item) => {
        amp = item?.price + Number(coachFee) + amp;
        //console.log(amp);
      });
      //amp = amp + Number(coachFee);
      setFieldValue("totalAmount", amp);
      setTotalAmount(amp);
    } else {
      setFieldValue("totalAmount", 0);
    }
  };

  const gettimeSlotList = useCallback(async () => {
    if (courtId) {
      dispatch(
        TimeSlotListReducer({
          date: moment(date).format("YYYY-MM-DD"),
          courtId: courtId,
          allowFullBooking: true,
        })
      );
    }
  }, [dispatch, date, courtId]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      gettimeSlotList();
    }
    return () => {
      mounted = false;
    };
  }, [gettimeSlotList]);
  return (
    <>
      <main id="mainbody" className="pt-0">
        <section className="">
          <div className="container">
            <div className="">
              <h4 className="py-5 text-center position-relative">
                Full Booking
                <span
                  onClick={() => navigate(-1)}
                  className="theme-color backIconPosi"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </span>
              </h4>
            </div>
            <Formik
              innerRef={ref}
              initialValues={initialValues}
              validationSchema={Yup.object({
                //sport: Yup.string().typeError("Required").required("Required"),
                //skill: Yup.string().typeError("Required").required("Required"),
                //gender: Yup.string().typeError("Required").required("Required"),
                court: Yup.string().typeError("Required").required("Required"),
                //slot: Yup.string().typeError("Required").required("Required"),
                maxCapacity: Yup.string()
                  .typeError("Required")
                  .required("Required"),
                minCapacity: Yup.string()
                  .typeError("Required")
                  .required("Required"),
              })}
              enableReinitialize
              onSubmit={(values) => {
                //console.log(values);
                handleShow();
                setModalName("fullBookingDetail");
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFormikState,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="FormBoxShadow mb-5">
                    <div className="row createEventForm">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="position-relative">
                          <img
                            src="assets/img/venueName.png"
                            className="createEventInputIcon"
                            alt=""
                          />
                          <label className="fw-bold">
                            {detail?.name || "NA"}
                          </label>
                          <p className="ps-4 mb-2">
                            {detail?.address ? `${detail?.address}` : "NA"}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="position-relative">
                          <img
                            src="assets/img/sports.png"
                            className="createEventInputIcon"
                            alt=""
                          />
                          <label className="fw-bold">Sports</label>
                          <p className="text-capitalize ps-4 mb-2">
                            {sport}
                            {/* {detail?.sports
                              ?.map((item) => {
                                return item?.sport;
                              })
                              ?.join(", ")} */}
                          </p>
                          {/* <select
                            className="form-control form-select pb-3"
                            name="sport"
                            value={values.sport}
                            onChange={(e) => {
                              setSport(e.target.value);
                            }}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Sport</option>
                            {detail?.sports?.map((item, i) => (
                              <option value={item?.sport} key={i}>
                                {item?.sport}
                              </option>
                            ))}
                          </select> */}
                        </div>
                        {errors.sport && touched.sport && (
                          <div className="text-red position-static">
                            {" "}
                            {errors.sport}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-4 d-none">
                        <div className="position-relative">
                          <img
                            src="assets/img/skill_level.png"
                            className="createEventInputIcon"
                            alt=""
                          />
                          <label>Skills</label>
                          <select
                            className="form-control form-select pb-3"
                            name="skill"
                            value={values.skill}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select skill</option>
                            <option value="beginner">beginner</option>
                            <option value="intermediate">intermediate</option>
                            <option value="professional">professional</option>
                          </select>
                        </div>
                        {errors.skill && touched.skill && (
                          <div className="text-red position-static">
                            {" "}
                            {errors.skill}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-4 d-none">
                        <div className="position-relative">
                          <img
                            src="assets/img/gender_male_icon.png"
                            className="createEventInputIcon"
                            alt=""
                          />
                          <label>Is the event applicable to all Gender ?</label>
                          <div className="custom-radio genderLine d-flex align-items-center gap-3">
                            <div className="radio-item">
                              <input
                                type="radio"
                                value="all"
                                name="gender"
                                id="all"
                                onChange={handleChange("gender")}
                                onBlur={handleBlur("radio")}
                                checked={values.gender === "all"}
                              />
                              <label
                                className="label-icon gender"
                                htmlFor="all"
                              >
                                <div className="genderRadioIcon"></div>
                                <p>yes</p>
                              </label>
                            </div>
                            <div className="radio-item">
                              <input
                                type="radio"
                                value="female"
                                name="gender"
                                id="female"
                                onChange={handleChange("gender")}
                                onBlur={handleBlur("radio")}
                                checked={values.gender === "female"}
                              />
                              <label
                                className="label-icon gender"
                                htmlFor="female"
                              >
                                <div className="genderRadioIcon"></div>
                                <p>female only</p>
                              </label>
                            </div>
                            <div className="radio-item">
                              <input
                                type="radio"
                                value="male"
                                name="gender"
                                id="male"
                                onChange={handleChange("gender")}
                                onBlur={handleBlur("radio")}
                                checked={values.gender === "male"}
                              />
                              <label
                                className="label-icon gender"
                                htmlFor="male"
                              >
                                <div className="genderRadioIcon"></div>
                                <p>male only</p>
                              </label>
                            </div>
                          </div>
                        </div>
                        {errors.gender && touched.gender && (
                          <div className="text-red position-static">
                            {" "}
                            {errors.gender}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="FormBoxShadow mb-5">
                    <div className="row createEventForm">
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div className="position-relative">
                          <label>Select Court</label>
                          <div className="row custom-radio">
                            {Courtlist?.length > 0 ? (
                              Courtlist?.map((item, i) => (
                                <div
                                  className="col-sm-6 col-sm-4 col-md-3 col-lg-3 mt-3"
                                  key={i}
                                >
                                  <div className="radio-item h-100">
                                    <input
                                      type="radio"
                                      id={item?._id}
                                      name="court"
                                      value={item?._id}
                                      onChange={(e) => {
                                        setCourtId(e.target.value);
                                      }}
                                      onBlur={handleBlur("radio")}
                                      checked={values.court === item?._id}
                                    />
                                    <label
                                      className="label-icon courtCheck"
                                      htmlFor={item?._id}
                                    >
                                      <img
                                        src={
                                          item?.image[0]?.image?.includes(
                                            "http"
                                          )
                                            ? item?.image[0]?.image
                                            : "assets/img/dummyImage.png"
                                        }
                                        className="courtImgSect"
                                        alt=""
                                      />
                                      <p>{item?.name}</p>
                                    </label>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="text-center">
                                Court Not Found{" "}
                              </div>
                            )}
                          </div>
                          {errors.court && touched.court && (
                            <div className="text-red position-static">
                              {" "}
                              {errors.court}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormBoxShadow mb-5">
                    <div className="row createEventForm">
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div className="calendar">
                          <Calendar
                            onChange={(val) => {
                              setDate(val);
                              let arr = [];
                              setFieldValue("slot", arr);
                            }}
                            value={date}
                            minDate={new Date()}
                            // selectRange={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 custom-radio ps-0">
                    {timeSlotlist?.length > 0 ? (
                      timeSlotlist?.map((item, i) => (
                        <div
                          className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3"
                          key={i}
                        >
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              //value={timeSlotlist?.[i]}
                              checked={
                                values?.slot?.findIndex((it) => {
                                  return (
                                    it?.timeSlotStart === item?.timeSlotStart &&
                                    it?.timeSlotEnd === item?.timeSlotEnd &&
                                    it?.price === item?.price
                                  );
                                }) !== -1
                              }
                              name="slot"
                              id={`timeslot${i}`}
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...values.slot];
                                let index = arr?.indexOf(item);
                                if (index === -1 && checked) {
                                  arr.push(item);
                                } else if (index !== -1 && !checked) {
                                  arr.splice(index, 1);
                                }
                                setFieldValue("slot", arr);
                                handleAmount(
                                  values,
                                  setFieldValue,
                                  arr,
                                  values?.wantCoach === "yes"
                                    ? values.coachFee
                                    : 0
                                );
                              }}
                              onBlur={handleBlur}
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor={`timeslot${i}`}
                            >
                              <p>
                                {moment(item?.timeSlotStart, "hh:mm:ss").format(
                                  "hh:mm A"
                                )}{" "}
                                -{" "}
                                {moment(item?.timeSlotEnd, "hh:mm:ss").format(
                                  "hh:mm A"
                                )}
                              </p>
                              <p>
                                <i className="fa fa-inr"></i>{" "}
                                {item?.price || "0"}
                              </p>
                              <div className="PlusRemoveIcon"></div>
                            </label>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center mb-3">No slots available</div>
                    )}
                    {errors.slot && touched.slot && (
                      <div className="text-red position-static">
                        {" "}
                        {errors.slot}
                      </div>
                    )}
                  </div>

                  <div className="FormBoxShadow mb-5">
                    <div className="row createEventForm">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="position-relative">
                          <img
                            src="assets/img/capacity.png"
                            className="createEventInputIcon"
                            alt=""
                          />
                          <label>Capacity</label>
                          <div className="capacityMinMax">
                            <div className="capacitySelect border-0 shadow-none">
                              <span className="fw-bold">Min:</span>
                              <div className="peopenNum">
                                <input
                                  type="number"
                                  className="border-0 p-0"
                                  placeholder="Number of People"
                                  name="minCapacity"
                                  value={values.minCapacity}
                                  onChange={(e) => {
                                    if (e.target.value > 0) {
                                      setFieldValue(
                                        "minCapacity",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  readOnly
                                />
                                {/* <div className="BtnIncDec">
                                  <span
                                    onClick={() => {
                                      setFieldValue(
                                        "minCapacity",
                                        parseInt(values.minCapacity) + 1
                                      );
                                    }}
                                  >
                                    <i className="fa fa-angle-up"></i>
                                  </span>
                                  <span
                                    onClick={() => {
                                      if (values.minCapacity > 1) {
                                        setFieldValue(
                                          "minCapacity",
                                          parseInt(values.minCapacity) - 1
                                        );
                                      }
                                    }}
                                  >
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div> */}
                              </div>
                            </div>
                            <div className="capacitySelect  border-0 shadow-none">
                              <span className="fw-bold">Max:</span>
                              <div className="peopenNum">
                                <input
                                  type="number"
                                  className=" border-0 p-0"
                                  placeholder="Number of People"
                                  name="maxCapacity"
                                  value={values.maxCapacity}
                                  onChange={(e) => {
                                    if (e.target.value > 0) {
                                      setFieldValue(
                                        "maxCapacity",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  readOnly
                                />
                                {/* <div className="BtnIncDec">
                                  <span
                                    onClick={() => {
                                      setFieldValue(
                                        "maxCapacity",
                                        parseInt(values.maxCapacity) + 1
                                      );
                                    }}
                                  >
                                    <i className="fa fa-angle-up"></i>
                                  </span>
                                  <span
                                    onClick={() => {
                                      if (values.maxCapacity > 1) {
                                        setFieldValue(
                                          "maxCapacity",
                                          parseInt(values.maxCapacity) - 1
                                        );
                                      }
                                    }}
                                  >
                                    <i className="fa fa-angle-down"></i>
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormBoxShadow mb-5">
                    <div className="row createEventForm">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="position-relative">
                          <label className="fw-bold mb-2">
                            Booking and Cancellation Policy
                          </label>
                          <ul className="AmenitiesList Rules ps-3">
                            <li className="text-capitalize">
                              <img src="assets/img/check_done.png" alt="" /> It
                              is a long established fact that a reader will be
                              distracted by the readable content of a page when
                              looking at its layout.
                            </li>
                            <li className="text-capitalize">
                              <img src="assets/img/check_done.png" alt="" /> It
                              is a long established fact that a reader will be
                              distracted by the readable content of a page when
                              looking at its layout.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="maxWidthCoach1">
                    <p className="fw-bold mb-0">You want a Coach?</p>
                    <div className="custom-radio ps-0 d-flex align-items-center gap-3 mb-3">
                      <div className="radio-item">
                        <input
                          type="radio"
                          value="yes"
                          name="wantCoach"
                          id="yes"
                          onChange={() => {
                            setFieldValue("wantCoach", "yes");
                            handleAmount(
                              values,
                              setFieldValue,
                              values?.slot,
                              values?.coachFee
                            );
                            console.log(values?.coachFee);
                            Hhan();
                          }}
                          onBlur={handleBlur("radio")}
                          checked={values.wantCoach === "yes"}
                        />
                        <label className="label-icon gender" htmlFor="yes">
                          <div className="genderRadioIcon"></div>
                          <p>yes</p>
                        </label>
                      </div>
                      <div className="radio-item">
                        <input
                          type="radio"
                          value="no"
                          name="wantCoach"
                          id="no"
                          onChange={() => {
                            setFieldValue("wantCoach", "no");
                            handleAmount(
                              values,
                              setFieldValue,
                              values?.slot,
                              0
                            );
                            Hhan();
                          }}
                          onBlur={handleBlur("radio")}
                          checked={values.wantCoach === "no"}
                        />
                        <label className="label-icon gender" htmlFor="no">
                          <div className="genderRadioIcon"></div>
                          <p>No</p>
                        </label>
                      </div>
                    </div>
                    <div
                      className={
                        values?.wantCoach === "yes"
                          ? "FormBoxShadow coacSelectFlex mb-5"
                          : "d-none"
                      }
                    >
                      <div className="coacFlex">
                        <p className="mb-0">Coach Fee</p>
                        <p className="mb-0 fw-bold">
                          <i className="fa fa-inr"></i> {values?.coachFee}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div
                      className={
                        values?.slot?.length > 0 ? "view-btns" : "d-none"
                      }
                    >
                      <button
                        type="submit"
                        // onClick={() => {
                        //   handleShow();
                        //   setModalName("fullBookingDetail");
                        // }}
                        className="btn-view btn-accept cursor-pointer"
                      >
                        {values?.slot?.length} Slots{" "}
                        {values.wantCoach === "yes"
                          ? `+ ${values?.coachFee}`
                          : ""}{" "}
                        = <i className="fa fa-inr"></i> {values?.totalAmount}{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; Book venue{" "}
                        <img
                          src="assets/img/rightArrow.png"
                          width="25px"
                          className=""
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </section>
      </main>
      {modalName === "fullBookingDetail" && (
        <FullBookingDetailModal
          show={show}
          handleShow={handleShow}
          setModalName="fullBookingDetail"
          totalAmount={totalAmount}
          values={ref.current.values}
          id={id}
          date={date}
          detail={detail}
        />
      )}
    </>
  );
}

export default FullBoking;
