import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CancelBookingModal from "../../Components/Modals/CancelBookingModal";

function BookingConfirmation() {
  const detail = useSelector((state) => state.main.CreateVenueEventDetail);
  const [id, setID] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const handleShow = () => {
    setShow(!show);
  };

  const location = useLocation();
  const { pathname } = location;
  //console.log(pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">Booking Confirmation</h3>
              </div>
            </div>
            <div className="bg-white p-3 rad mb-3">
              <h5>Booking Details</h5>
              <div className="px-md-5 mt-4">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <p className="mb-0">Booking Id:</p>
                    <h6 className="mb-0">{detail?.bookingId || "NA"}</h6>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <p className="mb-0">Venue Name:</p>
                    <h6 className="mb-0 text-capitalize">
                      {detail?.venueId?.name}
                    </h6>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <p className="mb-0">Sport:</p>
                    <h6 className="mb-0 text-capitalize">{detail?.sport}</h6>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <p className="mb-0">Booking Data</p>
                    <h6 className="mb-0">
                      {moment(detail?.date, "DD/MM/YYYY").format("ll")}
                    </h6>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <p className="mb-0">Total</p>
                    <h6 className="mb-0">
                      <i className="fa fa-inr"></i>{" "}
                      {parseFloat(detail?.total).toFixed(2) || "0"}/-
                    </h6>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <p className="mb-0">
                      {detail?.slot?.length > 1 ? "Slots" : "Slot"}
                    </p>
                    <h6 className="mb-0">
                      {detail?.slot?.map((item) => (
                        <p className="mb-0">
                          {moment(item?.timeSlotStart, "HH:mm").format(
                            "hh:mm a"
                          )}{" "}
                          -{" "}
                          {moment(item?.timeSlotEnd, "HH:mm").format("hh:mm a")}
                        </p>
                      ))}
                    </h6>
                  </div>
                  <div
                    className={
                      detail?.fullBooking === true
                        ? "d-none"
                        : "col-sm-6 col-md-4 col-lg-3 mb-3"
                    }
                  >
                    <p className="mb-0">Number of people booked:</p>
                    <h6 className="mb-0">{detail?.addPeople} people</h6>
                  </div>
                  {/* <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <p className="mb-0">Payment Mode:</p>
                    <h6 className="mb-0 text-capitalize">UPI</h6>
                  </div> */}
                  <div
                    className={
                      detail?.fullBooking === true
                        ? "d-none"
                        : "col-sm-12 col-md-12 col-lg-12 mb-3 text-end"
                    }
                  >
                    <span className="theme-color fw-bold text-decoration-underline cursor-pointer">
                      Invite Other Users
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                className="btn btn-submit w-25"
                onClick={() => {
                  setID(detail?._id);
                  setModalName("CancelBooking");
                  handleShow();
                }}
              >
                Cancel Booking
              </button>
            </div>
          </div>
        </section>
      </main>
      {modalName === "CancelBooking" && id && (
        <CancelBookingModal
          handleShow={handleShow}
          show={show}
          id={id}
          forUser="userSide"
          navi="BookingConfirmationThenCencel"
        />
      )}
    </>
  );
}

export default BookingConfirmation;
