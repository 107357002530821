import moment from "moment";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { DetailImageCarouselsettings, mapApiKey } from "../../Utils";
import {
  AllReadyCreatedEventdetailReducer,
  AllReadyCreatedEventdetailWithOutTokenReducer,
  ChangeouterLogin,
  ChangeurlId,
} from "../slice";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import JoinAnExistingEventModal from "../../Components/Modals/JoinAnExistingEventModal";
import LoginModal from "../../Components/Modals/LoginModal";
import LoginWithEmail from "../../Components/Modals/LoginWithEmail";
import SignUpModal from "../../Components/Modals/SignUpModal";
import SignUpWithMobileModal from "../../Components/Modals/SignUpWithMobileModal";
import ForgotPasswordModal from "../../Components/Modals/ForgotPasswordModal";
import OTPVerification from "../../Components/Modals/OTPVerification";
import ResetPassword from "../../Components/Modals/ResetPassword";

function AlreadyCreatedEventDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [peopleCount, setPeopleCount] = useState(0);
  const [modalName, setModalName] = useState("");
  const [createdEventId, setCreatedEventId] = useState("");
  const [AvailableSport, setAvailableSport] = useState("");
  const outerLogin = useSelector((state) => state.main.outerLogin);
  const urlId = useSelector((state) => state.main.urlId);

  const loginType = localStorage.getItem("loginType");

  const location = useLocation();
  const { pathname } = location;
  //console.log(pathname);

  const handleShow = () => {
    setShow(!show);
  };

  const detail = useSelector((state) => state.main.AllReadyCreatedEventdetail);
  const token = localStorage.getItem("token");
  const loginDetail = useSelector((state) => state.main.userDetail);
  const detailSport = localStorage.getItem("detailSport");

  const getAllReadyCreatedEvent = useCallback(async () => {
    if (token) {
      dispatch(
        AllReadyCreatedEventdetailReducer({
          eventbookingId: id,
        })
      );
    } else {
      dispatch(
        AllReadyCreatedEventdetailWithOutTokenReducer({
          eventbookingId: id,
        })
      );
    }
  }, [dispatch, id, token]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getAllReadyCreatedEvent();
    }
    return () => {
      mounted = false;
    };
  }, [getAllReadyCreatedEvent]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAvailableSport(detail?.sport);
  }, [detail?.sport]);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (detail) {
      setMarkerPosition({
        lat: Number(detail?.venueId?.latitude),
        lng: Number(detail?.venueId?.longitude),
      });
    }
  }, [detail]);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap defaultZoom={15} defaultCenter={props?.markerPosition}>
        <Marker position={props?.markerPosition} />
      </GoogleMap>
    ))
  );
  return (
    <>
      <main id="mainbody" className="pt-0">
        <section className="bg-white pt-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4 position-relative">
                <span
                  onClick={() => navigate(-1)}
                  className="theme-color backIconPosi"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </span>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 mb-2 position-relative">
                <Slider {...DetailImageCarouselsettings}>
                  {detail?.venueId?.image?.map((item, i) => (
                    <div key={i}>
                      <img
                        src={
                          item?.image?.includes("http")
                            ? item?.image
                            : "assets/img/dummyImage.png"
                        }
                        className="img-fluid Detailgounds"
                        alt=""
                      />
                    </div>
                  ))}
                </Slider>

                <img
                  src={
                    detail?.venueId?.isFevVenue === true
                      ? "assets/img/Favoriteselected.png"
                      : "assets/img/Favoriteunselected.png"
                  }
                  className="Favoriteselected"
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                <div className={markerPosition?.lat ? "mapView" : "NomapView"}>
                  {markerPosition?.lat ? (
                    <MapWithAMarker
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      markerPosition={markerPosition}
                    />
                  ) : (
                    <div className="NoMapFound">No Map Found</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8 mb-2 position-relative">
                <div className="detailTitle">
                  <span>
                    <h5 className="mb-0 text-capitalize">
                      {detail?.venueId?.name || "NA"}
                    </h5>
                    <p className="mb-0">
                      {detail?.venueId?.address
                        ? `${detail?.venueId?.address} `
                        : "NA"}
                    </p>
                  </span>
                  <span className="text-end">
                    <p className="mb-0">Payment required </p>
                    <p className="theme-color fw-bold mb-0">
                      <i className="fa fa-inr"></i>{" "}
                      {parseFloat(detail?.userPayment).toFixed(2)}/-
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
              <p className="mb-1">Created by:</p>
              <h6 className="mb-0 coacFlex text-capitalize justify-content-start gap-2">
                <img
                  src={
                    detail?.createdBy?.picture?.includes("http")
                      ? detail?.createdBy?.picture
                      : "assets/img/employe.png"
                  }
                  className="coachImgCenetr"
                  alt=""
                />
                {detail?.createdBy?.firstName
                  ? `${detail?.createdBy?.firstName} ${detail?.createdBy?.lastName}`
                  : "NA"}
              </h6>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
              <div className="">
                <p className="mb-0">Date and time of play:</p>

                <div className="d-flex fw-bold gap-2">
                  <span className="whiteNorap">
                    {moment(detail?.date).format("ll")} -{" "}
                  </span>
                  <span>
                    {detail?.slot?.map((slot, i) => (
                      <p className="mb-0" key={i}>
                        {moment(slot?.timeSlotStart, "hh:mm").format("hh:mm a")}{" "}
                        - {moment(slot?.timeSlotEnd, "hh:mm").format("hh:mm a")}
                      </p>
                    ))}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
              <div className="">
                <p className="mb-0">Total People Book the slot</p>
                {detail?.fullBooking === true ? (
                  <b>Full Booked</b>
                ) : (
                  <p className="mb-0">
                    {detail?.addPeople}/{detail?.max}
                  </p>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <p>How many people you wanted to book?</p>
              <span className="bg-white shadow rounded border-0 py-2 px-3">
                Min: {detail?.min}
              </span>{" "}
              <span className="bg-white shadow rounded border-0 py-2 px-3">
                Max: {detail?.max}
              </span>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h5>Available Sports</h5>
            </div>
            {detail?.venueId?.sports?.map((item, i) => (
              <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3" key={i}>
                <div
                  className={
                    AvailableSport?.toLowerCase() === item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : AvailableSport?.toLowerCase() ===
                        item?.sport?.toLowerCase()
                      ? "AvailableSportsName bgCOlorAmi"
                      : "AvailableSportsName"
                  }
                  // onClick={() => {
                  //   setAvailableSport(item?.sport);
                  //   localStorage.setItem("detailSport", item?.sport);
                  // }}
                >
                  <img
                    src={
                      item?.sport === "cricket"
                        ? "assets/img/cricket_ball_icon.png"
                        : item?.sport === "football"
                        ? "assets/img/Footall.png"
                        : item?.sport === "basketball"
                        ? "assets/img/Basketball.png"
                        : item?.sport === "table tennis"
                        ? "assets/img/table_tennis.png"
                        : item?.sport === "badminton"
                        ? "assets/img/badminton.png"
                        : item?.sport === "swimming"
                        ? "assets/img/swimming.png"
                        : item?.sport === "lawn tennis"
                        ? "assets/img/lawntennis.png"
                        : null
                    }
                    alt=""
                    className="unselectImg"
                  />{" "}
                  <img
                    src={
                      item?.sport === "cricket"
                        ? "assets/img/cricket_ball_icon_white.png"
                        : item?.sport === "football"
                        ? "assets/img/Footall_white.png"
                        : item?.sport === "basketball"
                        ? "assets/img/Basketball_white.png"
                        : item?.sport === "table tennis"
                        ? "assets/img/table_tennis_white.png"
                        : item?.sport === "badminton"
                        ? "assets/img/badminton_white.png"
                        : item?.sport === "swimming"
                        ? "assets/img/swimming_white.png"
                        : item?.sport === "lawn tennis"
                        ? "assets/img/lawntennis_white.png"
                        : null
                    }
                    alt=""
                    className="selectImg"
                  />{" "}
                  {item?.sport || "NA"}
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h5>Amenities</h5>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <ul className="AmenitiesList">
                {detail?.venueId?.amenities?.map((item, i) => (
                  <li
                    className={item?.amenities ? "text-capitalize" : "d-none"}
                    key={i}
                  >
                    <img
                      src={
                        item?.amenities === "cctv"
                          ? "assets/img/cctv.png"
                          : item?.amenities === "parking"
                          ? "assets/img/parking.png"
                          : item?.amenities === "air-conditioned"
                          ? "assets/img/air_conditioning.png"
                          : item?.amenities === "changing rooms"
                          ? "assets/img/ChangingRoom.png"
                          : item?.amenities === "washroom"
                          ? "assets/img/washroom.png"
                          : item?.amenities === "first aid"
                          ? "assets/img/firstaid_healtn.png"
                          : null
                      }
                      alt=""
                      width="16px"
                    />{" "}
                    {item?.amenities ? item?.amenities : ""}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h5>Venue Rules</h5>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <div
                className="rulePTage"
                dangerouslySetInnerHTML={{ __html: detail?.venueId?.rules }}
              ></div>
            </div>
          </div>

          <div className="mt-5">
            <div className="mb-3 text-center">
              <span className="theme-color fw-bold text-decoration-underline cursor-pointer">
                Invite Other Users
              </span>
            </div>
            <div className="view-btns">
              {loginType === "user" ? (
                <span
                  onClick={() => {
                    setModalName("JoinAnExistingEvent");
                    handleShow();
                  }}
                  className={
                    detail?.max === 1 ||
                    detail?.fullBooking === true ||
                    detail?.max === detail?.addPeople
                      ? "d-none"
                      : "btn-view btn-accept  cursor-pointer"
                  }
                >
                  Book my slot
                </span>
              ) : (
                <span
                  onClick={() => {
                    dispatch(ChangeouterLogin(true));
                    dispatch(
                      ChangeurlId(`/already-created-event-details/${id}`)
                    );
                    setModalName("LoginModal");
                    handleShow();
                  }}
                  className={
                    detail?.max === 1 ||
                    detail?.fullBooking === true ||
                    detail?.max === detail?.addPeople
                      ? "d-none"
                      : "btn-view btn-accept  cursor-pointer"
                  }
                >
                  Book my slot
                </span>
              )}

              {/* {token ? (
                <Link
                  to={`/venues-listing/detail/${id}/full-booking`}
                  className="btn-view btn-reject"
                >
                  Full Booking
                </Link>
              ) : (
                <span
                  onClick={() => {
                    dispatch(ChangeouterLogin(true));
                    dispatch(
                      ChangeurlId(`/venues-listing/detail/${id}/full-booking`)
                    );
                    setModalName("LoginModal");
                    handleShow();
                  }}
                  className="btn-view btn-reject cursor-pointer"
                >
                  Full Booking
                </span>
              )} */}
            </div>
          </div>
        </div>
      </main>
      {modalName === "JoinAnExistingEvent" && id && (
        <JoinAnExistingEventModal
          show={show}
          handleShow={handleShow}
          createdEventId={id}
          minCapacity={detail?.min}
        />
      )}
      {modalName === "LoginModal" && (
        <LoginModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "LoginWithEmail" && (
        <LoginWithEmail
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "SignUpModal" && (
        <SignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpWithMobile" && (
        <SignUpWithMobileModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ForgotPassword" && (
        <ForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "OTPVerification" && (
        <OTPVerification
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "ResetPassword" && (
        <ResetPassword
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
    </>
  );
}

export default AlreadyCreatedEventDetails;
