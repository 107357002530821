import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CoachBookingDetailModal from "../../../Components/CoachBookingDetailModal";
import Paginations from "../../../Components/Paginations";
import RatingReviewModal from "../../../Components/RatingReviewModal";
import {
  CoachDetailReducer,
  CoachVenueBookingListReducer,
} from "../../CoachVenueslice";

function ManageBooking() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("0");
  const [id, setID] = useState("");
  const [modalName, setModalName] = useState("");
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState("upcoming");
  const handlePage = (value) => {
    setPage(value);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const list = useSelector((state) => state.CoachVenueMain.CoachBookingList);
  const total = useSelector(
    (state) => state.CoachVenueMain.total_CoachBookingList
  );

  const getList = useCallback(async () => {
    dispatch(
      CoachVenueBookingListReducer({
        progress: progress,
        page: page,
        limit: 10,
      })
    );
  }, [dispatch, page, progress]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getList();
    }
    return () => {
      mounted = false;
    };
  }, [getList]);
  const getCoachDetail = useCallback(async () => {
    dispatch(CoachDetailReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCoachDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getCoachDetail]);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">Manage Booking</h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-start bookingTabs tabsnew gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active text-capitalize"
                      id="pills-upcoming-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-upcoming"
                      type="button"
                      role="tab"
                      aria-controls="pills-upcoming"
                      aria-selected="true"
                      onClick={() => {
                        setProgress("upcoming");
                      }}
                    >
                      upcoming
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link text-capitalize"
                      id="pills-ongoing-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-ongoing"
                      type="button"
                      role="tab"
                      aria-controls="pills-ongoing"
                      aria-selected="false"
                      onClick={() => {
                        setProgress("onGoing");
                      }}
                    >
                      on-going
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link text-capitalize"
                      id="pills-completed-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-completed"
                      type="button"
                      role="tab"
                      aria-controls="pills-completed"
                      aria-selected="false"
                      onClick={() => {
                        setProgress("completed");
                      }}
                    >
                      completed
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="tab-content mt-4" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-upcoming"
                    role="tabpanel"
                    aria-labelledby="pills-upcoming-tab"
                  >
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">user name</th>
                            <th scope="col">contact no.</th>
                            <th scope="col">email address</th>
                            <th scope="col">start date</th>
                            <th scope="col">end date</th>
                            <th scope="col">slot time</th>
                            <th scope="col">type of coaching</th>
                            <th scope="col">duration</th>
                            <th scope="col">amount</th>
                            <th scope="col">action</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.normaluser?.picture?.includes(
                                        "http"
                                      )
                                        ? item?.normaluser?.picture
                                        : "assets/img/employe.png"
                                    }
                                    className="table-img"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  {item?.normaluser?.firstName
                                    ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                    : "NA"}
                                </td>
                                <td>
                                  {item?.normaluser?.mobile
                                    ? `${item?.normaluser?.countryCode} ${item?.normaluser?.mobile}`
                                    : "NA"}
                                </td>
                                <td className="text-initial">
                                  {item?.normaluser?.email || "NA"}
                                </td>
                                <td>
                                  {moment(item?.startDate, "DD/MM/YYYY").format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.endDate, "DD/MM/YYYY").format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.timeing, "hh:mm:ss").format(
                                    "hh:mma"
                                  )}{" "}
                                </td>
                                <td>{item?.sport || "NA"}</td>
                                <td>
                                  {item?.isTrail === true
                                    ? "0"
                                    : item?.duration}{" "}
                                  m
                                </td>
                                <td>
                                  {item?.isTrail === true ? (
                                    ""
                                  ) : (
                                    <i className="fa fa-inr"></i>
                                  )}{" "}
                                  {item?.isTrail === true
                                    ? "free Trial"
                                    : `${parseFloat(item?.total).toFixed(2)}`}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action">
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        setID(item?._id);
                                        setModalName("DetailModal");
                                        handleShow();
                                      }}
                                    >
                                      <img src="assets/img/view.png" alt="" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="11">No Records Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center">
                      {list?.length > 0 && (
                        <Paginations
                          handlePage={handlePage}
                          page={page}
                          total={total && total}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-ongoing"
                    role="tabpanel"
                    aria-labelledby="pills-ongoing-tab"
                  >
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">user name</th>
                            <th scope="col">contact no.</th>
                            <th scope="col">email address</th>
                            <th scope="col">start date</th>
                            <th scope="col">end date</th>
                            <th scope="col">slot time</th>
                            <th scope="col">type of coaching</th>
                            <th scope="col">duration</th>
                            <th scope="col">amount</th>
                            <th scope="col">action</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.normaluser?.picture?.includes(
                                        "http"
                                      )
                                        ? item?.normaluser?.picture
                                        : "assets/img/employe.png"
                                    }
                                    className="table-img"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  {item?.normaluser?.firstName
                                    ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                    : "NA"}
                                </td>
                                <td>
                                  {item?.normaluser?.mobile
                                    ? `${item?.normaluser?.countryCode} ${item?.normaluser?.mobile}`
                                    : "NA"}
                                </td>
                                <td className="text-initial">
                                  {item?.normaluser?.email || "NA"}
                                </td>
                                <td>
                                  {moment(item?.startDate, "DD/MM/YYYY").format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.endDate, "DD/MM/YYYY").format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.timeing, "hh:mm:ss").format(
                                    "hh:mma"
                                  )}{" "}
                                </td>
                                <td>{item?.sport || "NA"}</td>
                                <td>
                                  {item?.isTrail === true
                                    ? "0"
                                    : item?.duration}{" "}
                                  m
                                </td>
                                <td>
                                  {item?.isTrail === true ? (
                                    ""
                                  ) : (
                                    <i className="fa fa-inr"></i>
                                  )}{" "}
                                  {item?.isTrail === true
                                    ? "free Trial"
                                    : `${parseFloat(item?.total).toFixed(2)}`}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action">
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        setID(item?._id);
                                        setModalName("DetailModal");
                                        handleShow();
                                      }}
                                    >
                                      <img src="assets/img/view.png" alt="" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="11">No Records Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="text-center">
                      {list?.length > 0 && (
                        <Paginations
                          handlePage={handlePage}
                          page={page}
                          total={total && total}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-completed"
                    role="tabpanel"
                    aria-labelledby="pills-completed-tab"
                  >
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">user name</th>
                            <th scope="col">contact no.</th>
                            <th scope="col">email address</th>
                            <th scope="col">start date</th>
                            <th scope="col">end date</th>
                            <th scope="col">slot time</th>
                            <th scope="col">type of coaching</th>
                            <th scope="col">duration</th>
                            <th scope="col">status</th>
                            <th scope="col">give reviews</th>
                            <th scope="col">action</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.normaluser?.picture?.includes(
                                        "http"
                                      )
                                        ? item?.normaluser?.picture
                                        : "assets/img/employe.png"
                                    }
                                    className="table-img"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  {item?.normaluser?.firstName
                                    ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                    : "NA"}
                                </td>
                                <td>
                                  {item?.normaluser?.mobile
                                    ? `${item?.normaluser?.countryCode} ${item?.normaluser?.mobile}`
                                    : "NA"}
                                </td>
                                <td className="text-initial">
                                  {item?.normaluser?.email || "NA"}
                                </td>
                                <td>
                                  {moment(item?.startDate, "DD/MM/YYYY").format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.endDate, "DD/MM/YYYY").format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.timeing, "hh:mm:ss").format(
                                    "hh:mma"
                                  )}{" "}
                                </td>
                                <td>{item?.sport || "NA"}</td>
                                <td>
                                  {item?.isTrail === true
                                    ? "0"
                                    : item?.duration}{" "}
                                  m
                                </td>

                                <td>
                                  <span className="StatusBtn approvedStatus">
                                    completed
                                  </span>
                                </td>
                                <td>
                                  <img
                                    src="assets/img/rate_review.png"
                                    className="cursor-pointer"
                                    alt=""
                                    onClick={() => {
                                      setID(item?._id);
                                      setModalName("ReviewModal");
                                      handleShow();
                                    }}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action">
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        setID(item?._id);
                                        setModalName("DetailModal");
                                        handleShow();
                                      }}
                                    >
                                      <img src="assets/img/view.png" alt="" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="12">No Records Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center">
                      {list?.length > 0 && (
                        <Paginations
                          handlePage={handlePage}
                          page={page}
                          total={total && total}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {modalName === "DetailModal" && id && (
        <CoachBookingDetailModal show={show} handleShow={handleShow} id={id} />
      )}
      {modalName === "ReviewModal" && id && (
        <RatingReviewModal
          show={show}
          handleShow={handleShow}
          id={id}
          getList={getList}
        />
      )}
    </>
  );
}

export default ManageBooking;
