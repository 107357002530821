import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, isObject } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { ChangeouterLogin, LoginReducer } from "../../features/slice";
import { toast } from "react-toastify";
import FacebookSocialLogin from "../socialLogin/facebookLogin";
import GoogleSocialLogin from "../socialLogin/googleLogin";
import Cookies from "universal-cookie";

function LoginWithEmail({ show, handleShow, setModalName, outerLogin, urlId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const cookies = new Cookies();

  const loginFor = cookies.get("loginFor");

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="xl">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div className="col-sm-12 col-md-12 col-lg-6 bgloginImgUser">
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-lg-5">
                    <div className="form-style mt-3 mt-lg-5 px-4">
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                          toggle: false,
                          error: "",
                          RememberMe: loginFor === "email" ? true : false,
                        }}
                        validationSchema={Yup.object({
                          email: Yup.string()
                            .email("email must be a valid format")
                            .required("Required"),
                          password: Yup.string().required("Required"),
                        })}
                        onSubmit={(values, action) => {
                          setLoading(true);
                          dispatch(
                            LoginReducer({
                              email: values.email,
                              password: values.password,
                              login_type: "email",
                              user_type: "normaluser",
                            })
                          ).then(async (data) => {
                            // console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 1) {
                              await localStorage.setItem(
                                "token",
                                data?.payload?.data?.token
                              );
                              await localStorage.setItem("loginType", "user");
                              toast.success(data?.payload?.message);
                              if (outerLogin) {
                                handleShow();
                                dispatch(ChangeouterLogin(false));
                                navigate(urlId, {
                                  replace: true,
                                });
                              } else {
                                if (
                                  data?.payload?.data?.user?.profileSetup === 0
                                ) {
                                  navigate("/edit-profile", {
                                    replace: true,
                                  });
                                  handleShow();
                                } else {
                                  navigate("/", {
                                    replace: true,
                                  });
                                  handleShow();
                                }
                              }
                            } else if (data?.payload?.code === 2) {
                              await localStorage.setItem(
                                "OTPVerify",
                                "LoginOTPVerify"
                              );
                              await localStorage.setItem("email", values.email);
                              await localStorage.setItem(
                                "EmailText",
                                "EmailText"
                              );
                              await localStorage.setItem(
                                "password",
                                values.password
                              );
                              await localStorage.removeItem("mobile");
                              await localStorage.removeItem("countryCode");
                              setModalName("OTPVerification");
                              toast.success(data?.payload?.message);
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFormikState,
                          setFieldValue,
                        }) => (
                          <form
                            className=""
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleSubmit();
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-12 text-center mb-3 mb-md-5">
                                <h2 className="mdl-ttl">Login</h2>
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4 mb-md-5">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      className="form-control ps-5"
                                      placeholder="Email Address"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    <img
                                      src="assets/img/email.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.email && touched.email && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4 mb-md-5">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      id="password-field"
                                      type={values.toggle ? "text" : "password"}
                                      name="password"
                                      className="form-control ps-5"
                                      placeholder="Password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />
                                    <img
                                      src="assets/img/password.png"
                                      className="input-img"
                                      alt=""
                                    />
                                    <span
                                      className={
                                        values.toggle
                                          ? "pass-hide field-icon toggle-password"
                                          : "pass-view field-icon toggle-password"
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFormikState((prevState) => {
                                          return {
                                            ...prevState,
                                            values: {
                                              ...prevState.values,
                                              toggle: !prevState.values.toggle,
                                            },
                                          };
                                        });
                                      }}
                                    ></span>
                                  </div>
                                  {errors.password && touched.password && (
                                    <div className="text-red">
                                      {" "}
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 text-end mb-4 mb-md-5">
                                <p className="mb-0">
                                  <span
                                    className="theme-color cursor-pointer fw-bold"
                                    onClick={() => {
                                      setModalName("ForgotPassword");
                                    }}
                                  >
                                    Forgot Password?
                                  </span>
                                </p>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="input-container">
                                  <div className="">
                                    <input
                                      className="styled-checkbox"
                                      id="RememberMe"
                                      type="checkbox"
                                      name="RememberMe"
                                      onChange={handleChange}
                                      onBlur={handleBlur("radio")}
                                      checked={values.RememberMe === true}
                                    />
                                    <label
                                      htmlFor="RememberMe"
                                      className="w-100"
                                      onClick={() => {
                                        cookies.set(
                                          "loginFor",
                                          `${
                                            loginFor === "email" ? "" : "email"
                                          }`
                                        );
                                      }}
                                    >
                                      Remember Me
                                    </label>
                                  </div>
                                  {errors.RememberMe && touched.RememberMe && (
                                    <div className="text-red">
                                      {" "}
                                      {errors.RememberMe}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn btn-submit mt-0 w-75"
                                >
                                  {loading ? <Loader /> : "Continue"}
                                </button>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="orLine">
                                  <span className="orLog">or</span>
                                </div>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <p
                                  className="loginwithEmailBtn btn w-75"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                  }}
                                >
                                  Login with Phone Number
                                </p>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="orLine">
                                  <span className="orLog">or</span>
                                </div>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="d-flex justify-content-center align-items-center gap-3 my-3">
                                  <FacebookSocialLogin
                                    handleShow={handleShow}
                                  />
                                  <GoogleSocialLogin handleShow={handleShow} />
                                </div>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                                Not register Yet ?{" "}
                                <span
                                  className="theme-color cursor-pointer fw-bold"
                                  onClick={() => {
                                    setModalName("SignUpModal");
                                  }}
                                >
                                  Create an Account
                                </span>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginWithEmail;
