import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, isObject } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { CoachVenueLoginReducer } from "../../features/CoachVenueslice";
import Cookies from "universal-cookie";

function CoachLogin({ setModalName, setLoginImg, handleShow }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const cookies = new Cookies();

  const loginFor = cookies.get("loginFor");

  return (
    <div className="form-style mt-3 mt-md-5 px-4">
      <Formik
        initialValues={{
          email: "",
          password: "",
          toggle: false,
          error: "",
          RememberMe: loginFor === "coach" ? true : false,
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("email must be a valid format")
            .required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={(values, action) => {
          setLoading(true);
          dispatch(
            CoachVenueLoginReducer({
              email: values.email,
              password: values.password,
              login_type: "email",
              user_type: "coach",
            })
          ).then(async (data) => {
            //console.log(data);
            setLoading(false);
            if (data?.payload?.code === 1) {
              await localStorage.setItem("loginType", "coach");
              toast.success(data?.payload?.message);
              if (data?.payload?.data?.user?.profileSetup === 0) {
                navigate("/coach/edit-personal-info", {
                  replace: true,
                });
                handleShow();
              } else {
                navigate("/coach/manage-booking", {
                  replace: true,
                });
                handleShow();
              }
            } else if (data?.payload?.code === 2) {
              await localStorage.setItem(
                "OTPVerify",
                "SignUpOTPVerifyEmailCoach"
              );
              await localStorage.setItem("email", values.email);
              await localStorage.setItem("password", values.password);
              setModalName("CoachVenueOTPVerification");
              toast.success(data?.payload?.message);
            } else {
              setLoading(false);
              toast.error(data?.payload?.message);
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFormikState,
          setFieldValue,
        }) => (
          <form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 text-center mb-3">
                <h2 className="mdl-ttl">Login</h2>
              </div>
              <div className="col-sm-12 col-md-12 mb-4">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      className="form-control ps-5"
                      placeholder="Email Address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <img
                      src="assets/img/email.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {errors.email && touched.email && (
                  <div className="text-red"> {errors.email}</div>
                )}
              </div>
              <div className="col-sm-12 col-md-12 mb-1">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      id="password-field"
                      type={values.toggle ? "text" : "password"}
                      name="password"
                      className="form-control ps-5"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      className={
                        values.toggle
                          ? "pass-hide field-icon toggle-password"
                          : "pass-view field-icon toggle-password"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setFormikState((prevState) => {
                          return {
                            ...prevState,
                            values: {
                              ...prevState.values,
                              toggle: !prevState.values.toggle,
                            },
                          };
                        });
                      }}
                    ></span>
                  </div>
                  {errors.password && touched.password && (
                    <div className="text-red"> {errors.password}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 text-end mb-1">
                <p className="mb-0">
                  <span
                    className="theme-color cursor-pointer fw-bold"
                    onClick={() => {
                      setModalName("CoachVenueForgotPassword");
                      setLoginImg("schoolLogin");
                    }}
                  >
                    Forgot Password?
                  </span>
                </p>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="input-container">
                  <div className="">
                    <input
                      className="styled-checkbox"
                      id="RememberMe"
                      type="checkbox"
                      name="RememberMe"
                      onChange={handleChange}
                      onBlur={handleBlur("radio")}
                      checked={values.RememberMe === true}
                    />
                    <label
                      htmlFor="RememberMe"
                      className="w-100"
                      onClick={() => {
                        cookies.set(
                          "loginFor",
                          `${loginFor === "coach" ? "" : "coach"}`
                        );
                      }}
                    >
                      Remember Me
                    </label>
                  </div>
                  {errors.RememberMe && touched.RememberMe && (
                    <div className="text-red"> {errors.RememberMe}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 text-center">
                <button type="submit" className="btn btn-submit mt-0">
                  {loading ? <Loader /> : "Login"}
                </button>
              </div>
              <div className="text-center my-5">
                Not register Yet ?{" "}
                <span
                  className="theme-color cursor-pointer fw-bold"
                  onClick={() => {
                    setModalName("CoachSignUpModal");
                  }}
                >
                  Create an Account
                </span>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default CoachLogin;
