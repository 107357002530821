import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  AddBlockedUserAPI,
  AddPaymentApi,
  AllReadyCreatedEventdetailApi,
  AllReadyCreatedEventdetailWithOutTokenApi,
  BlockedUserAPI,
  BookCoachAPI,
  BookingCancelApi,
  BookTrialSessionAPI,
  CalculatePriceApi,
  ChangePasswordApi,
  CheckoutApi,
  CoachBookingListApi,
  CoachingDetailAPI,
  CoachingDetailWithTokenAPI,
  CoachingListAPI,
  CoachingListWithTokenAPI,
  CoachingSlotAPI,
  CoachReviewRatingAPI,
  CompletedBookingGiveRatingReviewbyUserAPI,
  CouponListAPI,
  CourtDetailAPI,
  CourtListAPI,
  CreateOrderIdApi,
  CreateVenueEventAPI,
  EditProfilePApi,
  ForgotPasswordApi,
  GetAllKeysApi,
  GetVenueRatingAPI,
  HomePageDataAPI,
  HomePageDataAPIWithToken,
  HomePageDataCoachesAPI,
  HomePageDataCoachesAPIWithToken,
  HomePageDataVenueAPI,
  HomePageDataVenueAPIWithToken,
  JoinCreatedEventdetailApi,
  joinEventBookingApi,
  LoginApi,
  MyAccountAPI,
  MYEventBookingApi,
  MyFavouriteCoachListAPI,
  MyFavouriteVenueDetailAPI,
  MyFavouriteVenueDetailWithTokenAPI,
  MyFavouriteVenueListAPI,
  notificationApi,
  NotificationCountApi,
  OTPVerificationApi,
  PaymentVerifyApi,
  ResendOTPApi,
  ResetPasswordApi,
  ReviewRatingAPI,
  SendContactUsApi,
  SignUpApi,
  SlotListAPI,
  SocialLogingAPI,
  SportsListApi,
  SportsListAPI,
  UserDetailAPI,
  UserHelpAndSupportApi,
  VanueListAPI,
  VanueListWithTokenAPI,
  verifyBlockUserApi,
} from "../api/setting";

const initialState = {
  notification: [],
  total_notification: 0,
  ContactUsSendData: {},
  userDetail: {},
  normaluserId: {},
  venueListing: [],
  total_venue: 0,
  timeSlot: [],
  CoachingCenterList: [],
  total_coaches: 0,
  CoachingCenterDetail: {},
  MyFavouriteList: [],
  VenueDetail: {},
  BookingList: [],
  total_booking: 0,
  RatingReviewList: [],
  total_RatingReview: 0,
  totalRating: 0,
  totalRaview: 0,
  BlockedUserList: [],
  total_BlockedUser:0,
  SportList: [],
  CouponList: [],
  total_couponcode: 0,
  CourtList: [],
  CourtDetail: {},
  HomePageData: {},
  HomePageDataVenue: [],
  HomePageDataCoach: [],
  HomesliderList: [1, 1, 1, 1, 1, 1, 1, 1],
  calculatePrice: {},
  CreateVenueEventDetail: {},
  AllReadyCreatedEventdetail: {},
  CoachBookingList: [],
  total_CoachBookingList: 0,
  CoachingSlotList: [],
  MyAccount: {},
  amount: 0,
  rewardPoints: 0,
  FavouriteCoachList: [],
  VenueRatingReviewList: {},
  liveCoords: {
    lat: localStorage.getItem("liveLat") ? localStorage.getItem("liveLat") : "",
    lng: localStorage.getItem("liveLan") ? localStorage.getItem("liveLan") : "",
  },
  notificationCount: 0,
  // payment api data======================================
  orderId: "",
  // Save State================================================
  outerLogin: false,
  urlId: "",
  sportName: "",
  detailSport: "",
  SessionName: "",
  PlanDuration: "",
  planPrice: "",
  AllKeys: {},
  SportsList:[]
};

export const LoginReducer = createAsyncThunk(
  "loginRedcer",
  async (obj, { dispatch }) => {
    try {
      const response = await LoginApi(obj);
      //console.log(response);
      //await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const SignUpReducer = createAsyncThunk(
  "SignUpReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SignUpApi(obj);
      //console.log(response);
      //await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const ForgotPasswordReducer = createAsyncThunk(
  "ForgotPasswordReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ForgotPasswordApi(obj);
      //console.log(response);
      //await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const OTPVerificationReducer = createAsyncThunk(
  "OTPVerificationReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await OTPVerificationApi(obj);
      //console.log(response);
      if (response?.code === 1) {
        await localStorage.setItem("token", response?.data?.token);
      }
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const ResendOTPReducer = createAsyncThunk(
  "ResendOTPReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ResendOTPApi(obj);
      //console.log(response);
      //await localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const ResetPasswordReducer = createAsyncThunk(
  "ResetPasswordReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ResetPasswordApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const ChangePasswordReducer = createAsyncThunk(
  "ChangePasswordReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ChangePasswordApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const SocialLoginReducer = createAsyncThunk(
  "SocialLoginReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SocialLogingAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const UserDetailReducer = createAsyncThunk(
  "UserDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await UserDetailAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const VanueListReducer = createAsyncThunk(
  "VanueListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VanueListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const VanueListWithTokenReducer = createAsyncThunk(
  "VanueListWithTokenReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await VanueListWithTokenAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const TimeSlotListReducer = createAsyncThunk(
  "TimeSlotListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SlotListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachingCenterDetailReducer = createAsyncThunk(
  "CoachingCenterDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachingDetailAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachingCenterDetailWithTokenReducer = createAsyncThunk(
  "CoachingCenterDetailWithTokenReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachingDetailWithTokenAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const MyFavouriteVenueListReducer = createAsyncThunk(
  "MyFavouriteVenueListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await MyFavouriteVenueListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const MyFavouriteCoachListReducer = createAsyncThunk(
  "MyFavouriteCoachListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await MyFavouriteCoachListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const MyFavouriteVenueDetailReducer = createAsyncThunk(
  "MyFavouriteVenueDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await MyFavouriteVenueDetailAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const MyFavouriteVenueDetailWithTokenReducer = createAsyncThunk(
  "MyFavouriteVenueDetailWithTokenReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await MyFavouriteVenueDetailWithTokenAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const UpdateUserProfileReducer = createAsyncThunk(
  "UpdateUserProfileReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await EditProfilePApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const SportListReducer = createAsyncThunk(
  "SportListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SportsListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CouponListReducer = createAsyncThunk(
  "CouponListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CouponListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CourtListReducer = createAsyncThunk(
  "CourtListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CourtListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CourtDetailReducer = createAsyncThunk(
  "CourtDetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CourtDetailAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CreateVenueEventReducer = createAsyncThunk(
  "CreateVenueEventReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CreateVenueEventAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const MYEventBookingReducer = createAsyncThunk(
  "MYEventBookingReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await MYEventBookingApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const calculatePriceReducer = createAsyncThunk(
  "calculatePriceReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CalculatePriceApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachingCenterReducer = createAsyncThunk(
  "CoachingCenterReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachingListAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachingCenterWithTokenReducer = createAsyncThunk(
  "CoachingCenterWithTokenReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachingListWithTokenAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const AllReadyCreatedEventdetailReducer = createAsyncThunk(
  "AllReadyCreatedEventdetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AllReadyCreatedEventdetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const JoinCreatedEventdetailReducer = createAsyncThunk(
  "JoinCreatedEventdetailReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await JoinCreatedEventdetailApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const AllReadyCreatedEventdetailWithOutTokenReducer = createAsyncThunk(
  "AllReadyCreatedEventdetailWithOutTokenReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AllReadyCreatedEventdetailWithOutTokenApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachBookingListReducer = createAsyncThunk(
  "CoachBookingListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachBookingListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const BookTrialSessionReducer = createAsyncThunk(
  "BookTrialSessionReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await BookTrialSessionAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const BookCoachReducer = createAsyncThunk(
  "BookCoachReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await BookCoachAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachingSlotReducer = createAsyncThunk(
  "CoachingSlotReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachingSlotAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const MyAccountReducer = createAsyncThunk(
  "MyAccountReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await MyAccountAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const ReviewRatingReducer = createAsyncThunk(
  "ReviewRatingReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await ReviewRatingAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const HomePageDataReducer = createAsyncThunk(
  "HomePageDataReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await HomePageDataAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const HomePageDataCoachesReducer = createAsyncThunk(
  "HomePageDataCoachesReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await HomePageDataCoachesAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const HomePageDataVenueReducer = createAsyncThunk(
  "HomePageDataVenueReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await HomePageDataVenueAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const HomePageDataReducerWithToken = createAsyncThunk(
  "HomePageDataReducerWithToken",
  async (obj, { dispatch }) => {
    try {
      const response = await HomePageDataAPIWithToken(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const HomePageDataCoachesReducerWithToken = createAsyncThunk(
  "HomePageDataCoachesReducerWithToken",
  async (obj, { dispatch }) => {
    try {
      const response = await HomePageDataCoachesAPIWithToken(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const HomePageDataVenueReducerWithToken = createAsyncThunk(
  "HomePageDataVenueReducerWithToken",
  async (obj, { dispatch }) => {
    try {
      const response = await HomePageDataVenueAPIWithToken(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CoachReviewRatingReducer = createAsyncThunk(
  "CoachReviewRatingReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CoachReviewRatingAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const BlockedUserReducer = createAsyncThunk(
  "BlockedUserReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await BlockedUserAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CompletedBookingGiveRatingReviewbyUserReducer = createAsyncThunk(
  "CompletedBookingGiveRatingReviewbyUserReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CompletedBookingGiveRatingReviewbyUserAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const AddBlockedUserReducer = createAsyncThunk(
  "AddBlockedUserReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AddBlockedUserAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const GetVenueRatingReducer = createAsyncThunk(
  "GetVenueRatingReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await GetVenueRatingAPI(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const notificationReducer = createAsyncThunk(
  "notificationReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await notificationApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const notificationCountReducer = createAsyncThunk(
  "notificationCountReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await NotificationCountApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const SendContactUsReducer = createAsyncThunk(
  "SendContactUsReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SendContactUsApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const BookingCancelReducer = createAsyncThunk(
  "BookingCancelReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await BookingCancelApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const UserHelpAndSupportReducer = createAsyncThunk(
  "UserHelpAndSupportReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await UserHelpAndSupportApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const joinEventBookingReducer = createAsyncThunk(
  "joinEventBookingReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await joinEventBookingApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const verifyBlockUserReducer = createAsyncThunk(
  "verifyBlockUserReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await verifyBlockUserApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// Payment Apis========================================
export const CreateOrderIdReducer = createAsyncThunk(
  "CreateOrderIdReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CreateOrderIdApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const CheckoutReducer = createAsyncThunk(
  "CheckoutReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await CheckoutApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const PaymentVerifyReducer = createAsyncThunk(
  "PaymentVerifyReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await PaymentVerifyApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const AddPaymentReducer = createAsyncThunk(
  "AddPaymentReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await AddPaymentApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const GetAllKeysReducer = createAsyncThunk(
  "GetAllKeysReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await GetAllKeysApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
export const SportsListReducer = createAsyncThunk(
  "SportsListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await SportsListApi(obj);
      //console.log(response);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const MainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    clearList: (state, action) => {
      state.ProgramsList = action?.payload;
    },
    ChangePrograms: (state, action) => {
      state.programs = action?.payload;
    },
    ChangeLiveCoords: (state, action) => {
      state.liveCoords = action?.payload;
    },
    ChangeouterLogin: (state, action) => {
      state.outerLogin = action?.payload;
    },
    ChangeurlId: (state, action) => {
      state.urlId = action?.payload;
    },
    ChangeSportName: (state, action) => {
      state.sportName = action?.payload;
    },
    ChangeSportId: (state, action) => {
      state.detailSport = action?.payload;
    },
    ChangeSessionName: (state, action) => {
      state.SessionName = action?.payload;
    },
    ChangePlanDuration: (state, action) => {
      state.PlanDuration = action?.payload;
    },
    ChangeplanPrice: (state, action) => {
      state.planPrice = action?.payload;
    },
  },
  extraReducers: {
    [LoginReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.normaluserId = action?.payload?.data?.user;
      state.token = action?.payload?.data?.token;
    },
    [SocialLoginReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.normaluserId = action?.payload?.data?.user;
      state.token = action?.payload?.data?.token;
    },
    [UserDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.userDetail = action?.payload?.data?.normaluser;
    },
    [VanueListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.venueListing = action?.payload?.data?.venue;
      state.total_venue = action?.payload?.data?.total_venue;
    },
    [VanueListWithTokenReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.venueListing = action?.payload?.data?.venue;
      state.total_venue = action?.payload?.data?.total_venue;
    },
    [TimeSlotListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.timeSlot = action?.payload?.data?.timeSlot;
    },
    [CoachingCenterReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachingCenterList = action?.payload?.data?.coaches;
      state.total_coaches = action?.payload?.data?.total_coaches;
    },
    [CoachingCenterWithTokenReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachingCenterList = action?.payload?.data?.coaches;
      state.total_coaches = action?.payload?.data?.total_coaches;
    },
    [CoachingCenterDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachingCenterDetail = action?.payload?.data?.coaches;
    },
    [CoachingCenterDetailWithTokenReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachingCenterDetail = action?.payload?.data?.coaches;
    },
    [MyFavouriteVenueListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.MyFavouriteList = action?.payload?.data?.venues;
    },
    [MyFavouriteVenueDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueDetail = action?.payload?.data;
    },
    [MyFavouriteVenueDetailWithTokenReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueDetail = action?.payload?.data;
    },
    [SportListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.SportList = action?.payload?.data?.Program;
    },
    [CouponListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CouponList = action?.payload?.data?.couponcode;
      state.total_couponcode = action?.payload?.data?.total_couponcode;
    },
    [CourtListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CourtList = action?.payload?.data?.courtandtruf;
    },
    [CourtDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CourtDetail = action?.payload?.data;
    },
    [calculatePriceReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.calculatePrice = action?.payload?.data;
    },
    [MYEventBookingReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.BookingList = action?.payload?.data?.eventbooking;
      state.total_booking = action?.payload?.data?.total_eventbooking;
    },
    [CreateVenueEventReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CreateVenueEventDetail = action?.payload?.data?.eventbooking;
    },
    [joinEventBookingReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CreateVenueEventDetail = action?.payload?.data?.eventbooking;
    },
    [AllReadyCreatedEventdetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.AllReadyCreatedEventdetail = action?.payload?.data;
    },
    [JoinCreatedEventdetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.AllReadyCreatedEventdetail = action?.payload?.data;
    },
    [AllReadyCreatedEventdetailWithOutTokenReducer.fulfilled]: (
      state,
      action
    ) => {
      //console.log(action);
      state.AllReadyCreatedEventdetail = action?.payload?.data;
    },
    [CoachBookingListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachBookingList = action?.payload?.data?.coachingappointment;
      state.total_CoachBookingList =
        action?.payload?.data?.total_coachingappointment;
    },
    [CoachingSlotReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.CoachingSlotList = action?.payload?.data?.timeSlot;
    },
    [MyAccountReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.MyAccount = action?.payload?.data?.myaccount;
      state.rewardPoints = action?.payload?.data?.myaccount?.rewardPoints;
      state.amount = action?.payload?.data?.myaccount?.amount;
    },
    [ReviewRatingReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.RatingReviewList = action?.payload?.data?.coachingappointment;
      state.total_RatingReview =
        action?.payload?.data?.totalAppointments;
      state.totalRating = action?.payload?.data?.totalRatings;
      state.totalRaview = action?.payload?.data?.totalReviews;
    },
    [HomePageDataReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.HomePageData = action?.payload?.data;
    },
    [HomePageDataCoachesReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.HomePageDataCoach = action?.payload?.data?.coachesData;
    },
    [HomePageDataVenueReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.HomePageDataVenue = action?.payload?.data?.venueData;
    },
    [HomePageDataReducerWithToken.fulfilled]: (state, action) => {
      //console.log(action);
      state.HomePageData = action?.payload?.data;
    },
    [HomePageDataCoachesReducerWithToken.fulfilled]: (state, action) => {
      //console.log(action);
      state.HomePageDataCoach = action?.payload?.data?.coachesData;
    },
    [HomePageDataVenueReducerWithToken.fulfilled]: (state, action) => {
      //console.log(action);
      state.HomePageDataVenue = action?.payload?.data?.venueData;
    },
    [MyFavouriteCoachListReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.FavouriteCoachList = action?.payload?.data?.coaching;
    },
    [BlockedUserReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.BlockedUserList = action?.payload?.data?.blockUser;
      state.total_BlockedUser = action?.payload?.data?.totalBlockedUser;
    },
    [CreateOrderIdReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.orderId = action?.payload?.data?.id;
    },
    [GetVenueRatingReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.VenueRatingReviewList = action?.payload?.data;
    },
    [SendContactUsReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.ContactUsSendData = action?.payload?.data;
    },
    [notificationReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.notification = action?.payload?.data?.notification;
      state.total_notification = action?.payload?.data?.total_notification;
    },
    [notificationCountReducer.fulfilled]: (state, action) => {
      state.notificationCount = action?.payload?.data?.total_notification;
    },
    [GetAllKeysReducer.fulfilled]: (state, action) => {
      state.AllKeys = action?.payload?.data;
    },
    [SportsListReducer.fulfilled]: (state, action) => {
      state.SportsList = action?.payload?.data;
    },
  },
});

export const {
  clearList,
  ChangePrograms,
  ChangeLiveCoords,
  ChangeouterLogin,
  ChangeurlId,
  ChangeSportName,
  ChangeSportId,
  ChangeSessionName,
  ChangePlanDuration,
  ChangeplanPrice,
} = MainSlice.actions;

// export const { togglesuccess } = MainSlice.actions;

export default MainSlice.reducer;
