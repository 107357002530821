import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  CoachEarningChartReducer,
  CoachEarningReducer,
} from "../../CoachVenueslice";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

function CoachMyEarning() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const detail = useSelector((state) => state.CoachVenueMain.CoachEarning);
  const CoachEarningChart = useSelector(
    (state) => state.CoachVenueMain.CoachEarningChart
  );

  const [earning_type, setearning_type] = useState("1");
  const [SelectDate, setSelectDate] = useState(new Date());

  //console.log(changedate);

  const handlePage = (value) => {
    setPage(value);
  };

  const getdetailChart = useCallback(() => {
    dispatch(
      CoachEarningChartReducer({
        date: moment(SelectDate).format("YYYY-MM-DD"),
        earning_type: earning_type,
      })
    );
  }, [dispatch, earning_type, SelectDate]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getdetailChart();
    }
    return () => {
      mounted = false;
    };
  }, [getdetailChart]);

  const BarData = {
    labels:
      earning_type === "1"
        ? CoachEarningChart?.sales?.map((item) =>
            moment(item.date, "YYYY-MM-DD").format("ddd")
          )
        : earning_type === "2"
        ? CoachEarningChart?.sales?.map((item) => {
            return `${moment(item.date, "YYYY-MM-DD")
              .startOf("week")
              .format("DD MMM")}-${moment(item.date, "YYYY-MM-DD")
              .endOf("week")
              .format("DD MMM")} `;
          })
        : earning_type === "3"
        ? CoachEarningChart?.sales?.map((item) =>
            moment(item.date, "YYYY-MM-DD").format("MMM YY")
          )
        : "",
    datasets: [
      {
        data: CoachEarningChart?.sales?.map((item) => item.salesData),
        fill: true,
        backgroundColor: ["#f2bcbf"],
        borderColor: "#df0510",
        borderRadius: 50,
        borderWidth: 0,
        label: "₹",
        pointStyle: "#df0510",
        pointRadius: 5,
        pointBorderColor: "#df0510",
      },
    ],
  };
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">My Earning</h3>
              </div>
            </div>
            <div className="">
              <div className="row">
                <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                  <div class="paymentBox dueBox d-block p-4">
                    <div>
                      <h6 class="mb-3">Total earnings</h6>
                      <h3 class="mb-3">
                        <i className="fa fa-inr"></i>{" "}
                        {CoachEarningChart?.totalEarning
                          ? parseFloat(CoachEarningChart?.totalEarning).toFixed(
                              2
                            )
                          : "0"}
                      </h3>
                    </div>
                    <img
                      src="assets/img/Whitegraph.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                  <div class="paymentBox recivedBox d-block p-4">
                    <div>
                      <h6 class="mb-3">Total Payment received</h6>
                      <h3 class="mb-3">
                        <i className="fa fa-inr"></i>{" "}
                        {CoachEarningChart?.paymentReceived
                          ? parseFloat(
                              CoachEarningChart?.paymentReceived
                            ).toFixed(2)
                          : "0"}
                      </h3>
                    </div>
                    <img
                      src="assets/img/Redgraph.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
                  <div class="paymentBox recivedBox d-block p-4">
                    <div>
                      <h6 class="mb-3">Payment yet to receive</h6>
                      <h3 class="mb-3">
                        <i className="fa fa-inr"></i>{" "}
                        {CoachEarningChart?.paymentYetToReceived
                          ? parseFloat(
                              CoachEarningChart?.paymentYetToReceived
                            ).toFixed(2)
                          : "0"}
                      </h3>
                    </div>
                    <img
                      src="assets/img/Bluegraph.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rad p-3">
              <div className="row mt-4 rowBottomBorder">
                <div className="col-sm-8 col-md-9 col-lg-9 mb-3">
                  <ul
                    className="nav nav-pills mb-0 justify-content-start tabsnew paymentTabs"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active text-capitalize position-relative"
                        id="pills-sports-earning-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-sports-earning"
                        type="button"
                        role="tab"
                        aria-controls="pills-sports-earning"
                        aria-selected="true"
                      >
                        sports earning
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-4 col-md-3 col-lg-3 text-end">
                  <span class="d-inline-flex align-items-center gap-1 border nowrap p-2 rad">
                    <span>Earning By: </span>
                    <select
                      class="form-select border-0 fw-bold"
                      onClick={(e) => {
                        setearning_type(e.target.value);
                      }}
                    >
                      <option value="1">Daily</option>
                      <option value="2">Weekly</option>
                      <option value="3">Monthly</option>
                    </select>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="tab-content mt-4" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-sports-earning"
                      role="tabpanel"
                      aria-labelledby="pills-sports-earning-tab"
                    >
                      <div className="">
                        <Bar
                          data={BarData}
                          plugin={ChartDataLabels}
                          options={{
                            plugins: {
                              layout: {
                                padding: {
                                  left: 10,
                                  right: 25,
                                  top: 25,
                                  bottom: 0,
                                },
                              },
                              title: {
                                display: false,
                              },
                              legend: {
                                display: false,
                              },
                            },
                            maintainAspectRatio: true,
                            responsive: true,

                            scales: {
                              x: {
                                display: true,
                                ticks: {
                                  color: "#000",
                                },
                                time: {
                                  unit: "date",
                                },
                                grid: {
                                  display: false,
                                  drawBorder: false,
                                },
                              },
                              y: {
                                display: true,

                                ticks: {
                                  padding: 10,
                                  //color: "#000",
                                },
                                grid: {
                                  display: true,
                                  drawOnChartArea: true,
                                  drawTicks: true,
                                  drawBorder: true,
                                  borderDash: [5],
                                  zeroLineBorderDash: [5],
                                },
                              },
                            },
                            Tooltip: {
                              backgroundColor: "#fff",
                              bodyFontColor: "#000",
                              bodyFontStyle: "bold",
                              bodyFontSize: 20,
                              titleMarginBottom: 10,
                              titleFontColor: "#000",
                              titleFontSize: 25,
                              borderColor: "#fff",
                              borderWidth: 1,
                              xPadding: 15,
                              yPadding: 15,
                              displayColors: false,
                              intersect: false,
                              mode: "index",
                              caretPadding: 10,
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CoachMyEarning;
