import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <footer className="footer position-relative">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
              <div className="widget">
                <h3>Useful Links</h3>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <ul className="footer-li">
                      <li>
                        <Link to="/">home</Link>
                      </li>
                      <li>
                        <Link
                          to="/faq"
                          onClick={() => {
                            scrollTop();
                          }}
                        >
                          FAQ's
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about-us"
                          onClick={() => {
                            scrollTop();
                          }}
                        >
                          about us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/privacy-policy"
                          onClick={() => {
                            scrollTop();
                          }}
                        >
                          privacy policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact-us"
                          onClick={() => {
                            scrollTop();
                          }}
                        >
                          contact us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/terms-conditions"
                          onClick={() => {
                            scrollTop();
                          }}
                        >
                          terms & conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-1 col-lg-2 col-xl-4"></div>
            <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
              <div className="widget getAppFla1">
                <h3>Get Mobile App</h3>
                {/* <p className="mb-5">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa.
                </p> */}
                <p>
                  <a href="https://play.google.com/store/apps/details?id=app.gamehunt" target="_blank">
                  <img
                    src="assets/img/launchedGooglePlay.png"
                    alt=""
                    className="mt-2 me-2 appIconImg"
                  />
                  </a>
                  <img
                    src="assets/img/Appstore.png"
                    alt=""
                    className="mt-2 appIconImg"
                  />
                </p>
              </div>
              <div className="linkSocial d-md-flex1 d-grid1 align-items-center justify-content-center justify-content-md-end gap-2">
                <h6>Connect Us:</h6>
                <ul className="Social-Links">
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100086714991898&mibextid=LQQJ4d"
                      target="_blank"
                    >
                      <img
                        src="assets/img/facebook.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/gamehunt_india"
                      target="_blank"
                    >
                      <img
                        src="assets/img/Twitter.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/gamehuntofficial"
                      target="_blank"
                    >
                      <img
                        src="assets/img/linkedin.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/gamehuntofficial/"
                      target="_blank"
                    >
                      <img
                        src="assets/img/Instagram.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="widget">
                <h3>Signup for Newsletter</h3>

                <Newsletter />
              </div>
            </div> */}
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2 text-center fw-bold">
                &copy; GameHunt 2022. All rights reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
