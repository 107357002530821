import React from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  coachHelpAndSupportReducer,
  VenueHelpAndSupportReducer,
} from "../../features/CoachVenueslice";
import { UserHelpAndSupportReducer } from "../../features/slice";

function HelpAndSupportModal({ show, handleShow }) {
  const dispatch = useDispatch();

  const loginType = localStorage.getItem("loginType");

  const initialValues = {
    comment: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      comment: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const payload = {
        comment: values.comment,
      };
      if (loginType === "user") {
        dispatch(UserHelpAndSupportReducer(payload)).then((data) => {
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            handleShow();
          } else {
            toast.error(data.payload.message);
          }
        });
      } else if (loginType === "coach") {
        dispatch(coachHelpAndSupportReducer(payload)).then((data) => {
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            handleShow();
          } else {
            toast.error(data.payload.message);
          }
        });
      } else {
        dispatch(VenueHelpAndSupportReducer(payload)).then((data) => {
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            handleShow();
          } else {
            toast.error(data.payload.message);
          }
        });
      }
    },
  });
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <form className="row" onSubmit={formik.handleSubmit}>
                  <div className="col-sm-12 col-md-12 col-lg-12 px-5">
                    <div className="text-center">
                      <h3 className="fw-bold py-4">Help and Support</h3>
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="bg-white rad border-0 p-3 w-100"
                        rows="7"
                        placeholder="Query here.."
                        name="comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.errors.comment && formik.touched.comment && (
                        <div className="text-red"> {formik.errors.comment}</div>
                      )}
                    </div>
                    <div className="mb-4 text-center">
                      <button type="submit" className="btn btn-submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HelpAndSupportModal;
