import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paginations from "../../Components/Paginations";
import { ReviewRatingReducer } from "../slice";
import moment from "moment";
import Loader from "../../Components/Loader";

function ReviewRating() {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.main.RatingReviewList);
  const total = useSelector((state) => state.main.total_RatingReview);
  const totalRaview = useSelector((state) => state.main.totalRaview);
  const totalRating = useSelector((state) => state.main.totalRating);

  const [received, setReceived] = useState("1");
  const [gave, setGave] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [reviewRating, setReviewRating] = useState("received");
  const handlePage = (value) => {
    setPage(value);
  };
  const getRatingReviewList = useCallback(async () => {
    setLoading(true);
    dispatch(
      ReviewRatingReducer({
        page: page,
        limit: 10,
        isReceived: received === "1" ? received : "",
        isGave: gave === "1" ? gave : "",
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, received, page, gave]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getRatingReviewList();
    }
    return () => {
      mounted = false;
    };
  }, [getRatingReviewList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">Review & Rating</h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-10 col-md-10 col-lg-10 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-start bookingTabs tabsnew gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        reviewRating === "received"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setReceived("1");
                        setGave("");
                        setReviewRating("received");
                      }}
                    >
                      received
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        reviewRating === "given"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setGave("1");
                        setReceived("");
                        setReviewRating("given");
                      }}
                    >
                      given
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mb-3 text-end">
                <div className="theme-color text-center">
                  <h3>{totalRating}</h3>
                  <div className="ratingIcon mb-1">
                    {totalRating > 0.4 && totalRating < 0.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 0.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 1.4 && totalRating < 1.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 1.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 2.4 && totalRating < 2.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 2.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 3.4 && totalRating < 3.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 3.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 4.4 && totalRating < 4.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 4.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                  </div>
                  <p>
                    {totalRaview} ({totalRaview <= 1 ? "review" : "reviews"})
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                {loading ? (
                  <Loader colorChange="colorChange" />
                ) : (
                  <div className="tab-content mt-4" id="pills-tabContent">
                    {reviewRating === "received" ? (
                      <div>
                        <div className="tableDesign w-100">
                          <div className="">
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <div
                                  className="position-relative botmLine"
                                  key={i}
                                >
                                  <div className="d-md-flex justify-content-between gap-3">
                                    <span>
                                      <p className="text-muted text-capitalize">
                                        {item?.rateBy === "user" ? (
                                          <img
                                            src={
                                              item?.normaluser?.picture?.includes(
                                                "http"
                                              )
                                                ? item?.normaluser?.picture
                                                : "assets/img/employe.png"
                                            }
                                            className="coachImgCenetr"
                                            alt=""
                                          />
                                        ) : item?.rateBy === "venue" ? (
                                          <img
                                            src={
                                              item?.venue?.image?.[0]?.image?.includes(
                                                "http"
                                              )
                                                ? item?.venue?.image?.[0]?.image
                                                : "assets/img/employe.png"
                                            }
                                            className="coachImgCenetr"
                                            alt=""
                                          />
                                        ) : item?.rateBy === "coach" ? (
                                          <img
                                            src={
                                              item?.coaches?.image?.includes(
                                                "http"
                                              )
                                                ? item?.coaches?.image
                                                : "assets/img/employe.png"
                                            }
                                            className="coachImgCenetr"
                                            alt=""
                                          />
                                        ) : null}{" "}
                                        {item?.rateBy === "user"
                                          ? `${
                                              item?.normaluser?.firstName
                                                ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                                : item?.user?.mobile
                                                ? `${item?.user?.countryCode} ${item?.user?.mobile}`
                                                : ""
                                            } `
                                          : item?.rateBy === "venue"
                                          ? item?.venue?.name
                                          : item?.rateBy === "coach"
                                          ? item?.coaches?.coachingCenterName
                                            ? item?.coaches?.coachingCenterName
                                            : item?.coaches?.name
                                          : null}
                                        , {moment(item?.time).format("ll")}
                                      </p>
                                    </span>
                                    <small>
                                      {item?.rating === 1 ? (
                                        <img
                                          src="assets/img/star_full.png"
                                          alt=""
                                        />
                                      ) : item?.rating === 2 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 3 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 4 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 5 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : null}
                                    </small>
                                  </div>
                                  <h6>{item?.review}</h6>
                                </div>
                              ))
                            ) : (
                              <div className="text-center">No Data Found</div>
                            )}
                          </div>
                          {list?.length > 0 ? (
                            <div className="text-center mt-5">
                              <Paginations
                                handlePage={handlePage}
                                page={page}
                                total={total && total}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : reviewRating === "given" ? (
                      <div>
                        <div className="tableDesign w-100">
                          <div className="">
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <div
                                  className="position-relative botmLine"
                                  key={i}
                                >
                                  <div className="d-md-flex justify-content-between gap-3">
                                    <span>
                                      <p className="text-muted text-capitalize">
                                        {item?.rateBy === "user" ? (
                                          <img
                                            src={
                                              item?.normaluser?.picture?.includes(
                                                "http"
                                              )
                                                ? item?.normaluser?.picture
                                                : "assets/img/employe.png"
                                            }
                                            className="coachImgCenetr"
                                            alt=""
                                          />
                                        ) : item?.rateBy === "venue" ? (
                                          <img
                                            src={
                                              item?.venue?.image?.[0]?.image?.includes(
                                                "http"
                                              )
                                                ? item?.venue?.image?.[0]?.image
                                                : "assets/img/employe.png"
                                            }
                                            className="coachImgCenetr"
                                            alt=""
                                          />
                                        ) : item?.rateBy === "coach" ? (
                                          <img
                                            src={
                                              item?.coaches?.image?.includes(
                                                "http"
                                              )
                                                ? item?.coaches?.image
                                                : "assets/img/employe.png"
                                            }
                                            className="coachImgCenetr"
                                            alt=""
                                          />
                                        ) : null}{" "}
                                        {item?.rateBy === "user"
                                          ? `${
                                              item?.normaluser?.firstName
                                                ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                                : item?.user?.mobile
                                                ? `${item?.user?.countryCode} ${item?.user?.mobile}`
                                                : ""
                                            } `
                                          : item?.rateBy === "venue"
                                          ? item?.venue?.name
                                          : item?.rateBy === "coach"
                                          ? item?.coaches?.coachingCenterName
                                            ? item?.coaches?.coachingCenterName
                                            : item?.coaches?.name
                                          : null}
                                        , {moment(item?.time).format("ll")}
                                      </p>
                                    </span>
                                    <small>
                                      {item?.rating === 1 ? (
                                        <img
                                          src="assets/img/star_full.png"
                                          alt=""
                                        />
                                      ) : item?.rating === 2 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 3 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 4 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 5 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : null}
                                    </small>
                                  </div>
                                  <h6>{item?.review}</h6>
                                </div>
                              ))
                            ) : (
                              <div className="text-center">No Data Found</div>
                            )}
                          </div>
                          {list?.length > 0 ? (
                            <div className="text-center mt-5">
                              <Paginations
                                handlePage={handlePage}
                                page={page}
                                total={total && total}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ReviewRating;
