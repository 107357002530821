import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateFavVenueAPI } from "../../api/setting";
import { MyFavouriteVenueListReducer } from "../slice";

function MyFavourite() {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.main.MyFavouriteList);

  const getFavouriteList = useCallback(async () => {
    dispatch(MyFavouriteVenueListReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getFavouriteList();
    }
    return () => {
      mounted = false;
    };
  }, [getFavouriteList]);

  const UpdateFav = (data) => {
    //console.log(data);
    UpdateFavVenueAPI(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getFavouriteList();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-5">Favourite</h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-center tabsnew"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/my-favourite"
                      className="nav-link active text-capitalize"
                    >
                      venue
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/favourite-coach"
                      className="nav-link text-capitalize"
                    >
                      coach
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row FavouriteVenuesList">
              {list?.length > 0 ? (
                list?.map((item, i) => (
                  <div className="col-sm-6 col-md-6 col-lg-4 mb-5" key={i}>
                    <div className="AlreadyEvent p-3 position-relative">
                      <div className="AlreadyEventBox justify-content-start gap-2">
                        <img
                          src={
                            item?.image[0]?.image?.includes("http")
                              ? item?.image[0]?.image
                              : "assets/img/dummyImage.png"
                          }
                          alt=""
                          className="eventImg m-0"
                        />
                        <span>
                          <h5 className="mb-2 text-capitalize">
                            <Link
                              to={`/venues-listing/detail/${item?._id}`}
                              className="text-dark"
                              onClick={() => {
                                localStorage.setItem(
                                  "detailSport",
                                  item?.sports[0]?.sport
                                );
                              }}
                            >
                              {item?.name || "NA"}
                            </Link>
                          </h5>
                          <p className="mb-0 text-capitalize">
                            {item?.city
                              ? `${item?.city} ${item?.state} ${item?.zipCode}`
                              : "NA"}
                          </p>
                        </span>
                        <img
                          src="assets/img/Favoriteselected.png"
                          className="Favoriteselected"
                          alt=""
                          id={`venue-${item?._id}`}
                          onClick={() => {
                            UpdateFav({
                              venueId: item?._id,
                              isFevVenue:
                                item?.isFevVenue === true ? false : true,
                            });
                          }}
                        />
                      </div>
                      <hr />
                      <p className="fw-bold mb-0 text-capitalize">
                        {item?.sports
                          ?.map((sport) => {
                            return `${sport?.sport}`;
                          })
                          ?.join(", ")}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MyFavourite;
