import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { SignUpReducer } from "../../features/slice";
import FacebookSocialLogin from "../socialLogin/facebookLogin";
import GoogleSocialLogin from "../socialLogin/googleLogin";
import Phone from "../Phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import { formatPhoneNumberIntl } from "react-phone-number-input";

function SignUpWithMobileModal({ show, handleShow, setModalName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const isValid = (num, setFieldValue, setFieldError) => {
    console.log(num, "NUm");
    if (num) {
      const valid = isValidPhoneNumber(num);

      console.log(valid, num);
      if (valid) {
        setFieldValue("mobile", num);
      } else {
        setFieldError("mobile", "Kindly enter a valid number.");
      }
      return valid;
    }
    return false;
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="xl">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div className="col-sm-12 col-md-12 col-lg-6 bgSignUpImgUser">
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-lg-5">
                    <div className="form-style mt-3 mt-lg-5 px-4">
                      <Formik
                        initialValues={{
                          mobile: "",
                          countryCode: "91",
                        }}
                        validationSchema={Yup.object({
                          mobile: Yup.string().required("required"),
                        })}
                        onSubmit={(values, action) => {
                          setLoading(true);
                          const countryCode = values?.mobile
                            ? formatPhoneNumberIntl(values?.mobile).split(
                                " "
                              )[0]
                            : "";
                          dispatch(
                            SignUpReducer({
                              countryCode: countryCode,
                              mobile: values.mobile
                                ? values.mobile
                                    ?.replace(countryCode, "")
                                    ?.replaceAll(" ", "")
                                : "",
                              register_type: "mobile",
                            })
                          ).then(async (data) => {
                            //console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 2) {
                              await localStorage.setItem(
                                "mobile",
                                data?.payload?.data?.mobile
                              );
                              await localStorage.setItem(
                                "countryCode",
                                data?.payload?.data?.countryCode
                              );
                              await localStorage.setItem(
                                "OTPVerify",
                                "SignUpOTPVerify"
                              );
                              toast.success(data?.payload?.message);
                              setModalName("OTPVerification");
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFormikState,
                          setFieldValue,
                          setFieldError,
                        }) => (
                          <form
                            className=""
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleSubmit();
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-12 text-center mb-3 mb-md-5">
                                <h2 className="mdl-ttl">Sign Up</h2>
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <Phone
                                      onChange={(val) => {
                                        console.log(val);
                                        if (val) {
                                          isValid(
                                            val,
                                            setFieldValue,
                                            setFieldError
                                          );
                                        } else {
                                          setFieldValue(`mobile`, "");
                                        }
                                      }}
                                      // onBlur={(val) => {
                                      //   isValid(
                                      //     val,
                                      //     setFieldValue,
                                      //     setFieldError
                                      //   );
                                      // }}
                                      isValid={(val) => {
                                        isValid(
                                          val,
                                          setFieldValue,
                                          setFieldError
                                        );
                                      }}
                                      value={values.mobile}
                                      name={`mobile`}
                                      className="signupClass"
                                    />
                                    {/* <PhoneInput
                                      country="in"
                                      inputProps={{
                                        name: "mobile",
                                      }}
                                      enableSearch
                                      value={values.countryCode + values.mobile}
                                      onChange={(
                                        phone,
                                        country,
                                        e,
                                        formattedValue
                                      ) => {
                                        setCountryData({
                                          formattedValue,
                                          country,
                                        });
                                        if (phone.length > 0) {
                                          const raw = phone.slice(
                                            country?.dialCode?.length
                                          );
                                          setFieldValue("mobile", raw);

                                          setFieldValue(
                                            "countryCode",
                                            formattedValue?.split(" ")[0]
                                          );
                                          
                                        } else {
                                          setFieldValue("mobile", "");

                                          setFieldValue("countryCode", "");
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      placeholder="Primary Mobile Number"
                                      containerclassName="form-control signupClass"
                                      inputclassName="phoneInputClass"
                                    /> */}
                                    <img
                                      src="assets/img/mobile.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.mobile && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.mobile}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn btn-submit mt-0 w-75"
                                >
                                  {loading ? <Loader /> : "Sign Up"}
                                </button>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="orLine">
                                  <span className="orLog">or</span>
                                </div>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <p
                                  className="loginwithEmailBtn btn w-75"
                                  onClick={() => {
                                    setModalName("SignUpModal");
                                  }}
                                >
                                  <img
                                    src="assets/img/EmailArrowRight.png"
                                    alt=""
                                  />{" "}
                                  Sign up with Email
                                </p>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="orLine">
                                  <span className="orLog signOr">
                                    or use one of these option
                                  </span>
                                </div>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="d-flex justify-content-center align-items-center gap-3 my-3">
                                  <FacebookSocialLogin
                                    handleShow={handleShow}
                                  />
                                  <GoogleSocialLogin handleShow={handleShow} />
                                </div>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                                Already have an account ?{" "}
                                <span
                                  className="theme-color cursor-pointer fw-bold"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                  }}
                                >
                                  Login
                                </span>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignUpWithMobileModal;
