import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paginations from "../../Components/Paginations";
import { notificationCountReducer, notificationReducer } from "../slice";
import moment from "moment";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";

function Notification() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("0");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noti, setNoti] = useState("new");
  const handlePage = (value) => {
    setPage(value);
  };
  const list = useSelector((state) => state.main.notification);
  const total = useSelector((state) => state.main.total_notification);

  const getList = useCallback(async () => {
    setLoading(true);
    dispatch(
      notificationReducer({
        status: status,
        page: page,
        limit: 10,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, status, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getList();
    }
    return () => {
      mounted = false;
    };
  }, [getList]);

  const notiCount = () => {
    dispatch(
      notificationCountReducer({
        status: "0",
      })
    );
  };

  useEffect(() => {
    notiCount();
  }, []);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-5">Notifications</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-start bookingTabs notiTabs tabsnew gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        noti === "new"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setStatus("0");
                        setNoti("new");
                      }}
                    >
                      {" "}
                      new
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        noti === "read"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setStatus("1");
                        notiCount();
                        setNoti("read");
                      }}
                    >
                      {" "}
                      read
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                {loading ? (
                  <Loader colorChange="colorChange" />
                ) : (
                  <div>
                    {noti === "new" ? (
                      <div className="t">
                        <div className="bg-white w-100 mt-2 p-4 rad box-shodow">
                          <div className="">
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <div
                                  className="position-relative ps-5 botmLine"
                                  key={i}
                                >
                                  <span className="PopupcloseBg">
                                    <img
                                      src="assets/img/new_notification.png"
                                      alt=""
                                    />
                                  </span>
                                  <div className="d-sm-flex justify-content-between gap-3 mb-2">
                                    <span>
                                      <h5 className=" mb-1">
                                        {item?.notification || "NA"}
                                      </h5>
                                    </span>
                                    <small className="text-muted whiteNorap">
                                      <img
                                        src="assets/img/timeIcon.png"
                                        width="20px"
                                        className="mb-1"
                                        alt=""
                                      />{" "}
                                      {moment(item?.time).format("lll")}
                                    </small>
                                  </div>
                                  {item?.notificationFor === "event" ? (
                                    <Link
                                      to={`/notification/${item?.eventId}`}
                                      className="viewBtn"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "isCancelled",
                                          item?.isCancelled
                                        );
                                        localStorage.setItem(
                                          "time",
                                          item?.time
                                        );
                                      }}
                                    >
                                      View Booking Detail
                                    </Link>
                                  ) : null}
                                </div>
                              ))
                            ) : (
                              <div className="text-center">
                                No Records Found
                              </div>
                            )}
                          </div>

                          {list?.length > 0 && (
                            <div className="text-center mt-2">
                              <Paginations
                                handlePage={handlePage}
                                page={page}
                                total={total && total}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : noti === "read" ? (
                      <div className="t">
                        <div className="bg-white w-100 mt-2 p-4 rad box-shodow">
                          <div className="">
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <div
                                  className="position-relative ps-5 botmLine"
                                  key={i}
                                >
                                  <span className="PopupcloseBg">
                                    <img
                                      src="assets/img/read_notification.png"
                                      alt=""
                                    />
                                  </span>
                                  <div className="d-sm-flex justify-content-between gap-3 mb-2">
                                    <span>
                                      <h5 className=" mb-1">
                                        {item?.notification || "NA"}
                                      </h5>
                                    </span>
                                    <small className="text-muted whiteNorap">
                                      <img
                                        src="assets/img/timeIcon.png"
                                        width="20px"
                                        className="mb-1"
                                        alt=""
                                      />{" "}
                                      {moment(item?.time).format("lll")}
                                    </small>
                                  </div>
                                  {item?.notificationFor === "event" ? (
                                    <Link
                                      to={`/notification/${item?.eventId}`}
                                      className="viewBtn"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "isCancelled",
                                          item?.isCancelled
                                        );
                                        localStorage.setItem(
                                          "time",
                                          item?.time
                                        );
                                      }}
                                    >
                                      View Booking Detail
                                    </Link>
                                  ) : null}
                                </div>
                              ))
                            ) : (
                              <div className="text-center">
                                No Records Found
                              </div>
                            )}
                          </div>
                          {list?.length > 0 && (
                            <div className="text-center mt-3">
                              <Paginations
                                handlePage={handlePage}
                                page={page}
                                total={total && total}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Notification;
