import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./features/Home/Home";
import Layout from "./layout/Layout";
import PrivateRoute from "./routes/PrivateRoute";
import PrivateLayout from "./layout/PrivateLayout";
import Settings from "./features/settings/Settings";
import Notification from "./features/notification/Notification";
import AboutUs from "./features/Home/AboutUs";
import PrivacyPolicy from "./features/Home/PrivacyPolicy";
import TermsConditions from "./features/Home/TermsConditions";
import CommonLayout from "./layout/CommonLayout";
import ContactUs from "./features/Home/ContactUs";
import FAQS from "./features/Home/FAQS";
import NewsEventDetail from "./features/Home/NewsEventDetail";
import VenueListing from "./features/manageVenues/VenueListing";
import VenueDetail from "./features/manageVenues/VenueDetail";
import CreateNewEvent from "./features/manageVenues/CreateNewEvent";
import FullBoking from "./features/manageVenues/FullBoking";
import Coaching from "./features/ManageCoach/Coaching";
import CoachingDetail from "./features/ManageCoach/CoachingDetail";
import BookTrialSession from "./features/ManageCoach/BookTrialSession";
import MyFavourite from "./features/myFavourite/MyFavourite";
import MyBooking from "./features/myBooking/MyBooking";
import CompleteBookingDetail from "./features/myBooking/CompleteBookingDetail";
import ReviewRating from "./features/reviewRating/ReviewRating";
import MyAccount from "./features/myAccount/MyAccount";
import BlockedUser from "./features/blockedUser/BlockedUser";
import Coupons from "./features/coupons/Coupons";
import CoachBooking from "./features/myBooking/CoachBooking";
import BookingConfirmation from "./features/manageVenues/BookingConfirmation";
import AlreadyCreatedEventDetails from "./features/manageVenues/AlreadyCreatedEventDetails";
import VenueReview from "./features/manageVenues/VenueReview";
import Dashboard from "./features/venueOwner/dashboard/Dashboard";
import ManageBooking from "./features/coaches/managebooking/ManageBooking";
import CoachReviewRating from "./features/coaches/reviewrating/CoachReviewRating";
import CoachTermsServices from "./features/coaches/termsservices/CoachTermsServices";
import CoachEditProfile from "./features/coaches/settings/CoachEditProfile";
import CoachChangePassword from "./features/coaches/settings/CoachChangePassword";
import CoachNotification from "./features/coaches/notification/CoachNotification";
import CoachMyEarning from "./features/coaches/myearning/CoachMyEarning";
import VenueReviewRating from "./features/venueOwner/reviewrating/VenueReviewRating";
import CourtsTrufs from "./features/venueOwner/courtsTrufs/CourtsTrufs";
import DocumentUpload from "./features/venueOwner/DocumentUpload";
import VenueNotification from "./features/venueOwner/notification/VenueNotification";
import VenueMyEarning from "./features/venueOwner/myearning/VenueMyEarning";
import VenueChangePassword from "./features/venueOwner/settings/VenueChangePassword";
import Booking from "./features/venueOwner/booking/Booking";
import VenueEditProfile from "./features/venueOwner/settings/VenueEditProfile";
import GeneralDetail from "./features/venueOwner/settings/GeneralDetail";
import FavouriteCoach from "./features/myFavourite/FavouriteCoach";
import CoachBook from "./features/ManageCoach/CoachBook";
import VenueAccountInfo from "./features/venueOwner/accountInfo/VenueAccountInfo";
import CoachAccountInfo from "./features/coaches/accountInfo/CoachAccountInfo";
import ChangePassword from './features/settings/ChangePassword';
import WithOutHeaderFooterLayout from "./layout/WithOutHeaderFooterLayout";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter
      basename="/"
      onLoad={() => {
        window.scrollTo(0, 0);
      }}
    >
      <Routes>
        <Route path="/venues-listing" element={<CommonLayout />}>
          <Route index element={<VenueListing />} />
          <Route path="detail/:id" element={<VenueDetail />} />
          <Route
            path="detail/:id/create-new-event"
            element={<CreateNewEvent />}
          />
          <Route path="detail/:id/full-booking" element={<FullBoking />} />
          <Route path="review/:id" element={<VenueReview />} />
        </Route>

        <Route path="/coaching" element={<CommonLayout />}>
          <Route index element={<Coaching />} />
          <Route path="detail/:id" element={<CoachingDetail />} />
          <Route
            path="detail/:id/book-trial-session"
            element={<BookTrialSession />}
          />
          <Route path="detail/:id/book-coach" element={<CoachBook />} />
        </Route>

        <Route
          path="/my-favourite"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<MyFavourite />} />
          <Route path="detail/:id" element={<VenueDetail />} />
        </Route>

        <Route
          path="/favourite-coach"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<FavouriteCoach />} />
        </Route>

        <Route
          path="/my-booking"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<MyBooking />} />
          <Route path="detail/:id" element={<CompleteBookingDetail />} />
        </Route>

        <Route
          path="/coach-booking"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<CoachBooking />} />
          {/* <Route path="detail/:id" element={<CompleteBookingDetail />} /> */}
        </Route>

        <Route
          path="/review-rating"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<ReviewRating />} />
        </Route>

        <Route
          path="/my-account"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<MyAccount />} />
        </Route>

        <Route
          path="/blocked-user"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<BlockedUser />} />
        </Route>

        <Route
          path="/edit-profile"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Settings />} />
        </Route>

        <Route
          path="/coupons"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Coupons />} />
        </Route>

        <Route path="/already-created-event-details" element={<CommonLayout />}>
          <Route path=":id" element={<AlreadyCreatedEventDetails />} />
        </Route>

        <Route
          path="/booking-confirmation"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<BookingConfirmation />} />
        </Route>

        <Route
          path="/notification"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Notification />} />
          <Route path=":id" element={<CompleteBookingDetail/>} />
        </Route>

        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<ChangePassword />} />
        </Route>

        <Route
          path="/venue"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="bookings" element={<Booking />} />
          <Route path="review-ratings" element={<VenueReviewRating />} />
          <Route path="courts-turfs" element={<CourtsTrufs />} />
          <Route path="document-upload" element={<DocumentUpload />} />
          <Route path="notification" element={<VenueNotification />} />
          <Route path="my-earning" element={<VenueMyEarning />} />
          <Route path="change-password" element={<VenueChangePassword />} />
          <Route path="edit-profile" element={<VenueEditProfile />} />
          <Route path="venue-detail" element={<GeneralDetail />} />
          <Route path="account-info" element={<VenueAccountInfo />} />
        </Route>

        {/* Coach pages */}
        <Route
          path="/coach"
          element={
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          }
        >
          <Route path="manage-booking" element={<ManageBooking />} />
          <Route path="review-rating" element={<CoachReviewRating />} />
          <Route path="terms-services" element={<CoachTermsServices />} />
          <Route path="edit-personal-info" element={<CoachEditProfile />} />
          <Route path="change-password" element={<CoachChangePassword />} />
          <Route path="notification" element={<CoachNotification />} />
          <Route path="my-earning" element={<CoachMyEarning />} />
          <Route path="account-info" element={<CoachAccountInfo />} />
        </Route>

        <Route path="/news-event" element={<CommonLayout />}>
          <Route index element={<NewsEventDetail />} />
        </Route>
        <Route path="/about-us" element={<CommonLayout />}>
          <Route index element={<AboutUs />} />
        </Route>
        <Route path="/privacy-policy" element={<CommonLayout />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path="/terms-conditions" element={<CommonLayout />}>
          <Route index element={<TermsConditions />} />
        </Route>
        <Route path="/contact-us" element={<CommonLayout />}>
          <Route index element={<ContactUs />} />
        </Route>
        <Route path="/faq" element={<CommonLayout />}>
          <Route index element={<FAQS />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>

        <Route path="/about-us-app" element={<WithOutHeaderFooterLayout />}>
          <Route index element={<AboutUs />} />
        </Route>
        <Route path="/privacy-policy-app" element={<WithOutHeaderFooterLayout />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path="/terms-conditions-app" element={<WithOutHeaderFooterLayout />}>
          <Route index element={<TermsConditions />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
