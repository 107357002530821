import React from "react";

function Loader({ colorChange }) {
  return (
    <div className="holder">
      <div className={`preloader ${colorChange}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
