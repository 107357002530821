export const mapApiKey = "AIzaSyDyzzh26cd5ROGLOGe9jTGh26yDlu3FUow";

// For Payment key
export const payment_key_id = "rzp_live_vx4AlbV61nsTjE";
//export const payment_key_id = "rzp_test_aFBjFrxxXw8DJh";


export const config = {
  bucketName: "gamehunt-data",
  region: "ap-south-1",
  accessKeyId: "AKIA57HL4RRB7X3FNXXW",
  secretAccessKey: "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",
};

export const settings = {
  dots: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  //speed: 1000,
  className: "programIMages sportSlider",
  dotsClass: "slick-dots button__bar",
  infinite: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1.9,
        arrows: false,
        infinite: false,
        dots: true,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.94,
        arrows: false,
        infinite: false,
        dots: true,
        initialSlide: 0,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        dots: true,
        initialSlide: 0,
        slidesToScroll: 1,
      },
    },
  ],
};
export const eventNews = {
  dots: true,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "EventNewsSlider sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1.94,
        arrows: false,
        infinite: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.94,
        arrows: false,
        infinite: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
      },
    },
  ],
};
export const whyChooseUs = {
  dots: false,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "ProgramSlider",
  infinite: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        infinite: false,
      },
    },
  ],
};
export const onlineSession = {
  dots: true,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "EventNewsSlider sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1.94,
        arrows: false,
        infinite: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.94,
        arrows: false,
        infinite: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
      },
    },
  ],
};
export const CustomerSaying = {
  dots: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "CustomerSlider sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        infinite: false,
      },
    },
  ],
};

export const Carouselsettings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "Carouselsettings",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export const DetailImageCarouselsettings = {
  dots: false,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "Carouselsettings  sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

export const settingsMuiltiItems = {
  dots: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  //speed: 1000,
  className: "programIMages sportSlider",
  dotsClass: "slick-dots button__bar",
  //infinite: false,
  centerMode: false,
  //variableWidth: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        //variableWidth: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        variableWidth: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
        dots: true,
        variableWidth: false,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1.8,
        arrows: false,
        infinite: true,
        dots: true,
        initialSlide: 0,
        variableWidth: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.7,
        arrows: false,
        infinite: true,
        dots: true,
        initialSlide: 0,
        variableWidth: false,
      },
    },
    {
      breakpoint: 412,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: true,
        dots: true,
        initialSlide: 0,
        variableWidth: false,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: true,
        dots: true,
        initialSlide: 0,
        variableWidth: false,
      },
    },
  ],
};
export const eventNewsMultiItem = {
  dots: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "EventNewsSlider sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1.98,
        arrows: false,
        infinite: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.7,
        arrows: false,
        infinite: true,
      },
    },
    {
      breakpoint: 412,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: true,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: true,
      },
    },
  ],
};
export const whyChooseUsMultiItem = {
  dots: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "ProgramSlider",
  infinite: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 412,
      settings: {
        slidesToShow: 1,
        infinite: true,
      },
    },
  ],
};
export const onlineSessionMultiItem = {
  dots: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "EventNewsSlider sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1.98,
        arrows: false,
        infinite: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.98,
        arrows: true,
        infinite: true,
      },
    },
    {
      breakpoint: 412,
      settings: {
        slidesToShow: 1,
        arrows: true,
        infinite: true,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        arrows: true,
        infinite: true,
      },
    },
  ],
};
export const CustomerSayingMultiItem = {
  dots: false,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "CustomerSlider sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.7,
        infinite: true,
      },
    },
    {
      breakpoint: 412,
      settings: {
        slidesToShow: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        infinite: true,
      },
    },
  ],
};



export const CreatedEventSlider = {
  dots: true,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  className: "EventNewsSlider sportSlider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1.94,
        arrows: false,
        infinite: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.94,
        arrows: false,
        infinite: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
      },
    },
  ],
};
