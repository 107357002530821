import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MyAccountReducer } from "../slice";

function MyAccount() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const list = useSelector((state) => state.main.MyAccount);
  const amount = useSelector((state) => state.main.amount);
  const rewardPoints = useSelector((state) => state.main.rewardPoints);

  const getAccountList = useCallback(async () => {
    dispatch(MyAccountReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getAccountList();
    }
    return () => {
      mounted = false;
    };
  }, [getAccountList]);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">My Account</h3>
              </div>
            </div>
            {/* <div className="bg-white p-3 rad mb-3">
              <div className="row align-items-center justify-content-lg-center">
                <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                  <p className="fw-bold mb-1">My Balance</p>
                  <h3 className="theme-color mb-0">
                    <i className="fa fa-inr"></i> {amount || "0"}
                  </h3>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2 mb-3">
                  <p className="fw-bold mb-1">Valid Thru</p>
                  <p className="mb-0">08/21</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2 mb-3">
                  <p className="fw-bold mb-1">Card Holder</p>
                  <p className="mb-0">Thomas Kors</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2 mb-3">
                  <p className="mb-0">**** **** **** 1425</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2 mb-3 text-center">
                  <img
                    src="assets/img/cardImg.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8 mb-3">
                <div className="bg-white p-3 rad mb-3">
                  <h4>Transaction History</h4>
                  <div className="table-responsive">
                    <table className="table borderless">
                      <thead>
                        <tr>
                          <th>Date & time</th>
                          <th>Type</th>
                          <th>Deposit Wallet</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.transations > 0 ? (
                          list?.transations?.map((item, i) => (
                            <tr key={i}>
                              <td>
                                12-11-2022
                                <br />
                                9:00am
                              </td>
                              <td>Deposit</td>
                              <td>Spot Wallet</td>
                              <td>
                                <i className="fa fa-inr"></i> 5000.00
                              </td>
                              <td>
                                <span className="theme-color">Completed</span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} align="center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
                <div className="bg-white p-3 rad mb-3">
                  <h4 className="mb-4">Rewards Points</h4>
                  <div className="RewardsPoints">
                    <div className="">
                      <p className="fs-4">Current Balance</p>
                      <span className="d-flex align-items-baseline gap-2">
                        <h2 className="theme-color fw-bold">
                          {rewardPoints || "0"}
                        </h2>{" "}
                        <sub>Rewards Points</sub>
                      </span>
                    </div>
                    <img
                      src="assets/img/medal_silver.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MyAccount;
