import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import "antd/dist/antd.css";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import TimePicker from "react-time-picker";
import moment from "moment";
import Loader from "../../../Components/Loader";
import { CourtsAndTrufEditReducer } from "../../CoachVenueslice";
import { toast } from "react-toastify";

const weekdays = [
  {
    weekday: "1",
    startTime: "",
    endTime: "",
    checked: false,
  },
  {
    weekday: "2",
    startTime: "",
    endTime: "",
    checked: false,
  },
  {
    weekday: "3",
    startTime: "",
    endTime: "",
    checked: false,
  },
  {
    weekday: "4",
    startTime: "",
    endTime: "",
    checked: false,
  },
  {
    weekday: "5",
    startTime: "",
    endTime: "",
    checked: false,
  },
  {
    weekday: "6",
    startTime: "",
    endTime: "",
    checked: false,
  },
  {
    weekday: "0",
    startTime: "",
    endTime: "",
    checked: false,
  },
];

function EditSlot({
  onClose,
  visible,
  getlist,
  setCopyModalName,
  handleShow,
  copyData,
  setCopyData,
  initialData,
  id,
}) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const initialValues = {
    weekdays:
      initialData?.days?.length > 0
        ? weekdays?.map((item) => {
            let arr = [...initialData?.days];
            let isfound = arr.find(
              (it) => it.weekday?.toString() === item?.weekday?.toString()
            );
            //console.log(isfound);
            if (isfound) {
              return {
                weekday: isfound?.weekday,
                startTime:
                  isfound?.startTime === "Invalid date"
                    ? ""
                    : isfound?.startTime,
                endTime:
                  isfound?.endTime === "Invalid date" ? "" : isfound?.endTime,
                checked: isfound?.checked,
              };
            }
            return item;
          })
        : weekdays,

    costPerSlot: initialData?.costPerSlot,
    copySlotData: false,
    description: initialData.description,
    maxCapacity: initialData.maxCapacity,
    sports: initialData.sports,
    courtTrufNumber: initialData.courtTrufNumber,
    minCapacity: initialData.minCapacity,
    rulesandregulations: initialData.rulesandregulations,
    imageName: initialData.imageName,
    imageName1: initialData.imageName1,
    imageName2: initialData.imageName2,
  };

  // console.log(initialData?.days);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      costPerSlot: Yup.string().min(3, "Min Cost Rs.100").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const arrWeekdays = values.weekdays
        ?.map((item) => {
          if (item?.checked) {
            return {
              weekday: item?.weekday,
              startTime: moment(item?.startTime, "hh:mm:ss").format("HH:mm"),
              endTime: moment(item?.endTime, "hh:mm:ss").format("HH:mm"),
              checked: item?.checked,
            };
          }
          return false;
        })
        ?.filter((it) => !it === false);

      setLoading(true);
      const payload = {
        courtandtrufId: id,
        days: JSON.stringify(arrWeekdays),
        costPerSlot: values.costPerSlot,
        description: values.description,
        maxCapacity: values.maxCapacity,
        sport: values.sports,
        name: values.courtTrufNumber,
        minCapacity: values.minCapacity,
        rules: values.rulesandregulations,
        image: JSON.stringify([
          {
            image: values.imageName,
          },
          {
            image: values.imageName1,
          },
          {
            image: values.imageName2,
          },
        ]),
      };
      // console.log(payload);
      dispatch(CourtsAndTrufEditReducer(payload)).then((data) => {
        setLoading(false);
        if (data?.payload?.code === 1) {
          toast.success(data.payload.message);
          onClose();
          getlist();
        } else {
          setLoading(false);
          toast.error(data.payload.message);
        }
      });
    },
  });
  return (
    <>
      <Drawer placement="right" onClose={onClose} visible={visible}>
        <div className="">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3 fw-bold">Edit Court/Turfs</h2>
          </div>
          <form className="form-style formCavas" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 mb-4">
                <div className="form-group">
                  <h6 className="fw-bold">Week Days</h6>
                  <div className="table-responsive">
                    <table className="table storetable">
                      <tbody className="bg-transparent">
                        <tr>
                          <th></th>
                          <th>from time</th>
                          <th>to time</th>
                        </tr>
                        <FormikProvider value={formik}>
                          <FieldArray name="weekdays" />
                        </FormikProvider>
                        {formik.values?.weekdays?.map((week, i) => (
                          <tr key={i}>
                            <td>
                              <input
                                className="styled-checkbox"
                                id={`other-${week?.weekday}`}
                                type="checkbox"
                                checked={week?.checked}
                                name={`weekdays[${i}].checked`}
                                onChange={(e) => {
                                  let checked = e.target.checked;

                                  formik.setFieldValue(
                                    `weekdays[${i}].checked`,
                                    checked
                                  );
                                }}
                              />
                              <label htmlFor={`other-${week?.weekday}`}>
                                {week?.weekday?.toString() === "1"
                                  ? "monday"
                                  : week?.weekday?.toString() === "2"
                                  ? "tuesday"
                                  : week?.weekday?.toString() === "3"
                                  ? "wednesday"
                                  : week?.weekday?.toString() === "4"
                                  ? "thursday"
                                  : week?.weekday?.toString() === "5"
                                  ? "friday"
                                  : week?.weekday?.toString() === "6"
                                  ? "saturday"
                                  : week?.weekday?.toString() === "0"
                                  ? "sunday"
                                  : null}
                              </label>
                            </td>
                            <td>
                              <TimePicker
                                onChange={(val) => {
                                  formik.setFieldValue(
                                    `weekdays[${i}].startTime`,
                                    val
                                  );
                                }}
                                clockIcon={null}
                                disableClock={true}
                                clearIcon={null}
                                minutePlaceholder="MM"
                                hourPlaceholder="HH"
                                disabled={!week?.checked}
                                value={week?.startTime || undefined}
                                className={"form-control"}
                                name={`weekdays[${i}].startTime`}
                                format="hh:mm a"
                              />
                            </td>
                            <td>
                              <TimePicker
                                onChange={(val) => {
                                  formik.setFieldValue(
                                    `weekdays[${i}].endTime`,
                                    val
                                  );
                                }}
                                clockIcon={null}
                                disableClock={true}
                                clearIcon={null}
                                minutePlaceholder="MM"
                                hourPlaceholder="HH"
                                disabled={!week?.checked}
                                value={week?.endTime || undefined}
                                className={"form-control"}
                                name={`weekdays[${i}].endTime`}
                                format="hh:mm a"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {formik.errors.weekdays && formik.touched.weekdays && (
                  <div className="text-red"> {formik.errors.weekdays}</div>
                )}
              </div>

              <div className="col-sm-12 col-md-12 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      type="number"
                      className="form-control ps-5"
                      placeholder="Enter cost per slot"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.costPerSlot}
                      name="costPerSlot"
                    />
                    <img
                      src="assets/img/costPerSlot.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.costPerSlot && formik.touched.costPerSlot && (
                  <div className="text-red"> {formik.errors.costPerSlot}</div>
                )}
              </div>
              {/* <div className="col-sm-12 col-md-12 mb-4 position-relative text-end">
                <h6
                  className="theme-color cursor-pointer d-inline-block"
                  onClick={() => {
                    setCopyModalName("copySlot");
                    handleShow();
                  }}
                >
                  + Add Slot 2
                </h6>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="input-container">
                  <div className="">
                    <input
                      className="styled-checkbox"
                      id="Iaccept"
                      type="checkbox"
                      name="copySlotData"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur("radio")}
                      checked={formik.values.copySlotData === true}
                    />
                    <label htmlFor="Iaccept" className="w-100">
                      Can we apply the same details to other court/turfs.
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="col-sm-12 col-md-12 text-center">
                <button type="submit" className="btn btn-submit w-50">
                  {loading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
}

export default EditSlot;
