import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Paginations from "../../../Components/Paginations";
import { CoacheRatingReviewReducer } from "../../CoachVenueslice";

function CoachReviewRating() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [reviewRating, setReviewRating] = useState("received");
  const handlePage = (value) => {
    setPage(value);
  };
  const list = useSelector((state) => state.CoachVenueMain.CoachReviewRating);
  const total = useSelector(
    (state) => state.CoachVenueMain.total_CoachReviewRating
  );

  const totalRaview = useSelector(
    (state) => state.CoachVenueMain.coachtotalReview
  );
  const totalRating = useSelector(
    (state) => state.CoachVenueMain.CoachtotalRating
  );

  const getdetail = useCallback(() => {
    setLoading(true);
    dispatch(
      CoacheRatingReviewReducer({
        isReceived: status,
        page: page,
        limit: 10,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, status, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getdetail();
    }
    return () => {
      mounted = false;
    };
  }, [getdetail]);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">Review & Rating</h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-8 col-md-8 col-lg-10 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-start bookingTabs tabsnew gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        reviewRating === "received"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setStatus(true);
                        setReviewRating("received");
                      }}
                    >
                      received
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        reviewRating === "given"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setStatus("");
                        setReviewRating("given");
                      }}
                    >
                      given
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2 mb-3 text-end">
                <div className="theme-color text-center">
                  <h3>
                    {totalRating ? parseFloat(totalRating).toFixed(1) : "0"}
                  </h3>
                  <div className="ratingIcon mb-1">
                    {totalRating > 0.4 && totalRating < 0.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 0.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 1.4 && totalRating < 1.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 1.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 2.4 && totalRating < 2.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 2.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 3.4 && totalRating < 3.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 3.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {totalRating > 4.4 && totalRating < 4.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : totalRating > 4.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                  </div>
                  <p>
                    {totalRaview} ({totalRaview <= 1 ? "review" : "reviews"})
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                {loading ? (
                  <Loader colorChange="colorChange" />
                ) : (
                  <div className="tab-content mt-4" id="pills-tabContent">
                    {reviewRating === "received" ? (
                      <div>
                        <div className="w-100">
                          <div className="">
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <div
                                  className="position-relative botmLine"
                                  key={i}
                                >
                                  <div className="d-md-flex justify-content-between gap-3">
                                    <span>
                                      <p className="text-muted text-capitalize">
                                        <img
                                          src={
                                            item?.normaluser?.picture?.includes(
                                              "http"
                                            )
                                              ? item?.normaluser?.picture
                                              : "assets/img/employe.png"
                                          }
                                          className="coachImgCenetr"
                                          alt=""
                                        />{" "}
                                        {item?.normaluser?.firstName
                                          ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                          : "NA"}
                                        , {moment(item?.time).format("ll")}
                                      </p>
                                    </span>
                                    <small>
                                      {item?.rating === 1 ? (
                                        <img
                                          src="assets/img/star_full.png"
                                          alt=""
                                        />
                                      ) : item?.rating === 2 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 3 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 4 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.rating === 5 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : null}
                                    </small>
                                  </div>
                                  <h6>{item?.userFeedback || "NA"}</h6>
                                </div>
                              ))
                            ) : (
                              <div className="text-center">
                                No Records Found
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : reviewRating === "given" ? (
                      <div>
                        <div className=" w-100">
                          <div className="">
                            {list?.length > 0 ? (
                              list?.map((item, i) => (
                                <div
                                  className="position-relative botmLine"
                                  key={i}
                                >
                                  <div className="d-md-flex justify-content-between gap-3">
                                    <span>
                                      <p className="text-muted text-capitalize">
                                        <img
                                          src={
                                            item?.normaluser?.picture?.includes(
                                              "http"
                                            )
                                              ? item?.normaluser?.picture
                                              : "assets/img/employe.png"
                                          }
                                          className="coachImgCenetr"
                                          alt=""
                                        />{" "}
                                        {item?.normaluser?.firstName
                                          ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                          : "NA"}
                                        , {moment(item?.time).format("ll")}
                                      </p>
                                    </span>
                                    <small>
                                      {item?.coachRating === 1 ? (
                                        <img
                                          src="assets/img/star_full.png"
                                          alt=""
                                        />
                                      ) : item?.coachRating === 2 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.coachRating === 3 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.coachRating === 4 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : item?.coachRating === 5 ? (
                                        <>
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                          <img
                                            src="assets/img/star_full.png"
                                            alt=""
                                          />
                                        </>
                                      ) : null}
                                    </small>
                                  </div>
                                  <h6>{item?.coachFeedback || "sdfaew"}</h6>
                                </div>
                              ))
                            ) : (
                              <div className="text-center">
                                No Records Found
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CoachReviewRating;
