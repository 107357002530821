import { configureStore } from "@reduxjs/toolkit";
import CoachVenueMainSlice from "../features/CoachVenueslice";
import MainSlice from "../features/slice";

export const store = configureStore({
  reducer: {
    main: MainSlice,
    CoachVenueMain: CoachVenueMainSlice,
  },
});
