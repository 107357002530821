import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import {
  ChangeouterLogin,
  OTPVerificationReducer,
  ResendOTPReducer,
} from "../../features/slice";

function OTPVerification({
  show,
  handleShow,
  setModalName,
  outerLogin,
  urlId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryCode = localStorage.getItem("countryCode");
  const mobile = localStorage.getItem("mobile");
  const OTPVerify = localStorage.getItem("OTPVerify");
  const [loading, setLoading] = useState(false);
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const EmailText = localStorage.getItem("EmailText");

  // const outerLogin = useSelector((state) => state.main.ChangeouterLogin);

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };

    // if (seconds > 0) {
    //   setTimeout(() => {
    //     setSeconds(seconds - 1);
    //   }, 1000);
    // }
  }, [seconds, minutes]);

  const resendCode = () => {
    dispatch(
      ResendOTPReducer({
        otp_for:
          OTPVerify === "LoginOTPVerify"
            ? "login"
            : OTPVerify === "SignUpOTPVerify"
            ? "signup_via_mobile"
            : OTPVerify === "SignUpOTPVerifyEmail"
            ? "signup_via_email"
            : "forgot_password",
        countryCode: countryCode ? countryCode : null,
        mobile: mobile ? mobile : null,
        email: email ? email : null,
        user_type: "normaluser",
      })
    ).then((data) => {
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="xl">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div className="col-sm-12 col-md-12 col-lg-6 bgloginImgUser">
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-lg-5">
                    <div className="form-style mt-lg-5 px-4">
                      <Formik
                        initialValues={{ otp: "" }}
                        validationSchema={Yup.object({
                          otp: Yup.string()
                            .required("Required")
                            .matches(/^[0-9]+$/, "Must be only digits")
                            .min(6, "Kindly enter valid otp.")
                            .max(6, "Kindly enter valid otp."),
                        })}
                        onSubmit={(values, { isSubmitting, resetForm }) => {
                          setLoading(true);
                          dispatch(
                            OTPVerificationReducer({
                              code: values.otp,
                              countryCode: countryCode ? countryCode : null,
                              mobile: mobile ? mobile : null,
                              user_type: "normaluser",
                              otp_for:
                                OTPVerify === "LoginOTPVerify"
                                  ? "login"
                                  : OTPVerify === "SignUpOTPVerify"
                                  ? "signup_via_mobile"
                                  : OTPVerify === "SignUpOTPVerifyEmail"
                                  ? "signup_via_email"
                                  : "forgot_password",
                              email: email ? email : null,
                              password: password ? password : null,
                            })
                          ).then(async (data) => {
                            //console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 1) {
                              toast.success(data?.payload?.message);
                              await localStorage.setItem("loginType", "user");
                              if (OTPVerify === "LoginOTPVerify") {
                                await localStorage.removeItem("mobile");
                                await localStorage.removeItem("countryCode");
                                await localStorage.removeItem("OTPVerify");
                                await localStorage.removeItem("email");
                                await localStorage.removeItem("password");
                                await localStorage.removeItem("EmailText");
                                if (outerLogin) {
                                  handleShow();
                                  dispatch(ChangeouterLogin(false));
                                  navigate(urlId, {
                                    replace: true,
                                  });
                                } else {
                                  if (
                                    data?.payload?.data?.user?.profileSetup ===
                                    0
                                  ) {
                                    navigate("/edit-profile", {
                                      replace: true,
                                    });
                                    handleShow();
                                  } else {
                                    navigate("/", {
                                      replace: true,
                                    });
                                    handleShow();
                                  }
                                }
                              } else if (OTPVerify === "SignUpOTPVerify") {
                                await localStorage.removeItem("mobile");
                                await localStorage.removeItem("countryCode");
                                await localStorage.removeItem("OTPVerify");
                                await localStorage.removeItem("EmailText");
                                await localStorage.setItem(
                                  "token",
                                  data?.payload?.data?.token
                                );
                                await localStorage.setItem("loginType", "user");
                                navigate("/edit-profile", {
                                  replace: true,
                                });
                                handleShow();
                              } else if (OTPVerify === "SignUpOTPVerifyEmail") {
                                await localStorage.removeItem("mobile");
                                await localStorage.removeItem("countryCode");
                                await localStorage.removeItem("OTPVerify");
                                await localStorage.removeItem("email");
                                await localStorage.removeItem("password");
                                await localStorage.removeItem("EmailText");
                                await localStorage.setItem(
                                  "token",
                                  data?.payload?.data?.token
                                );
                                await localStorage.setItem("loginType", "user");
                                navigate("/edit-profile", {
                                  replace: true,
                                });
                                handleShow();
                              } else {
                                await localStorage.setItem(
                                  "token",
                                  data?.payload?.data?.token
                                );

                                setModalName("ResetPassword");
                              }
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <form className="row" onSubmit={handleSubmit}>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                              <h2 className="mdl-ttl">OTP Verification</h2>
                              <p
                                className={
                                  EmailText === "EmailText" ? "" : "d-none"
                                }
                              >
                                Please enter 6 digit code which has been send on
                                your registered Email id: {email}
                              </p>
                              <p
                                className={
                                  EmailText === "EmailText" ? "d-none" : ""
                                }
                              >
                                Please enter 6 digit code which has been send on
                                your registered{" "}
                                {OTPVerify === "LoginOTPVerify" ? (
                                  <>
                                    Mobile Number: <b>{mobile}</b>
                                  </>
                                ) : OTPVerify === "SignUpOTPVerify" ? (
                                  <>
                                    Mobile Number: <b>{mobile}</b>
                                  </>
                                ) : OTPVerify === "ForgotOTPVerify" ? (
                                  <>
                                    email id: <b>{email}</b>
                                  </>
                                ) : EmailText === "EmailText" ? (
                                  <>
                                    email id: <b>{email}</b>
                                  </>
                                ) : (
                                  <>
                                    email id: <b>{email}</b>
                                  </>
                                )}
                              </p>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                              <div className="">
                                <img
                                  src="assets/img/OTPVery.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <OtpInput
                                isInputNum={true}
                                containerStyle="passcode-wrapper"
                                value={values.otp}
                                onChange={(val) => {
                                  setFieldValue("otp", val);
                                }}
                                inputStyle={"otpInputs"}
                                numInputs={6}
                                separator={<span> </span>}
                                keyboardType={"numeric"}
                                shouldAutoFocus={true}
                              />
                              {errors.otp && touched.otp && (
                                <div className="text-red">{errors.otp}</div>
                              )}
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-3">
                              <p className="otp-timing">
                                {" "}
                                <span>
                                  {minutes === 0 && seconds === 0 ? null : (
                                    <span>
                                      {" "}
                                      0{minutes}:
                                      {seconds < 10 ? `0${seconds}` : seconds}
                                    </span>
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center">
                              <button type="submit" className="btn btn-submit">
                                {loading ? <Loader /> : "Verify"}
                              </button>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                              <span
                                className={
                                  seconds === 0
                                    ? "forgot-pass cursor-pointer p-0"
                                    : "forgot-pass d-none p-0"
                                }
                                onClick={() => {
                                  resendCode();
                                  setMinutes(2);
                                  setSeconds(0);
                                }}
                              >
                                Resend Code
                              </span>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OTPVerification;
