import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { config, mapApiKey } from "../../../Utils";
import GooglePlace from "../../../Components/GooglePlace";
import {
  VenueOwnerDetailReducer,
  VenueOwnerEditProfileReducer,
} from "../../CoachVenueslice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Geocode from "react-geocode";
import Loader from "./../../../Components/Loader";
import Autocomplete from "react-google-autocomplete";
import { useRef } from "react";
window.Buffer = Buffer;

function VenueEditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countryData, setCountryData] = useState();
  const [loading, setLoading] = useState(false);

  const [country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [address, setaddress] = useState("");
  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");
  const [ownerName, setownerName] = useState("");
  const [venueName, setvenueName] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [street, setstreet] = useState("");
  const [VenueImageArr, setVenueImageArr] = useState([]);
  const [amenitiesArr, setamenitiesArr] = useState([]);
  const [ruls, setruls] = useState("");

  const autocompleteRef = useRef();
  const loginDetail = useSelector(
    (state) => state.CoachVenueMain.VenueOwnerDetail
  );

  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const initialValues = {
    ownerName: ownerName ? ownerName : "",
    venueName: venueName ? venueName : "",
    doorNo: doorNo ? doorNo : "",
    street: street ? street : "",
    city: city ? city : "",
    state: state ? state : "",
    country: country ? country : "",
    pincode: pincode ? pincode : "",
    address: address,
    latitude: lat,
    longitude: long,
    coaches: loginDetail?.venue?.haveCoach === true ? "yes" : "no",
    externalCoaches: loginDetail?.venue?.wantCoach === true ? "yes" : "no",
    rulesandregulations: ruls ? ruls : "",
    acceptTermsCondition: loginDetail?.profileSetup === 1 ? true : false,
    index: "",
    amenities: amenitiesArr?.length > 0 ? amenitiesArr : [],
    VenueImageArr:
      VenueImageArr?.length > 0
        ? VenueImageArr
        : [
            {
              image: "",
            },
          ],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      venueName: Yup.string().typeError("Required").required("Required"),
      ownerName: Yup.string().typeError("Required").required("Required"),
      //doorNo: Yup.string().typeError("Required").required("Required"),
      //street: Yup.string().typeError("Required").required("Required"),
      city: Yup.string().typeError("Required").required("Required"),
      state: Yup.string().typeError("Required").required("Required"),
      country: Yup.string().typeError("Required").required("Required"),
      pincode: Yup.string().typeError("Required").required("Required"),
      address: Yup.string().typeError("Required").required("Required"),
      coaches: Yup.string().typeError("Required").required("Required"),
      externalCoaches: Yup.string().required("Required"),
      // amenitesName: Yup.string().required("Required"),
      // amenitesIcon: Yup.string().required("Required"),
      rulesandregulations: Yup.string()
        .typeError("Required")
        .required("Required"),
      acceptTermsCondition: Yup.boolean().oneOf([true], "Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      //console.log(values);
      setLoading(true);

      const payload = {
        venueName: values.venueName,
        venueOwnerName: values.ownerName,
        doorNo: values.doorNo,
        street: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        zipCode: values.pincode,
        haveCoach: values.coaches,
        wantCoach: values.externalCoaches,
        amenities: JSON.stringify(values.amenities),
        rules: values.rulesandregulations,
        image: JSON.stringify(values.VenueImageArr),
        longitude: values.longitude,
        latitude: values.latitude,
        address: values.address,
      };

      if (values.latitude === undefined || values.latitude === "") {
        toast.error("Please Check Venue Current Location");
      } else {
        dispatch(VenueOwnerEditProfileReducer(payload)).then((data) => {
          setLoading(false);
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            navigate("/venue/venue-detail", {
              replace: true,
            });
          } else {
            setLoading(false);
            toast.error(data.payload.message);
          }
        });
      }
    },
  });

  const getVenueDetail = useCallback(async () => {
    dispatch(VenueOwnerDetailReducer({})).then((data) => {
      if (data?.payload?.code === 1) {
        setownerName(data?.payload?.data?.venueowner?.name);
        setvenueName(data?.payload?.data?.venueowner?.venue?.name);
        setstreet(data?.payload?.data?.venueowner?.venue?.street);
        setdoorNo(data?.payload?.data?.venueowner?.venue?.doorNo);
        setVenueImageArr(data?.payload?.data?.venueowner?.venue?.image);

        setCountry(data?.payload?.data?.venueowner?.venue?.country);
        setpincode(data?.payload?.data?.venueowner?.venue?.zipCode);
        setstate(data?.payload?.data?.venueowner?.venue?.state);
        setcity(data?.payload?.data?.venueowner?.venue?.city);
        setaddress(data?.payload?.data?.venueowner?.venue?.address);
        setlat(data?.payload?.data?.venueowner?.venue?.latitude);
        setlong(data?.payload?.data?.venueowner?.venue?.longitude);
        setamenitiesArr(data?.payload?.data?.venueowner?.venue?.amenities);
        setruls(data?.payload?.data?.venueowner?.venue?.rules);
      } else {
        setCountry("");
        setpincode("");
        setstate("");
        setcity("");
        setaddress("");
        setlat("");
        setlong("");
      }
    });
  }, [dispatch]);
  useEffect(() => {
    getVenueDetail();
  }, [getVenueDetail]);

  if (lat) {
    Geocode.setApiKey(mapApiKey);
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        const address = response.results[0];
        //console.log(address);
        let city, state, country, postal_code;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postal_code =
                  response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        //console.log(city, state, country, postal_code);
        setCountry(country);
        setpincode(postal_code);
        setstate(state);
        setcity(city);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="form-style mt-5">
                  <form className="row" onSubmit={formik.handleSubmit}>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                      <h2 className="mdl-ttl">Edit Personal info</h2>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                      <label className="mb-2">Venue name</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="Venue name"
                          name="venueName"
                          value={formik.values.venueName}
                          onChange={(e) => {
                            setvenueName(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/venueName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.venueName && formik.touched.venueName && (
                        <div className="text-red">
                          {" "}
                          {formik.errors.venueName}
                        </div>
                      )}
                    </div>
                    <label className="mb-2">Venue Photo</label>
                    {formik.values?.VenueImageArr?.map((item, i) => {
                      return (
                        <div
                          className="form-group col-sm-12 col-md-6 col-lg-4 mb-2 position-relative"
                          key={i}
                        >
                          <div className="upload-btn-wrapper imageUpoad veneEdirImas p-0 d-block rounded-0">
                            <span
                              className={
                                item?.image?.includes("http")
                                  ? "btn p-0"
                                  : "btn"
                              }
                            >
                              <img
                                src={
                                  item?.image?.includes("http")
                                    ? item?.image
                                    : "assets/img/uploadImg.png"
                                }
                                alt=""
                                className={
                                  item?.image?.includes("http")
                                    ? "EditImages"
                                    : "uploadImageicon"
                                }
                              />

                              <img
                                src="assets/img/CloseGrey.png"
                                alt=""
                                className="CloseGrey"
                                onClick={() => {
                                  let arr = [...VenueImageArr];
                                  arr.splice(i, 1);
                                  setVenueImageArr(arr);
                                  // formik.setFormikState((prev) => {
                                  //   return {
                                  //     ...prev,
                                  //     values: {
                                  //       ...prev.values,
                                  //       VenueImageArr: arr,
                                  //     },
                                  //   };
                                  // });
                                }}
                              />
                            </span>
                            <input
                              type="file"
                              accept="image/*"
                              name={`VenueImageArr[i].image`}
                              onChange={(event) => {
                                setIsActive(true);
                                formik.setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      index: i,
                                    },
                                  };
                                });

                                S3FileUpload.uploadFile(
                                  event.target.files[0],
                                  config
                                )
                                  .then((data) => {
                                    let arr = [...formik.values.VenueImageArr];
                                    arr[i] = {
                                      image: data.location,
                                    };
                                    // formik.setFormikState((prevState) => {
                                    //   return {
                                    //     ...prevState,
                                    //     values: {
                                    //       ...prevState.values,
                                    //       VenueImageArr: arr,
                                    //     },
                                    //   };
                                    // });
                                    setVenueImageArr(arr);
                                    setTime(100);
                                    setIsActive(true);
                                    setTimeout(() => {
                                      setTime(0);
                                      setIsActive(false);
                                      formik.setFormikState((PreState) => {
                                        return {
                                          ...PreState,
                                          values: {
                                            ...PreState.values,
                                            index: "",
                                          },
                                        };
                                      });
                                    }, 3000);
                                  })
                                  .catch((err) => console.error(err));
                              }}
                            />
                          </div>
                          {time > 0 &&
                            isActive &&
                            formik.values.index === i && (
                              <Progress
                                type="line"
                                width={100}
                                percent={time}
                              />
                            )}
                          {formik.errors.image && formik.touched.image && (
                            <div className="text-red">
                              {" "}
                              {formik.errors.image}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-end">
                      <div
                        className="position-relative pw-3 AddtoList cursor-pointer"
                        onClick={() => {
                          //setTimeSlotIndex(timeSlotIndex + 1);
                          let arr = [...VenueImageArr];
                          arr.push({
                            image: "",
                          });
                          setVenueImageArr(arr);
                          // formik.setFormikState((prev) => {
                          //   console.log(prev);
                          //   return {
                          //     ...prev,
                          //     values: {
                          //       ...prev.values,
                          //       VenueImageArr: arr,
                          //     },
                          //   };
                          // });
                          //console.log(arr);
                        }}
                      >
                        <b>+ Add More</b>
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                      <label className="mb-2">Venue Owner</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="Owner name"
                          name="ownerName"
                          value={formik.values.ownerName}
                          onChange={(e) => {
                            setownerName(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.ownerName && formik.touched.ownerName && (
                        <div className="text-red">
                          {" "}
                          {formik.errors.ownerName}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                      <h5>Contact Address</h5>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="mb-2">Door No.</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="Door No"
                          name="doorNo"
                          value={formik.values.doorNo}
                          onChange={(e) => {
                            setdoorNo(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/door.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.doorNo && formik.touched.doorNo && (
                        <div className="text-red"> {formik.errors.doorNo}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="mb-2">Street</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="Street"
                          name="street"
                          value={formik.values.street}
                          onChange={(e) => {
                            setstreet(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/street.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.street && formik.touched.street && (
                        <div className="text-red"> {formik.errors.street}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label className="mb-2">City</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="City"
                          name="city"
                          value={formik.values.city}
                          onChange={(e) => {
                            setcity(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/city.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.city && formik.touched.city && (
                        <div className="text-red"> {formik.errors.city}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label className="mb-2">State</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="State"
                          name="state"
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.state && formik.touched.state && (
                        <div className="text-red"> {formik.errors.state}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="mb-2">Country</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="Country"
                          name="country"
                          value={formik.values.country}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/Country.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.country && formik.touched.country && (
                        <div className="text-red"> {formik.errors.country}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="mb-2">Pincode</label>
                      <div className="input-container">
                        <input
                          type="number"
                          className="form-control ps-5"
                          placeholder="Pincode"
                          name="pincode"
                          value={formik.values.pincode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/Bar-code.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.pincode && formik.touched.pincode && (
                        <div className="text-red"> {formik.errors.pincode}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label className="mb-2">Venue Current Location</label>
                      <div className="input-container profile-loca">
                        {/* <GooglePlace
                          name="address"
                          value={address}
                          latLong={{
                            lat: lat,
                            lng: long,
                          }}
                          onBlur={formik.handleBlur}
                          onChange={(val, latLong) => {
                            //console.log(val, latLong);
                            // console.log(formik.values.address);
                            if (val) {
                              setaddress(val);
                              setlat(latLong.lat);
                              setlong(latLong.lng);
                            } else {
                              setaddress("");
                              setlat("");
                              setlong("");
                            }
                          }}
                        /> */}
                        <Autocomplete
                          apiKey={mapApiKey}
                          name="address"
                          value={address}
                          ref={autocompleteRef}
                          className="form-control ps-5"
                          placeholder="Current Location"
                          onBlur={formik.handleBlur}
                          onChange={() => {
                            // console.log(autocompleteRef);
                            setaddress(autocompleteRef.current.value);
                            setlat("");
                            setlong("");
                          }}
                          onPlaceSelected={(
                            place,
                            inputRef,
                            autocompleteRef
                          ) => {
                            // console.log(place, inputRef, autocompleteRef);
                            if (place) {
                              let lat = place.geometry.location.lat();
                              let lng = place.geometry.location.lng();
                              let address = place.formatted_address;

                              setaddress(address);
                              setlat(lat);
                              setlong(lng);
                            } else {
                              setaddress("");
                              setlat("");
                              setlong("");
                            }
                          }}
                          options={{
                            types: ["(regions)"],
                            componentRestrictions: { country: "in" },
                          }}
                        />
                        <img
                          src="assets/img/location.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.address && formik.touched.address && (
                        <div className="text-red"> {formik.errors.address}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="position-relative">
                        <label>You have coaches in your venue?</label>
                        <div className="custom-radio ps-0 d-flex align-items-center gap-3">
                          <div className="radio-item">
                            <input
                              type="radio"
                              value="yes"
                              name="coaches"
                              id="yes"
                              onChange={formik.handleChange("coaches")}
                              onBlur={formik.handleBlur("radio")}
                              checked={formik.values.coaches === "yes"}
                            />
                            <label className="label-icon gender" htmlFor="yes">
                              <div className="genderRadioIcon"></div>
                              <p>yes</p>
                            </label>
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              value="no"
                              name="coaches"
                              id="no"
                              onChange={formik.handleChange("coaches")}
                              onBlur={formik.handleBlur("radio")}
                              checked={formik.values.coaches === "no"}
                            />
                            <label className="label-icon gender" htmlFor="no">
                              <div className="genderRadioIcon"></div>
                              <p>No</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      {formik.errors.coaches && formik.touched.coaches && (
                        <div className="text-red position-static">
                          {" "}
                          {formik.errors.coaches}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="position-relative">
                        <label>
                          Will you allow external coach to come and coach in
                          your venue?
                        </label>
                        <div className="custom-radio ps-0 d-flex align-items-center gap-3">
                          <div className="radio-item">
                            <input
                              type="radio"
                              value="yes"
                              name="externalCoaches"
                              id="externalyes"
                              onChange={formik.handleChange("externalCoaches")}
                              onBlur={formik.handleBlur("radio")}
                              checked={formik.values.externalCoaches === "yes"}
                            />
                            <label
                              className="label-icon gender"
                              htmlFor="externalyes"
                            >
                              <div className="genderRadioIcon"></div>
                              <p>yes</p>
                            </label>
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              value="no"
                              name="externalCoaches"
                              id="externalno"
                              onChange={formik.handleChange("externalCoaches")}
                              onBlur={formik.handleBlur("radio")}
                              checked={formik.values.externalCoaches === "no"}
                            />
                            <label
                              className="label-icon gender"
                              htmlFor="externalno"
                            >
                              <div className="genderRadioIcon"></div>
                              <p>No</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      {formik.errors.externalCoaches &&
                        formik.touched.externalCoaches && (
                          <div className="text-red position-static">
                            {" "}
                            {formik.errors.externalCoaches}
                          </div>
                        )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                      <h5>Amenities</h5>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <ul className="slotTimeList custom-radio ps-0 sprortCheckImg">
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="cctv"
                              name="amenities"
                              id={`amenities-cctv`}
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...amenitiesArr];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                // formik.setFormikState((prev) => {
                                //   return {
                                //     ...prev,
                                //     values: {
                                //       ...prev.values,
                                //       amenities: [...arr],
                                //     },
                                //   };
                                // });
                                setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                amenitiesArr?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "cctv"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor={`amenities-cctv`}
                            >
                              <div>
                                <img
                                  src="assets/img/cctv.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              CCTV
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="parking"
                              name="amenities"
                              id="amenities-parking"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...amenitiesArr];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                // formik.setFormikState((prev) => {
                                //   return {
                                //     ...prev,
                                //     values: {
                                //       ...prev.values,
                                //       amenities: [...arr],
                                //     },
                                //   };
                                // });
                                setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                amenitiesArr?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "parking"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-parking"
                            >
                              <div>
                                <img
                                  src="assets/img/parking.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Parking
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="air-conditioned"
                              name="amenities"
                              id="amenities-air-conditioned"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...amenitiesArr];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                // formik.setFormikState((prev) => {
                                //   return {
                                //     ...prev,
                                //     values: {
                                //       ...prev.values,
                                //       amenities: [...arr],
                                //     },
                                //   };
                                // });
                                setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                amenitiesArr?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    "air-conditioned"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-air-conditioned"
                            >
                              <div>
                                <img
                                  src="assets/img/air_conditioning.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Air-Conditioned
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="changing rooms"
                              name="amenities"
                              id="amenities-changing rooms"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...amenitiesArr];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                // formik.setFormikState((prev) => {
                                //   return {
                                //     ...prev,
                                //     values: {
                                //       ...prev.values,
                                //       amenities: [...arr],
                                //     },
                                //   };
                                // });
                                setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                amenitiesArr?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    "changing rooms"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-changing rooms"
                            >
                              <div>
                                <img
                                  src="assets/img/ChangingRoom.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Changing Rooms
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="washroom"
                              name="amenities"
                              id="amenities-washroom"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...amenitiesArr];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                // formik.setFormikState((prev) => {
                                //   return {
                                //     ...prev,
                                //     values: {
                                //       ...prev.values,
                                //       amenities: [...arr],
                                //     },
                                //   };
                                // });
                                setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                amenitiesArr?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "washroom"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-washroom"
                            >
                              <div>
                                <img
                                  src="assets/img/washroom.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Washroom
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="first aid"
                              name="amenities"
                              id="amenities-first aid"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...amenitiesArr];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                // formik.setFormikState((prev) => {
                                //   return {
                                //     ...prev,
                                //     values: {
                                //       ...prev.values,
                                //       amenities: [...arr],
                                //     },
                                //   };
                                // });
                                setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                amenitiesArr?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "first aid"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-first aid"
                            >
                              <div>
                                <img
                                  src="assets/img/firstaid_healtn.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              First Aid
                            </label>
                          </div>
                        </li>
                      </ul>
                      {formik.errors.amenities && formik.touched.amenities && (
                        <div className="text-red">
                          {" "}
                          {formik.errors.amenities}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4 position-relative">
                      <label className="mb-2">Rules and Regulations</label>
                      <div className="form-group">
                        <div className="input-container">
                          <ReactQuill
                            theme="snow"
                            className="textarea-control ps-5 py-3"
                            value={formik.values.rulesandregulations}
                            onChange={(content, data, source, editor) => {
                              setruls(content);
                              // formik.setFieldValue(
                              //   "rulesandregulations",
                              //   content
                              // );
                            }}
                            name="rulesandregulations"
                            placeholder="Rules and regulations"
                            modules={{ toolbar: false }}
                          />

                          <img
                            src="assets/img/rulesandregulations.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.rulesandregulations &&
                        formik.touched.rulesandregulations && (
                          <div className="text-red">
                            {" "}
                            {formik.errors.rulesandregulations}
                          </div>
                        )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        <div className="">
                          <input
                            className="styled-checkbox"
                            id="Iaccept"
                            type="checkbox"
                            name="acceptTermsCondition"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur("radio")}
                            checked={
                              formik.values.acceptTermsCondition === true
                            }
                          />
                          <label htmlFor="Iaccept" className="w-100">
                            I accept to the{" "}
                            <Link
                              to="/terms-conditions"
                              className="theme-color"
                              target="_blank"
                            >
                              Terms & Conditions
                            </Link>
                          </label>
                        </div>
                      </div>
                      {formik.errors.acceptTermsCondition &&
                        formik.touched.acceptTermsCondition && (
                          <div className="text-red">
                            {" "}
                            {formik.errors.acceptTermsCondition}
                          </div>
                        )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-submit">
                        {loading ? <Loader /> : "Save"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default VenueEditProfile;
