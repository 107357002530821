import React, { useCallback } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { DetailImageCarouselsettings, mapApiKey } from "../../../Utils";
import { useState } from "react";
import { useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { VenueOwnerDetailReducer } from "../../CoachVenueslice";

function GeneralDetail() {
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.CoachVenueMain.VenueOwnerDetail);

  const getvenueDetail = useCallback(async () => {
    dispatch(VenueOwnerDetailReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getvenueDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getvenueDetail]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (detail) {
      setMarkerPosition({
        lat: Number(detail?.venue?.latitude),
        lng: Number(detail?.venue?.longitude),
      });
    }
  }, [detail]);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap defaultZoom={15} defaultCenter={props?.markerPosition}>
        <Marker position={props?.markerPosition} />
      </GoogleMap>
    ))
  );

  return (
    <>
      <main id="mainbody" className="pt-0">
        <div className="container">
          <div className="py-5 text-center">
            <h2 className="mdl-ttl">General Detail</h2>
          </div>
          <section className="bg-white p-4">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8 mb-2 position-relative">
                <h5 className="text-capitalize">
                  {detail?.venue?.name || "NA"}
                </h5>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 mb-2 position-relative">
                <Slider {...DetailImageCarouselsettings}>
                  {detail?.venue?.image?.map((item, i) => (
                    <div key={i}>
                      <img
                        src={
                          item?.image?.includes("http")
                            ? item?.image
                            : "assets/img/dummyImage.png"
                        }
                        className="img-fluid Detailgounds"
                        alt=""
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                <div className="mapView">
                  {markerPosition?.lat ? (
                    <MapWithAMarker
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      markerPosition={markerPosition}
                    />
                  ) : (
                    "No Map Found"
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <h5>Amenities</h5>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="AmenitiesList">
                  {detail?.venue?.amenities?.map((item, i) => (
                    <li
                      className={item?.amenities ? "text-capitalize" : "d-none"}
                      key={i}
                    >
                      <img
                        src={
                          item?.amenities === "cctv"
                            ? "assets/img/cctv.png"
                            : item?.amenities === "parking"
                            ? "assets/img/parking.png"
                            : item?.amenities === "air-conditioned"
                            ? "assets/img/air_conditioning.png"
                            : item?.amenities === "changing rooms"
                            ? "assets/img/ChangingRoom.png"
                            : item?.amenities === "washroom"
                            ? "assets/img/washroom.png"
                            : item?.amenities === "first aid"
                            ? "assets/img/firstaid_healtn.png"
                            : null
                        }
                        alt=""
                        width="16px"
                      />{" "}
                      {item?.amenities ? item?.amenities : ""}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
              <h5>Rules</h5>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <div
                className="rulePTage"
                dangerouslySetInnerHTML={{ __html: detail?.venue?.rules }}
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div class="accordion GeneralDetailColl" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      You have coaches in your venue?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {detail?.venue?.haveCoach === true ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Will you allow external coach to come and coach in your
                      venue?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {detail?.venue?.wantCoach === true ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default GeneralDetail;
