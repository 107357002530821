import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Paginations from "../../Components/Paginations";
import { GetVenueRatingReducer } from "../slice";
import moment from "moment";

function VenueReview() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [page, setPage] = useState(1);
  const handlePage = (value) => {
    setPage(value);
  };

  const list = useSelector((state) => state.main.VenueRatingReviewList);

  const getRatingReviewList = useCallback(async () => {
    dispatch(
      GetVenueRatingReducer({
        page: page,
        limit: 10,
        venueId: id,
      })
    );
  }, [dispatch, page, id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getRatingReviewList();
    }
    return () => {
      mounted = false;
    };
  }, [getRatingReviewList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 text-center position-relative">
                <span
                  onClick={() => navigate(-1)}
                  className="theme-color backIconPosi"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </span>
                <h3 className="mt-3 fw-bold mb-0">Venue Reviews</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-end">
                <div className="theme-color text-center d-inline-block">
                  <h3>
                    {list?.totalRating
                      ? parseFloat(list?.totalRating).toFixed(1)
                      : "0"}
                  </h3>
                  <div className="ratingIcon mb-1">
                    {list?.totalRating > 0.4 && list?.totalRating < 0.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 0.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 1.4 && list?.totalRating < 1.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 1.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 2.4 && list?.totalRating < 2.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 2.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 3.4 && list?.totalRating < 3.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 3.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 4.4 && list?.totalRating < 4.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 4.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                  </div>
                  <p>
                    {list?.totalReview || "0"} (
                    {list?.totalReview <= 1 ? "review" : "reviews"})
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className=" w-100">
                  <div className="">
                    {list?.eventbooking?.length > 0 ? (
                      list?.eventbooking?.map((item, i) => (
                        <div className="position-relative botmLine" key={i}>
                          <div className="d-md-flex justify-content-between gap-3">
                            <span>
                              <p className="text-muted text-capitalize">
                                <img
                                  src={
                                    item?.normaluser?.picture?.includes("http")
                                      ? item?.normaluser?.picture
                                      : "assets/img/employe.png"
                                  }
                                  className="coachImgCenetr"
                                  alt=""
                                />{" "}
                                {item?.normaluser?.firstName
                                  ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                  : "NA"}
                                , {moment(item?.time).format("ll")}
                              </p>
                            </span>
                            <small>
                              {item?.rating === 1 ? (
                                <img src="assets/img/star_full.png" alt="" />
                              ) : item?.rating === 2 ? (
                                <>
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                </>
                              ) : item?.rating === 3 ? (
                                <>
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                </>
                              ) : item?.rating === 4 ? (
                                <>
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                </>
                              ) : item?.rating === 5 ? (
                                <>
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                  <img src="assets/img/star_full.png" alt="" />
                                </>
                              ) : null}
                            </small>
                          </div>
                          <h6>{item?.review || "NA"}</h6>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Data Found</div>
                    )}
                  </div>
                  {list?.eventbooking?.length > 0 ? (
                    <div className="text-center mt-5">
                      <Paginations
                        handlePage={handlePage}
                        page={page}
                        total={
                          list?.total_venueappointment &&
                          list?.total_venueappointment
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default VenueReview;
