import moment from "moment";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  CourtDetailReducer,
  CourtListReducer,
  MyFavouriteVenueDetailReducer,
  MyFavouriteVenueDetailWithTokenReducer,
  TimeSlotListReducer,
} from "../slice";
import { useRef } from "react";
import CreateEventPaymentModal from "../../Components/Modals/CreateEventPaymentModal";

function CreateNewEvent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [date, setDate] = useState(new Date());
  const [modalName, setModalName] = useState("");
  const [show, setShow] = useState(false);
  const [peopleCount, setPeopleCount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [courtId, setCourtId] = useState("");
  const [skill, setskill] = useState("");
  const [event, setevent] = useState("");
  const [gender, setgender] = useState("");

  const ref = useRef();

  const handleShow = () => {
    setShow(!show);
  };

  const detail = useSelector((state) => state.main.VenueDetail);
  const token = localStorage.getItem("token");
  const detailSport = localStorage.getItem("detailSport");

  const [sport, setSport] = useState(detailSport);

  const Courtlist = useSelector((state) => state.main.CourtList);

  const CourtDetail = useSelector((state) => state.main.CourtDetail);

  const initialValues = {
    sport: sport,
    skill: skill,
    gender: gender,
    event: event,
    court: courtId,
    slot: [],
    maxCapacity: CourtDetail?.maxCapacity || "1",
    minCapacity: CourtDetail?.minCapacity || "0",
    peopleCount: 1,
    totalAmount: 0,
    courts: [],
  };

  const formik = useFormik({
    innerRef: ref,
    initialValues: initialValues,
    validationSchema: Yup.object({
      sport: Yup.string().typeError("Required").required("Required"),
      skill: Yup.string().typeError("Required").required("Required"),
      gender: Yup.string().typeError("Required").required("Required"),
      event: Yup.string().typeError("Required").required("Required"),
      court: Yup.string().typeError("Required").required("Required"),
      //slot: Yup.string().typeError("Required").required("Required"),
      maxCapacity: Yup.string().typeError("Required").required("Required"),
      minCapacity: Yup.string().typeError("Required").required("Required"),
      peopleCount: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      handleShow();
      setModalName("CreateEventBookingDetail");
    },
  });

  const handleAmount = (
    values,
    setFieldValue,
    arr,
    count,
    amount,
    minCapacity
  ) => {
    if (arr?.length > 0) {
      let amp = 0;
      arr?.forEach((item) => {
        amp = item?.price + amp;
        //console.log(amp);
      });
      amp = (amp / minCapacity) * Number(count);
      setFieldValue("totalAmount", amp);
      setPeopleCount(count);
      setTotalAmount(amp);
      console.log(amp);
      console.log(minCapacity);
    } else {
      setFieldValue("totalAmount", 0);
    }
  };

  const getCourtList = useCallback(async () => {
    if (formik.values?.sport === "") {
      setCourtId("");
    } else {
      dispatch(
        CourtListReducer({
          isActive: 1,
          venueId: id,
          sport: formik.values?.sport,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          setCourtId(res?.payload?.data?.courtandtruf?.[0]?._id);
        }
      });
    }
  }, [dispatch, id, formik.values?.sport]);

  useEffect(() => {
    getCourtList();
  }, [getCourtList]);

  const getCourtDetail = useCallback(async () => {
    if (formik.values.court) {
      dispatch(
        CourtDetailReducer({
          courtandtrufId: formik.values.court,
        })
      );
    }
  }, [dispatch, formik.values.court]);

  useEffect(() => {
    getCourtDetail();
  }, [getCourtDetail]);

  const timeSlotlist = useSelector((state) => state.main.timeSlot);

  const gettimeSlotList = useCallback(async () => {
    if (formik.values.court) {
      dispatch(
        TimeSlotListReducer({
          date: moment(date).format("YYYY-MM-DD"),
          courtId: formik?.values?.court,
          //allowFullBooking: false,
        })
      );
    }
  }, [dispatch, date, formik.values.court]);

  useEffect(() => {
    gettimeSlotList();
  }, [gettimeSlotList]);

  const getVenueDetail = useCallback(async () => {
    if (token) {
      dispatch(
        MyFavouriteVenueDetailWithTokenReducer({
          venueId: id,
          sport: sport === "" ? detailSport : sport,
        })
      );
    } else {
      dispatch(
        MyFavouriteVenueDetailReducer({
          venueId: id,
          sport: sport === "" ? detailSport : sport,
        })
      );
    }
  }, [dispatch, id, sport, token, detailSport]);

  useEffect(() => {
    getVenueDetail();
  }, [getVenueDetail]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody" className="pt-0">
        <section className="">
          <div className="container">
            <h4 className="py-5 text-center position-relative">
              Create a new event{" "}
              <span
                onClick={() => navigate(-1)}
                className="theme-color backIconPosi"
              >
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </span>
            </h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="FormBoxShadow mb-5">
                <div className="row createEventForm">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="position-relative">
                      <img
                        src="assets/img/venueName.png"
                        className="createEventInputIcon"
                        alt=""
                      />
                      <label className="fw-bold text-capitalize">
                        {detail?.name || "NA"}
                      </label>
                      <p className="ps-4">
                        {detail?.address ? `${detail?.address}` : "NA"}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="position-relative">
                      <img
                        src="assets/img/sports.png"
                        className="createEventInputIcon"
                        alt=""
                      />
                      <label>Sports</label>
                      <select
                        className="form-control form-select"
                        name="sport"
                        value={formik?.values?.sport}
                        onChange={(e) => {
                          //formik.setFieldValue("sport", e.target.value);
                          setSport(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Sport</option>
                        {detail?.sports?.map((item, i) => (
                          <option value={item?.sport} key={i}>
                            {item?.sport}
                          </option>
                        ))}
                      </select>
                    </div>
                    {formik.errors.sport && formik.touched.sport && (
                      <div className="text-red position-static">
                        {" "}
                        {formik.errors.sport}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="position-relative">
                      <img
                        src="assets/img/skill_level.png"
                        className="createEventInputIcon"
                        alt=""
                      />
                      <label>Skills</label>
                      <select
                        className="form-control form-select"
                        name="skill"
                        value={formik.values.skill}
                        onChange={(e) => {
                          setskill(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select skill</option>
                        <option value="beginner">beginner</option>
                        <option value="intermediate">intermediate</option>
                        <option value="professional">professional</option>
                      </select>
                    </div>
                    {formik.errors.skill && formik.touched.skill && (
                      <div className="text-red position-static">
                        {" "}
                        {formik.errors.skill}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="FormBoxShadow mb-5">
                <div className="row createEventForm">
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="position-relative">
                      <img
                        src="assets/img/gender_male_icon.png"
                        className="createEventInputIcon"
                        alt=""
                      />
                      <label>Is the event applicable to all Gender?</label>
                      <div className="custom-radio genderLine d-flex align-items-center genderCreateEvent">
                        <div className="radio-item">
                          <input
                            type="radio"
                            value="all"
                            name="gender"
                            id="all"
                            onChange={(e) => {
                              setgender(e.target.value);
                            }}
                            onBlur={formik.handleBlur("gender")}
                            checked={formik.values.gender === "all"}
                          />
                          <label className="label-icon gender" htmlFor="all">
                            <div className="genderRadioIcon"></div>
                            <p>yes</p>
                          </label>
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            value="female"
                            name="gender"
                            id="female"
                            onChange={(e) => {
                              setgender(e.target.value);
                            }}
                            onBlur={formik.handleBlur("gender")}
                            checked={formik.values.gender === "female"}
                          />
                          <label className="label-icon gender" htmlFor="female">
                            <div className="genderRadioIcon"></div>
                            <p>female only</p>
                          </label>
                        </div>
                        <div className="radio-item">
                          <input
                            type="radio"
                            value="male"
                            name="gender"
                            id="male"
                            onChange={(e) => {
                              setgender(e.target.value);
                            }}
                            onBlur={formik.handleBlur("gender")}
                            checked={formik.values.gender === "male"}
                          />
                          <label className="label-icon gender" htmlFor="male">
                            <div className="genderRadioIcon"></div>
                            <p>male only</p>
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.errors.gender && formik.touched.gender && (
                      <div className="text-red position-static">
                        {" "}
                        {formik.errors.gender}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="position-relative">
                      <img
                        src="assets/img/calendar_date.png"
                        className="createEventInputIcon"
                        alt=""
                      />
                      <label>Create Event</label>
                      <select
                        className="form-control form-select pb-1 pt-3"
                        name="event"
                        value={formik.values.event}
                        onChange={(e) => {
                          setevent(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Event</option>
                        <option value="private">Private Event</option>
                        <option value="public">Public Event</option>
                      </select>
                    </div>
                    {formik.errors.event && formik.touched.event && (
                      <div className="text-red position-static">
                        {" "}
                        {formik.errors.event}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="FormBoxShadow mb-5">
                <div className="row createEventForm">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="position-relative">
                      <label>Select Court</label>
                      {sport ? (
                        <div className="row custom-radio">
                          {Courtlist?.length > 0 ? (
                            Courtlist?.map((item, i) => (
                              <div
                                className="col-sm-6 col-sm-4 col-md-3 col-lg-3 mt-3"
                                key={i}
                              >
                                <div className="radio-item h-100">
                                  <input
                                    type="radio"
                                    id={item?._id}
                                    name="court"
                                    value={item?._id}
                                    onChange={(e) => {
                                      //formik.setFieldValue("court", item?._id);
                                      setCourtId(item?._id);
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    onBlur={formik.handleBlur("court")}
                                    checked={formik.values.court === item?._id}
                                  />
                                  <label
                                    className="label-icon courtCheck"
                                    htmlFor={item?._id}
                                    // onClick={() => {
                                    //   setCourtId(item?._id);
                                    // }}
                                  >
                                    <img
                                      src={
                                        item?.image[0]?.image?.includes("http")
                                          ? item?.image[0]?.image
                                          : "assets/img/dummyImage.png"
                                      }
                                      className="courtImgSect"
                                      alt=""
                                    />
                                    <p>{item?.name}</p>
                                  </label>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="text-center">Court Not Found </div>
                          )}
                        </div>
                      ) : (
                        <div className="text-center fw-bold mt-4">
                          Please Select Sport
                        </div>
                      )}

                      {formik.errors.court && formik.touched.court && (
                        <div className="text-red position-static">
                          {" "}
                          {formik.errors.court}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormBoxShadow mb-5">
                <div className="row createEventForm">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="calendar">
                      <Calendar
                        onChange={(val) => {
                          setDate(val);
                          let arr = [];
                          formik.setFieldValue("slot", arr);
                        }}
                        value={date}
                        minDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {sport ? (
                <div className="row mt-5 custom-radio ps-0">
                  {timeSlotlist?.length > 0 ? (
                    timeSlotlist?.map((item, i) => (
                      <div
                        className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3"
                        key={i}
                      >
                        <div className="radio-item h-100">
                          <input
                            type="checkbox"
                            //value={timeSlotlist?.[i]}
                            checked={
                              formik.values?.slot?.findIndex((it) => {
                                return (
                                  it?.timeSlotStart === item?.timeSlotStart &&
                                  it?.timeSlotEnd === item?.timeSlotEnd &&
                                  it?.price === item?.price
                                );
                              }) !== -1
                            }
                            name="slot"
                            id={`timeslot${i}`}
                            onChange={(e) => {
                              let checked = e.target.checked;
                              let arr = [...formik.values.slot];
                              let index = arr?.indexOf(item);
                              if (index === -1 && checked) {
                                arr.push(item);
                              } else if (index !== -1 && !checked) {
                                arr.splice(index, 1);
                              }
                              formik.setFieldValue("slot", arr);
                              handleAmount(
                                formik.values,
                                formik.setFieldValue,
                                arr,
                                formik.values?.peopleCount,
                                formik.values?.totalAmount,
                                CourtDetail?.minCapacity
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="label-icon timeSlotAdd"
                            htmlFor={`timeslot${i}`}
                          >
                            <p>
                              {moment(item?.timeSlotStart, "hh:mm:ss").format(
                                "hh:mm A"
                              )}{" "}
                              -{" "}
                              {moment(item?.timeSlotEnd, "hh:mm:ss").format(
                                "hh:mm A"
                              )}
                            </p>
                            <p>
                              <i className="fa fa-inr"></i> {item?.price || "0"}
                            </p>
                            <div className="PlusRemoveIcon"></div>
                          </label>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center mb-3">No slots available</div>
                  )}
                  {formik.errors.slot && formik.touched.slot && (
                    <div className="text-red position-static">
                      {" "}
                      {formik.errors.slot}
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center my-5">Please Select Sport</div>
              )}

              <div className="FormBoxShadow mb-5">
                <div className="row createEventForm">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="position-relative">
                      <img
                        src="assets/img/capacity.png"
                        className="createEventInputIcon"
                        alt=""
                      />
                      <label>Capacity</label>
                      <div className="capacityMinMax">
                        <div className="capacitySelect border-0 shadow-none">
                          <span className="fw-bold">Min:</span>
                          <div className="peopenNum">
                            <input
                              type="number"
                              className="border-0 py-2"
                              placeholder="Number of People"
                              name="minCapacity"
                              value={formik.values.minCapacity}
                              onChange={(e) => {
                                if (e.target.value > 0) {
                                  formik.setFieldValue(
                                    "minCapacity",
                                    e.target.value
                                  );
                                  handleAmount(
                                    formik.values,
                                    formik.setFieldValue,
                                    formik.values?.slot
                                  );
                                }
                              }}
                              onBlur={formik.handleBlur}
                              readOnly
                            />
                            <div className="BtnIncDec">
                              <span
                                className={
                                  formik.values.minCapacity ===
                                    CourtDetail?.maxCapacity ||
                                  formik.values.minCapacity ===
                                    formik.values.maxCapacity
                                    ? "cursorNotWork"
                                    : ""
                                }
                                onClick={() => {
                                  if (
                                    formik.values.minCapacity <
                                    formik.values.maxCapacity
                                  ) {
                                    formik.setFieldValue(
                                      "minCapacity",
                                      parseInt(formik.values.minCapacity) + 1
                                    );
                                    handleAmount(
                                      formik.values,
                                      formik.setFieldValue,
                                      formik.values?.slot,
                                      formik.values?.peopleCount,
                                      formik.values?.totalAmount,
                                      formik.values.minCapacity + 1
                                    );
                                  }
                                }}
                              >
                                <i className="fa fa-angle-up"></i>
                              </span>
                              <span
                                className={
                                  CourtDetail?.minCapacity ===
                                  formik.values.minCapacity
                                    ? "cursorNotWork"
                                    : ""
                                }
                                onClick={() => {
                                  if (
                                    formik.values.minCapacity >
                                    CourtDetail?.minCapacity
                                  ) {
                                    formik.setFieldValue(
                                      "minCapacity",
                                      parseInt(formik.values.minCapacity) - 1
                                    );
                                    handleAmount(
                                      formik.values,
                                      formik.setFieldValue,
                                      formik.values?.slot,
                                      formik.values?.peopleCount,
                                      formik.values?.totalAmount,
                                      formik.values.minCapacity - 1
                                    );
                                  }
                                }}
                              >
                                <i className="fa fa-angle-down"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="capacitySelect border-0 shadow-none">
                          <span className="fw-bold">Max:</span>
                          <div className="peopenNum">
                            <input
                              type="number"
                              className=" border-0 py-2"
                              placeholder="Number of People"
                              name="maxCapacity"
                              value={formik.values.maxCapacity}
                              onChange={(e) => {
                                if (e.target.value > 0) {
                                  formik.setFieldValue(
                                    "maxCapacity",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={formik.handleBlur}
                              readOnly
                            />
                            <div className="BtnIncDec">
                              <span
                                className={
                                  formik.values.maxCapacity ===
                                  CourtDetail?.maxCapacity
                                    ? "cursorNotWork"
                                    : ""
                                }
                                onClick={() => {
                                  if (
                                    formik.values.maxCapacity <
                                    CourtDetail?.maxCapacity
                                  ) {
                                    formik.setFieldValue(
                                      "maxCapacity",
                                      parseInt(formik.values.maxCapacity) + 1
                                    );
                                  }
                                }}
                              >
                                <i className="fa fa-angle-up"></i>
                              </span>
                              <span
                                className={
                                  formik.values.maxCapacity ===
                                  formik.values.minCapacity
                                    ? "cursorNotWork"
                                    : ""
                                }
                                onClick={() => {
                                  if (
                                    formik.values.maxCapacity >
                                    formik.values.minCapacity
                                  ) {
                                    formik.setFieldValue(
                                      "maxCapacity",
                                      parseInt(formik.values.maxCapacity) - 1
                                    );
                                  }
                                }}
                              >
                                <i className="fa fa-angle-down"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormBoxShadow mb-5">
                <div className="row createEventForm">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="position-relative">
                      <label className="fw-bold mb-2">
                        Booking and Cancellation Policy
                      </label>
                      <p className="ms-4">
                        The absolute Owner and Operator of this website is
                        GAMEHUNT. Any kind of use of this Website by you will
                        ever mean your irrefutable Agreement/Consent to the
                        Terms of use stated herein. GAMEHUNT urges you to please
                        read these Terms of use carefully preceding the use of
                        this Website.{" "}
                        <Link
                          to={"/terms-conditions"}
                          target="_blank"
                          className="theme-color"
                        >
                          Read More..
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <h4 className="mb-4">How many people you wanted to book?</h4>
                <div className="peopenNum mb-4">
                  <input
                    type="number"
                    className="bg-white shadow rounded border-0 Howmany"
                    placeholder="Number of People"
                    name="peopleCount"
                    value={formik.values.peopleCount}
                    onChange={(e) => {
                      if (e.target.value > 0) {
                        formik.setFieldValue("peopleCount", e.target.value);
                        handleAmount(
                          formik.values,
                          formik.setFieldValue,
                          formik.values?.slot,
                          formik.values.minCapacity
                        );
                      }
                    }}
                    onBlur={formik.handleBlur}
                    readOnly
                  />
                  <div className="BtnIncDec">
                    {formik.values.maxCapacity === formik.values.peopleCount ? (
                      <span className="cursorNotWork">
                        <i className="fa fa-angle-up"></i>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          formik.setFieldValue(
                            "peopleCount",
                            parseInt(formik.values.peopleCount) + 1
                          );
                          handleAmount(
                            formik.values,
                            formik.setFieldValue,
                            formik.values?.slot,
                            formik.values?.peopleCount + 1,
                            formik.values?.totalAmount,
                            formik.values.minCapacity
                          );
                        }}
                      >
                        <i className="fa fa-angle-up"></i>
                      </span>
                    )}

                    <span
                      onClick={() => {
                        if (formik.values.peopleCount > 1) {
                          formik.setFieldValue(
                            "peopleCount",
                            parseInt(formik.values.peopleCount) - 1
                          );
                          handleAmount(
                            formik.values,
                            formik.setFieldValue,
                            formik.values?.slot,
                            formik.values?.peopleCount - 1,
                            formik.values?.totalAmount,
                            formik.values.minCapacity
                          );
                        }
                      }}
                    >
                      <i className="fa fa-angle-down"></i>
                    </span>
                  </div>
                </div>

                {formik.errors.peopleCount && formik.touched.peopleCount && (
                  <div className="text-red position-static">
                    {" "}
                    {formik.errors.peopleCount}
                  </div>
                )}
                <div
                  className={
                    formik.values?.slot?.length > 0 ? "view-btns" : "d-none"
                  }
                >
                  <button
                    type="submit"
                    className="btn-view btn-accept cursor-pointer"
                  >
                    {formik.values?.slot?.length} Slots +{" "}
                    {formik.values.peopleCount} people ={" "}
                    <i className="fa fa-inr"></i>{" "}
                    {parseFloat(formik.values?.totalAmount).toFixed(2)}
                    &nbsp;&nbsp;&nbsp;&nbsp; Create event{" "}
                    <img
                      src="assets/img/rightArrow.png"
                      width="25px"
                      className=""
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      {show && modalName === "CreateEventBookingDetail" && (
        <CreateEventPaymentModal
          show={show}
          handleShow={handleShow}
          setModalName="CreateEventBookingDetail"
          peopleCount={peopleCount}
          totalAmount={totalAmount}
          values={formik.values}
          id={id}
          date={date}
          detail={detail}
        />
      )}
    </>
  );
}

export default CreateNewEvent;
