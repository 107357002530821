import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import BlockedUserModal from "../../Components/Modals/BlockedUserModal";
import { BlockedUserReducer } from "../slice";
import Paginations from "./../../Components/Paginations";

function BlockedUser() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const list = useSelector((state) => state.main.BlockedUserList);
  const total = useSelector((state) => state.main.total_BlockedUser);
  const [page, setPage] = useState(1);

  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState("");
  const handleShow = () => {
    setShow(!show);
  };

  const handlePage = (value) => {
    setPage(value);
  };
  const getBlockedUserList = useCallback(async () => {
    setLoading(true);
    dispatch(
      BlockedUserReducer({
        page: page,
        limit: 10,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getBlockedUserList();
    }
    return () => {
      mounted = false;
    };
  }, [getBlockedUserList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-5">Blocked Users</h3>
              </div>
            </div>
            {loading ? (
              <Loader colorChange="colorChange" />
            ) : (
              <div className="row mt-3">
                {list?.length > 0 ? (
                  list?.map((item, i) => (
                    <div className="col-sm-6 col-md-6 col-lg-4 mb-5" key={i}>
                      <div className="AlreadyEvent p-3 position-relative">
                        <div className="d-block d-md-flex align-items-center gap-2 justify-content-start">
                          <img
                            src={
                              item?.user?.picture?.includes("http")
                                ? item?.user?.picture
                                : "assets/img/employe.png"
                            }
                            alt=""
                            className="eventImg m-0 rounded-circle"
                          />
                          <span>
                            <h5 className="mb-2 text-capitalize">
                              {item?.user?.firstName
                                ? `${item?.user?.firstName} ${item?.user?.lastName}`
                                : item?.user?.mobile
                                ? `${item?.user?.countryCode} ${item?.user?.mobile}`
                                : ""}
                            </h5>
                            <p className="mb-0">{item?.reason || "NA"}</p>
                          </span>
                        </div>
                        <hr />
                        <div className="text-end">
                          <button
                            className="joinUsBtn"
                            onClick={() => {
                              setUserId(item?.user?._id);
                              handleShow();
                            }}
                          >
                            Unblocked
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No Data Found</div>
                )}
                <div className="text-center">
                  {list?.length > 0 && (
                    <Paginations
                      handlePage={handlePage}
                      page={page}
                      total={total && total}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      {userId && show && (
        <BlockedUserModal
          handleShow={handleShow}
          show={show}
          id={userId}
          getdetail={getBlockedUserList}
          isBlocked={true}
        />
      )}
    </>
  );
}

export default BlockedUser;
