import React, { useCallback, useEffect, useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { config, mapApiKey } from "../../../Utils";
import moment from "moment";
import GooglePlace from "../../../Components/GooglePlace";
import TimePicker from "react-time-picker";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import {
  CoachDetailReducer,
  CoachEditProfileReducer,
} from "../../CoachVenueslice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import Loader from "../../../Components/Loader";
import { useRef } from "react";
window.Buffer = Buffer;
const Coachdays = [
  {
    weekday: "monday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "tuesday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "wednesday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "thursday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "friday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "saturday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "sunday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
];

const ForOtherVenuedays = [
  {
    weekday: "monday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "tuesday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "wednesday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "thursday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "friday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "saturday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
  {
    weekday: "sunday",
    timeFrom: "",
    timeTo: "",
    checked: false,
  },
];

function CoachEditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countryData, setCountryData] = useState();
  const [country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [address, setaddress] = useState("");
  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");
  const loginDetail = useSelector((state) => state.CoachVenueMain.CoachDetail);
  const [loading, setLoading] = useState(false);

  const [imageName, setimageName] = useState("");
  const [fullName, setfullName] = useState("");
  const [coachingCenterName, setcoachingCenterName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [street, setstreet] = useState("");
  const [amenitiesArr, setamenitiesArr] = useState([]);
  const [ruls, setruls] = useState("");

  const [coachSport, setCoachSport] = useState([]);

  const autocompleteRef = useRef();

  const [showAb, setShowAb] = useState(false);
  const Hhan = () => {
    setShowAb(!showAb);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const initialValues = {
    coachingCenterName: loginDetail?.coachingCenterName ? loginDetail?.coachingCenterName : "",
    fullName: loginDetail?.name ? loginDetail?.name : "",
    email: loginDetail?.email ? loginDetail?.email : "",
    mobile: loginDetail?.mobile ? loginDetail?.mobile : "",
    countryCode: loginDetail?.countryCode ? loginDetail?.countryCode : "",
    imageName: loginDetail?.image ? loginDetail?.image : "",
    doorNo: loginDetail?.doorNo ? loginDetail?.doorNo : "",
    street: loginDetail?.street ? loginDetail?.street : "",
    city: loginDetail?.city ? loginDetail?.city : "",
    state: loginDetail?.state ? loginDetail?.state : "",
    country: loginDetail?.country ? loginDetail?.country : "",
    pinCode: loginDetail?.zipCode ? loginDetail?.zipCode : "",
    address: loginDetail?.address ? loginDetail?.address : "",
    latitude: loginDetail?.latitude ? loginDetail?.latitude : "",
    longitude: loginDetail?.longitude ? loginDetail?.longitude : "",
    amenities: loginDetail?.amenities?.length > 0 ? loginDetail?.amenities : [],
    rulesandregulations: loginDetail?.rules ? loginDetail?.rules : "",
    differentvenue:
      loginDetail?.forVenue === true
        ? "yes"
        : loginDetail?.forVenue === false
        ? "no"
        : "",
    otherVenuesports:
      loginDetail?.venueSports?.length > 0 ? loginDetail?.venueSports : [],
    locationRange: loginDetail?.venueLocationRange
      ? loginDetail?.venueLocationRange
      : 10,
    ForOtherVenueWeekdays:
      loginDetail?.daysForVenue?.length > 0
        ? ForOtherVenuedays?.map((item) => {
            let arr = [...loginDetail?.daysForVenue];
            let isfound = arr.find(
              (it) => it.weekday.toLowerCase() === item?.weekday.toLowerCase()
            );
            if (isfound) {
              return {
                weekday: isfound?.weekday,
                timeFrom:
                  isfound?.timeFrom === "Invalid date" ? "" : isfound?.timeFrom,
                timeTo:
                  isfound?.timeTo === "Invalid date" ? "" : isfound?.timeTo,
                checked: isfound?.checked,
              };
            }
            return item;
          })
        : ForOtherVenuedays,

    SportsSlotArr:
      loginDetail?.sports?.length > 0
        ? loginDetail?.sports?.map((arritem) => {
            return {
              sport: arritem?.sport,
              coverImage: arritem?.coverImage,
              threeMonths: arritem?.threeMonths ? arritem?.threeMonths : "",
              sixMonths: arritem?.sixMonths ? arritem?.sixMonths : "",
              twelveMonths: arritem?.twelveMonths ? arritem?.twelveMonths : "",
              achievements: arritem?.achievements ? arritem?.achievements : "",
              certificate: arritem?.certificate ? arritem?.certificate : "",
              exprience: arritem?.exprience ? arritem?.exprience : "",
              sessionName: arritem?.sessionName ? arritem?.sessionName : "",
              days:
                arritem?.days?.length > 0
                  ? Coachdays?.map((item) => {
                      let arr = [...arritem?.days];
                      let isfound = arr.find(
                        (it) =>
                          it.weekday.toLowerCase() ===
                          item?.weekday.toLowerCase()
                      );

                      if (isfound) {
                        return {
                          weekday: isfound?.weekday,
                          timeFrom:
                            isfound?.timeFrom === "Invalid date"
                              ? ""
                              : isfound?.timeFrom,
                          timeTo:
                            isfound?.timeTo === "Invalid date"
                              ? ""
                              : isfound?.timeTo,
                          checked: isfound?.checked,
                        };
                      }
                      return item;
                    })
                  : Coachdays,
            };
          })
        : [
            {
              sport: "",
              coverImage: "",
              threeMonths: "",
              sixMonths: "",
              twelveMonths: "",
              achievements: "",
              certificate: "",
              exprience: "",
              sessionName: "",
              days: Coachdays,
            },
          ],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      coachingCenterName: Yup.string()
        .typeError("Required")
        .required("Required"),
      fullName: Yup.string().typeError("Required").required("Required"),
      email: Yup.string().typeError("Required").required("Required"),
      mobile: Yup.string()
        .required("required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Mobile Number should be only 10 digits")
        .max(10, "Mobile Number should be only 10 digits"),
      countryCode: Yup.string().typeError("Required").required("Required"),
      address: Yup.string().typeError("Required").required("Required"),
      //doorNo: Yup.string().typeError("Required").required("Required"),
      //street: Yup.string().typeError("Required").required("Required"),
      city: Yup.string().typeError("Required").required("Required"),
      state: Yup.string().typeError("Required").required("Required"),
      country: Yup.string().typeError("Required").required("Required"),
      pinCode: Yup.string().typeError("Required").required("Required"),
      differentvenue: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const arrWeekdays = formik.values.SportsSlotArr?.map((item) => {
        let arr = item?.days?.filter((week) => week?.checked === true);
        return {
          ...item,
          days: arr,
        };
      });

      const OtherVenuearrWeekdays = formik.values.ForOtherVenueWeekdays?.map(
        (item) => {
          if (item?.checked) {
            return {
              weekday: item?.weekday,
              timeFrom: moment(item?.timeFrom, "hh:mm:ss").format("HH:mm"),
              timeTo: moment(item?.timeTo, "hh:mm:ss").format("HH:mm"),
              checked: item?.checked,
            };
          }
          return false;
        }
      )?.filter((it) => !it === false);

      setLoading(true);

      const payload = {
        image: values.imageName,
        coachingCenterName: values.coachingCenterName,
        name: values.fullName,
        //countryCode: values.countryCode,
        //mobile: values.mobile,
        //email: values.email,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.pinCode,
        doorNo: values.doorNo,
        street: values.street,
        address: values.address,
        latitude: values.latitude,
        longitude: values.longitude,

        forVenue: values.differentvenue,

        sports: JSON.stringify(arrWeekdays),
        amenities: JSON.stringify(values.amenities),
        rules: values.rulesandregulations,
        daysForVenue:
          formik.values.differentvenue === "yes"
            ? JSON.stringify(OtherVenuearrWeekdays)
            : null,
        // venueSports:
        //   formik.values.differentvenue === "yes"
        //     ? JSON.stringify(values.otherVenuesports)
        //     : null,
        // venueLocationRange:
        //   formik.values.differentvenue === "yes" ? values.locationRange : "",
      };

      if (values.latitude === undefined || values.latitude === "") {
        toast.error("Please Check Current Location");
      } else {
        dispatch(CoachEditProfileReducer(payload)).then((data) => {
          setLoading(false);
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            navigate("/coach/manage-booking", {
              replace: true,
            });
          } else {
            setLoading(false);
            toast.error(data.payload.message);
          }
        });
      }
    },
  });

  const handleTimeSlot = (e, ind, name, values, setFormikState) => {
    console.log(e, ind, name)
    //setTimeSlotIndex(ind);
    const arr = [...values.SportsSlotArr];

    if (name) {
      const { value } = e.target;
      arr[ind] = {
        ...arr[ind],
        [name]: value,
      };
    } else {
      arr[ind] = {
        ...arr[ind],
        [name]: e,
      };
    }
// setCoachSport(arr)
    setFormikState((prev) => {
      return {
        ...prev,
        values: {
          ...prev.values,
          SportsSlotArr: arr,
        },
      };
    });
  };

  const getCoachDetail = useCallback(async () => {
    dispatch(CoachDetailReducer({}))
  }, [dispatch]);

  useEffect(() => {
    getCoachDetail();
  }, [getCoachDetail]);

  if (formik.values?.latitude) {
    Geocode.setApiKey(mapApiKey);
    Geocode.fromLatLng(formik.values?.latitude, formik.values?.longitude).then(
      (response) => {
        const address = response.results[0];
        //console.log(address);
        let city, state, country, postal_code;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postal_code =
                  response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        //console.log(city, state, country, postal_code);
        // setCountry(country);
        // setpincode(postal_code);
        // setstate(state);
        // setcity(city);

        formik.setFieldValue("country", country)
        formik.setFieldValue("pinCode", postal_code)
        formik.setFieldValue("state", state)
        formik.setFieldValue("city", city)
      },
      (error) => {
        console.error(error);
      }
    );
  }
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="form-style mt-5">
                  <form className="row" onSubmit={formik.handleSubmit}>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                      <h2 className="mdl-ttl">Edit Personal info</h2>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 text-center">
                      <div className="upload-btn-wrapper">
                        <span className="btn shadow">
                          <img
                            src={
                              formik.values.imageName &&
                              formik.values.imageName?.includes("http")
                                ? formik.values.imageName
                                : "assets/img/employe.png"
                            }
                            alt=""
                          />

                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        </span>
                        <input
                          type="file"
                          accept="image/*"
                          name="imageName"
                          onChange={(event) => {
                            setIsActive(true);
                            S3FileUpload.uploadFile(
                              event.target.files[0],
                              config
                            )
                              .then((data) => {
                                formik.setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      imageName: data.location,
                                    },
                                  };
                                });
                                // setimageName(data.location);
                                setTime(100);
                                setIsActive(true);
                                setTimeout(() => {
                                  setTime(0);
                                  setIsActive(false);
                                }, 3000);
                              })
                              .catch((err) => console.error(err));
                          }}
                        />
                      </div>
                      {time > 0 && isActive && (
                        <Progress type="line" width={100} percent={time} />
                      )}
                      {formik.errors.imageName && formik.touched.imageName && (
                        <div className="text-red">
                          {" "}
                          {formik.errors.imageName}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="Coaching Center Name"
                          name="coachingCenterName"
                          value={formik.values.coachingCenterName}
                          // onChange={(e) => {
                          //   setcoachingCenterName(e.target.value);
                          // }}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <img
                          src="assets/img/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.coachingCenterName &&
                        formik.touched.coachingCenterName && (
                          <div className="text-red">
                            {" "}
                            {formik.errors.coachingCenterName}
                          </div>
                        )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-5"
                          placeholder="Full name"
                          name="fullName"
                          value={formik.values.fullName}
                          // onChange={(e) => {
                          //   setfullName(e.target.value);
                          // }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src="assets/img/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.fullName && formik.touched.fullName && (
                        <div className="text-red">
                          {" "}
                          {formik.errors.fullName}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                      <div className="input-container">
                        <input
                          type="email"
                          className="form-control ps-5"
                          placeholder="Email"
                          name="email"
                          value={formik.values.email}
                          // onChange={(e) => {
                          //   setemail(e.target.value);
                          // }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                        />
                        <img
                          src="assets/img/email.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.email && formik.touched.email && (
                        <div className="text-red"> {formik.errors.email}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                      <div className="input-container">
                        <PhoneInput
                          country="in"
                          inputProps={{
                            name: "mobile",
                          }}
                          enableSearch
                          value={
                            formik.values.countryCode
                              ? formik.values.countryCode + formik.values.mobile
                              : undefined
                          }
                          onChange={(phone, country, e, formattedValue) => {
                            // setCountryData({ formattedValue, country });
                            formik.setFieldValue("countryCode",{ formattedValue, country });
                            if (phone.length > 0) {
                              const raw = phone.slice(
                                country?.dialCode?.length
                              );
                              // setmobile(raw);
                              formik.setFieldValue("mobile",raw);

                              setCountryData(formattedValue?.split(" ")[0]);
                            } else {
                              // setmobile("");
                              formik.setFieldValue("countryCode","");

                              // setCountryData("");
                              formik.setFieldValue("countryCode","");
                            }
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Mobile Number"
                          containerclassName="form-control signupClass"
                          inputclassName="phoneInputClass"
                          disabled
                        />
                        <img
                          src="assets/img/mobile.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.mobile && formik.touched.mobile && (
                        <div className="text-red"> {formik.errors.mobile}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <h5>Coaching Centre Address</h5>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="Door No."
                            name="doorNo"
                            // onChange={(e) => {
                            //   setdoorNo(e.target.value);
                            // }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.doorNo}
                          />
                          <img
                            src="assets/img/doorno.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.doorNo && formik.touched.doorNo && (
                        <div className="text-red"> {formik.errors.doorNo}</div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="Street"
                            name="street"
                            // onChange={(e) => {
                            //   setstreet(e.target.value);
                            // }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.street}
                          />
                          <img
                            src="assets/img/street.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.street && formik.touched.street && (
                        <div className="text-red"> {formik.errors.street}</div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="City"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                          />
                          <img
                            src="assets/img/city.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.city && formik.touched.city && (
                        <div className="text-red"> {formik.errors.city}</div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="State"
                            name="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={formik.ha}
                            value={formik.values.state}
                          />
                          <img
                            src="assets/img/State.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.state && formik.touched.state && (
                        <div className="text-red"> {formik.errors.state}</div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="Country"
                            name="country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.country}
                          />
                          <img
                            src="assets/img/Country.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.country && formik.touched.country && (
                        <div className="text-red"> {formik.errors.country}</div>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control ps-5"
                            placeholder="Pin Code"
                            name="pinCode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pinCode}
                          />
                          <img
                            src="assets/img/pincode.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.pinCode && formik.touched.pinCode && (
                        <div className="text-red"> {formik.errors.pinCode}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container profile-loca">
                        {/* <GooglePlace
                          name="address"
                          value={address}
                          latLong={{
                            lat: lat,
                            lng: long,
                          }}
                          onBlur={formik.handleBlur}
                          onChange={(val, latLong) => {
                            if (val) {
                              setaddress(val);
                              setlat(latLong.lat);
                              setlong(latLong.lng);
                            } else {
                              setaddress("");
                              setlat("");
                              setlong("");
                            }
                          }}
                        /> */}
                        <Autocomplete
                          apiKey={mapApiKey}
                          name="address"
                          value={formik.values.address}
                          ref={autocompleteRef}
                          className="form-control ps-5"
                          placeholder="Current Location"
                          onBlur={formik.handleBlur}
                          onChange={() => {
                            // console.log(autocompleteRef);
                            // setaddress(autocompleteRef.current.value);
                            // setlat("");
                            // setlong("");

                            formik.setFieldValue("address", autocompleteRef.current.value)
                            formik.setFieldValue("latitude","")
                            formik.setFieldValue("longitude","")
                          }}
                          onPlaceSelected={(
                            place,
                            inputRef,
                            autocompleteRef
                          ) => {
                            // console.log(place, inputRef, autocompleteRef);
                            if (place) {
                              let lat = place.geometry.location.lat();
                              let lng = place.geometry.location.lng();
                              let address = place.formatted_address;

                              formik.setFieldValue("address",address)
                              formik.setFieldValue("latitude",lat)
                              formik.setFieldValue("longitude",lng)


                              // setaddress(address);
                              // setlat(lat);
                              // setlong(lng);
                            } else {

                              formik.setFieldValue("address","")
                              formik.setFieldValue("latitude","")
                              formik.setFieldValue("longitude","")

                              // setaddress("");
                              // setlat("");
                              // setlong("");
                            }
                          }}
                          options={{
                            types: ["(regions)"],
                            componentRestrictions: { country: "in" },
                          }}
                        />
                        <img
                          src="assets/img/location.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {formik.errors.address && formik.touched.address && (
                        <div className="text-red"> {formik.errors.address}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                      {formik.values?.SportsSlotArr?.map((itemspot, i) => {
                        return (
                          <div
                            className="row position-relative coachEditArr"
                            key={i}
                          >
                            <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-2">
                              <h4>Sports {i + 1}</h4>
                              <div
                                className="remove"
                                onClick={() => {
                                  let arr = [...formik.values?.SportsSlotArr];
                                  arr.splice(i, 1);
                                  formik.setFormikState((prev) => {
                                    return {
                                      ...prev,
                                      values: {
                                        ...prev.values,
                                        SportsSlotArr: arr,
                                      },
                                    };
                                  });
                                  // setCoachSport(arr)
                                }}
                              >
                                <img src="assets/img/delete.png" alt="" />
                              </div>
                            </div>

                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <div className="input-container">
                                <select
                                  className="form-control ps-5 form-select"
                                  onChange={(e) => {
                                    handleTimeSlot(
                                      e,
                                      i,
                                      "sport",
                                      formik.values,
                                      formik.setFormikState
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={itemspot?.sport}
                                  name={`SportsSlotArr[i].sport`}
                                >
                                  <option value="">Select</option>
                                  <option value="badminton">badminton</option>
                                  <option value="table tennis">
                                    table tennis
                                  </option>
                                  <option value="football">football</option>
                                  <option value="cricket">cricket</option>
                                  <option value="basketball">basketBall</option>
                                  <option value="lawn tennis">
                                    Lawn tennis
                                  </option>
                                  <option value="swimming">Swimming</option>
                                </select>
                                <img
                                  src="assets/img/sports.png"
                                  className="input-img"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <div className="input-container">
                                <input
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Session Name"
                                  name={`SportsSlotArr[i].sessionName`}
                                  onChange={(e) => {
                                    handleTimeSlot(
                                      e,
                                      i,
                                      "sessionName",
                                      formik.values,
                                      formik.setFormikState
                                    );
                                  }}
                                  value={itemspot?.sessionName}
                                />
                                <img
                                  src="assets/img/Schedule.png"
                                  className="input-img"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <div className="form-group text-center">
                                <div
                                  className={
                                    itemspot.coverImage &&
                                    itemspot.coverImage?.includes("http")
                                      ? "upload-btn-wrapper uploadFile CoverUpload"
                                      : "upload-btn-wrapper uploadFile"
                                  }
                                >
                                  <span className="btn bg-transparent">
                                    <img
                                      src={
                                        itemspot.coverImage &&
                                        itemspot.coverImage?.includes("http")
                                          ? itemspot.coverImage
                                          : "assets/img/file_files.png"
                                      }
                                      //src="assets/img/file_files.png"
                                      alt=""
                                      className={
                                        itemspot.coverImage &&
                                        itemspot.coverImage?.includes("http")
                                          ? "uploadImageicon CoverImageUpload"
                                          : "uploadImageicon"
                                      }
                                    />
                                    <span
                                      className={
                                        itemspot.coverImage?.includes("http")
                                          ? "text-success"
                                          : "theme-color"
                                      }
                                    >
                                      {itemspot.coverImage &&
                                      itemspot.coverImage?.includes("http")
                                        ? "Sport Cover Image"
                                        : "Upload Sport Cover Image"}
                                    </span>
                                  </span>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name={`SportsSlotArr[i].coverImage`}
                                    onChange={(e) => {
                                      setIsActive(true);
                                      S3FileUpload.uploadFile(
                                        e.target.files[0],
                                        config
                                      )
                                        .then((data) => {

                                          // handleTimeSlot(
                                          //   e,
                                          //   i,
                                          //   "coverImage",
                                          //   formik.values,
                                          //   formik.setFormikState
                                          // );
                                          formik.setFieldValue(
                                            `SportsSlotArr[${i}].coverImage`,
                                            data.location
                                          );
                                          // formik.setFormikState((prevState) => {
                                          //   return {
                                          //     ...prevState,
                                          //     values: {
                                          //       ...prevState.values,
                                          //       certificate: data.location,
                                          //     },
                                          //   };
                                          // });
                                          setTime(100);
                                          setIsActive(true);
                                          setTimeout(() => {
                                            setTime(0);
                                            setIsActive(false);
                                          }, 3000);
                                        })
                                        .catch((err) => console.error(err));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                              <h5>Availabilty</h5>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-3">
                              <div className="table-responsive">
                                <table className="table storetable">
                                  <tbody className="bg-transparent">
                                    <tr>
                                      <th className="text-center">days</th>
                                      <th>from time</th>
                                      <th>to time</th>
                                    </tr>
                                    <FormikProvider value={formik}>
                                      <FieldArray name="days" />
                                      <>
                                        {itemspot?.days?.map((item, ii) => (
                                          <tr key={ii}>
                                            <td>
                                              <input
                                                className="styled-checkbox"
                                                id={`dayas-${item?.weekday}${i}`}
                                                type="checkbox"
                                                checked={item?.checked}
                                                name={`SportsSlotArr[${i}].days[${ii}].checked`}
                                                onChange={(e) => {
                                                  let checked =
                                                    e.target.checked;

                                                  formik.setFieldValue(
                                                    `SportsSlotArr[${i}].days[${ii}].checked`,
                                                    checked
                                                  );
                                                }}
                                              />
                                              <label
                                                htmlFor={`dayas-${item?.weekday}${i}`}
                                              >
                                                {item?.weekday}
                                              </label>
                                            </td>
                                            <td>
                                              <TimePicker
                                                onChange={(val) => {
                                                  formik.setFieldValue(
                                                    `SportsSlotArr[${i}].days[${ii}].timeFrom`,
                                                    val
                                                  );
                                                }}
                                                clockIcon={null}
                                                disableClock={true}
                                                clearIcon={null}
                                                minutePlaceholder="MM"
                                                hourPlaceholder="HH"
                                                disabled={!item?.checked}
                                                value={
                                                  item?.timeFrom || undefined
                                                }
                                                className={"form-control"}
                                                name={`SportsSlotArr[${i}].days[${ii}].timeFrom`}
                                                format="hh:mm a"
                                              />
                                            </td>
                                            <td>
                                              <TimePicker
                                                onChange={(val) => {
                                                  formik.setFieldValue(
                                                    `SportsSlotArr[${i}].days[${ii}].timeTo`,
                                                    val
                                                  );
                                                }}
                                                clockIcon={null}
                                                disableClock={true}
                                                clearIcon={null}
                                                minutePlaceholder="MM"
                                                hourPlaceholder="HH"
                                                disabled={!item?.checked}
                                                value={
                                                  item?.timeTo || undefined
                                                }
                                                className={"form-control"}
                                                name={`SportsSlotArr[${i}].days[${ii}].timeTo`}
                                                format="hh:mm a"
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    </FormikProvider>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                              <h5>Price per person</h5>
                            </div>
                            <div className="form-group col-sm-6 col-md-4 col-lg-4 mb-4">
                              <label className="fw-bold mb-2">3 Months</label>
                              <input
                                type="number"
                                className="form-control ps-4"
                                placeholder="Price"
                                name={`SportsSlotArr[i].threeMonths`}
                                onChange={(e) => {
                                  handleTimeSlot(
                                    e,
                                    i,
                                    "threeMonths",
                                    formik.values,
                                    formik.setFormikState
                                  );
                                }}
                                value={itemspot?.threeMonths}
                              />
                            </div>
                            <div className="form-group col-sm-6 col-md-4 col-lg-4 mb-4">
                              <label className="fw-bold mb-2">6 Months</label>
                              <input
                                type="number"
                                className="form-control ps-4"
                                placeholder="Price"
                                name={`SportsSlotArr[i].sixMonths`}
                                onChange={(e) => {
                                  handleTimeSlot(
                                    e,
                                    i,
                                    "sixMonths",
                                    formik.values,
                                    formik.setFormikState
                                  );
                                }}
                                value={itemspot?.sixMonths}
                              />
                            </div>
                            <div className="form-group col-sm-6 col-md-4 col-lg-4 mb-4">
                              <label className="fw-bold mb-2">12 Months</label>
                              <input
                                type="number"
                                className="form-control ps-4"
                                placeholder="Price"
                                name={`SportsSlotArr[i].twelveMonths`}
                                onChange={(e) => {
                                  handleTimeSlot(
                                    e,
                                    i,
                                    "twelveMonths",
                                    formik.values,
                                    formik.setFormikState
                                  );
                                }}
                                value={itemspot?.twelveMonths}
                              />
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <div className="input-container">
                                <input
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Achievements"
                                  name={`SportsSlotArr[i].achievements`}
                                  onChange={(e) => {
                                    handleTimeSlot(
                                      e,
                                      i,
                                      "achievements",
                                      formik.values,
                                      formik.setFormikState
                                    );
                                  }}
                                  value={itemspot?.achievements}
                                />
                                <img
                                  src="assets/img/achievement_award.png"
                                  className="input-img"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <div className="form-group text-center">
                                <div
                                  className={
                                    itemspot.certificate &&
                                    itemspot.certificate?.includes("http")
                                      ? "upload-btn-wrapper uploadFile CoverUpload"
                                      : "upload-btn-wrapper uploadFile"
                                  }
                                >
                                  <span className="btn bg-transparent">
                                    <img
                                      src={
                                        itemspot.certificate &&
                                        itemspot.certificate?.includes("http")
                                          ? itemspot.certificate
                                          : "assets/img/file_files.png"
                                      }
                                      //src="assets/img/file_files.png"
                                      alt=""
                                      className={
                                        itemspot.certificate &&
                                        itemspot.certificate?.includes("http")
                                          ? "uploadImageicon CoverImageUpload"
                                          : "uploadImageicon"
                                      }
                                    />
                                    <span
                                      className={
                                        itemspot.certificate?.includes("http")
                                          ? "text-success"
                                          : "theme-color"
                                      }
                                    >
                                      {itemspot.certificate &&
                                      itemspot.certificate?.includes("http")
                                        ? "Certificate"
                                        : "Upload certificate"}
                                    </span>
                                  </span>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name={`SportsSlotArr[i].certificate`}
                                    onChange={(event) => {
                                      setIsActive(true);
                                      S3FileUpload.uploadFile(
                                        event.target.files[0],
                                        config
                                      )
                                        .then((data) => {
                                          formik.setFieldValue(
                                            `SportsSlotArr[${i}].certificate`,
                                            data.location
                                          );
                                          // formik.setFormikState((prevState) => {
                                          //   return {
                                          //     ...prevState,
                                          //     values: {
                                          //       ...prevState.values,
                                          //       certificate: data.location,
                                          //     },
                                          //   };
                                          // });
                                          setTime(100);
                                          setIsActive(true);
                                          setTimeout(() => {
                                            setTime(0);
                                            setIsActive(false);
                                          }, 3000);
                                        })
                                        .catch((err) => console.error(err));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                              <div className="input-container">
                                <input
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Year of experience"
                                  name={`SportsSlotArr[i].exprience`}
                                  onChange={(e) => {
                                    handleTimeSlot(
                                      e,
                                      i,
                                      "exprience",
                                      formik.values,
                                      formik.setFormikState
                                    );
                                  }}
                                  value={itemspot?.exprience}
                                />
                                <img
                                  src="assets/img/feedback_rating_review_icon.png"
                                  className="input-img"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-end">
                      <div
                        className="position-relative pw-3 AddtoList cursor-pointer d-inline-block"
                        onClick={() => {
                          //setTimeSlotIndex(timeSlotIndex + 1);
                          let arr = [...formik.values?.SportsSlotArr];
                          arr.push({
                            sport: "",
                            coverImage: "",
                            threeMonths: "",
                            sixMonths: "",
                            twelveMonths: "",
                            achievements: "",
                            certificate: "",
                            exprience: "",
                            sessionName: "",
                            days: Coachdays,
                          });
                          formik.setFormikState((prev) => {
                            return {
                              ...prev,
                              values: {
                                ...prev.values,
                                SportsSlotArr: arr,
                              },
                            };
                          });
                          // setCoachSport(arr);
                        }}
                      >
                        <b>+ Add More Sports</b>
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                      <h5>Amenities</h5>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <ul className="slotTimeList custom-radio ps-0 sprortCheckImg">
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="cctv"
                              name="amenities"
                              id={`amenities-cctv`}
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...formik.values?.amenities];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                formik.setFormikState((prev) => {
                                  return {
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      amenities: [...arr],
                                    },
                                  };
                                });
                                // setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                formik.values?.amenities?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "cctv"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor={`amenities-cctv`}
                            >
                              <div>
                                <img
                                  src="assets/img/cctv.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              CCTV
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="parking"
                              name="amenities"
                              id="amenities-parking"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...formik.values?.amenities];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                formik.setFormikState((prev) => {
                                  return {
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      amenities: [...arr],
                                    },
                                  };
                                });
                                // setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                formik.values?.amenities?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "parking"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-parking"
                            >
                              <div>
                                <img
                                  src="assets/img/parking.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Parking
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="air-conditioned"
                              name="amenities"
                              id="amenities-air-conditioned"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...formik.values?.amenities];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                formik.setFormikState((prev) => {
                                  return {
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      amenities: [...arr],
                                    },
                                  };
                                });
                                // setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                formik.values?.amenities?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    "air-conditioned"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-air-conditioned"
                            >
                              <div>
                                <img
                                  src="assets/img/air_conditioning.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Air-Conditioned
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="changing rooms"
                              name="amenities"
                              id="amenities-changing rooms"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...formik.values?.amenities];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                formik.setFormikState((prev) => {
                                  return {
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      amenities: [...arr],
                                    },
                                  };
                                });
                                // setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                formik.values?.amenities?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    "changing rooms"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-changing rooms"
                            >
                              <div>
                                <img
                                  src="assets/img/ChangingRoom.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Changing Rooms
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="washroom"
                              name="amenities"
                              id="amenities-washroom"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...formik.values?.amenities];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                formik.setFormikState((prev) => {
                                  return {
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      amenities: [...arr],
                                    },
                                  };
                                });
                                // setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                formik.values?.amenities?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "washroom"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-washroom"
                            >
                              <div>
                                <img
                                  src="assets/img/washroom.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              Washroom
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="radio-item h-100">
                            <input
                              type="checkbox"
                              value="first aid"
                              name="amenities"
                              id="amenities-first aid"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                let arr = [...formik.values?.amenities];
                                let index = arr.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() ===
                                    e.target.value?.toLowerCase()
                                );
                                if (checked) {
                                  if (index === -1) {
                                    arr.push({ amenities: e.target.value });
                                  }
                                } else if (!checked) {
                                  if (index !== -1) {
                                    arr.splice(index, 1);
                                  }
                                }
                                formik.setFormikState((prev) => {
                                  return {
                                    ...prev,
                                    values: {
                                      ...prev.values,
                                      amenities: [...arr],
                                    },
                                  };
                                });
                                // setamenitiesArr(arr);
                              }}
                              onBlur={formik.handleBlur}
                              checked={
                                formik.values?.amenities?.findIndex(
                                  (it) =>
                                    it?.amenities?.toLowerCase() === "first aid"
                                ) !== -1
                              }
                            />
                            <label
                              className="label-icon timeSlotAdd"
                              htmlFor="amenities-first aid"
                            >
                              <div>
                                <img
                                  src="assets/img/firstaid_healtn.png"
                                  alt=""
                                  className="unselect"
                                />
                              </div>
                              First Aid
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4 position-relative">
                      <label className="mb-2">Rules and Regulations</label>
                      <div className="form-group">
                        <div className="input-container">
                          <ReactQuill
                            theme="snow"
                            className="textarea-control ps-5 py-3"
                            value={formik.values.rulesandregulations}
                            onChange={(content, data, source, editor) => {
                              // setruls(content);
                              formik.setFieldValue(
                                "rulesandregulations",
                                content
                              );
                            }}
                            name="rulesandregulations"
                            placeholder="Rules and regulations"
                            modules={{ toolbar: false }}
                          />
                          {/* <textarea
                            className="textarea-control ps-5 py-3"
                            placeholder="Rules and regulations"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.rulesandregulations}
                            name="rulesandregulations"
                            rows="5"
                          ></textarea> */}
                          <img
                            src="assets/img/rulesandregulations.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {formik.errors.rulesandregulations &&
                        formik.touched.rulesandregulations && (
                          <div className="text-red">
                            {" "}
                            {formik.errors.rulesandregulations}
                          </div>
                        )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="position-relative">
                        <label className="fw-bold">
                          Are you available to coach in a different venue?
                        </label>
                        <div className="custom-radio ps-0 d-flex align-items-center gap-3">
                          <div className="radio-item">
                            <input
                              type="radio"
                              value="yes"
                              name="differentvenue"
                              id="yes"
                              onChange={formik.handleChange("differentvenue")}
                              onBlur={formik.handleBlur("radio")}
                              checked={formik.values.differentvenue === "yes"}
                            />
                            <label
                              className="label-icon gender"
                              htmlFor="yes"
                              onClick={() => {
                                formik.setFieldValue("differentvenue", "yes");
                                Hhan();
                              }}
                            >
                              <div className="genderRadioIcon"></div>
                              <p>yes</p>
                            </label>
                          </div>
                          <div className="radio-item">
                            <input
                              type="radio"
                              value="no"
                              name="differentvenue"
                              id="no"
                              onChange={formik.handleChange("differentvenue")}
                              onBlur={formik.handleBlur("radio")}
                              checked={formik.values.differentvenue === "no"}
                            />
                            <label
                              className="label-icon gender"
                              htmlFor="no"
                              onClick={() => {
                                formik.setFieldValue("differentvenue", "no");
                                Hhan();
                              }}
                            >
                              <div className="genderRadioIcon"></div>
                              <p>No</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      {formik.errors.differentvenue &&
                        formik.touched.differentvenue && (
                          <div className="text-red position-static">
                            {" "}
                            {formik.errors.differentvenue}
                          </div>
                        )}
                    </div>
                    <div
                      className={
                        formik.values.differentvenue === "yes"
                          ? "form-group col-sm-12 col-md-12 differentVenue"
                          : "d-none"
                      }
                    >
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h5>Availabilty</h5>
                        </div>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-3">
                          <div className="table-responsive">
                            <table className="table storetable">
                              <tbody className="bg-transparent">
                                <tr>
                                  <th className="text-center">days</th>
                                  <th>from time</th>
                                  <th>to time</th>
                                </tr>
                                <FormikProvider value={formik}>
                                  <FieldArray name="ForOtherVenueWeekdays" />
                                </FormikProvider>
                                {formik.values?.ForOtherVenueWeekdays?.map(
                                  (week, i) => (
                                    <tr key={i}>
                                      <td>
                                        <input
                                          className="styled-checkbox"
                                          id={`other-${week?.weekday}`}
                                          type="checkbox"
                                          checked={week?.checked}
                                          name={`ForOtherVenueWeekdays[${i}].checked`}
                                          onChange={(e) => {
                                            let checked = e.target.checked;

                                            formik.setFieldValue(
                                              `ForOtherVenueWeekdays[${i}].checked`,
                                              checked
                                            );
                                          }}
                                        />
                                        <label
                                          htmlFor={`other-${week?.weekday}`}
                                        >
                                          {week?.weekday}
                                        </label>
                                      </td>
                                      <td>
                                        <TimePicker
                                          onChange={(val) => {
                                            formik.setFieldValue(
                                              `ForOtherVenueWeekdays[${i}].timeFrom`,
                                              val
                                            );
                                          }}
                                          clockIcon={null}
                                          disableClock={true}
                                          clearIcon={null}
                                          minutePlaceholder="MM"
                                          hourPlaceholder="HH"
                                          disabled={!week?.checked}
                                          value={week?.timeFrom || undefined}
                                          className={"form-control"}
                                          name={`ForOtherVenueWeekdays[${i}].timeFrom`}
                                        />
                                      </td>
                                      <td>
                                        <TimePicker
                                          onChange={(val) => {
                                            formik.setFieldValue(
                                              `ForOtherVenueWeekdays[${i}].timeTo`,
                                              val
                                            );
                                          }}
                                          clockIcon={null}
                                          disableClock={true}
                                          clearIcon={null}
                                          minutePlaceholder="MM"
                                          hourPlaceholder="HH"
                                          disabled={!week?.checked}
                                          value={week?.timeTo || undefined}
                                          className={"form-control"}
                                          name={`ForOtherVenueWeekdays[${i}].timeTo`}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          {formik.errors.ForOtherVenueWeekdays &&
                            formik.touched.ForOtherVenueWeekdays && (
                              <div className="text-red">
                                {" "}
                                {formik.errors.ForOtherVenueWeekdays}
                              </div>
                            )}
                        </div>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 d-none">
                          <h5>Location Range</h5>
                        </div>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 d-none">
                          <InputRange
                            maxValue={10}
                            minValue={0}
                            value={formik.values.locationRange}
                            name="locationRange"
                            onChange={(locationRange) =>
                              formik.setFieldValue(
                                "locationRange",
                                locationRange
                              )
                            }
                          />
                        </div>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-2 d-none">
                          <h5>Sports</h5>
                        </div>
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 d-none">
                          <ul className="slotTimeList custom-radio ps-0 sprortCheckImg">
                            <li>
                              <div className="radio-item h-100">
                                <input
                                  type="checkbox"
                                  value="badminton"
                                  name="otherVenuesports"
                                  id={`otherVenuesports-badminton`}
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    let arr = [
                                      ...formik.values.otherVenuesports,
                                    ];
                                    let index = arr.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        e.target.value?.toLowerCase()
                                    );
                                    if (checked) {
                                      if (index === -1) {
                                        arr.push({ sport: e.target.value });
                                      }
                                    } else if (!checked) {
                                      if (index !== -1) {
                                        arr.splice(index, 1);
                                      }
                                    }
                                    formik.setFormikState((prev) => {
                                      return {
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          otherVenuesports: [...arr],
                                        },
                                      };
                                    });
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik.values.otherVenuesports?.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() === "badminton"
                                    ) !== -1
                                  }
                                />
                                <label
                                  className="label-icon timeSlotAdd"
                                  htmlFor={`otherVenuesports-badminton`}
                                >
                                  <div>
                                    <img
                                      src="assets/img/badminton.png"
                                      alt=""
                                      className="unselect"
                                    />
                                  </div>
                                  badminton
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-item h-100">
                                <input
                                  type="checkbox"
                                  value="football"
                                  name="otherVenuesports"
                                  id="otherVenuesports-Football"
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    let arr = [
                                      ...formik.values.otherVenuesports,
                                    ];
                                    let index = arr.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        e.target.value?.toLowerCase()
                                    );
                                    if (checked) {
                                      if (index === -1) {
                                        arr.push({ sport: e.target.value });
                                      }
                                    } else if (!checked) {
                                      if (index !== -1) {
                                        arr.splice(index, 1);
                                      }
                                    }
                                    formik.setFormikState((prev) => {
                                      return {
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          otherVenuesports: [...arr],
                                        },
                                      };
                                    });
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik.values.otherVenuesports?.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() === "football"
                                    ) !== -1
                                  }
                                />
                                <label
                                  className="label-icon timeSlotAdd"
                                  htmlFor="otherVenuesports-Football"
                                >
                                  <div>
                                    <img
                                      src="assets/img/Footall.png"
                                      alt=""
                                      className="unselect"
                                    />
                                  </div>
                                  Football
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-item h-100">
                                <input
                                  type="checkbox"
                                  value="cricket"
                                  name="otherVenuesports"
                                  id="otherVenuesports-Cricket"
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    let arr = [
                                      ...formik.values.otherVenuesports,
                                    ];
                                    let index = arr.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        e.target.value?.toLowerCase()
                                    );
                                    if (checked) {
                                      if (index === -1) {
                                        arr.push({ sport: e.target.value });
                                      }
                                    } else if (!checked) {
                                      if (index !== -1) {
                                        arr.splice(index, 1);
                                      }
                                    }
                                    formik.setFormikState((prev) => {
                                      return {
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          otherVenuesports: [...arr],
                                        },
                                      };
                                    });
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik.values.otherVenuesports?.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() === "cricket"
                                    ) !== -1
                                  }
                                />
                                <label
                                  className="label-icon timeSlotAdd"
                                  htmlFor="otherVenuesports-Cricket"
                                >
                                  <div>
                                    <img
                                      src="assets/img/cricket_ball_icon.png"
                                      alt=""
                                      className="unselect"
                                    />
                                  </div>
                                  Cricket
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-item h-100">
                                <input
                                  type="checkbox"
                                  value="basketBall"
                                  name="otherVenuesports"
                                  id="otherVenuesports-BasketBall"
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    let arr = [
                                      ...formik.values.otherVenuesports,
                                    ];
                                    let index = arr.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        e.target.value?.toLowerCase()
                                    );
                                    if (checked) {
                                      if (index === -1) {
                                        arr.push({ sport: e.target.value });
                                      }
                                    } else if (!checked) {
                                      if (index !== -1) {
                                        arr.splice(index, 1);
                                      }
                                    }
                                    formik.setFormikState((prev) => {
                                      return {
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          otherVenuesports: [...arr],
                                        },
                                      };
                                    });
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik.values.otherVenuesports?.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        "basketball"
                                    ) !== -1
                                  }
                                />
                                <label
                                  className="label-icon timeSlotAdd"
                                  htmlFor="otherVenuesports-BasketBall"
                                >
                                  <div>
                                    <img
                                      src="assets/img/Basketball.png"
                                      alt=""
                                      className="unselect"
                                    />
                                  </div>
                                  Basket Ball
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-item h-100">
                                <input
                                  type="checkbox"
                                  value="table tennis"
                                  name="otherVenuesports"
                                  id="otherVenuesports-tabletennis"
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    let arr = [
                                      ...formik.values.otherVenuesports,
                                    ];
                                    let index = arr.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        e.target.value?.toLowerCase()
                                    );
                                    if (checked) {
                                      if (index === -1) {
                                        arr.push({ sport: e.target.value });
                                      }
                                    } else if (!checked) {
                                      if (index !== -1) {
                                        arr.splice(index, 1);
                                      }
                                    }
                                    formik.setFormikState((prev) => {
                                      return {
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          otherVenuesports: [...arr],
                                        },
                                      };
                                    });
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik.values.otherVenuesports?.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        "table tennis"
                                    ) !== -1
                                  }
                                />
                                <label
                                  className="label-icon timeSlotAdd"
                                  htmlFor="otherVenuesports-tabletennis"
                                >
                                  <div>
                                    <img
                                      src="assets/img/table_tennis.png"
                                      alt=""
                                      className="unselect"
                                    />
                                  </div>
                                  Table Tennis
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-item h-100">
                                <input
                                  type="checkbox"
                                  value="swimming"
                                  name="otherVenuesports"
                                  id="otherVenuesports-swimming"
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    let arr = [
                                      ...formik.values.otherVenuesports,
                                    ];
                                    let index = arr.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        e.target.value?.toLowerCase()
                                    );
                                    if (checked) {
                                      if (index === -1) {
                                        arr.push({ sport: e.target.value });
                                      }
                                    } else if (!checked) {
                                      if (index !== -1) {
                                        arr.splice(index, 1);
                                      }
                                    }
                                    formik.setFormikState((prev) => {
                                      return {
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          otherVenuesports: [...arr],
                                        },
                                      };
                                    });
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik.values.otherVenuesports?.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() === "swimming"
                                    ) !== -1
                                  }
                                />
                                <label
                                  className="label-icon timeSlotAdd"
                                  htmlFor="otherVenuesports-swimming"
                                >
                                  <div>
                                    <img
                                      src="assets/img/swimming.png"
                                      alt=""
                                      className="unselect"
                                    />
                                  </div>
                                  Swimming
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="radio-item h-100">
                                <input
                                  type="checkbox"
                                  value="lawn tennis"
                                  name="otherVenuesports"
                                  id="otherVenuesports-LawnTennis"
                                  onChange={(e) => {
                                    let checked = e.target.checked;
                                    let arr = [
                                      ...formik.values.otherVenuesports,
                                    ];
                                    let index = arr.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        e.target.value?.toLowerCase()
                                    );
                                    if (checked) {
                                      if (index === -1) {
                                        arr.push({ sport: e.target.value });
                                      }
                                    } else if (!checked) {
                                      if (index !== -1) {
                                        arr.splice(index, 1);
                                      }
                                    }
                                    formik.setFormikState((prev) => {
                                      return {
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          otherVenuesports: [...arr],
                                        },
                                      };
                                    });
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik.values.otherVenuesports?.findIndex(
                                      (it) =>
                                        it?.sport?.toLowerCase() ===
                                        "lawn tennis"
                                    ) !== -1
                                  }
                                />
                                <label
                                  className="label-icon timeSlotAdd"
                                  htmlFor="otherVenuesports-LawnTennis"
                                >
                                  <div>
                                    <img
                                      src="assets/img/lawntennis.png"
                                      alt=""
                                      className="unselect"
                                    />
                                  </div>
                                  Lawn Tennis
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center">
                      <button className="btn btn-submit">
                        {loading ? <Loader /> : "Save Changes"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default CoachEditProfile;
