import {
  getData,
  patchFormData,
  postDataURLIncoded,
  postFormData,
  postFormDataChekoutPayment,
  postFormDataNoToken,
  postRawData,
  putFormData,
} from "./index";

export const CoachVenueLoginApi = async (payload) => {
  return postFormData("auth/login", payload).then((data) => {
    return data;
  });
};
export const CoachVenueForgotPasswordApi = async (payload) => {
  return postDataURLIncoded("auth/forgotPassword", payload).then((data) => {
    return data;
  });
};
export const CoachVenueOTPVerificationApi = async (payload) => {
  return postDataURLIncoded("auth/verifyOtp", payload).then((data) => {
    return data;
  });
};
export const CoachVenueResendOTPApi = async (payload) => {
  return putFormData("auth/resendOtp", payload).then((data) => {
    return data;
  });
};
export const CoachVenueResetPasswordApi = async (payload) => {
  return patchFormData("auth/resetPassword", payload).then((data) => {
    return data;
  });
};
export const CoachVenueChangePasswordApi = async (payload) => {
  return patchFormData("auth/changePassword", payload).then((data) => {
    return data;
  });
};
export const VenueSignUpApi = async (payload) => {
  return postFormData("venueowner/register", payload).then((data) => {
    return data;
  });
};
export const CoachSignUpApi = async (payload) => {
  return postFormData("coach/register", payload).then((data) => {
    return data;
  });
};
export const VenueOwnerDetailApi = async (payload) => {
  return getData("venueowner/getDetails", payload).then((data) => {
    return data;
  });
};
export const CoachDetailApi = async (payload) => {
  return getData("coach/getDetails", payload).then((data) => {
    return data;
  });
};
export const SendContactUsApi = async (payload) => {
  return postFormDataNoToken("contactus/addContactUs", payload).then((data) => {
    return data;
  });
};
export const CoachVenueBookingListApi = async (payload) => {
  return getData("coach/getCoachingAppointment", payload).then((data) => {
    return data;
  });
};
export const CoachVenueBookingDetailApi = async (payload) => {
  return getData("coach/CoachingCenterAppointment", payload).then((data) => {
    return data;
  });
};
export const CoachNotificationListApi = async (payload) => {
  return getData("coach/getAllNotificationList", payload).then((data) => {
    return data;
  });
};
export const CoachNotificationCountApi = async (payload) => {
  return getData("coach/getNotificationCount", payload).then((data) => {
    return data;
  });
};
export const CoachPutReviewRatingApi = async (payload) => {
  return putFormData("coach/coachingRating", payload).then((data) => {
    return data;
  });
};

export const CoachAddBankDetailApi = async (payload) => {
  return postFormData("coach/addUserBankInfo", payload).then((data) => {
    return data;
  });
};
export const CoachBankDetailApi = async (payload) => {
  return getData("coach/getUserBankInfoDetails", payload).then((data) => {
    return data;
  });
};
export const CoachEditBankDetailApi = async (payload) => {
  return putFormData("coach/editUserBankInfo", payload).then((data) => {
    return data;
  });
};

export const CourtsAndTrufListApi = async (payload) => {
  return getData("venueowner/getAllCourtsAndTruf", payload).then((data) => {
    return data;
  });
};
export const CourtsAndTrufStatusApi = async (payload) => {
  return patchFormData(
    "venueowner/activateDeactivateCourtsAndTruf",
    payload
  ).then((data) => {
    return data;
  });
};
export const AddCourtsAndTrufApi = async (payload) => {
  return postFormData("venueowner/addCourtsAndTruf", payload).then((data) => {
    return data;
  });
};
export const CourtsAndTrufDetailApi = async (payload) => {
  return getData("venueowner/getCourtsAndTrufDetails", payload).then((data) => {
    return data;
  });
};
export const CourtsAndTrufEditApi = async (payload) => {
  return putFormData("venueowner/editCourtsAndTruf", payload).then((data) => {
    return data;
  });
};
export const VenueBookingListApi = async (payload) => {
  return getData(
    "venueowner/getAllEventBookingListForVenueOwner",
    payload
  ).then((data) => {
    return data;
  });
};
export const venueOwenerBookingApproRejectApi = async (payload) => {
  return putFormData("venueowner/acceptRejectEvent", payload).then((data) => {
    return data;
  });
};
export const CoachEditProfileApi = async (payload) => {
  return putFormData("coach/editProfile", payload).then((data) => {
    return data;
  });
};
export const VenueDashboardApi = async (payload) => {
  return getData("venueowner/venueDashboard", payload).then((data) => {
    return data;
  });
};
export const CoachEarningApi = async (payload) => {
  return getData("coach/getWallet", payload).then((data) => {
    return data;
  });
};
export const CoachEarningChartApi = async (payload) => {
  return getData("coach/coachEarning", payload).then((data) => {
    return data;
  });
};
export const CoacheRatingReviewApi = async (payload) => {
  return getData("coach/getCoacheRating", payload).then((data) => {
    return data;
  });
};
export const VenueOwnerEditProfileApi = async (payload) => {
  return putFormData("venueowner/editProfile", payload).then((data) => {
    return data;
  });
};
export const VenueOwnerBookingDetailApi = async (payload) => {
  return getData("venueowner/getEventDetails", payload).then((data) => {
    return data;
  });
};
export const VenueOwnerBookingCancelApi = async (payload) => {
  return putFormData("venueowner/acceptRejectEvent", payload).then((data) => {
    return data;
  });
};
export const VenueOwnerReviewRatingListApi = async (payload) => {
  return getData("venueowner/getVenueRating", payload).then((data) => {
    return data;
  });
};
export const VenueOwnerNotifiactionListApi = async (payload) => {
  return getData("venueowner/getAllNotificationList", payload).then((data) => {
    return data;
  });
};
export const VenueOwnerNotifiactionCountApi = async (payload) => {
  return getData("venueowner/getNotificationCount", payload).then((data) => {
    return data;
  });
};
export const VenueUpcomingEventBookingListApi = async (payload) => {
  return getData(
    "venueowner/getUpcomingEventBookingListForVenueOwner",
    payload
  ).then((data) => {
    return data;
  });
};
export const VenuePutRatingReviewApi = async (payload) => {
  return putFormData("venueowner/ratings", payload).then((data) => {
    return data;
  });
};
export const VenueEarningApi = async (payload) => {
  return getData("venueowner/getWallet", payload).then((data) => {
    return data;
  });
};

export const coachHelpAndSupportApi = async (payload) => {
  return postFormData("coach/addHelpAndSupport", payload).then((data) => {
    return data;
  });
};
export const VenueHelpAndSupportApi = async (payload) => {
  return postFormData("venueowner/addHelpAndSupport", payload).then((data) => {
    return data;
  });
};
export const VenueAddBankDetailApi = async (payload) => {
  return postFormData("venueowner/addUserBankInfo", payload).then((data) => {
    return data;
  });
};
export const VenueBankDetailApi = async (payload) => {
  return getData("venueowner/getUserBankInfoDetails", payload).then((data) => {
    return data;
  });
};
export const VenueEditBankDetailApi = async (payload) => {
  return putFormData("venueowner/editUserBankInfo", payload).then((data) => {
    return data;
  });
};


