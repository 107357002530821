import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MYEventBookingReducer } from "../slice";
import Paginations from "../../Components/Paginations";
import moment from "moment";
import EditBooking from "./EditBooking";
import CancelBookingModal from "../../Components/Modals/CancelBookingModal";
import Loader from "../../Components/Loader";

function MyBooking() {
  const dispatch = useDispatch();
  const [bookingTab, setBookingTab] = useState("upcoming");
  const [status, setStatus] = useState("0");
  const [page, setPage] = useState(1);
  const [modalName, setModalName] = useState("");
  const [id, setID] = useState("");
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  const handlePage = (value) => {
    setPage(value);
  };
  const onClose = () => {
    setVisible(false);
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const list = useSelector((state) => state.main.BookingList);
  const total = useSelector((state) => state.main.total_booking);

  const getBookingList = useCallback(async () => {
    setLoading(true);
    dispatch(
      MYEventBookingReducer({
        page: page,
        limit: 10,
        progress: bookingTab,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, bookingTab]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getBookingList();
    }
    return () => {
      mounted = false;
    };
  }, [getBookingList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">My Bookings</h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-center tabsnew"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/my-booking"
                      className="nav-link active text-capitalize"
                    >
                      venue Booking
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/coach-booking"
                      className="nav-link text-capitalize"
                    >
                      coach booking
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-start bookingTabs ndf tabsnew gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        bookingTab === "upcoming"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setBookingTab("upcoming");
                        setStatus("0");
                      }}
                    >
                      upcoming
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        bookingTab === "completed"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setBookingTab("completed");
                        setStatus("1");
                      }}
                    >
                      completed
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        bookingTab === "cancelled"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setBookingTab("cancelled");
                        setStatus("2");
                      }}
                    >
                      cancelled
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mb-3 text-end">
                {/* <div className="position-relative filterDrop">
                  <div className="filter position-static">
                    <img src="assets/img/filter.png" alt="" />
                  </div>
                </div> */}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                {loading ? (
                  <Loader colorChange="colorChange" />
                ) : (
                  <div className="tab-content mt-4" id="pills-tabContent">
                    {bookingTab === "upcoming" ? (
                      <div className="row">
                        {list?.length > 0 ? (
                          list?.map((item, i) => (
                            <div
                              className="col-sm-12 col-md-6 col-lg-4 mb-4"
                              key={i}
                            >
                              <div className="bookingBox bg-white rad shadow p-3 h-100">
                                <div className="row">
                                  <div className="col-sm-6 col-md-6 col-lg-6">
                                    <h5 className="text-capitalize mb-0">
                                      {item?.venue?.name || "NA"}
                                    </h5>
                                    <p className="text-capitalize mb-0">
                                      Sport: <b>{item?.sport || "NA"}</b>
                                    </p>
                                    <p className="text-capitalize mb-2">
                                      Court/Turf:{" "}
                                      <b>{item?.courts?.[0]?.name || "NA"}</b>
                                    </p>
                                    <p className="text-capitalize">
                                      Booking no:{" "}
                                      <b>{item?.bookingId || "NA"}</b>
                                    </p>
                                    {item?.fullBooking === false ? (
                                      <>
                                        <p
                                          className={
                                            item?.myAdded ? "mb-0" : ""
                                          }
                                        >
                                          Total People:{" "}
                                          <b>{item?.addPeople || "NA"}</b>
                                        </p>
                                        {item?.myAdded ? (
                                          <p className="">
                                            Added People:{" "}
                                            <b>{item?.myAdded || "NA"}</b>
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <b>Full Booking</b>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2 text-sm-end">
                                    <p className="mb-2">
                                      <a
                                        href={`https://www.google.com/maps/place/${item?.venue?.address}`}
                                        target="_blank"
                                        className="theme-color"
                                      >
                                        <img
                                          src="assets/img/direction_location.png"
                                          alt=""
                                        />{" "}
                                        Get Direction
                                      </a>
                                    </p>
                                    <p className="mb-0 timeDateText">
                                      On: {moment(item?.date).format("ll")}
                                    </p>
                                    <div className="mb-3 slotOverFlow">
                                      {item?.slot?.map((item) => (
                                        <p className="mb-0 timeDateText">
                                          {moment(
                                            item?.timeSlotStart,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}{" "}
                                          -{" "}
                                          {moment(
                                            item?.timeSlotEnd,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}
                                        </p>
                                      ))}
                                    </div>
                                    <span
                                      className={
                                        item?.status === "0"
                                          ? "StatusBtn pendingStatus"
                                          : item?.status === "1"
                                          ? "StatusBtn approvedStatus"
                                          : null
                                      }
                                    >
                                      {item?.status === "0"
                                        ? "Pending"
                                        : item?.status === "1"
                                        ? "Confirmed"
                                        : null}
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <p className="mb-0">Location</p>
                                    <p className="mb-0 fw-bold ellips1Line w-100">
                                      {item?.venue?.address
                                        ? `${item?.venue?.address}`
                                        : "NA"}
                                    </p>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <p className="mb-0 fw-bold text-capitalize">
                                      <img
                                        src={
                                          item?.createdBy?.picture?.includes(
                                            "http"
                                          )
                                            ? item?.createdBy?.picture
                                            : "assets/img/employe.png"
                                        }
                                        className="rounded-circle eventCreaterIcon"
                                        alt=""
                                      />{" "}
                                      {item?.createdBy?.firstName
                                        ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                        : "NA"}
                                    </p>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <p className="mb-0 theme-color fw-bold">
                                      <i className="fa fa-inr"></i>{" "}
                                      {item?.userTotal
                                        ? parseFloat(item?.userTotal).toFixed(2)
                                        : "0"}
                                      /-
                                    </p>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="d-flex justify-content-end gap-2">
                                      {/* <img
                                      src="assets/img/EditRed.png"
                                      alt=""
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setID(item?._id);
                                        setModalName("EditBooking");
                                        showDrawer();
                                      }}
                                    /> */}
                                      <img
                                        src="assets/img/delete.png"
                                        alt=""
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setID(item?._id);
                                          setModalName("CancelBooking");
                                          handleShow();
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">No Booking Found</div>
                        )}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : bookingTab === "completed" ? (
                      <div className="row">
                        {list?.length > 0 ? (
                          list?.map((item, i) => (
                            <div
                              className="col-sm-12 col-md-6 col-lg-4 mb-4"
                              key={i}
                            >
                              <div className="bookingBox bg-white rad shadow p-3 h-100">
                                <div className="row">
                                  <div className="col-sm-6 col-md-6 col-lg-6">
                                    <h5 className="text-capitalize mb-0">
                                      {item?.venue?.name || "NA"}
                                    </h5>
                                    <p className="text-capitalize mb-0">
                                      Sport: <b>{item?.sport || "NA"}</b>
                                    </p>
                                    <p className="text-capitalize mb-2">
                                      Court/Turf:{" "}
                                      <b>{item?.courts?.[0]?.name || "NA"}</b>
                                    </p>
                                    <p className="text-capitalize">
                                      Booking no:{" "}
                                      <b>{item?.bookingId || "NA"}</b>
                                    </p>
                                    {item?.fullBooking === false ? (
                                      <>
                                        <p
                                          className={
                                            item?.myAdded ? "mb-0" : ""
                                          }
                                        >
                                          Total People:{" "}
                                          <b>{item?.addPeople || "NA"}</b>
                                        </p>
                                        {item?.myAdded ? (
                                          <p className="">
                                            Added People:{" "}
                                            <b>{item?.myAdded || "NA"}</b>
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <b>Full Booking</b>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2 text-sm-end">
                                    <p className="mb-0 timeDateText">
                                      On: {moment(item?.date).format("ll")}
                                    </p>
                                    <div className="mb-3 slotOverFlow">
                                      {item?.slot?.map((item) => (
                                        <p className="mb-0 timeDateText">
                                          {moment(
                                            item?.timeSlotStart,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}{" "}
                                          -{" "}
                                          {moment(
                                            item?.timeSlotEnd,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}
                                        </p>
                                      ))}
                                    </div>
                                    <span className="StatusBtn approvedStatus">
                                      Completed
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <p className="mb-0">Location</p>
                                    <p className="mb-0 fw-bold ellips1Line w-100">
                                      {item?.venue?.address
                                        ? `${item?.venue?.address}`
                                        : "NA"}
                                    </p>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <p className="mb-0 fw-bold text-capitalize">
                                      <img
                                        src={
                                          item?.createdBy?.picture?.includes(
                                            "http"
                                          )
                                            ? item?.createdBy?.picture
                                            : "assets/img/employe.png"
                                        }
                                        className="rounded-circle eventCreaterIcon"
                                        alt=""
                                      />{" "}
                                      {item?.createdBy?.firstName
                                        ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                        : "NA"}
                                    </p>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <p className="mb-0 theme-color fw-bold">
                                      <i className="fa fa-inr"></i>{" "}
                                      {parseFloat(item?.userTotal).toFixed(2) ||
                                        "0"}
                                      /-
                                    </p>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="d-flex justify-content-end gap-2">
                                      <Link
                                        to={`/my-booking/detail/${item?._id}`}
                                        className="theme-color fw-bold"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "isCancelled",
                                            "false"
                                          );
                                        }}
                                      >
                                        <img
                                          src="assets/img/rate_review.png"
                                          alt=""
                                        />{" "}
                                        Review
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">No Booking Found</div>
                        )}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : bookingTab === "cancelled" ? (
                      <div className="row">
                        {list?.length > 0 ? (
                          list?.map((item, i) => (
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <div className="bookingBox bg-white rad shadow p-3 h-100">
                                <div className="row">
                                  <div className="col-sm-6 col-md-6 col-lg-6">
                                    <h5 className="text-capitalize mb-0">
                                      {item?.venue?.name || "NA"}
                                    </h5>
                                    <p className="text-capitalize mb-0">
                                      Sport: <b>{item?.sport || "NA"}</b>
                                    </p>
                                    <p className="text-capitalize mb-2">
                                      Court/Turf:{" "}
                                      <b>{item?.courts?.[0]?.name || "NA"}</b>
                                    </p>
                                    <p className="text-capitalize">
                                      Booking no:{" "}
                                      <b>{item?.bookingId || "NA" || "NA"}</b>
                                    </p>
                                    {item?.fullBooking === false ? (
                                      <>
                                        <p
                                          className={
                                            item?.myAdded ? "mb-0" : ""
                                          }
                                        >
                                          Total People:{" "}
                                          <b>{item?.addPeople || "NA"}</b>
                                        </p>
                                        {item?.myAdded ? (
                                          <p className="">
                                            Added People:{" "}
                                            <b>{item?.myAdded || "NA"}</b>
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <b>Full Booking</b>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 mb-2 text-sm-end">
                                    <p className="mb-0 timeDateText">
                                      On: {moment(item?.date).format("ll")}
                                    </p>
                                    <div className="mb-3 slotOverFlow">
                                      {item?.slot?.map((item) => (
                                        <p className="mb-0 timeDateText">
                                          {moment(
                                            item?.timeSlotStart,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}{" "}
                                          -{" "}
                                          {moment(
                                            item?.timeSlotEnd,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}
                                        </p>
                                      ))}
                                    </div>
                                    <span className="StatusBtn rejectedStatus">
                                      Cancelled
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <p className="mb-0">Location</p>
                                    <p className="mb-0 fw-bold ellips1Line w-100">
                                      {item?.venue?.address
                                        ? `${item?.venue?.address}`
                                        : "NA"}
                                    </p>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <p className="mb-0 fw-bold text-capitalize">
                                      <img
                                        src={
                                          item?.createdBy?.picture?.includes(
                                            "http"
                                          )
                                            ? item?.createdBy?.picture
                                            : "assets/img/employe.png"
                                        }
                                        className="rounded-circle eventCreaterIcon"
                                        alt=""
                                      />{" "}
                                      {item?.createdBy?.firstName
                                        ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                                        : "NA"}
                                    </p>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <p className="mb-0 theme-color fw-bold">
                                      <i className="fa fa-inr"></i>{" "}
                                      {parseFloat(item?.userTotal).toFixed(2) ||
                                        "0"}
                                      /-
                                    </p>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="d-flex justify-content-end gap-2">
                                      <span
                                        className={
                                          item?.refundStatus === "pending"
                                            ? "StatusBtn pendingStatus text-capitalize"
                                            : "StatusBtn approvedStatus text-capitalize"
                                        }
                                      >
                                        {item?.refundStatus}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">No Booking Found</div>
                        )}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      {visible && modalName === "EditBooking" && id && (
        <EditBooking
          onClose={onClose}
          visible={visible}
          getlist={getBookingList}
          showDrawer={showDrawer}
          id={id}
        />
      )}
      {modalName === "CancelBooking" && id && (
        <CancelBookingModal
          handleShow={handleShow}
          show={show}
          getList={getBookingList}
          id={id}
          forUser="userSide"
        />
      )}
    </>
  );
}

export default MyBooking;
