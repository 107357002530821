import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateFavCoachAPI } from "../../api/setting";
import Loader from "../../Components/Loader";
import ForgotPasswordModal from "../../Components/Modals/ForgotPasswordModal";
import LoginModal from "../../Components/Modals/LoginModal";
import LoginWithEmail from "../../Components/Modals/LoginWithEmail";
import OTPVerification from "../../Components/Modals/OTPVerification";
import ResetPassword from "../../Components/Modals/ResetPassword";
import SignUpModal from "../../Components/Modals/SignUpModal";
import SignUpWithMobileModal from "../../Components/Modals/SignUpWithMobileModal";
import Paginations from "../../Components/Paginations";
import {
  CoachingCenterReducer,
  CoachingCenterWithTokenReducer,
} from "../slice";

function Coaching() {
  const dispatch = useDispatch();
  const [sport, setSport] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [loading, setLoading] = useState(false);
  const handlePage = (value) => {
    setPage(value);
  };
  const handleShow = () => {
    setShow(!show);
  };

  const list = useSelector((state) => state.main.CoachingCenterList);
  const total_coaches = useSelector((state) => state.main.total_coaches);
  const token = localStorage.getItem("token");
  const loginType = localStorage.getItem("loginType");
  const liveLat = useSelector((state) => state.main.liveCoords?.lat);
  const liveLan = useSelector((state) => state.main.liveCoords?.lng);

  const getCoachingCenterList = useCallback(async () => {
    if (token) {
      setLoading(true);
      dispatch(
        CoachingCenterWithTokenReducer({
          sport: sport,
          page: page,
          limit: 12,
          search: search,
          isfev: filter,
          isActive: true,
          longitude: liveLan,
          latitude: liveLat,
        })
      ).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
      dispatch(
        CoachingCenterReducer({
          sport: sport,
          page: page,
          limit: 12,
          search: search,
          isfev: filter,
          isActive: true,
          longitude: liveLan,
          latitude: liveLat,
        })
      ).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, sport, page, search, filter, token, liveLan, liveLat]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCoachingCenterList();
    }
    return () => {
      mounted = false;
    };
  }, [getCoachingCenterList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const UpdateFav = (data) => {
    //console.log(data);
    UpdateFavCoachAPI(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getCoachingCenterList();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-5">Training by Professionals</h3>
                <div className="venuesGameFlex">
                  <div
                    className={
                      sport === "cricket"
                        ? "venueIcon sportActive"
                        : "venueIcon"
                    }
                    onClick={() => {
                      setSport(
                        sport === ""
                          ? "cricket"
                          : sport === "football"
                          ? "cricket"
                          : sport === "basketball"
                          ? "cricket"
                          : sport === "table tennis"
                          ? "cricket"
                          : sport === "badminton"
                          ? "cricket"
                          : sport === "swimming"
                          ? "cricket"
                          : sport === "lawn tennis"
                          ? "cricket"
                          : ""
                      );
                      setPage(1);
                    }}
                  >
                    <img
                      src={
                        sport === "cricket"
                          ? "assets/img/cricket_ball_icon_white.png"
                          : "assets/img/cricket_ball_icon.png"
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      sport === "football"
                        ? "venueIcon sportActive"
                        : "venueIcon"
                    }
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "football"
                          : sport === ""
                          ? "football"
                          : sport === "basketball"
                          ? "football"
                          : sport === "table tennis"
                          ? "football"
                          : sport === "badminton"
                          ? "football"
                          : sport === "swimming"
                          ? "football"
                          : sport === "lawn tennis"
                          ? "football"
                          : ""
                      );
                      setPage(1);
                    }}
                  >
                    <img
                      src={
                        sport === "football"
                          ? "assets/img/Footall_white.png"
                          : "assets/img/Footall.png"
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      sport === "swimming"
                        ? "venueIcon sportActive"
                        : "venueIcon"
                    }
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "swimming"
                          : sport === "football"
                          ? "swimming"
                          : sport === ""
                          ? "swimming"
                          : sport === "table tennis"
                          ? "swimming"
                          : sport === "badminton"
                          ? "swimming"
                          : sport === "basketball"
                          ? "swimming"
                          : sport === "lawn tennis"
                          ? "swimming"
                          : ""
                      );
                      setPage(1);
                    }}
                  >
                    <img
                      src={
                        sport === "swimming"
                          ? "assets/img/swimming_white.png"
                          : "assets/img/swimming.png"
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      sport === "lawn tennis"
                        ? "venueIcon sportActive"
                        : "venueIcon"
                    }
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "lawn tennis"
                          : sport === "football"
                          ? "lawn tennis"
                          : sport === "basketball"
                          ? "lawn tennis"
                          : sport === ""
                          ? "lawn tennis"
                          : sport === "badminton"
                          ? "lawn tennis"
                          : sport === "swimming"
                          ? "lawn tennis"
                          : sport === "table tennis"
                          ? "lawn tennis"
                          : ""
                      );
                      setPage(1);
                    }}
                  >
                    <img
                      src={
                        sport === "lawn tennis"
                          ? "assets/img/lawntennis_white.png"
                          : "assets/img/lawntennis.png"
                      }
                      alt=""
                    />
                  </div>
                  {/* <div
                    className={
                      sport === "basketball"
                        ? "venueIcon sportActive"
                        : "venueIcon"
                    }
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "basketball"
                          : sport === "football"
                          ? "basketball"
                          : sport === ""
                          ? "basketball"
                          : sport === "table tennis"
                          ? "basketball"
                          : sport === "badminton"
                          ? "basketball"
                          :  sport === "swimming"
                          ? "basketball" : sport === "lawn tennis"
                          ? "basketball" :""
                      );
                      setPage(1);
                    }}
                  >
                    <img
                      src={
                        sport === "basketball"
                          ? "assets/img/Basketball_white.png"
                          : "assets/img/Basketball.png"
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      sport === "table tennis"
                        ? "venueIcon sportActive"
                        : "venueIcon"
                    }
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "table tennis"
                          : sport === "football"
                          ? "table tennis"
                          : sport === "basketball"
                          ? "table tennis"
                          : sport === ""
                          ? "table tennis"
                          : sport === "badminton"
                          ? "table tennis"
                          :  sport === "swimming"
                          ? "table tennis" : sport === "lawn tennis"
                          ? "table tennis" :""
                      );
                      setPage(1);
                    }}
                  >
                    <img
                      src={
                        sport === "table tennis"
                          ? "assets/img/table_tennis_white.png"
                          : "assets/img/table_tennis.png"
                      }
                      alt=""
                    />
                  </div> */}
                  <div
                    className={
                      sport === "badminton"
                        ? "venueIcon sportActive"
                        : "venueIcon"
                    }
                    onClick={() => {
                      setSport(
                        sport === "cricket"
                          ? "badminton"
                          : sport === "football"
                          ? "badminton"
                          : sport === "basketball"
                          ? "badminton"
                          : sport === "table tennis"
                          ? "badminton"
                          : sport === ""
                          ? "badminton"
                          : sport === "swimming"
                          ? "badminton"
                          : sport === "lawn tennis"
                          ? "badminton"
                          : ""
                      );
                      setPage(1);
                    }}
                  >
                    <img
                      src={
                        sport === "badminton"
                          ? "assets/img/badminton_white.png"
                          : "assets/img/badminton.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <form className="searchVenues">
                  <input
                    className="form-control"
                    placeholder="Search Professionals"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <span
                    className="btn btn-submit"
                    onClick={() => {
                      getCoachingCenterList();
                    }}
                  >
                    Search
                  </span>
                </form>
                <div
                  className={loginType === "user" ? "mb-3 text-end" : "d-none"}
                >
                  <div className="position-relative filterDrop">
                    <div
                      className={
                        filter === ""
                          ? "filter position-static"
                          : "filter FilterFill position-static"
                      }
                    >
                      <img
                        src={
                          filter === ""
                            ? "assets/img/filter.png"
                            : "assets/img/filter_white.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="filterDropSelct">
                      <span className="me-2 fw-bold">Filter By:</span>
                      <select
                        onChange={(e) => {
                          setFilter(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="1">My favourite</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <Loader colorChange="colorChange" />
            ) : (
              <>
                <div className="row">
                  {sport === "" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.sports?.[0]?.coverImage?.includes(
                                        "http"
                                      )
                                        ? item?.sports?.[0]?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sports[0]?._id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.sports?.[0]?.coverImage?.includes(
                                        "http"
                                      )
                                        ? item?.sports?.[0]?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}

                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark ellips1Line w-100">
                                    {item?.sports?.length > 0
                                      ? item?.sports
                                          ?.map((spt) => {
                                            return spt?.sport || "NA";
                                          })
                                          ?.join(", ")
                                      : "NA"}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sports[0]?._id
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark ellips1Line w-100">
                                    {item?.sports?.length > 0
                                      ? item?.sports
                                          ?.map((spt) => {
                                            return `${spt?.sport}`;
                                          })
                                          ?.join(", ")
                                      : "NA"}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : sport === "cricket" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}
                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : sport === "football" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}
                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : sport === "basketball" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}
                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : sport === "table tennis" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}
                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "table tennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "table tennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : sport === "badminton" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}
                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : sport === "swimming" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}
                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : sport === "lawn tennis" ? (
                    list?.length > 0 ? (
                      list?.map((item, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-3"
                          key={i}
                        >
                          <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                            <div className="position-relative">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      item?.coverImage?.includes("http")
                                        ? item?.coverImage
                                        : "assets/img/dummyImage.png"
                                    }
                                    className="img-fluid Coachbigimg"
                                    alt=""
                                  />
                                </Link>
                              )}
                              <div className="venueBoxIcons">
                                <span className="votesRatingBg">
                                  {item?.rating
                                    ? parseFloat(item?.rating).toFixed(1)
                                    : "0"}{" "}
                                  <img
                                    src="assets/img/star.png"
                                    alt=""
                                    className="start"
                                  />
                                </span>{" "}
                                {item?.review || "0"}{" "}
                                {item?.review <= 1 ? "vote" : "votes"}
                              </div>
                              <img
                                src={
                                  item?.isFev === true
                                    ? "assets/img/Favoriteselected.png"
                                    : "assets/img/Favoriteunselected.png"
                                }
                                alt=""
                                className={
                                  loginType === "user" ? "homeFav" : "d-none"
                                }
                                onClick={() => {
                                  UpdateFav({
                                    coachId: item?._id,
                                    isfev: item?.isFev === true ? false : true,
                                  });
                                }}
                              />
                            </div>

                            <div className="text-start mt-3">
                              {loginType === "venue" ||
                              loginType === "coach" ? (
                                <span
                                  className="text-dark text-capitalize cursor-pointer"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                    handleShow();
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </span>
                              ) : (
                                <Link
                                  to={`/coaching/detail/${item._id}`}
                                  className="text-dark"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "detailSport",
                                      item?.sportId
                                    );
                                  }}
                                >
                                  <h5
                                    className="ellips1Line w-100 text-dark fw-bold"
                                    title={
                                      item?.coachingCenterName
                                        ? item?.coachingCenterName
                                        : item?.name
                                    }
                                  >
                                    {item?.coachingCenterName
                                      ? item?.coachingCenterName
                                      : item?.name}
                                  </h5>
                                  <p className="text-dark">
                                    <img
                                      src={
                                        item?.sport === "cricket"
                                          ? "assets/img/cricket_ball_icon.png"
                                          : item?.sport === "football"
                                          ? "assets/img/Footall.png"
                                          : item?.sport === "basketball"
                                          ? "assets/img/Basketball.png"
                                          : item?.sport === "tableTennis"
                                          ? "assets/img/table_tennis.png"
                                          : item?.sport === "badminton"
                                          ? "assets/img/badminton.png"
                                          : null
                                      }
                                      alt=""
                                      className="venueSportIcon"
                                      width="18px"
                                    />{" "}
                                    {item?.sport}
                                  </p>
                                </Link>
                              )}

                              <div className="eventCreater">
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />
                                <span>
                                  <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                    {item?.name || "NA"}
                                  </p>
                                </span>
                              </div>
                              <p
                                className="mb-0 ellips1Line w-100 text-dark"
                                title={item?.address}
                              >
                                {item?.address || "NA"}
                              </p>
                              <div className="d-flex justify-content-end align-items-center gap-2 mb-2">
                                <a
                                  href={`https://www.google.com/maps/place/${item?.address}`}
                                  target="_blank"
                                  className="clubKMS box-shadow"
                                >
                                  <img
                                    src="assets/img/map_pins_icon.png"
                                    alt=""
                                    className="map_pins_icon"
                                  />
                                  {item?.dist?.calculated
                                    ? parseFloat(
                                        item?.dist?.calculated
                                      ).toFixed(2)
                                    : "0"}{" "}
                                  Km
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center">No Professionals Found</div>
                    )
                  ) : null}
                </div>
                <div className="text-center">
                  {list?.length > 0 && (
                    <Paginations
                      handlePage={handlePage}
                      page={page}
                      total={total_coaches && total_coaches}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </section>
      </main>
      {modalName === "LoginModal" && (
        <LoginModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "LoginWithEmail" && (
        <LoginWithEmail
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpModal" && (
        <SignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpWithMobile" && (
        <SignUpWithMobileModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ForgotPassword" && (
        <ForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "OTPVerification" && (
        <OTPVerification
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ResetPassword" && (
        <ResetPassword
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
    </>
  );
}

export default Coaching;
