import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CourtsAndTrufDetailReducer } from "../features/CoachVenueslice";

function CourtTurfDetailModal({ show, handleShow, id }) {
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.CoachVenueMain.CourtsTrufsDetail);

  const getDetail = useCallback(async () => {
    dispatch(
      CourtsAndTrufDetailReducer({
        courtandtrufId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getDetail]);
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="form-style bg-white p-3 mb-3">
                  <h4 className="mb-4 text-center">Court/Turf Detail</h4>
                  <div className="row  text-capitalize">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="d-flex align-items-center gap-3 text-capitalize">
                        <img
                          src={
                            detail?.image?.[0]?.image?.includes("http")
                              ? detail?.image?.[0]?.image
                              : "assets/img/employe.png"
                          }
                          className="CurriculumImges"
                          alt=""
                        />
                        <span>
                          <p className="mb-0">Court/Turf name</p>
                          <b>{detail?.name || "NA"}</b>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/TypeOfCoaching.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">sports</p>
                        <h6 className="mb-1">{detail?.sport || "NA"}</h6>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/minCapacity.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Min Capacity</p>
                        <h6 className="mb-1">{detail?.minCapacity || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/maxCapacity.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Max Capacity</p>
                        <h6 className="mb-1">{detail?.maxCapacity || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/amountpaid.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">cost Per Slot</p>
                        <h6 className="mb-1">
                          <i className="fa fa-inr"></i>{" "}
                          {detail?.costPerSlot || "NA"}/-
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/Description.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Description</p>
                        <h6 className="mb-1">{detail?.description || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/rulesandregulations.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Rules and regulations</p>
                        <div
                          className="rulePTage fw-bold"
                          dangerouslySetInnerHTML={{ __html: detail?.rules }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mt-3 position-relative ps-4">
                        <h6 className="fw-bold">Availabilty</h6>
                        <div className="table-responsive">
                          <table className="table storetable">
                            <tbody className="bg-transparent">
                              <tr>
                                <th>Week Days</th>
                                <th>from time</th>
                                <th>to time</th>
                              </tr>

                              {detail?.days?.map((week, i) => (
                                <tr key={i}>
                                  <td>
                                    {week?.weekday === 1
                                      ? "monday"
                                      : week?.weekday === 2
                                      ? "tuesday"
                                      : week?.weekday === 3
                                      ? "wednesday"
                                      : week?.weekday === 4
                                      ? "thursday"
                                      : week?.weekday === 5
                                      ? "friday"
                                      : week?.weekday === 6
                                      ? "saturday"
                                      : week?.weekday === 0
                                      ? "sunday"
                                      : null}
                                  </td>
                                  <td>
                                    {moment(week?.startTime, "hh:mm").format(
                                      "hh:mm A"
                                    )}
                                  </td>
                                  <td>
                                    {moment(week?.endTime, "hh:mm").format(
                                      "hh:mm A"
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="btn btn-submit w-50" onClick={handleShow}>
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CourtTurfDetailModal;
