import { Tree } from "antd";
import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CoachingCenterRatingReview from "../../Components/CoachingCenterRatingReview";
import Loader from "../../Components/Loader";
import Paginations from "../../Components/Paginations";
import { CoachBookingListReducer } from "../slice";

function CoachBooking() {
  const dispatch = useDispatch();
  const [bookingTab, setBookingTab] = useState("upcoming");
  const [status, setStatus] = useState("0");
  const [coacingId, setCoacingId] = useState("");
  const [modalName, setModalName] = useState("");
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [userFeedback, setUserFeedback] = useState("");
  const [userRating, setUserRating] = useState();
  const [loading, setLoading] = useState(false);
  const handlePage = (value) => {
    setPage(value);
  };
  const handleShow = () => {
    setShow(!show);
  };

  const list = useSelector((state) => state.main.CoachBookingList);
  const total = useSelector((state) => state.main.total_CoachBookingList);

  const getCoachList = useCallback(async () => {
    setLoading(true);
    dispatch(
      CoachBookingListReducer({
        progress: bookingTab,
        page: page,
        limit: 10,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, bookingTab, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCoachList();
    }
    return () => {
      mounted = false;
    };
  }, [getCoachList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">My Bookings</h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-center ndf tabsnew"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Link to="/my-booking" className="nav-link text-capitalize">
                      venue Booking
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/coach-booking"
                      className="nav-link active text-capitalize"
                    >
                      coach booking
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-start bookingTabs tabsnew gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        bookingTab === "upcoming"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setBookingTab("upcoming");
                        setStatus("0");
                      }}
                    >
                      upcoming
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        bookingTab === "onGoing"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setBookingTab("onGoing");
                        setStatus("1");
                      }}
                    >
                      ongoing
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        bookingTab === "completed"
                          ? "nav-link active text-capitalize"
                          : "nav-link text-capitalize"
                      }
                      type="button"
                      onClick={() => {
                        setBookingTab("completed");
                        setStatus("2");
                      }}
                    >
                      Completed
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mb-3 text-end">
                {/* <div className="position-relative filterDrop">
                  <div className="filter position-static">
                    <img src="assets/img/filter.png" alt="" />
                  </div>
                </div> */}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                {loading ? (
                  <Loader colorChange="colorChange" />
                ) : (
                  <div className="tab-content mt-4" id="pills-tabContent">
                    {bookingTab === "upcoming" ? (
                      <div className="row">
                        {list?.length > 0 ? (
                          list?.map((item, i) => (
                            <div
                              className="col-sm-12 col-md-6 col-lg-4 mb-4"
                              key={i}
                            >
                              <div className="bookingBox bg-white rad shadow p-3">
                                <div className="row">
                                  <div className="col-sm-6 col-md-6 col-lg-7">
                                    <div className="coacFlex gap-2 text-capitalize">
                                      <img
                                        src={
                                          item?.coaches?.image?.includes("http")
                                            ? item?.coaches?.image
                                            : "assets/img/employe.png"
                                        }
                                        className="coachImgCenetr"
                                        alt=""
                                      />
                                      <span>
                                        <h6 className="mb-0">
                                          {item?.coaches?.coachingCenterName
                                            ? item?.coaches?.coachingCenterName
                                            : item?.coaches?.name}
                                        </h6>
                                        <small className="mb-0 ">
                                          Experience: {item?.exprience || "0"}
                                        </small>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-5 mt-2 mt-sm-0 text-sm-end">
                                    <p className="mb-0 timeDateText">
                                      {moment(item?.date, "DD/MM/YYYY").format(
                                        "DD MMM YY"
                                      )}{" "}
                                      -{" "}
                                      {moment(
                                        item?.endDate,
                                        "DD/MM/YYYY"
                                      ).format("DD MMM YY")}
                                    </p>
                                    <p className="mb-3 timeDateText">
                                      {moment(item?.timeing, "hh:mm:ss").format(
                                        "hh:mm a"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-7">
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        Sports : <b>{item?.sport || "NA"}</b>
                                      </small>
                                    </p>
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        duration :{" "}
                                        <b>{item?.duration || "0"} Months</b>
                                      </small>
                                    </p>
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        Achievement:{" "}
                                        <b>{item?.achievements || "NA"}</b>
                                      </small>
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-5 my-3 text-sm-end">
                                    <span className="StatusBtn approvedStatus">
                                      Confirmed
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p className="mb-0">Location</p>
                                    <p className="mb-2 fw-bold ellips1Line w-100">
                                      <small>
                                        {item?.coaches?.address || "NA"}
                                      </small>
                                    </p>
                                    <p className="mb-0 theme-color fw-bold">
                                      {item?.isTrail === true ? (
                                        ""
                                      ) : (
                                        <i className="fa fa-inr"></i>
                                      )}
                                      {item?.isTrail === true
                                        ? "Free Trial"
                                        : `${parseFloat(item?.total).toFixed(
                                            2
                                          )}/-`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            No Appointment Found
                          </div>
                        )}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : bookingTab === "onGoing" ? (
                      <div className="row">
                        {list?.length > 0 ? (
                          list?.map((item, i) => (
                            <div
                              className="col-sm-12 col-md-6 col-lg-4 mb-4"
                              key={i}
                            >
                              <div className="bookingBox bg-white rad shadow p-3">
                                <div className="row">
                                  <div className="col-sm-6 col-md-6 col-lg-7">
                                    <div className="coacFlex gap-2 text-capitalize">
                                      <img
                                        src={
                                          item?.coaches?.image?.includes("http")
                                            ? item?.coaches?.image
                                            : "assets/img/employe.png"
                                        }
                                        className="coachImgCenetr"
                                        alt=""
                                      />
                                      <span>
                                        <h6 className="mb-0">
                                          {item?.coaches?.coachingCenterName
                                            ? item?.coaches?.coachingCenterName
                                            : item?.coaches?.name}
                                        </h6>
                                        <small className="mb-0 ">
                                          Experience: {item?.exprience || "0"}
                                        </small>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-5 mt-2 mt-sm-0 text-sm-end">
                                    <p className="mb-0 timeDateText">
                                      {moment(item?.date, "DD/MM/YYYY").format(
                                        "DD MMM YY"
                                      )}{" "}
                                      -{" "}
                                      {moment(
                                        item?.endDate,
                                        "DD/MM/YYYY"
                                      ).format("DD MMM YY")}
                                    </p>
                                    <p className="mb-3 timeDateText">
                                      {moment(item?.timeing, "hh:mm:ss").format(
                                        "hh:mm a"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-7">
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        Sports : <b>{item?.sport || "NA"}</b>
                                      </small>
                                    </p>
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        duration :{" "}
                                        <b>{item?.duration || "0"} Months</b>
                                      </small>
                                    </p>
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        Achievement:{" "}
                                        <b>{item?.achievements || "NA"}</b>
                                      </small>
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-5 my-3 text-sm-end">
                                    <span className="StatusBtn approvedStatus">
                                      Ongoing
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p className="mb-0">Location</p>
                                    <p className="mb-2 fw-bold ellips1Line w-100">
                                      <small>
                                        {item?.coaches?.address || "NA"}
                                      </small>
                                    </p>
                                    <p className="mb-0 theme-color fw-bold">
                                      {item?.isTrail === true ? (
                                        ""
                                      ) : (
                                        <i className="fa fa-inr"></i>
                                      )}
                                      {item?.isTrail === true
                                        ? "Free Trial"
                                        : `${parseFloat(item?.total).toFixed(
                                            2
                                          )}/-`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            No Appointment Found
                          </div>
                        )}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : bookingTab === "completed" ? (
                      <div className="row">
                        {list?.length > 0 ? (
                          list?.map((item, i) => (
                            <div
                              className="col-sm-12 col-md-6 col-lg-4 mb-4"
                              key={i}
                            >
                              <div className="bookingBox bg-white rad shadow p-3">
                                <div className="row">
                                  <div className="col-sm-6 col-md-6 col-lg-7">
                                    <div className="coacFlex gap-2 text-capitalize">
                                      <img
                                        src={
                                          item?.coaches?.image?.includes("http")
                                            ? item?.coaches?.image
                                            : "assets/img/employe.png"
                                        }
                                        className="coachImgCenetr"
                                        alt=""
                                      />
                                      <span>
                                        <h6 className="mb-0">
                                          {item?.coaches?.coachingCenterName
                                            ? item?.coaches?.coachingCenterName
                                            : item?.coaches?.name}
                                        </h6>
                                        <small className="mb-0 ">
                                          Experience: {item?.exprience || "0"}
                                        </small>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-5 mt-2 mt-sm-0 text-sm-end">
                                    <p className="mb-0 timeDateText">
                                      {moment(item?.date, "DD/MM/YYYY").format(
                                        "DD MMM YY"
                                      )}{" "}
                                      -{" "}
                                      {moment(
                                        item?.endDate,
                                        "DD/MM/YYYY"
                                      ).format("DD MMM YY")}
                                    </p>
                                    <p className="mb-3 timeDateText">
                                      {moment(item?.timeing, "hh:mm:ss").format(
                                        "hh:mm a"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-7">
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        Sports : <b>{item?.sport || "NA"}</b>
                                      </small>
                                    </p>
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        duration :{" "}
                                        <b>{item?.duration || "0"} Months</b>
                                      </small>
                                    </p>
                                    <p className="mb-0 text-capitalize">
                                      <small>
                                        Achievement:{" "}
                                        <b>{item?.achievements || "NA"}</b>
                                      </small>
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-5 my-3 text-sm-end">
                                    <span className="StatusBtn approvedStatus">
                                      Completed
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p className="mb-0">Location</p>
                                    <p className="mb-2 fw-bold ellips1Line w-100">
                                      <small>
                                        {item?.coaches?.address || "NA"}
                                      </small>
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6">
                                    <p className="mb-0 theme-color fw-bold">
                                      {item?.isTrail === true ? (
                                        ""
                                      ) : (
                                        <i className="fa fa-inr"></i>
                                      )}
                                      {item?.isTrail === true
                                        ? "Free Trial"
                                        : `${parseFloat(item?.total).toFixed(
                                            2
                                          )}/-`}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="d-flex justify-content-end gap-2">
                                      <span
                                        className="theme-color fw-bold cursor-pointer"
                                        onClick={() => {
                                          setCoacingId(item?._id);
                                          setModalName("CochingCenterReview");
                                          setUserRating(item?.rating);
                                          setUserFeedback(item?.userFeedback);
                                          handleShow();
                                        }}
                                      >
                                        <img
                                          src="assets/img/rate_review.png"
                                          alt=""
                                        />{" "}
                                        Review
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            No Appointment Found
                          </div>
                        )}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {list?.length > 0 && (
                            <Paginations
                              handlePage={handlePage}
                              page={page}
                              total={total && total}
                            />
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      {modalName === "CochingCenterReview" && coacingId && (
        <CoachingCenterRatingReview
          show={show}
          handleShow={handleShow}
          getList={getCoachList}
          id={coacingId}
          userRating={userRating}
          userFeedback={userFeedback}
        />
      )}
    </>
  );
}

export default CoachBooking;
