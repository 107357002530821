import { get } from "jquery";
import {
  getData,
  getDataAndDownload,
  getDataNoToken,
  patchFormData,
  postDataURLIncoded,
  postFormData,
  postFormDataChekoutPayment,
  postFormDataNoToken,
  putFormData,
} from "./index";

export const LoginApi = async (payload) => {
  return postFormData("auth/login", payload).then((data) => {
    return data;
  });
};
export const SignUpApi = async (payload) => {
  return postFormData("normaluser/register", payload).then((data) => {
    return data;
  });
};
export const ForgotPasswordApi = async (payload) => {
  return postDataURLIncoded("auth/forgotPassword", payload).then((data) => {
    return data;
  });
};
export const OTPVerificationApi = async (payload) => {
  return postDataURLIncoded("auth/verifyOtp", payload).then((data) => {
    return data;
  });
};
export const ResendOTPApi = async (payload) => {
  return putFormData("auth/resendOtp", payload).then((data) => {
    return data;
  });
};
export const ResetPasswordApi = async (payload) => {
  return patchFormData("auth/resetPassword", payload).then((data) => {
    return data;
  });
};
export const ChangePasswordApi = async (payload) => {
  return patchFormData("auth/changePassword", payload).then((data) => {
    return data;
  });
};
export const SocialLogingAPI = async (payload) => {
  return postFormData("auth/login/social", payload).then((data) => {
    return data;
  });
};
export const UserDetailAPI = async (payload) => {
  return getData("normaluser/getDetails", payload).then((data) => {
    return data;
  });
};
export const VanueListAPI = async (payload) => {
  return getDataNoToken("findpartner/venue", payload).then((data) => {
    return data;
  });
};
export const VanueListWithTokenAPI = async (payload) => {
  return getData("findpartner/venue", payload).then((data) => {
    return data;
  });
};
export const SlotListAPI = async (payload) => {
  return getData("findpartner/getSlote", payload).then((data) => {
    return data;
  });
};

export const CoachingListAPI = async (payload) => {
  return getDataNoToken("normaluser/getCoachingCenter", payload).then(
    (data) => {
      return data;
    }
  );
};
export const CoachingListWithTokenAPI = async (payload) => {
  return getData("normaluser/getCoachingCenter", payload).then((data) => {
    return data;
  });
};
export const CoachingDetailAPI = async (payload) => {
  return getDataNoToken("normaluser/CoachingCenterDetails", payload).then(
    (data) => {
      return data;
    }
  );
};
export const CoachingDetailWithTokenAPI = async (payload) => {
  return getData("normaluser/CoachingCenterDetails", payload).then((data) => {
    return data;
  });
};
export const CoachingSlotAPI = async (payload) => {
  return getData("normaluser/coachinggetSlote", payload).then((data) => {
    return data;
  });
};

export const MyFavouriteVenueListAPI = async (payload) => {
  return getData("normaluser/getFevVenue", payload).then((data) => {
    return data;
  });
};
export const MyFavouriteCoachListAPI = async (payload) => {
  return getData("normaluser/getFevCoach", payload).then((data) => {
    return data;
  });
};
export const MyFavouriteVenueDetailAPI = async (payload) => {
  return getDataNoToken("normaluser/getDetailsWithOutToken", payload).then(
    (data) => {
      return data;
    }
  );
};
export const MyFavouriteVenueDetailWithTokenAPI = async (payload) => {
  return getData("normaluser/getVenueDetails", payload).then((data) => {
    return data;
  });
};
export const UpdateFavVenueAPI = async (payload) => {
  return postFormData("normaluser/addFevVenue", payload).then((data) => {
    return data;
  });
};
export const EditProfilePApi = async (payload) => {
  return putFormData("normaluser/editProfile", payload).then((data) => {
    return data;
  });
};
export const SportsListAPI = async (payload) => {
  return getData("programs/getAllProgramList", payload).then((data) => {
    return data;
  });
};
export const CouponListAPI = async (payload) => {
  return getData("normaluser/getAllCouponCodeList", payload).then((data) => {
    return data;
  });
};
export const CourtListAPI = async (payload) => {
  return getData("normaluser/getAllCourtsAndTruf", payload).then((data) => {
    return data;
  });
};
export const CourtDetailAPI = async (payload) => {
  return getData("normaluser/getCourtsAndTrufDetails", payload).then((data) => {
    return data;
  });
};
export const CreateVenueEventAPI = async (payload) => {
  return postFormData("normaluser/addEventBooking", payload).then((data) => {
    return data;
  });
};
export const MYEventBookingApi = async (payload) => {
  return getData("normaluser/getMYEventBookingList", payload).then((data) => {
    return data;
  });
};
export const CalculatePriceApi = async (payload) => {
  return getData("normaluser/calculatePrice", payload).then((data) => {
    return data;
  });
};
export const AllReadyCreatedEventdetailApi = async (payload) => {
  return getData("normaluser/getEventBookingDetails", payload).then((data) => {
    return data;
  });
};
export const JoinCreatedEventdetailApi = async (payload) => {
  return getData("normaluser/getEventBookingDetail", payload).then((data) => {
    return data;
  });
};
export const AllReadyCreatedEventdetailWithOutTokenApi = async (payload) => {
  return getData("admin/getEventDetails", payload).then((data) => {
    return data;
  });
};
export const CoachBookingListApi = async (payload) => {
  return getData("normaluser/getCoachingAppointment", payload).then((data) => {
    return data;
  });
};
export const UpdateFavCoachAPI = async (payload) => {
  return postFormData("normaluser/addFevCoach", payload).then((data) => {
    return data;
  });
};
export const BookTrialSessionAPI = async (payload) => {
  return postFormData("normaluser/addCoachingTrailAppointment", payload).then(
    (data) => {
      return data;
    }
  );
};
export const BookCoachAPI = async (payload) => {
  return postFormData("normaluser/addCoachingAppointment", payload).then(
    (data) => {
      return data;
    }
  );
};
export const MyAccountAPI = async (payload) => {
  return getData("normaluser/getWallet", payload).then((data) => {
    return data;
  });
};
export const ReviewRatingAPI = async (payload) => {
  return getData("normaluser/getUserRating", payload).then((data) => {
    return data;
  });
};
export const HomePageDataCoachesAPI = async (payload) => {
  return getDataNoToken("coach/homeScreen", payload).then((data) => {
    return data;
  });
};
export const HomePageDataVenueAPI = async (payload) => {
  return getDataNoToken("coach/homeScreen", payload).then((data) => {
    return data;
  });
};
export const HomePageDataAPI = async (payload) => {
  return getDataNoToken("coach/homeScreen", payload).then((data) => {
    return data;
  });
};
export const HomePageDataCoachesAPIWithToken = async (payload) => {
  return getData("normaluser/homeScreen", payload).then((data) => {
    return data;
  });
};
export const HomePageDataVenueAPIWithToken = async (payload) => {
  return getData("normaluser/homeScreen", payload).then((data) => {
    return data;
  });
};
export const HomePageDataAPIWithToken = async (payload) => {
  return getData("normaluser/homeScreen", payload).then((data) => {
    return data;
  });
};
export const CoachReviewRatingAPI = async (payload) => {
  return putFormData("normaluser/coachingRating", payload).then((data) => {
    return data;
  });
};
export const BlockedUserAPI = async (payload) => {
  return getData("normaluser/getblockUser", payload).then((data) => {
    return data;
  });
};
export const AddBlockedUserAPI = async (payload) => {
  return postFormData("normaluser/blockUser", payload).then((data) => {
    return data;
  });
};
export const CompletedBookingGiveRatingReviewbyUserAPI = async (payload) => {
  return putFormData("normaluser/rating", payload).then((data) => {
    return data;
  });
};
export const GetVenueRatingAPI = async (payload) => {
  return getData("normaluser/getVenueRating", payload).then((data) => {
    return data;
  });
};
export const notificationApi = async (payload) => {
  return getData("normaluser/getAllNotificationList", payload).then((data) => {
    return data;
  });
};
export const BookingCancelApi = async (payload) => {
  return putFormData("normaluser/cancelEvent", payload).then((data) => {
    return data;
  });
};
export const UserHelpAndSupportApi = async (payload) => {
  return postFormData("normaluser/addHelpAndSupport", payload).then((data) => {
    return data;
  });
};
//other

export const DownloadCoachReviewApi = async (payload) => {
  return getDataAndDownload("batch/downloadCoachReview", payload).then(
    (data) => {
      return data;
    }
  );
};
export const DownloadNutritionApi = async (payload) => {
  return getDataAndDownload("batch/downloadNutrition", payload).then((data) => {
    return data;
  });
};
export const DownloadAttendanceApi = async (payload) => {
  return getDataAndDownload("batch/downloadAttendance", payload).then(
    (data) => {
      return data;
    }
  );
};
export const SendContactUsApi = async (payload) => {
  return postFormDataNoToken("contactus/addContactUs", payload).then((data) => {
    return data;
  });
};
export const joinEventBookingApi = async (payload) => {
  return putFormData("normaluser/joinEventBooking", payload).then((data) => {
    return data;
  });
};
export const verifyBlockUserApi = async (payload) => {
  return getData("normaluser/verifyBlockUser", payload).then((data) => {
    return data;
  });
};
export const SportsListApi = async (payload) => {
  return getData("normaluser/sports", payload).then((data) => {
    return data;
  });
};

// Payment APis==========================================================================
export const CreateOrderIdApi = async (payload) => {
  return postFormData("payment/order", payload).then((data) => {
    return data;
  });
};
export const CheckoutApi = async (payload) => {
  return postFormDataChekoutPayment(
    "https://checkout.razorpay.com/v1/checkout.js",
    payload
  ).then((data) => {
    return data;
  });
};
export const PaymentVerifyApi = async (payload) => {
  return postFormData("payment/verify", payload).then((data) => {
    return data;
  });
};
export const AddPaymentApi = async (payload) => {
  return postFormData("payment/addPayments", payload).then((data) => {
    return data;
  });
};
export const NotificationCountApi = async (payload) => {
  return getData("normaluser/getNotificationCount", payload).then((data) => {
    return data;
  });
};
export const GetAllKeysApi = async (payload) => {
  return getData("admin/keys", payload).then((data) => {
    return data;
  });
};
