import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ChangeLiveCoords,
  ChangePrograms,
  notificationCountReducer,
  UserDetailReducer,
} from "../features/slice";
import ForgotPasswordModal from "./Modals/ForgotPasswordModal";
import LoginModal from "./Modals/LoginModal";
import LoginWithEmail from "./Modals/LoginWithEmail";
import OTPVerification from "./Modals/OTPVerification";
import ResetPassword from "./Modals/ResetPassword";
import SignUpModal from "./Modals/SignUpModal";
import SignUpWithMobileModal from "./Modals/SignUpWithMobileModal";
import GooglePlace from "./GooglePlace";
import VenueLoginModal from "./Modals/VenueLoginModal";
import VenueSignUpModal from "./Modals/VenueSignUpModal";
import CoachSignUpModal from "./Modals/CoachSignUpModal";
import CoachVenueForgotPasswordModal from "./Modals/CoachVenueForgotPasswordModal";
import CoachVenueOTPVerification from "./Modals/CoachVenueOTPVerification";
import CoachVenueResetPassword from "./Modals/CoachVenueResetPassword";
import {
  CoachDetailReducer,
  VenueOwnerDetailReducer,
} from "../features/CoachVenueslice";
import { useGeolocated } from "react-geolocated";
import Geocode from "react-geocode";
import { mapApiKey } from "../Utils";
import Cookies from "universal-cookie";
import HelpAndSupportModal from "./Modals/HelpAndSupportModal";
import { VenueOwnerEditProfileApi } from "../api/CoachVenuesetting";
import { toast } from "react-toastify";
import Autocomplete from "react-google-autocomplete";

function HomeHeader({ className }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [navActive, setNavActive] = useState("home");
  const [showdropdown, setShowdropdown] = useState(false);
  const [settingCollespe, setSettingCollespe] = useState(false);
  const [UserDropMenu, setUserDropMenu] = useState(false);
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const cookies = new Cookies();
  const loginFor = cookies.get("loginFor");
  const token = localStorage.getItem("token");
  const autocompleteRef = useRef();
  const handleShow = () => {
    setShow(!show);
    localStorage.removeItem("loginImg");
  };
  const handleShowDrop = () => {
    setShowdropdown(!showdropdown);
  };
  const handleSettingCollespe = () => {
    setSettingCollespe(!settingCollespe);
  };
  const handleUserDropMenu = () => {
    setUserDropMenu(!UserDropMenu);
  };

  async function logout(e) {
    await localStorage.clear();
    dispatch(ChangePrograms("Physical Education"));
    navigate("/");
    getUserDetail();
  }

  const NormaluserId = useSelector((state) => state.main.normaluserId);

  const loginType = localStorage.getItem("loginType");

  const VenueloginDetail = useSelector(
    (state) => state.CoachVenueMain.VenueOwnerDetail
  );

  const getVenueDetail = useCallback(async () => {
    if (token) {
      dispatch(VenueOwnerDetailReducer({}));
    }
  }, [dispatch, token]);

  const CoachDetail = useSelector((state) => state.CoachVenueMain.CoachDetail);

  const getCoachDetail = useCallback(async () => {
    if (token) {
      dispatch(CoachDetailReducer({}));
    }
  }, [dispatch, token]);

  const loginDetail = useSelector((state) => state.main.userDetail);

  const getUserDetail = useCallback(async () => {
    if (token) {
      dispatch(
        UserDetailReducer({
          normaluserId: NormaluserId?._id,
        })
      );
    }
  }, [dispatch, NormaluserId, token]);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });
  //console.log(coords);

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      Geocode.setApiKey(mapApiKey);
      Geocode.fromLatLng(coords?.latitude, coords?.longitude).then(
        (response) => {
          const address = response.results[0];
          //console.log(address);
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          //console.log(city, state, country);
          dispatch(
            ChangeLiveCoords({
              lat: coords?.latitude ? coords?.latitude : "",
              lng: coords?.longitude ? coords?.longitude : "",
            })
          );
          (async () => {
            await localStorage.setItem(
              "liveLat",
              coords?.latitude ? coords?.latitude : ""
            );
            await localStorage.setItem(
              "liveLan",
              coords?.longitude ? coords?.longitude : ""
            );
          })();

          setMarkerPosition({
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
          setAddress(`${city}, ${state}`);
          //setAddress(address?.formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);

  useEffect(() => {
    if (loginType === "coach") {
      getCoachDetail();
    } else if (loginType === "venue") {
      getVenueDetail();
    } else {
      getUserDetail();
    }
  }, [getCoachDetail, getVenueDetail, getUserDetail, loginType]);

  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowdropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });

  const total = useSelector((state) => state.main.notificationCount);

  const getList = useCallback(async () => {
    if (token) {
      dispatch(
        notificationCountReducer({
          status: "0",
        })
      );
    }
  }, [dispatch, token]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getList();
    }
    return () => {
      mounted = false;
    };
  }, [getList]);

  const UpdateStatus = (data) => {
    //console.log(data);
    VenueOwnerEditProfileApi(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getVenueDetail();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  const handleClickLocation = () => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      Geocode.setApiKey(mapApiKey);
      Geocode.fromLatLng(coords?.latitude, coords?.longitude).then(
        (response) => {
          const address = response.results[0];
          //console.log(address);
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          //console.log(city, state, country);
          dispatch(
            ChangeLiveCoords({
              lat: coords?.latitude ? coords?.latitude : "",
              lng: coords?.longitude ? coords?.longitude : "",
            })
          );
          (async () => {
            await localStorage.setItem(
              "liveLat",
              coords?.latitude ? coords?.latitude : ""
            );
            await localStorage.setItem(
              "liveLan",
              coords?.longitude ? coords?.longitude : ""
            );
          })();

          setMarkerPosition({
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
          setAddress(`${city}, ${state}`);
          //setAddress(address?.formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  // const AllKeys = useCallback(async () => {
  //   dispatch(GetAllKeysReducer({}));
  // }, [dispatch]);

  // useEffect(() => {
  //   AllKeys();
  // }, [AllKeys]);

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-dark ${className}`}
        id="mainNav"
      >
        <div className="container position-relative">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              handleShowDrop();
            }}
          >
            <i className="fa fa-bars"></i>
          </button>
          <Link className="navbar-brand" to="/">
            <img src="assets/img/logo.png" alt="Game Hunt" title="Game Hunt" />
          </Link>
          <div className="nav-item get-in-touch loginBtnMobileBlock">
            {loginType === "coach" ? (
              <div className="noti-flex">
                <Link
                  to="/coach/notification"
                  className="notificatin-drop cursor-pointer"
                >
                  <img src="assets/img/notification.png" className="" alt="" />
                  <div className="numNoti">0</div>
                </Link>
                <div className="profile-drop cursor-pointer position-relative homePageDrop">
                  <div
                    className="tabs-list shadow"
                    onClick={() => {
                      handleUserDropMenu();
                      setShowdropdown(false);
                    }}
                  >
                    <span>
                      <img
                        src={
                          CoachDetail?.image?.includes("http")
                            ? CoachDetail?.image
                            : "assets/img/employe.png"
                        }
                        className="profile-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5 className="mb-0">
                        {CoachDetail?.name || "Hello Coach"}
                      </h5>
                    </span>
                  </div>
                  <div
                    className={
                      UserDropMenu
                        ? "profile-collapse notificatin-box coachProfileMenu text-capitalize"
                        : "profile-collapse hideDrop notificatin-box coachProfileMenu text-capitalize"
                    }
                  >
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/user_EditProfile.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        className={
                          pathname.includes("edit-personal-info")
                            ? "dropdown-item theme-color"
                            : "dropdown-item"
                        }
                        to="/coach/edit-personal-info"
                      >
                        <h6>My Profile & Services</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/wallet_icon.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/coach/my-earning"
                        className={
                          pathname.includes("my-earning") ? "theme-color" : ""
                        }
                      >
                        <h6>My Earnings</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/account-info.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/coach/account-info"
                        className={
                          pathname.includes("account-info") ? "theme-color" : ""
                        }
                      >
                        <h6>account info</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/user_review_icon.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/coach/review-rating"
                        className={
                          pathname.includes("review-rating")
                            ? "theme-color"
                            : ""
                        }
                      >
                        <h6>My Reviews</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/iconpassword.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        className={
                          pathname.includes("change-password")
                            ? "dropdown-item theme-color"
                            : "dropdown-item"
                        }
                        to="/coach/change-password"
                      >
                        <h6>Change Password</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/use_MyAccount.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        className={
                          pathname.includes("terms-services")
                            ? "dropdown-item theme-color"
                            : "dropdown-item"
                        }
                        to="/coach/terms-services"
                      >
                        <h6>Terms of Services</h6>
                      </Link>
                    </div>
                    <div className="text-center mt-4">
                      <span
                        className="fw-bold"
                        style={{ color: "#ff2f2f" }}
                        onClick={(e) => {
                          logout(e);
                        }}
                      >
                        <img
                          src="assets/img/logout.png"
                          className=""
                          alt=""
                          width="14px"
                        />{" "}
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : loginType === "venue" ? (
              <div className="noti-flex">
                <Link
                  to="/venue/notification"
                  className="notificatin-drop cursor-pointer"
                >
                  <img src="assets/img/notification.png" className="" alt="" />
                  <div className="numNoti">0</div>
                </Link>
                <div className="profile-drop cursor-pointer position-relative homePageDrop">
                  <div
                    className="tabs-list shadow"
                    onClick={() => {
                      handleUserDropMenu();
                      setShowdropdown(false);
                    }}
                  >
                    <span>
                      <img
                        src={
                          VenueloginDetail?.picture?.includes("http")
                            ? VenueloginDetail?.picture
                            : "assets/img/employe.png"
                        }
                        className="profile-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5 className="mb-0">
                        {VenueloginDetail?.name || "Hello Venue Owner"}
                      </h5>
                    </span>
                  </div>
                  <div
                    className={
                      UserDropMenu
                        ? "profile-collapse notificatin-box coachProfileMenu text-capitalize"
                        : "profile-collapse hideDrop notificatin-box coachProfileMenu text-capitalize"
                    }
                  >
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/register.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/venue/venue-detail"
                        className={
                          pathname.includes("venue-detail") ? "theme-color" : ""
                        }
                      >
                        <h6>General Detail</h6>
                      </Link>
                    </div>
                    {/* <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/document-upload.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link to="/venue/document-upload">
                        <h6>Documents Upload</h6>
                      </Link>
                    </div> */}
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/wallet_icon.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link to="/venue/my-earning" className="">
                        <h6>My Earnings</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/account-info.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/venue/account-info"
                        className={
                          pathname.includes("account-info") ? "theme-color" : ""
                        }
                      >
                        <h6>account info</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/rate_review-drop.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link to="/venue/review-ratings">
                        <h6>Review and Ratings</h6>
                      </Link>
                    </div>
                    <div className="text-start position-relative mb-3">
                      <img
                        src="assets/img/settings_outline_icon.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <span
                        className=""
                        onClick={() => {
                          handleSettingCollespe();
                        }}
                      >
                        <h6>Setting</h6>
                      </span>
                      <ul
                        className={
                          settingCollespe
                            ? "collapse ps-0 setting-nav list-style-none show"
                            : "collapse ps-0 setting-nav list-style-none"
                        }
                        id="collapseExample"
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/venue/edit-profile"
                          >
                            Edit Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/venue/change-password"
                          >
                            Change Password
                          </Link>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                          <span>Turn Notification</span>
                          <span className="btn p-0">
                            <input
                              type="checkbox"
                              className="toggle"
                              id=""
                              checked={
                                VenueloginDetail?.notification_permission ===
                                "allow"
                              }
                              onChange={() => {
                                UpdateStatus({
                                  notification_permission:
                                    VenueloginDetail?.notification_permission ===
                                    "allow"
                                      ? "deny"
                                      : "allow",
                                });
                              }}
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="text-center mt-4">
                      <span
                        className="fw-bold"
                        style={{ color: "#ff2f2f" }}
                        onClick={(e) => {
                          logout(e);
                        }}
                      >
                        Logout{" "}
                        <img
                          src="assets/img/logout.png"
                          className=""
                          alt=""
                          width="18px"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : loginType === "user" ? (
              <div className="noti-flex">
                <Link
                  to="/notification"
                  className="notificatin-drop cursor-pointer"
                >
                  <img src="assets/img/notification.png" className="" alt="" />
                  <div className={total === 0 ? "d-none" : "numNoti"}>
                    {total >= 10 ? "9+" : total}
                  </div>
                </Link>

                <div className="profile-drop cursor-pointer position-relative homePageDrop">
                  <div
                    className="tabs-list shadow"
                    onClick={() => {
                      handleUserDropMenu();
                      setShowdropdown(false);
                    }}
                  >
                    <span>
                      <img
                        src={
                          loginDetail?.picture?.includes("http")
                            ? loginDetail?.picture
                            : "assets/img/employe.png"
                        }
                        className="profile-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5 className="mb-0">
                        {loginDetail?.firstName
                          ? `${loginDetail?.firstName} ${loginDetail?.lastName}`
                          : "Hello User"}
                      </h5>
                    </span>
                  </div>
                  <div
                    className={
                      UserDropMenu
                        ? "profile-collapse notificatin-box text-capitalize coachProfileMenu"
                        : "profile-collapse hideDrop notificatin-box text-capitalize coachProfileMenu"
                    }
                  >
                    <div
                      className="text-start  position-relative mb-3"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/user_EditProfile.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/edit-profile"
                        className={
                          pathname.includes("profile") ? "theme-color" : ""
                        }
                      >
                        <h6>My Profile</h6>
                      </Link>
                    </div>
                    <div
                      className="text-start  position-relative mb-3 linkNone"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/user_BookVenue.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/venues-listing"
                        className={
                          pathname.includes("venues")
                            ? "theme-color"
                            : pathname.includes("event")
                            ? "theme-color"
                            : ""
                        }
                      >
                        <h6>book your venues</h6>
                      </Link>
                    </div>
                    <div
                      className="text-start  position-relative mb-3 linkNone"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/user_Coaching.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/coaching"
                        className={
                          pathname.includes("coaching") ? "theme-color" : ""
                        }
                      >
                        <h6>coaching</h6>
                      </Link>
                    </div>
                    <div
                      className="text-start  position-relative mb-3 linkNone"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/user_Favourite.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/my-favourite"
                        className={
                          pathname.includes("favourite") ? "theme-color" : ""
                        }
                      >
                        <h6>my favourite</h6>
                      </Link>
                    </div>
                    <div
                      className="text-start  position-relative mb-3"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/User_MyBooking.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/my-booking"
                        className={
                          pathname.includes("my-booking")
                            ? "theme-color"
                            : pathname.includes("coach-booking")
                            ? "theme-color"
                            : ""
                        }
                      >
                        <h6>My Booking</h6>
                      </Link>
                    </div>
                    <div
                      className="text-start  position-relative mb-3"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/user_review_icon.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/review-rating"
                        className={
                          pathname.includes("review-rating")
                            ? "theme-color"
                            : ""
                        }
                      >
                        <h6>review & rating</h6>
                      </Link>
                    </div>
                    <div
                      className="text-start  position-relative mb-3 linkNone"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/use_MyAccount.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/my-account"
                        className={
                          pathname.includes("my-account") ? "theme-color" : ""
                        }
                      >
                        <h6>My Account</h6>
                      </Link>
                    </div>
                    <div
                      className="text-start  position-relative mb-3"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/user_blockesd.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/blocked-user"
                        className={
                          pathname.includes("blocked-user") ? "theme-color" : ""
                        }
                      >
                        <h6>Blocked Users</h6>
                      </Link>
                    </div>

                    <div
                      className="text-start  position-relative mb-3"
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/user_cuopon.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/coupons"
                        className={
                          pathname.includes("coupons") ? "theme-color" : ""
                        }
                      >
                        <h6>coupons</h6>
                      </Link>
                    </div>
                    <div
                      className={
                        loginDetail?.passwordSetUp === true
                          ? "text-start position-relative mb-3"
                          : "d-none"
                      }
                      onClick={() => {
                        handleUserDropMenu();
                      }}
                    >
                      <img
                        src="assets/img/iconpassword.png"
                        alt=""
                        className="coachProfileMenuIcon"
                      />
                      <Link
                        to="/change-password"
                        className={
                          pathname.includes("change-password")
                            ? "theme-color"
                            : ""
                        }
                      >
                        <h6>change password</h6>
                      </Link>
                    </div>
                    <div className="text-center mt-4">
                      <span
                        className="fw-bold"
                        onClick={(e) => {
                          logout(e);
                        }}
                      >
                        <img
                          src="assets/img/logout.png"
                          className=""
                          alt=""
                          width="13px"
                        />{" "}
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span
                className="nav-link login-b cursor-pointer"
                onClick={() => {
                  setModalName(
                    loginFor === "mobile"
                      ? "LoginModal"
                      : loginFor === "email"
                      ? "LoginWithEmail"
                      : "LoginModal"
                  );
                  handleShow();
                }}
              >
                <img src="assets/img/userLogin.png" alt="" />{" "}
                <img src="assets/img/userLogin.png" alt="" /> Login | Signup
              </span>
            )}
          </div>
          <div
            className={
              showdropdown === true
                ? "collapse navbar-collapse homePAgeMenu show"
                : "collapse navbar-collapse homePAgeMenu"
            }
            // ref={ref}
          >
            <form className="createEventForm nav-link locationFormDesk">
              <div className="position-relative">
                {/* <GooglePlace
                  name="location"
                  value={address}
                  latLong={markerPosition}
                  //onBlur={handleBlur}
                  onChange={(val, latLong) => {
                    //console.log(val, latLong);
                    //console.log(formik.values.location);
                    if (val) {
                      dispatch(
                        ChangeLiveCoords({
                          lat: latLong?.lat,
                          lng: latLong?.lng,
                        })
                      );
                      (async () => {
                        await localStorage.setItem("liveLat", latLong?.lat);
                        await localStorage.setItem("liveLan", latLong?.lng);
                      })();
                      setAddress(val);
                      setMarkerPosition(latLong);
                    } else {
                      setAddress("");
                      setMarkerPosition({
                        lat: "",
                        lng: "",
                      });
                    }
                  }}
                />{" "} */}
                <Autocomplete
                  apiKey={mapApiKey}
                  name="location"
                  value={address}
                  ref={autocompleteRef}
                  className="form-control ps-4 fw-normal"
                  placeholder="Current Location"
                  onChange={() => {
                    //console.log(autocompleteRef);
                    setAddress(autocompleteRef.current.value);
                    setMarkerPosition({
                      lat: "",
                      lng: "",
                    });
                  }}
                  onPlaceSelected={(place, inputRef, autocompleteRef) => {
                    //console.log(place, inputRef, autocompleteRef);
                    if (place) {
                      let lat = place.geometry.location.lat();
                      let lng = place.geometry.location.lng();
                      let address = place.formatted_address;
                      dispatch(
                        ChangeLiveCoords({
                          lat: lat,
                          lng: lng,
                        })
                      );
                      (async () => {
                        await localStorage.setItem("liveLat", lat);
                        await localStorage.setItem("liveLan", lng);
                      })();
                      setAddress(address);
                      setMarkerPosition({
                        lat: lat,
                        lng: lng,
                      });
                    }
                  }}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "in" },
                  }}
                />
                <img
                  src="assets/img/address.png"
                  className="input-img loactun"
                  alt=""
                />
                <img
                  src="assets/img/my_location_regular_icon.png"
                  className="input-img loactunRight"
                  alt=""
                  onClick={() => {
                    handleClickLocation();
                  }}
                />
              </div>
            </form>
            <ul className="navbar-nav py-4 py-lg-0">
              <li className="nav-item">
                <a
                  className={
                    navActive === "academy" ? "nav-link active" : "nav-link"
                  }
                  href="https://www.thegamehunt.com/academy"
                >
                  gamehunt academy
                </a>
              </li>
              <li className="nav-item profile-drop cursor-pointer position-relative">
                {loginType === "coach" ? (
                  <Link
                    className={
                      navActive === "coach" ? "nav-link active" : "nav-link"
                    }
                    to="/coach/manage-booking"
                  >
                    manage booking
                  </Link>
                ) : loginType === "venue" ? (
                  <Link
                    className={
                      navActive === "venue" ? "nav-link active" : "nav-link"
                    }
                    to="/venue/dashboard"
                  >
                    dashboard
                  </Link>
                ) : (
                  <span
                    className={
                      navActive === "partners" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      setModalName("VenueLoginModal");
                      handleShow();
                      localStorage.setItem(
                        "loginImg",
                        loginFor === "venue"
                          ? "venueLogin"
                          : loginFor === "coach"
                          ? "coachLogin"
                          : "venueLogin"
                      );
                    }}
                  >
                    partners
                  </span>
                )}
              </li>
              <li className="nav-item get-in-touch loginBtnDstBlock ">
                {loginType === "coach" ? (
                  <div className="noti-flex">
                    <Link
                      to="/coach/notification"
                      className="notificatin-drop cursor-pointer"
                    >
                      <img
                        src="assets/img/notification.png"
                        className=""
                        alt=""
                      />
                      <div className="numNoti">0</div>
                    </Link>
                    <div className="profile-drop cursor-pointer position-relative homePageDrop">
                      <div className="tabs-list shadow">
                        <span>
                          <img
                            src={
                              CoachDetail?.image?.includes("http")
                                ? CoachDetail?.image
                                : "assets/img/employe.png"
                            }
                            className="profile-img"
                            alt=""
                          />
                        </span>
                        <span>
                          <h5 className="mb-0">
                            {CoachDetail?.name || "Hello Coach"}
                          </h5>
                        </span>
                      </div>
                      <div className="profile-collapse notificatin-box coachProfileMenu text-capitalize">
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/user_EditProfile.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            className={
                              pathname.includes("edit-personal-info")
                                ? "dropdown-item theme-color"
                                : "dropdown-item"
                            }
                            to="/coach/edit-personal-info"
                          >
                            <h6>My Profile & Services</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/wallet_icon.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/coach/my-earning"
                            className={
                              pathname.includes("my-earning")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>My Earnings</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/account-info.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/coach/account-info"
                            className={
                              pathname.includes("account-info")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>account info</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/user_review_icon.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/coach/review-rating"
                            className={
                              pathname.includes("review-rating")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>My Reviews</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/iconpassword.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            className={
                              pathname.includes("change-password")
                                ? "dropdown-item theme-color"
                                : "dropdown-item"
                            }
                            to="/coach/change-password"
                          >
                            <h6>Change Password</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/use_MyAccount.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            className={
                              pathname.includes("terms-services")
                                ? "dropdown-item theme-color"
                                : "dropdown-item"
                            }
                            to="/coach/terms-services"
                          >
                            <h6>Terms of Services</h6>
                          </Link>
                        </div>
                        <div className="text-center mt-4">
                          <span
                            className="fw-bold"
                            style={{ color: "#ff2f2f" }}
                            onClick={(e) => {
                              logout(e);
                            }}
                          >
                            <img
                              src="assets/img/logout.png"
                              className=""
                              alt=""
                              width="14px"
                            />{" "}
                            Logout
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : loginType === "venue" ? (
                  <div className="noti-flex">
                    <Link
                      to="/venue/notification"
                      className="notificatin-drop cursor-pointer"
                    >
                      <img
                        src="assets/img/notification.png"
                        className=""
                        alt=""
                      />
                      <div className="numNoti">0</div>
                    </Link>
                    <div className="profile-drop cursor-pointer position-relative homePageDrop">
                      <div className="tabs-list shadow">
                        <span>
                          <img
                            src={
                              VenueloginDetail?.picture?.includes("http")
                                ? VenueloginDetail?.picture
                                : "assets/img/employe.png"
                            }
                            className="profile-img"
                            alt=""
                          />
                        </span>
                        <span>
                          <h5 className="mb-0">
                            {VenueloginDetail?.name || "Hello Venue Owner"}
                          </h5>
                        </span>
                      </div>
                      <div className="profile-collapse notificatin-box coachProfileMenu text-capitalize">
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/register.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/venue/venue-detail"
                            className={
                              pathname.includes("venue-detail")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>General Detail</h6>
                          </Link>
                        </div>
                        {/* <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/document-upload.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link to="/venue/document-upload">
                            <h6>Documents Upload</h6>
                          </Link>
                        </div> */}
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/wallet_icon.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link to="/venue/my-earning" className="">
                            <h6>My Earnings</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/account-info.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/venue/account-info"
                            className={
                              pathname.includes("account-info")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>account info</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/rate_review-drop.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link to="/venue/review-ratings">
                            <h6>Review and Ratings</h6>
                          </Link>
                        </div>
                        <div className="text-start position-relative mb-3">
                          <img
                            src="assets/img/settings_outline_icon.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <span
                            className=""
                            onClick={() => {
                              handleSettingCollespe();
                            }}
                          >
                            <h6>Setting</h6>
                          </span>
                          <ul
                            className={
                              settingCollespe
                                ? "collapse ps-0 setting-nav list-style-none show"
                                : "collapse ps-0 setting-nav list-style-none"
                            }
                            id="collapseExample"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/venue/edit-profile"
                              >
                                Edit Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/venue/change-password"
                              >
                                Change Password
                              </Link>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <span>Turn Notification</span>
                              <span className="btn p-0">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  id=""
                                  checked={
                                    VenueloginDetail?.notification_permission ===
                                    "allow"
                                  }
                                  onChange={() => {
                                    UpdateStatus({
                                      notification_permission:
                                        VenueloginDetail?.notification_permission ===
                                        "allow"
                                          ? "deny"
                                          : "allow",
                                    });
                                  }}
                                />
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="text-center mt-4">
                          <span
                            className="fw-bold"
                            style={{ color: "#ff2f2f" }}
                            onClick={(e) => {
                              logout(e);
                            }}
                          >
                            Logout{" "}
                            <img
                              src="assets/img/logout.png"
                              className=""
                              alt=""
                              width="18px"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : loginType === "user" ? (
                  <div className="noti-flex">
                    <Link
                      to="/notification"
                      className="notificatin-drop cursor-pointer"
                    >
                      <img
                        src="assets/img/notification.png"
                        className=""
                        alt=""
                      />
                      <div className={total === 0 ? "d-none" : "numNoti"}>
                        {total >= 10 ? "9+" : total}
                      </div>
                    </Link>

                    <div className="profile-drop cursor-pointer position-relative homePageDrop">
                      <div className="tabs-list shadow">
                        <span>
                          <img
                            src={
                              loginDetail?.picture?.includes("http")
                                ? loginDetail?.picture
                                : "assets/img/employe.png"
                            }
                            className="profile-img"
                            alt=""
                          />
                        </span>
                        <span>
                          <h5 className="mb-0">
                            {loginDetail?.firstName
                              ? `${loginDetail?.firstName} ${loginDetail?.lastName}`
                              : "Hello User"}
                          </h5>
                        </span>
                      </div>
                      <div className="profile-collapse notificatin-box text-capitalize coachProfileMenu">
                        <div className="text-start  position-relative mb-3">
                          <img
                            src="assets/img/user_EditProfile.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/edit-profile"
                            className={
                              pathname.includes("profile") ? "theme-color" : ""
                            }
                          >
                            <h6>My Profile</h6>
                          </Link>
                        </div>
                        <div className="text-start  position-relative mb-3 linkNone">
                          <img
                            src="assets/img/user_BookVenue.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/venues-listing"
                            className={
                              pathname.includes("venues")
                                ? "theme-color"
                                : pathname.includes("event")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>book your venues</h6>
                          </Link>
                        </div>
                        <div className="text-start  position-relative mb-3 linkNone">
                          <img
                            src="assets/img/user_Coaching.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/coaching"
                            className={
                              pathname.includes("coaching") ? "theme-color" : ""
                            }
                          >
                            <h6>coaching</h6>
                          </Link>
                        </div>
                        <div className="text-start  position-relative mb-3 linkNone">
                          <img
                            src="assets/img/user_Favourite.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/my-favourite"
                            className={
                              pathname.includes("favourite")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>my favourite</h6>
                          </Link>
                        </div>
                        <div className="text-start  position-relative mb-3">
                          <img
                            src="assets/img/User_MyBooking.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/my-booking"
                            className={
                              pathname.includes("my-booking")
                                ? "theme-color"
                                : pathname.includes("coach-booking")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>My Booking</h6>
                          </Link>
                        </div>
                        <div className="text-start  position-relative mb-3">
                          <img
                            src="assets/img/user_review_icon.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/review-rating"
                            className={
                              pathname.includes("review-rating")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>review & rating</h6>
                          </Link>
                        </div>
                        <div className="text-start  position-relative mb-3 linkNone">
                          <img
                            src="assets/img/use_MyAccount.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/my-account"
                            className={
                              pathname.includes("my-account")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>My Account</h6>
                          </Link>
                        </div>
                        <div className="text-start  position-relative mb-3">
                          <img
                            src="assets/img/user_blockesd.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/blocked-user"
                            className={
                              pathname.includes("blocked-user")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>Blocked Users</h6>
                          </Link>
                        </div>

                        <div className="text-start  position-relative mb-3">
                          <img
                            src="assets/img/user_cuopon.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/coupons"
                            className={
                              pathname.includes("coupons") ? "theme-color" : ""
                            }
                          >
                            <h6>coupons</h6>
                          </Link>
                        </div>
                        <div
                          className={
                            loginDetail?.passwordSetUp === true
                              ? "text-start position-relative mb-3"
                              : "d-none"
                          }
                        >
                          <img
                            src="assets/img/iconpassword.png"
                            alt=""
                            className="coachProfileMenuIcon"
                          />
                          <Link
                            to="/change-password"
                            className={
                              pathname.includes("change-password")
                                ? "theme-color"
                                : ""
                            }
                          >
                            <h6>change password</h6>
                          </Link>
                        </div>
                        <div className="text-center mt-4">
                          <span
                            className="fw-bold"
                            onClick={(e) => {
                              logout(e);
                            }}
                          >
                            <img
                              src="assets/img/logout.png"
                              className=""
                              alt=""
                              width="13px"
                            />{" "}
                            Logout
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span
                    className="nav-link login-b cursor-pointer"
                    onClick={() => {
                      setModalName(
                        loginFor === "mobile"
                          ? "LoginModal"
                          : loginFor === "email"
                          ? "LoginWithEmail"
                          : "LoginModal"
                      );
                      handleShow();
                    }}
                  >
                    <img src="assets/img/userLogin.png" alt="" />{" "}
                    <img src="assets/img/userLogin.png" alt="" /> Login | Signup
                  </span>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <form className="createEventForm nav-link locationFormMobile">
        <div className="position-relative">
          <GooglePlace
            name="location"
            value={address}
            latLong={markerPosition}
            //onBlur={handleBlur}
            onChange={(val, latLong) => {
              //console.log(val, latLong);
              //console.log(formik.values.location);
              if (val) {
                dispatch(
                  ChangeLiveCoords({
                    lat: latLong?.lat,
                    lng: latLong?.lng,
                  })
                );
                (async () => {
                  await localStorage.setItem("liveLat", latLong?.lat);
                  await localStorage.setItem("liveLan", latLong?.lng);
                })();
                setAddress(val);
                setMarkerPosition(latLong);
              } else {
                setAddress("");
                setMarkerPosition({
                  lat: "",
                  lng: "",
                });
              }
            }}
          />
          {/* <Autocomplete
            apiKey={mapApiKey}
            name="location"
            value={address}
            ref={autocompleteRef}
            className="form-control ps-4 fw-normal"
            placeholder="Current Location"
            onChange={() => {
              console.log(autocompleteRef);
              setAddress(autocompleteRef.current.value);
              setMarkerPosition({
                lat: "",
                lng: "",
              });
            }}
            onPlaceSelected={(place, inputRef, autocompleteRef) => {
              console.log(place, inputRef, autocompleteRef);
              if (place) {
                let lat = place.geometry.location.lat();
                let lng = place.geometry.location.lng();
                let address = place.formatted_address;
                dispatch(
                  ChangeLiveCoords({
                    lat: lat,
                    lng: lng,
                  })
                );
                (async () => {
                  await localStorage.setItem("liveLat", lat);
                  await localStorage.setItem("liveLan", lng);
                })();
                setAddress(address);
                setMarkerPosition({
                  lat: lat,
                  lng: lng,
                });
              }
            }}
          /> */}
          <img
            src="assets/img/address.png"
            className="input-img loactun"
            alt=""
          />
          <img
            src="assets/img/my_location_regular_icon.png"
            className="input-img loactunRight"
            alt=""
            onClick={() => {
              handleClickLocation();
            }}
          />
        </div>
      </form>

      <div
        className={token ? "helpBtn" : "d-none"}
        onClick={() => {
          setModalName("helpBtn");
          handleShow();
        }}
      >
        <i className="fa fa-question-circle-o" aria-hidden="true"></i> Help
      </div>

      {modalName === "LoginModal" && (
        <LoginModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "LoginWithEmail" && (
        <LoginWithEmail
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpModal" && (
        <SignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpWithMobile" && (
        <SignUpWithMobileModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ForgotPassword" && (
        <ForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "OTPVerification" && (
        <OTPVerification
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ResetPassword" && (
        <ResetPassword
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "VenueLoginModal" && (
        <VenueLoginModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "VenueSignUpModal" && (
        <VenueSignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "CoachSignUpModal" && (
        <CoachSignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "CoachVenueForgotPassword" && (
        <CoachVenueForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "CoachVenueOTPVerification" && (
        <CoachVenueOTPVerification
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "CoachVenueResetPassword" && (
        <CoachVenueResetPassword
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {show && modalName === "helpBtn" && (
        <HelpAndSupportModal show={show} handleShow={handleShow} />
      )}
    </>
  );
}

export default HomeHeader;
