import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, isObject } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import CoachLogin from "./CoachLogin";
import { CoachVenueLoginReducer } from "../../features/CoachVenueslice";
import Cookies from "universal-cookie";

function VenueLoginModal({ show, handleShow, setModalName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loginImgType = localStorage.getItem("loginImg");

  const [loginImg, setLoginImg] = useState(loginImgType);

  const cookies = new Cookies();

  const loginFor = cookies.get("loginFor");

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div
                    className={
                      loginImg === "venueLogin"
                        ? "col-sm-12 col-md-12 col-lg-6 bgloginImgvenue pb-1"
                        : "col-sm-12 col-md-12 col-lg-6 bgloginImgCoach pb-1 "
                    }
                  >
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-5">
                    <ul
                      className="nav nav-pills mb-2 justify-content-center tabsnew"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={
                            loginImgType === "venueLogin"
                              ? "nav-link active text-capitalize"
                              : "nav-link text-capitalize"
                          }
                          type="button"
                          role="tab"
                          onClick={() => {
                            setLoginImg("venueLogin");
                            localStorage.setItem("loginImg", "venueLogin");
                          }}
                        >
                          <img
                            src="assets/img/venueName_red.png"
                            className="selectIcon"
                            alt=""
                          />
                          <img
                            src="assets/img/venueName.png"
                            className="UnselectIcon"
                            alt=""
                          />
                          venues
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={
                            loginImgType === "coachLogin"
                              ? "nav-link active text-capitalize"
                              : "nav-link text-capitalize"
                          }
                          type="button"
                          role="tab"
                          onClick={() => {
                            setLoginImg("coachLogin");
                            localStorage.setItem("loginImg", "coachLogin");
                          }}
                        >
                          <img
                            src="assets/img/coachIcon_red.png"
                            className="selectIcon"
                            alt=""
                          />
                          <img
                            src="assets/img/coachIcon.png"
                            className="UnselectIcon"
                            alt=""
                          />
                          coach
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content pe-2" id="pills-tabContent">
                      {loginImgType === "venueLogin" ? (
                        <div className="form-style mt-3 mt-md-5 px-4">
                          <Formik
                            initialValues={{
                              email: "",
                              password: "",
                              toggle: false,
                              error: "",
                              RememberMe: loginFor === "venue" ? true : false,
                            }}
                            validationSchema={Yup.object({
                              email: Yup.string()
                                .email("email must be a valid format")
                                .required("Required"),
                              password: Yup.string().required("Required"),
                            })}
                            onSubmit={(values, action) => {
                              setLoading(true);
                              dispatch(
                                CoachVenueLoginReducer({
                                  email: values.email,
                                  password: values.password,
                                  login_type: "email",
                                  user_type: "venueowner",
                                })
                              ).then(async (data) => {
                                //console.log(data);
                                setLoading(false);
                                if (data?.payload?.code === 1) {
                                  await localStorage.setItem(
                                    "loginType",
                                    "venue"
                                  );
                                  toast.success(data?.payload?.message);
                                  if (
                                    data?.payload?.data?.user?.profileSetup ===
                                    0
                                  ) {
                                    navigate("/venue/edit-profile", {
                                      replace: true,
                                    });
                                    handleShow();
                                  } else {
                                    navigate("/venue/dashboard", {
                                      replace: true,
                                    });
                                    handleShow();
                                  }
                                } else if (data?.payload?.code === 2) {
                                  await localStorage.setItem(
                                    "OTPVerify",
                                    "SignUpOTPVerifyEmailVenue"
                                  );
                                  await localStorage.setItem(
                                    "email",
                                    values.email
                                  );
                                  await localStorage.setItem(
                                    "password",
                                    values.password
                                  );
                                  setModalName("CoachVenueOTPVerification");
                                  toast.success(data?.payload?.message);
                                } else {
                                  setLoading(false);
                                  toast.error(data?.payload?.message);
                                }
                              });
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFormikState,
                              setFieldValue,
                            }) => (
                              <form
                                className=""
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleSubmit();
                                }}
                              >
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 text-center mb-3">
                                    <h2 className="mdl-ttl">Login</h2>
                                  </div>
                                  <div className="col-sm-12 col-md-12 mb-4">
                                    <div className="form-group">
                                      <div className="input-container">
                                        <input
                                          className="form-control ps-5"
                                          placeholder="Email Address"
                                          name="email"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.email}
                                        />
                                        <img
                                          src="assets/img/email.png"
                                          className="input-img"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    {errors.email && touched.email && (
                                      <div className="text-red">
                                        {" "}
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-12 mb-1">
                                    <div className="form-group">
                                      <div className="input-container">
                                        <input
                                          id="password-field"
                                          type={
                                            values.toggle ? "text" : "password"
                                          }
                                          name="password"
                                          className="form-control ps-5"
                                          placeholder="Password"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.password}
                                        />
                                        <img
                                          src="assets/img/password.png"
                                          className="input-img"
                                          alt=""
                                        />
                                        <span
                                          className={
                                            values.toggle
                                              ? "pass-hide field-icon toggle-password"
                                              : "pass-view field-icon toggle-password"
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFormikState((prevState) => {
                                              return {
                                                ...prevState,
                                                values: {
                                                  ...prevState.values,
                                                  toggle:
                                                    !prevState.values.toggle,
                                                },
                                              };
                                            });
                                          }}
                                        ></span>
                                      </div>
                                      {errors.password && touched.password && (
                                        <div className="text-red">
                                          {" "}
                                          {errors.password}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-12 text-end mb-1">
                                    <p className="mb-0">
                                      <span
                                        className="theme-color cursor-pointer fw-bold"
                                        onClick={() => {
                                          setModalName(
                                            "CoachVenueForgotPassword"
                                          );
                                          setLoginImg("venueLogin");
                                        }}
                                      >
                                        Forgot Password?
                                      </span>
                                    </p>
                                  </div>
                                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <div className="input-container">
                                      <div className="">
                                        <input
                                          className="styled-checkbox"
                                          id="RememberMe"
                                          type="checkbox"
                                          name="RememberMe"
                                          onChange={handleChange}
                                          onBlur={handleBlur("radio")}
                                          checked={values.RememberMe === true}
                                        />
                                        <label
                                          htmlFor="RememberMe"
                                          className="w-100"
                                          onClick={() => {
                                            cookies.set(
                                              "loginFor",
                                              `${
                                                loginFor === "venue"
                                                  ? ""
                                                  : "venue"
                                              }`
                                            );
                                          }}
                                        >
                                          Remember Me
                                        </label>
                                      </div>
                                      {errors.RememberMe &&
                                        touched.RememberMe && (
                                          <div className="text-red">
                                            {" "}
                                            {errors.RememberMe}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-12 text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-submit mt-0"
                                    >
                                      {loading ? <Loader /> : "Login"}
                                    </button>
                                  </div>
                                  <div className="text-center my-5">
                                    Not register Yet ?{" "}
                                    <span
                                      className="theme-color cursor-pointer fw-bold"
                                      onClick={() => {
                                        setModalName("VenueSignUpModal");
                                      }}
                                    >
                                      Create an Account
                                    </span>
                                  </div>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      ) : loginImgType === "coachLogin" ? (
                        <CoachLogin
                          setModalName={setModalName}
                          setLoginImg={setLoginImg}
                          loginImg={loginImg}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VenueLoginModal;
