import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, isObject } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { SignUpReducer } from "../../features/slice";
import FacebookSocialLogin from "../socialLogin/facebookLogin";
import GoogleSocialLogin from "../socialLogin/googleLogin";

function SignUpModal({ show, handleShow, setModalName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="xl">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="row fliprow">
                  <div className="col-sm-12 col-md-12 col-lg-6 bgSignUpImgUser">
                    <div className="logo-bg position-relative">
                      <img
                        src="assets/img/gamehuntlogo.png"
                        className="logo-icon"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mt-lg-5">
                    <div className="form-style mt-3 mt-lg-5 px-4">
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                          firstName: "",
                          lastName: "",
                          confirmPassword: "",
                          acceptTermsCondition: false,
                        }}
                        validationSchema={Yup.object({
                          firstName: Yup.string()
                            .max(50, "Name is very long")
                            .required("Required"),
                          lastName: Yup.string()
                            .max(50, "Name is very long")
                            .required("Required"),
                          email: Yup.string()
                            .email("email must be a valid format")
                            .required("Required"),
                          password: Yup.string().required("Required"),
                          acceptTermsCondition: Yup.boolean().oneOf(
                            [true],
                            "Required"
                          ),
                          confirmPassword: Yup.string()
                            .required("Required")
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            ),
                        })}
                        onSubmit={(values, action) => {
                          setLoading(true);
                          dispatch(
                            SignUpReducer({
                              firstName: values.firstName,
                              lastName: values.lastName,
                              email: values.email,
                              password: values.password,
                              register_type: "email",
                            })
                          ).then(async (data) => {
                            //console.log(data);
                            setLoading(false);
                            if (data?.payload?.code === 2) {
                              await localStorage.setItem(
                                "email",
                                data?.payload?.data?.email
                              );
                              await localStorage.setItem(
                                "password",
                                values.password
                              );
                              toast.success(data?.payload?.message);
                              // await localStorage.setItem(
                              //   "token",
                              //   data?.payload?.data?.token
                              // );
                              // await localStorage.setItem("loginType", "user");
                              await localStorage.setItem(
                                "OTPVerify",
                                "SignUpOTPVerifyEmail"
                              );
                              setModalName("OTPVerification");
                              // navigate("/edit-profile", {
                              //   replace: true,
                              // });
                              // handleShow();
                            } else {
                              setLoading(false);
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFormikState,
                          setFieldValue,
                        }) => (
                          <form
                            className=""
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleSubmit();
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-12 text-center mb-4">
                                <h2 className="mdl-ttl">sign up</h2>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="input-container">
                                  <input
                                    type="text"
                                    className="form-control ps-5"
                                    placeholder="First Name"
                                    name="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                  />
                                  <img
                                    src="assets/img/userBlack.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                                {errors.firstName && touched.firstName && (
                                  <div className="text-red">
                                    {errors.firstName}
                                  </div>
                                )}
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="input-container">
                                  <input
                                    type="text"
                                    className="form-control ps-5"
                                    placeholder="Last Name"
                                    name="lastName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                  />
                                  <img
                                    src="assets/img/userBlack.png"
                                    className="input-img"
                                    alt=""
                                  />
                                </div>
                                {errors.lastName && touched.lastName && (
                                  <div className="text-red">
                                    {errors.lastName}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      className="form-control ps-5"
                                      placeholder="Email Address"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    <img
                                      src="assets/img/email.png"
                                      className="input-img"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {errors.email && touched.email && (
                                  <div className="text-red">
                                    {" "}
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type={values.toggle ? "text" : "password"}
                                      name="password"
                                      className="form-control ps-5"
                                      placeholder="Password*"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                    />
                                    <img
                                      src="assets/img/password.png"
                                      className="input-img"
                                      alt=""
                                    />
                                    <span
                                      className={
                                        values.toggle
                                          ? "pass-hide field-icon toggle-password cursor-pointer"
                                          : "pass-view field-icon toggle-password cursor-pointer"
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFormikState((prevState) => {
                                          return {
                                            ...prevState,
                                            values: {
                                              ...prevState.values,
                                              toggle: !prevState.values.toggle,
                                            },
                                          };
                                        });
                                      }}
                                    ></span>
                                  </div>
                                  {errors.password && touched.password && (
                                    <div className="text-red">
                                      {" "}
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="form-group">
                                  <div className="input-container">
                                    <input
                                      type={
                                        values.toggle2 ? "text" : "password"
                                      }
                                      name="confirmPassword"
                                      className="form-control ps-5"
                                      placeholder="Confirm Password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.confirmPassword}
                                    />
                                    <img
                                      src="assets/img/password.png"
                                      className="input-img"
                                      alt=""
                                    />
                                    <span
                                      className={
                                        values.toggle2
                                          ? "pass-hide field-icon toggle-password cursor-pointer"
                                          : "pass-view field-icon toggle-password cursor-pointer"
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFormikState((prevState) => {
                                          return {
                                            ...prevState,
                                            values: {
                                              ...prevState.values,
                                              toggle2:
                                                !prevState.values.toggle2,
                                            },
                                          };
                                        });
                                      }}
                                    ></span>
                                  </div>
                                  {errors.confirmPassword &&
                                    touched.confirmPassword && (
                                      <div className="text-red">
                                        {" "}
                                        {errors.confirmPassword}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div className="input-container">
                                  <div className="">
                                    <input
                                      className="styled-checkbox"
                                      id="Iaccept"
                                      type="checkbox"
                                      name="acceptTermsCondition"
                                      onChange={handleChange}
                                      onBlur={handleBlur("radio")}
                                      checked={
                                        values.acceptTermsCondition === true
                                      }
                                    />
                                    <label htmlFor="Iaccept" className="w-100">
                                      I accept to the{" "}
                                      <Link
                                        to="/terms-conditions"
                                        className="theme-color"
                                        target="_blank"
                                      >
                                        Terms & Conditions
                                      </Link>
                                    </label>
                                  </div>
                                  {errors.acceptTermsCondition &&
                                    touched.acceptTermsCondition && (
                                      <div className="text-red">
                                        {" "}
                                        {errors.acceptTermsCondition}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn btn-submit mt-0 w-75"
                                >
                                  {loading ? <Loader /> : "Sign up"}
                                </button>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="orLine">
                                  <span className="orLog">or</span>
                                </div>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <p
                                  className="loginwithEmailBtn btn w-75 cursor-pointer"
                                  onClick={() => {
                                    setModalName("SignUpWithMobile");
                                  }}
                                >
                                  Sign with Phone Number
                                </p>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="orLine">
                                  <span className="orLog signOr">
                                    or use one of these option
                                  </span>
                                </div>
                              </div>
                              <div className="form-group col-sm-12 col-md-12 text-center">
                                <div className="d-flex justify-content-center align-items-center gap-3 my-3">
                                  <FacebookSocialLogin
                                    handleShow={handleShow}
                                  />
                                  <GoogleSocialLogin handleShow={handleShow} />
                                </div>
                              </div>

                              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                                Already have an account ?{" "}
                                <span
                                  className="theme-color cursor-pointer fw-bold"
                                  onClick={() => {
                                    setModalName("LoginModal");
                                  }}
                                >
                                  Login
                                </span>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignUpModal;
