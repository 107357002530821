import React from "react";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";

function Settings() {
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                {/* <ul
                  className="nav nav-pills mb-3 justify-content-center tabsnew"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active text-capitalize"
                      id="pills-editProfile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-editProfile"
                      type="button"
                      role="tab"
                      aria-controls="pills-editProfile"
                      aria-selected="true"
                    >
                      edit Profile
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link text-capitalize"
                      id="pills-changePassword-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-changePassword"
                      type="button"
                      role="tab"
                      aria-controls="pills-changePassword"
                      aria-selected="false"
                    >
                      change Password
                    </button>
                  </li>
                </ul> */}
                <div className="tab-content mt-4" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-editProfile"
                    role="tabpanel"
                    aria-labelledby="pills-editProfile-tab"
                  >
                    <EditProfile />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-changePassword"
                    role="tabpanel"
                    aria-labelledby="pills-changePassword-tab"
                  >
                    <ChangePassword />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Settings;
