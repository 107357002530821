import React from "react";
import { Modal } from "react-bootstrap";

function CopySlotModal({ show, handleShow, setCopyData }) {
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="text-center">
                  <h2 className="mb-5 mt-2">Second Court/Turfs</h2>
                  <h6>
                    Do you want to copy the details from other court/turfs?
                  </h6>
                  <div className="view-btns mt-5">
                    <button
                      className="btn-view btn-accept"
                      onClick={() => {
                        setCopyData(true);
                        handleShow();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="btn-view btn-reject"
                      onClick={() => {
                        setCopyData(false);
                        handleShow();
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CopySlotModal;
