import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AddPaymentReducer,
  calculatePriceReducer,
  CouponListReducer,
  CreateOrderIdReducer,
  CreateVenueEventReducer,
  PaymentVerifyReducer,
} from "../../features/slice";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { payment_key_id } from "../../Utils";

function FullBookingDetailModal({
  show,
  handleShow,
  totalAmount,
  values,
  date,
  id,
  detail,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unique_id = uuid();

  const [couponName, setCouponName] = useState("");
  const userDetail = useSelector((state) => state.main.userDetail);
  const calculatePrice = useSelector((state) => state.main.calculatePrice);

  const handleCouponPrice = useCallback(async () => {
    dispatch(
      calculatePriceReducer({
        amount: totalAmount,
        // couponCode: couponName.toLocaleUpperCase(),
      })
    );
  }, [dispatch, totalAmount]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      handleCouponPrice();
    }
    return () => {
      mounted = false;
    };
  }, [handleCouponPrice]);

  const handleApplyCoupon = () => {
    dispatch(
      calculatePriceReducer({
        amount: totalAmount,
        couponCode: couponName.toLocaleUpperCase(),
        gst: calculatePrice?.gst,
        user_type: "venue",
        userId: id,
      })
    ).then((data) => {
      if (data?.payload?.code === 1) {
        toast.success(data.payload.message);
      } else {
        handleCouponPrice();
        toast.error(data.payload.message);
      }
    });
  };

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleOnSubmit = () => {
    dispatch(
      CreateOrderIdReducer({
        amount: parseFloat(calculatePrice?.totalAmount * 100).toFixed(0),
        currency: "INR",
        receipt: unique_id.slice(0, 8),
        ownerId: detail?.venueowner?._id,
        time: values.slot?.[0]?.timeSlotStart,
        date: moment(date).format("DD/MM/YYYY"),
      })
    ).then(async (data) => {
      //console.log(data);
      if (data?.payload?.code === 1) {
        var options = {
          key: payment_key_id,
          amount: "",
          name: "GameHunt Payment",
          description: "GameHunt Transaction",
          image: "https://gamehunt-data.s3.amazonaws.com/logo.png",
          order_id: "",
          prefill: {
            name: userDetail?.firstName
              ? `${userDetail?.firstName} ${userDetail?.lastName}`
              : "",
            email: userDetail?.email,
            contact: userDetail?.mobile
              ? `${userDetail?.countryCode}${userDetail?.mobile}`
              : "",
          },
          handler: function (response) {
            console.log(response);
            const razorpay_Signature = response?.razorpay_signature;
            const razorpay_OrderId = response?.razorpay_order_id;
            const razorpay_PaymentId = response?.razorpay_payment_id;
            const paymentMode = response?.request?.content?.method;
            console.log(paymentMode);
            dispatch(
              PaymentVerifyReducer({
                razorpay_order_id: razorpay_OrderId,
                razorpay_payment_id: razorpay_PaymentId,
                razorpay_signature: razorpay_Signature,
              })
            ).then((res) => {
              if (res?.payload?.code === 1) {
                dispatch(
                  CreateVenueEventReducer({
                    venueId: id,
                    sport: values.sport,
                    skill: values.skill,
                    gender: values.gender,
                    //eventType: values.event,
                    date: moment(date).format("DD/MM/YYYY"),
                    courtId: values.court,
                    slot: JSON.stringify(values.slot),
                    min: values.minCapacity,
                    max: values.maxCapacity,
                    cost: values.totalAmount,
                    gst: calculatePrice?.gst,
                    discount: calculatePrice?.discount,
                    couponCode: couponName,
                    total: calculatePrice?.totalAmount,
                    addPeople: 0,
                    fullBooking: 1,
                    isCoach: values?.wantCoach,
                  })
                ).then(async (data) => {
                  const eventId = data?.payload?.data?.eventbooking?._id;
                  if (data?.payload?.code === 1) {
                    dispatch(
                      AddPaymentReducer({
                        bookingId: eventId,
                        payment_id: razorpay_PaymentId,
                        order_id: razorpay_OrderId,
                        signature: razorpay_Signature,
                        bookingFor: "venue",
                        amount: calculatePrice?.totalAmount,
                        paymentMethod: "UPI",
                      })
                    ).then((data) => {
                      if (data?.payload?.code === 1) {
                        toast.success(data.payload.message);
                        handleShow();
                        navigate("/booking-confirmation", {
                          replace: true,
                        });
                      } else {
                        toast.error(data.payload.message);
                        handleShow();
                      }
                    });
                  } else {
                    toast.error(data.payload.message);
                  }
                });
              } else {
                toast.error(data.payload.message);
                handleShow();
              }
            });
          },
        };
        options.order_id = data?.payload?.data?.id;
        options.amount = data?.payload?.data?.amount;
        //console.log(options);
        openPayModal(options);
      } else {
        toast.error(data.payload.message);
      }
    });
  };

  const Couponlist = useSelector((state) => state.main.CouponList);

  const getCouponList = useCallback(async () => {
    dispatch(
      CouponListReducer({
        isActive: 1,
        user_type: "venue",
        userId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCouponList();
    }
    return () => {
      mounted = false;
    };
  }, [getCouponList]);

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Modal.Body className="">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              handleShow();
              setCouponName("");
            }}
          ></button>
          <div className="">
            <h5 className="text-center">Full Booking Detail</h5>
            <form className="row searchVenues detailPromo">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="position-relative">
                  <input
                    className="form-control"
                    placeholder="Apply Coupon"
                    value={couponName}
                    onChange={(e) => {
                      setCouponName(e.target.value);
                    }}
                    list="CouponList"
                  />
                  <datalist id="CouponList">
                    {Couponlist?.map((item, i) => (
                      <option value={item?.code}>{item?.code}</option>
                    ))}
                  </datalist>
                  <img
                    src="assets/img/Glyph.png"
                    className="couponIcon"
                    alt=""
                  />
                  <span
                    className="inputImg btn-apply"
                    onClick={() => {
                      handleApplyCoupon(couponName);
                    }}
                  >
                    Apply
                  </span>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <table className="table moalTab">
                  <thead>
                    <tr>
                      <th>{detail?.name || "NA"}</th>

                      <th className="text-end">
                        <i className="fa fa-inr"></i> {totalAmount || "0"}
                      </th>
                    </tr>
                    {calculatePrice?.discount !== "0.00" ? (
                      <tr>
                        <th>
                          Promotion code{" "}
                          {couponName
                            ? `(${couponName.toLocaleUpperCase()})`
                            : ""}
                        </th>
                        <th className="text-end">
                          <i className="fa fa-inr"></i>{" "}
                          {parseFloat(calculatePrice?.discount).toFixed(2) ||
                            "0"}
                        </th>
                      </tr>
                    ) : null}

                    <tr>
                      <th>GST({calculatePrice?.gstPercentage}%)</th>
                      <th className="text-end">
                        <i className="fa fa-inr"></i>{" "}
                        {parseFloat(calculatePrice?.gst).toFixed(2)
                          ? parseFloat(calculatePrice?.gst).toFixed(2)
                          : "0"}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="AlreadyEventBox justify-content-start gap-3">
              <img
                src={
                  detail?.image?.[0]?.image?.includes("http")
                    ? detail?.image?.[0]?.image
                    : "assets/img/dummyImage.png"
                }
                alt=""
                className="eventImg"
              />
              <span>
                <h6 className="mb-0">{detail?.name || "NA"}</h6>
                <p className="">
                  {detail?.address ? `${detail?.address}` : "NA"}
                </p>
              </span>
            </div>
            <div className="mt-3">
              <p className="mb-1">Sports</p>
              <h6 className="mb-1 text-capitalize">
                <img
                  src={
                    values.sport === "cricket"
                      ? "assets/img/cricket_ball_red.png"
                      : values.sport === "football"
                      ? "assets/img/Footall_red.png"
                      : values.sport === "basketball"
                      ? "assets/img/Basketball_red.png"
                      : values.sport === "table tennis"
                      ? "assets/img/table_tennis_red.png"
                      : values.sport === "badminton"
                      ? "assets/img/badminton_red.png"
                      : null
                  }
                  alt=""
                  width="20px"
                  className=""
                />{" "}
                {values.sport}
              </h6>
            </div>
            <div className="mt-3">
              <p className="mb-1">Time of play</p>
              <h6 className="mb-1">
                <div className="d-flex fw-bold gap-2 align-items-baseline position-relative ps-4">
                  <img
                    src="assets/img/calender_red.png"
                    alt=""
                    className="TimeofplayIcon"
                  />
                  {moment(date, "DD/MM/YYYY").format("ll")} -{" "}
                  <span>
                    {values.slot?.map((slot, i) => (
                      <p className="mb-0" key={i}>
                        {moment(slot?.timeSlotStart, "hh:mm").format("hh:mm a")}{" "}
                        - {moment(slot?.timeSlotEnd, "hh:mm").format("hh:mm a")}
                      </p>
                    ))}
                  </span>
                </div>
              </h6>
            </div>
            <div className="bg-white py-3 mt-4">
              <div className="d-flex align-items-center justify-content-between px-4">
                <p className="mb-1 fw-bold">Total Amount</p>
                <h6 className="mb-1 theme-color">
                  <i className="fa fa-inr"></i>{" "}
                  {calculatePrice?.totalAmount
                    ? parseFloat(calculatePrice?.totalAmount).toFixed(2)
                    : "0"}
                  /-
                </h6>
              </div>

              <div className="mt-3">
                <div className="view-btns">
                  <button
                    className="btn-view btn-accept"
                    onClick={() => {
                      handleOnSubmit();
                    }}
                  >
                    Proceed To Pay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FullBookingDetailModal;
