import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AddPaymentReducer,
  calculatePriceReducer,
  CreateOrderIdReducer,
  JoinCreatedEventdetailReducer,
  joinEventBookingReducer,
  PaymentVerifyReducer,
} from "../../features/slice";
import { payment_key_id } from "../../Utils";
import { v4 as uuid } from "uuid";
import { verifyBlockUserReducer } from "./../../features/slice";

function JoinAnExistingEventModal({
  show,
  handleShow,
  createdEventId,
  minCapacity,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unique_id = uuid();

  const [couponName, setCouponName] = useState("");

  const [peopleCount, setPeopleCount] = useState("1");
  const userDetail = useSelector((state) => state.main.userDetail);
  const [newPrice, setNewPrice] = useState("0");
  const [remainingPeople, setRemainingPeople] = useState("");

  const [arrSlot, setArrSlot] = useState([]);

  const detail = useSelector((state) => state.main.AllReadyCreatedEventdetail);

  console.log(detail)

  // console.log(moment(detail?.date).format("DD/MM/YYYY"))
  // console.log(detail?.slot?.[0].timeSlotStart)

  const getAllReadyCreatedEvent = useCallback(async () => {
    if (createdEventId) {
      dispatch(
        JoinCreatedEventdetailReducer({
          eventbookingId: createdEventId,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          //setPeopleCount(res?.payload?.data?.addPeople);

          let arrSlot2 = res?.payload?.data?.slot;
          if (arrSlot2?.length > 0) {
            let amp = 0;
            arrSlot2?.forEach((item) => {
              amp = Number(item?.price) + amp;
              //console.log(amp);
            });
            amp = (amp / minCapacity) * Number(peopleCount);
            setNewPrice(amp);
          } else {
            //setFieldValue("totalAmount", 0);
          }
          setArrSlot(res?.payload?.data?.slot);
          setRemainingPeople(
            `${res?.payload?.data?.max}` - `${res?.payload?.data?.addPeople}`
          );
        }
      });
    }
  }, [dispatch, createdEventId, minCapacity, peopleCount]);

  useEffect(() => {
    getAllReadyCreatedEvent();
  }, [getAllReadyCreatedEvent]);

  const handleAmount = (arrSlot, peopleCount, minCapacity) => {
    if (arrSlot?.length > 0) {
      let amp = 0;
      arrSlot?.forEach((item) => {
        amp = Number(item?.price) + amp;
        console.log(amp);
      });
      amp = (amp / minCapacity) * Number(peopleCount);
      setNewPrice(amp);
    } else {
      //setFieldValue("totalAmount", 0);
    }
  };

  const calculatePrice = useSelector((state) => state.main.calculatePrice);

  const handleCouponPrice = useCallback(async () => {
    dispatch(
      calculatePriceReducer({
        amount: newPrice,
        //couponCode: couponName.toLocaleUpperCase(),
      })
    );
  }, [dispatch, newPrice]);

  useEffect(() => {
    handleCouponPrice();
  }, [handleCouponPrice]);

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleOnSubmit = () => {
    dispatch(verifyBlockUserReducer({ eventbookingId: createdEventId })).then(
      async (data) => {
        if (data?.payload?.code === 1) {
          dispatch(
            CreateOrderIdReducer({
              amount: parseFloat(calculatePrice?.totalAmount * 100).toFixed(0),
              currency: "INR",
              receipt: unique_id.slice(0, 8),
              ownerId: detail?.venueId?.VenueOwnerId,
              time: detail?.slot?.[0].timeSlotStart,
              date: moment(detail?.date).format("DD/MM/YYYY"),
            })
          ).then(async (data) => {
            //console.log(data);
            if (data?.payload?.code === 1) {
              var options = {
                key: payment_key_id,
                amount: "",
                name: "GameHunt Payment",
                description: "GameHunt Transaction",
                image: "https://gamehunt-data.s3.amazonaws.com/logo.png",
                order_id: "",
                prefill: {
                  name: userDetail?.firstName
                    ? `${userDetail?.firstName} ${userDetail?.lastName}`
                    : "",
                  email: userDetail?.email,
                  contact: userDetail?.mobile
                    ? `${userDetail?.countryCode}${userDetail?.mobile}`
                    : "",
                },
                handler: function (response) {
                  console.log(response.method);
                  const razorpay_Signature = response?.razorpay_signature;
                  const razorpay_OrderId = response?.razorpay_order_id;
                  const razorpay_PaymentId = response?.razorpay_payment_id;
                  //const paymentMode = response?.request?.content?.method;
                  //console.log(paymentMode);
                  dispatch(
                    PaymentVerifyReducer({
                      razorpay_order_id: razorpay_OrderId,
                      razorpay_payment_id: razorpay_PaymentId,
                      razorpay_signature: razorpay_Signature,
                    })
                  ).then((res) => {
                    if (res?.payload?.code === 1) {
                      dispatch(
                        joinEventBookingReducer({
                          eventbookingId: createdEventId,
                          gst: calculatePrice?.gst,
                          total: calculatePrice?.totalAmount,
                          addPeople: peopleCount,
                        })
                      ).then((data) => {
                        if (data?.payload?.code === 1) {
                          //const eventId = data?.payload?.data?.eventbooking?._id;
                          //console.log(eventId);
                          dispatch(
                            AddPaymentReducer({
                              bookingId: createdEventId,
                              payment_id: razorpay_PaymentId,
                              order_id: razorpay_OrderId,
                              signature: razorpay_Signature,
                              bookingFor: "venue",
                              amount: calculatePrice?.totalAmount,
                              paymentMethod: "UPI",
                            })
                          ).then((data) => {
                            if (data?.payload?.code === 1) {
                              toast.success(data.payload.message);
                              handleShow();
                              navigate("/booking-confirmation", {
                                replace: true,
                              });
                            } else {
                              toast.error(data.payload.message);
                              handleShow();
                            }
                          });
                        } else {
                          toast.error(data.payload.message);
                          handleShow();
                        }
                      });
                    } else {
                      toast.error(data.payload.message);
                      handleShow();
                    }
                  });
                },
              };
              options.order_id = data?.payload?.data?.id;
              options.amount = data?.payload?.data?.amount;
              //console.log(options);
              openPayModal(options);
            } else {
              toast.error(data.payload.message);
            }
          });
        } else {
          toast.error(data.payload.message);
        }
      }
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Modal.Body className="">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              handleShow();
              setCouponName("");
              setPeopleCount("1");
            }}
          ></button>
          <div className="">
            <h5 className="text-center">Join an existing event</h5>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <table className="table moalTab text-capitalize">
                  <thead>
                    <tr>
                      <th>{detail?.venueId?.name || "NA"}</th>
                      <th>
                        <div className="peopenNum countBox">
                          <div className="bg-white countValue shadow rounded border-0">
                            {peopleCount}
                          </div>
                          <div className="BtnIncDec">
                            {remainingPeople === peopleCount ||
                            remainingPeople === 0 ||
                            remainingPeople === 1 ? (
                              <span className="cursorNotWork">
                                <i className="fa fa-angle-up"></i>
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  setPeopleCount(parseInt(peopleCount) + 1);
                                  handleAmount(
                                    arrSlot,
                                    parseInt(peopleCount) + 1,
                                    minCapacity
                                  );
                                }}
                              >
                                <i className="fa fa-angle-up"></i>
                              </span>
                            )}

                            <span
                              onClick={() => {
                                if (peopleCount > 1) {
                                  setPeopleCount(parseInt(peopleCount) - 1);
                                  handleAmount(
                                    arrSlot,
                                    peopleCount - 1,
                                    minCapacity
                                  );
                                }
                              }}
                            >
                              <i className="fa fa-angle-down"></i>
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className="text-end">
                        <i className="fa fa-inr"></i>{" "}
                        {parseFloat(newPrice && newPrice).toFixed(2) || "0"}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>GST({calculatePrice?.gstPercentage}%)</th>
                      <th className="text-end">
                        <i className="fa fa-inr"></i>{" "}
                        {parseFloat(calculatePrice?.gst).toFixed(2) || "0"}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="AlreadyEventBox justify-content-start gap-3">
              <img
                src={
                  detail?.venueId?.image?.[0]?.image?.includes("http")
                    ? detail?.venueId?.image?.[0]?.image
                    : "assets/img/dummyImage.png"
                }
                alt=""
                className="eventImg"
              />
              <span>
                <h6 className="mb-0">{detail?.venueId?.name || "NA"}</h6>
                <p className="">
                  {detail?.venueId?.address
                    ? `${detail?.venueId?.address}`
                    : "NA"}
                </p>
              </span>
            </div>
            <div className="mt-3">
              <p className="mb-1">Sports</p>
              <h6 className="mb-1 text-capitalize">
                <img
                  src={
                    detail.sport === "cricket"
                      ? "assets/img/cricket_ball_red.png"
                      : detail.sport === "football"
                      ? "assets/img/Footall_red.png"
                      : detail.sport === "basketball"
                      ? "assets/img/Basketball_red.png"
                      : detail.sport === "table tennis"
                      ? "assets/img/table_tennis_red.png"
                      : detail.sport === "badminton"
                      ? "assets/img/Badmintion_red.png"
                      : null
                  }
                  alt=""
                  width="20px"
                  className=""
                />{" "}
                {detail.sport}
              </h6>
            </div>
            <div className="mt-3">
              <p className="mb-1">Date & Time of play</p>
              <h6 className="mb-1">
                <div className="d-flex fw-bold gap-2 align-items-baseline position-relative ps-4">
                  <img
                    src="assets/img/calender_red.png"
                    alt=""
                    className="TimeofplayIcon"
                  />
                  {moment(detail?.date).format("ll")} -{" "}
                  <span>
                    {detail?.slot?.map((slot, i) => (
                      <p className="mb-0" key={i}>
                        {moment(slot?.timeSlotStart, "hh:mm").format("hh:mm a")}{" "}
                        - {moment(slot?.timeSlotEnd, "hh:mm").format("hh:mm a")}
                      </p>
                    ))}
                  </span>
                </div>
              </h6>
            </div>
            <div className="mt-3">
              <p className="mb-1">Booked By</p>
              <h6 className="mb-1">
                <img
                  src="assets/img/add_user_users_icon.png"
                  alt=""
                  className=""
                />{" "}
                {detail?.addPeople} People
              </h6>
            </div>
            <div className="bg-white py-3 mt-4">
              <div className="d-flex align-items-center justify-content-between px-4">
                <p className="mb-1 fw-bold">Total Amount</p>
                <h6 className="mb-1 theme-color">
                  <i className="fa fa-inr"></i>{" "}
                  {calculatePrice?.totalAmount
                    ? parseFloat(calculatePrice?.totalAmount).toFixed(2)
                    : "0"}
                  /-
                </h6>
              </div>

              <div className="mt-3">
                <div className="view-btns">
                  <button
                    onClick={() => {
                      handleOnSubmit();
                    }}
                    className="btn-view btn-accept"
                  >
                    Proceed To Pay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default JoinAnExistingEventModal;
