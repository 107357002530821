import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import HomeHeader from "../Components/HomeHeader";
import VenueHeader from "../Components/VenueHeader";
import CoachHeader from "../Components/CoachHeader";

function PrivateLayout() {
  const loginType = localStorage.getItem("loginType");
  return (
    <>
      {loginType === "venue" ? (
        <VenueHeader className="loginAfter" />
      ) : loginType === "coach" ? (
        <CoachHeader className="loginAfter" />
      ) : loginType === "user" ? (
        <HomeHeader className="loginAfter" />
      ) : (
        <HomeHeader className="loginAfter" />
      )}

      <Outlet />
      <Footer />
    </>
  );
}

export default PrivateLayout;
