import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CoachVenueBookingDetailReducer } from "../features/CoachVenueslice";

function CoachBookingDetailModal({ show, handleShow, id }) {
  const dispatch = useDispatch();
  const detail = useSelector(
    (state) => state.CoachVenueMain.CoachBookingDetail
  );

  const getSchoolDetail = useCallback(async () => {
    dispatch(
      CoachVenueBookingDetailReducer({
        coachingappointmentId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getSchoolDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getSchoolDetail]);

  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="form-style bg-white p-3 mb-3">
                  <h4 className="mb-4 text-center">Booking Detail</h4>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="d-flex align-items-center gap-3 text-capitalize">
                        <img
                          src={
                            detail?.normaluser?.picture?.includes("http")
                              ? detail?.normaluser?.picture
                              : "assets/img/employe.png"
                          }
                          className="CurriculumImges"
                          alt=""
                        />
                        <span>
                          <p className="mb-0">user name</p>
                          <b>
                            {detail?.normaluser?.firstName
                              ? `${detail?.normaluser?.firstName} ${detail?.normaluser?.lastName}`
                              : "NA"}
                          </b>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/feedback_rating_review_icon.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Review Ratings</p>
                        <h6 className="mb-1">{detail?.rating || "0"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/mobile.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Contact No.</p>
                        <h6 className="mb-1">
                          {detail?.normaluser?.mobile
                            ? `${detail?.normaluser?.countryCode} ${detail?.normaluser?.mobile}`
                            : "NA"}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/email.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Email Address</p>
                        <h6 className="mb-1">
                          {detail?.normaluser?.email || "NA"}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/TypeOfCoaching.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Sports</p>
                        <h6 className="mb-1">{detail?.sport || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/calendar_date.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Start Date</p>
                        <h6 className="mb-1">
                          {moment(detail?.startDate, "DD/MM/YYYY").format(
                            "DD/MM/YYYY"
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/calendar_date.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">End Date</p>
                        <h6 className="mb-1">
                          {moment(detail?.endDate, "DD/MM/YYYY").format(
                            "DD/MM/YYYY"
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/Duration.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Duration</p>
                        <h6 className="mb-1">
                          {detail?.isTrail === true ? "0" : detail?.duration} m
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/Slots.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Slot</p>
                        <h6 className="mb-1">
                          {moment(detail?.timeing, "hh:mm").format("hh:mm a")}
                        </h6>
                      </div>
                    </div>
                    <div
                      className={
                        detail?.isTrail === true
                          ? "d-none"
                          : "col-sm-12 col-md-12 col-lg-6"
                      }
                    >
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/BookingID.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Booking ID</p>
                        <h6 className="mb-1">{detail?.bookingId || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/BookingdateTime.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Booking Date & Time</p>
                        <h6 className="mb-1">
                          {moment(detail?.time).format("DD-MM-YYYY")} at{" "}
                          {moment(detail?.time, "hh:mm:ss[Z]").format(
                            "hh:mm a"
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/amountpaid.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Amount Paid</p>
                        <h6 className="mb-1">
                          {detail?.isTrail === true ? (
                            ""
                          ) : (
                            <i className="fa fa-inr"></i>
                          )}
                          {detail?.isTrail === true
                            ? "Free Trial"
                            : `${parseFloat(detail?.total).toFixed(2)}/-`}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CoachBookingDetailModal;
