import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Paginations from "../../Components/Paginations";
import { CouponListReducer } from "../slice";

function Coupons() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const handlePage = (value) => {
    setPage(value);
  };

  const list = useSelector((state) => state.main.CouponList);
  const total = useSelector((state) => state.main.total_couponcode);

  const getCouponList = useCallback(async () => {
    setLoading(true);
    dispatch(
      CouponListReducer({
        isActive: 1,
        page: page,
        limit: 10,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getCouponList();
    }
    return () => {
      mounted = false;
    };
  }, [getCouponList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-5">Exclusive Coupons</h3>
              </div>
            </div>
            {loading ? (
              <Loader colorChange="colorChange" />
            ) : (
              <>
                <div className="row mt-3">
                  {list?.length > 0 ? (
                    list?.map((item, i) => (
                      <div
                        className="col-sm-6 col-md-6 col-lg-4 mb-5 couponlist"
                        key={i}
                      >
                        <div className="AlreadyEvent couponBox position-relative">
                          <div className="couponBoxRow">
                            <div className="CodeOffBg">
                              <div className="circlePonts">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="codeNum">
                                {item?.percentage
                                  ? `${item?.percentage || "0"}%OFF`
                                  : `₹${item?.amount || "0"} OFF`}
                              </div>
                            </div>
                            <div className="w-100 py-3">
                              <div className="AlreadyEventBox gap-2 justify-content-start">
                                <div className="couponCircle">
                                  {item?.name.slice(0, 2)}
                                </div>
                                <span>
                                  <h6 className="mb-0 text-uppercase">
                                    {item?.code || "NA"}
                                  </h6>
                                  <p className="mb-0 text-success fw-bold">
                                    {item?.name || "NA"}
                                  </p>
                                  {/* <p className="mb-0 text-success fw-bold">
                                Save <i className="fa fa-inr"></i> 90.00 On this
                                order
                              </p> */}
                                </span>
                              </div>
                              <hr />
                              <div className="">
                                <p>
                                  Use this code {item?.code || "NA"} &{" "}
                                  {item?.percentage
                                    ? `${item?.percentage || "0"}% off`
                                    : `₹${item?.amount || "0"} off`}{" "}
                                  on order
                                </p>
                              </div>
                              {/* <div className="text-end">
                            <button className="border-0 btn theme-color fw-bold">
                              Apply
                            </button>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">No Data Found</div>
                  )}
                </div>
                <div className="text-center">
                  {list?.length > 0 && (
                    <Paginations
                      handlePage={handlePage}
                      page={page}
                      total={total && total}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </section>
      </main>
    </>
  );
}

export default Coupons;
