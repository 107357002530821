import React, { useEffect, useState } from "react";
import { useCallback } from "react";

function FAQS() {
  const [show, setShow] = useState(true);
  const [toggleid, setToggleId] = useState(0);
  const [faqTabFor, setFaqTabFor] = useState("about");

  const toggle = useCallback(() => {
    setShow(!show);
  }, [faqTabFor, show]);

  const handleToggle = (tab, isOpen) => {
    if (tab === faqTabFor && show !== isOpen) {
      setShow(!show);
    } else if (tab !== faqTabFor && show !== isOpen) {
      setFaqTabFor(tab);
      setShow(true);
    }
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody" className="faqFont">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center position-relative">
                <h4 className="faqTitle">FAQ's</h4>
                <img src="assets/img/FAQ.png" alt="" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mb-4">
                <div className="bg-white p-3 outerSide">
                  <button
                    type="button"
                    className={
                      show && faqTabFor === "about"
                        ? "mainTitile iconflip"
                        : "mainTitile"
                    }
                    onClick={() => {
                      // setFaqTabFor("about");
                      // toggle();
                      handleToggle("about", !show);
                    }}
                  >
                    GameHunt
                  </button>
                  <div
                    className={
                      show && faqTabFor === "about"
                        ? "collapse multi-collapse show"
                        : "collapse multi-collapse"
                    }
                  >
                    <div className="card card-body border-0">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="AboutOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#AboutQuestioncollapseOne"
                              aria-expanded="true"
                              aria-controls="AboutQuestioncollapseOne"
                            >
                              1.How many sports are listed in The Gamehunt?
                            </button>
                          </h2>
                          <div
                            id="AboutQuestioncollapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="AboutOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              The Gamehunt provides all round services for 5
                              different sports. Which are Cricket, Football,
                              Batminton, Lawn Tennis and Swimming. We also have
                              various trainers for yoga.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="aboutTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#AboutQuestioncollapseTwo"
                              aria-expanded="false"
                              aria-controls="AboutQuestioncollapseTwo"
                            >
                              2.In which cities does Gamehunt provide it’s
                              services?
                            </button>
                          </h2>
                          <div
                            id="AboutQuestioncollapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="aboutTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Currently, The Gamehunt is operational only in
                              Mumbai. However, we are expanding our services to
                              other major cities in the country, which will be
                              updated on the website.
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="aboutThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#AboutQuestioncollapseThree"
                              aria-expanded="false"
                              aria-controls="AboutQuestioncollapseThree"
                            >
                              What if my child has already enrolled for
                              programs/sports outside the school, how is
                              Gamehunt Academy different and better?
                            </button>
                          </h2>
                          <div
                            id="AboutQuestioncollapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="aboutThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Gamehunt Academy offers top-notch facilities for
                              growth by our verified and trained professional
                              coaches/trainers who are certified in their
                              respective domains offering the best in Sports
                              Training, Mental Fitness, and Personal Development
                              for your child’s growth and building Future
                              Professional Athletes in India
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="aboutfour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#AboutQuestioncollapsefour"
                              aria-expanded="false"
                              aria-controls="AboutQuestioncollapsefour"
                            >
                              What is the difference between Gamehunt and
                              Gamehunt Academy?
                            </button>
                          </h2>
                          <div
                            id="AboutQuestioncollapsefour"
                            className="accordion-collapse collapse"
                            aria-labelledby="aboutfour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Gamehunt Academy is a part of Gamehunt platform
                              which provides a 360-degree solution to schools
                              and students. We do that by providing customised
                              sporting solutions to students & parents looking
                              for guidance, knowledge, and tools to hone skills
                              for different levels of play. At the same time, we
                              are also a platform which lowers the barriers and
                              improves the ease of access to sports
                              infrastructure, training camps, trained coaches,
                              and life skills experts for schools who are
                              looking for ways to help their students deal with
                              the ever-increasing academic pressure.  On the
                              other hand, the Game hunt is a platform which
                              serves everyone and just not schools. We have
                              different inventories, training camps, a list of
                              coaches and online sessions listed on the Gamehunt
                              platform. Users can pay and book their respective
                              sports in just one click. Hassle of searching for
                              the right venue is no longer a problem, visit
                              Gamehunt and find all the solutions for your
                              interests in sports.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="aboutfive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#AboutQuestioncollapsefive"
                              aria-expanded="false"
                              aria-controls="AboutQuestioncollapsefive"
                            >
                              How to contact Gamehunt Academy?
                            </button>
                          </h2>
                          <div
                            id="AboutQuestioncollapsefive"
                            className="accordion-collapse collapse"
                            aria-labelledby="aboutfive"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Please fill in your details on the website or
                              write to us at play@thegamehunt.com  Our team will
                              contact you in less than two working days.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="aboutsix">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#AboutQuestioncollapsesix"
                              aria-expanded="false"
                              aria-controls="AboutQuestioncollapsesix"
                            >
                              How can Gamehunt be used for Distance Learning?
                            </button>
                          </h2>
                          <div
                            id="AboutQuestioncollapsesix"
                            className="accordion-collapse collapse"
                            aria-labelledby="aboutsix"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              We have a team of coaches and experts who have
                              years of experience in online training and
                              coaching. We create structure in such a way that
                              students enjoy online classes and keep getting
                              productive every day. During Covid, our trainers
                              served more than 40 schools and 350 clients across
                              the country.
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-4">
                <div className="bg-white p-3 outerSide">
                  <button
                    type="button"
                    className={
                      show && faqTabFor === "school"
                        ? "mainTitile iconflip"
                        : "mainTitile"
                    }
                    onClick={() => {
                      // setFaqTabFor("school");
                      // toggle();
                      handleToggle("school", !show);
                    }}
                  >
                    Venues
                  </button>
                  <div
                    className={
                      show && faqTabFor === "school"
                        ? "collapse multi-collapse show"
                        : "collapse multi-collapse"
                    }
                  >
                    <div className="card card-body border-0">
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="schoolOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#SchoolcollapseOne"
                              aria-expanded="true"
                              aria-controls="SchoolcollapseOne"
                            >
                              1. How can I search for sports venues?
                            </button>
                          </h2>
                          <div
                            id="SchoolcollapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="schoolOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              In search option, choose location{" "}
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>{" "}
                              Filter the area and sports{" "}
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>{" "}
                              Sort out the filter. Click on Apply filters.
                              Select your preferred venue from a wide range of
                              options.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="SchoolTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#SchoolcollapseTwo"
                              aria-expanded="false"
                              aria-controls="SchoolcollapseTwo"
                            >
                              2. Where are the venues?
                            </button>
                          </h2>
                          <div
                            id="SchoolcollapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="SchoolTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              We have venues from across Mumbai listed with us.
                              You can choose your preferred venue according to
                              your desired location.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="SchoolThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#SchoolcollapseThree"
                              aria-expanded="false"
                              aria-controls="SchoolcollapseThree"
                            >
                              3. Why can't I find venues near me?
                            </button>
                          </h2>
                          <div
                            id="SchoolcollapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="SchoolThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              Adjust your location or filter the preferences
                              search again. If you still don’t find it, write to
                              us at{" "}
                              <a href="mailto:play@thegamehunt.com">
                                play@thegamehunt.com
                              </a>{" "}
                              and we will try to add your desired location on
                              our platform.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="School4">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Schoolcollapse4"
                              aria-expanded="false"
                              aria-controls="Schoolcollapse4"
                            >
                              4. Do I have to carry my own equipment to the
                              venue?
                            </button>
                          </h2>
                          <div
                            id="Schoolcollapse4"
                            className="accordion-collapse collapse"
                            aria-labelledby="School4"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              The information regarding gears and equipment will
                              be listed below each sports venue. Kindly go
                              through the information before booking your
                              desired venue.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="School5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Schoolcollapse5"
                              aria-expanded="false"
                              aria-controls="Schoolcollapse5"
                            >
                              5. What is the significance of venue rating?
                            </button>
                          </h2>
                          <div
                            id="Schoolcollapse5"
                            className="accordion-collapse collapse"
                            aria-labelledby="School5"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              The venue rating is the average of ratings given
                              by Gamehunt users for a particular venue. These
                              ratings help us improve your experience during
                              your future visit.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="School6">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Schoolcollapse6"
                              aria-expanded="false"
                              aria-controls="Schoolcollapse6"
                            >
                              6. How do I report an issue for the venue?
                            </button>
                          </h2>
                          <div
                            id="Schoolcollapse6"
                            className="accordion-collapse collapse"
                            aria-labelledby="School6"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              Please share your valuable feedback with us at{" "}
                              <a href="mailto:play@thegamehunt.com">
                                play@thegamehunt.com
                              </a>
                              .
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="School7">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Schoolcollapse7"
                              aria-expanded="false"
                              aria-controls="Schoolcollapse7"
                            >
                              7. Is there any limit to the number of hours I can
                              book at a particular event?
                            </button>
                          </h2>
                          <div
                            id="Schoolcollapse7"
                            className="accordion-collapse collapse"
                            aria-labelledby="School7"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              No, there is no set limit to the number of hours
                              you can book at a particular venue.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="School8">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Schoolcollapse8"
                              aria-expanded="false"
                              aria-controls="Schoolcollapse8"
                            >
                              8. Can the user book a venue offline?
                            </button>
                          </h2>
                          <div
                            id="Schoolcollapse8"
                            className="accordion-collapse collapse"
                            aria-labelledby="School8"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              The Gamehunt only offers online booking options
                              for the listed venues. We are not responsible for
                              any offline bookings made by the user.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="School9">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Schoolcollapse9"
                              aria-expanded="false"
                              aria-controls="Schoolcollapse9"
                            >
                              9. How can I book a venue?
                            </button>
                          </h2>
                          <div
                            id="Schoolcollapse9"
                            className="accordion-collapse collapse"
                            aria-labelledby="School9"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              There are two ways to book a venue:
                              <ol start="a" type="a">
                                <li>
                                  After logging in, navigate to the 'Venues near
                                  me' section on the home page, select your
                                  preferred venue, and reserve a slot.
                                </li>
                                <li>
                                  After logging in, go to View all Venues -
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  search for the venue -
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  choose your preferred venue -
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  book the slot.
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="School10">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Schoolcollapse10"
                              aria-expanded="false"
                              aria-controls="Schoolcollapse10"
                            >
                              10. Can the user book a venue offline?
                            </button>
                          </h2>
                          <div
                            id="Schoolcollapse10"
                            className="accordion-collapse collapse"
                            aria-labelledby="School10"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              The Gamehunt only offers online booking options
                              for the listed venues. We are not responsible for
                              any offline bookings made by the user.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-4">
                <div className="bg-white p-3 outerSide">
                  <button
                    type="button"
                    className={
                      show && faqTabFor === "student"
                        ? "mainTitile iconflip"
                        : "mainTitile"
                    }
                    onClick={() => {
                      // setFaqTabFor("about");
                      // toggle();
                      handleToggle("student", !show);
                    }}
                  >
                    Coaches & Trainers
                  </button>
                  <div
                    className={
                      show && faqTabFor === "student"
                        ? "collapse multi-collapse show"
                        : "collapse multi-collapse"
                    }
                  >
                    <div className="card card-body border-0">
                      <div className="accordion" id="accordionExample2">
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="StudentOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#StudentcollapseOne"
                              aria-expanded="true"
                              aria-controls="StudentcollapseOne"
                            >
                              1. How can I find a coach near my location?
                            </button>
                          </h2>
                          <div
                            id="StudentcollapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="StudentOne"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              There are two ways to book a coach.
                              <ol start="a" type="a">
                                <li>
                                  Once you log in, move to the ‘Coaching Centers
                                  Near Me’ section on the home page, select the
                                  coach and book your slot.
                                </li>
                                <li>
                                  Once you log in, click on Coaching Centre -
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  search for the Coaching centre -
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  select the coaching centre -
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  pay for the coaching subscription.
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="StudentTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#StudentcollapseTwo"
                              aria-expanded="false"
                              aria-controls="StudentcollapseTwo"
                            >
                              2. How long will I have access to the coach?
                            </button>
                          </h2>
                          <div
                            id="StudentcollapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="StudentTwo"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              We offer coaching packages of three, six, and
                              twelve months. Access to the coach will be
                              provided based on the package. Furthermore, all of
                              our coaches are always friendly and approachable.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="StudentThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#StudentcollapseThree"
                              aria-expanded="false"
                              aria-controls="StudentcollapseThree"
                            >
                              3. Can I change the coach in the middle of the
                              training period?
                            </button>
                          </h2>
                          <div
                            id="StudentcollapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="StudentThree"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              Gamehunt offers the best-in-class seasoned coaches
                              who have gone through a rigorous screening
                              process. If you want to change a coach, send an
                              email to{" "}
                              <a href="mailto:play@thegamehunt.com">
                                play@thegamehunt.com
                              </a>{" "}
                              with your reasons. Our team will contact you for
                              your feedback and suggestions for next steps.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student4">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse4"
                              aria-expanded="false"
                              aria-controls="Studentcollapse4"
                            >
                              4. Will the training hours differ for each and
                              every coach?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse4"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student4"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              Yes, training will be scheduled according to both
                              your and the coach's availability.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse5"
                              aria-expanded="false"
                              aria-controls="Studentcollapse5"
                            >
                              5.Will I be able to compete with other players
                              under the coach?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse5"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student5"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              Yes, the coach will inform you once you are
                              prepared to face off against other players.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student6">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse6"
                              aria-expanded="false"
                              aria-controls="Studentcollapse6"
                            >
                              6. How will Gamehunt match me with the appropriate
                              coach?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse6"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student6"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              The Gamehunt offers coaches who have undergone a
                              rigorous screening process and are the best in
                              their field.
                              <br /> The Gamehunt algorithm will choose the best
                              coach for you based on your skill set and
                              location.
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student7">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse7"
                              aria-expanded="false"
                              aria-controls="Studentcollapse7"
                            >
                              How will these programs help my students?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse7"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student7"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              We help the Indian youth achieve that ‘extra
                              edge’, which youngsters of other countries have;
                              be it the right sporting infrastructure or the
                              relevant coaching guidance, we are the bridge
                              between dreams and accomplishments for sports in
                              India. And we do this in close conjunction with
                              schools and provide it on one platter to students
                              by training them on mind, body and spirit.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student8">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse8"
                              aria-expanded="false"
                              aria-controls="Studentcollapse8"
                            >
                              How can I enrol my students for Gamehunt Academy?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse8"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student8"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              Please write to us at{" "}
                              <a href="mailto:play@thegamehunt.com">
                                play@thegamehunt.com
                              </a>{" "}
                              and our team will connect with you.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student9">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse9"
                              aria-expanded="false"
                              aria-controls="Studentcollapse9"
                            >
                              How to register for sports coaching directly to
                              you?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse9"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student9"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              Contact the Gamehunt Academy Counsellor at your
                              school if assigned or Please write to us at{" "}
                              <a href="mailto:play@thegamehunt.com">
                                play@thegamehunt.com
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student10">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse10"
                              aria-expanded="false"
                              aria-controls="Studentcollapse10"
                            >
                              What are the charges for private coaching for
                              sports?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse10"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student10"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              Every sport has different rates for coaching. We
                              have provided the brochure to the schools.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Student11">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Studentcollapse11"
                              aria-expanded="false"
                              aria-controls="Studentcollapse11"
                            >
                              Does Gamehunt Academy give homework for
                              post-school hours?
                            </button>
                          </h2>
                          <div
                            id="Studentcollapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="Student11"
                            data-bs-parent="#accordionExample2"
                          >
                            <div className="accordion-body">
                              No, the sessions are very practical in nature and
                              it's up to the students to practise and come for
                              future classes.
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-4">
                <div className="bg-white p-3 outerSide">
                  <button
                    type="button"
                    className={
                      show && faqTabFor === "Payment"
                        ? "mainTitile iconflip"
                        : "mainTitile"
                    }
                    onClick={() => {
                      // setFaqTabFor("about");
                      // toggle();
                      handleToggle("Payment", !show);
                    }}
                  >
                    Payment
                  </button>
                  <div
                    className={
                      show && faqTabFor === "Payment"
                        ? "collapse multi-collapse show"
                        : "collapse multi-collapse"
                    }
                  >
                    <div className="card card-body border-0">
                      <div className="accordion" id="accordionExample3">
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="PaymentOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#PaymentcollapseOne"
                              aria-expanded="true"
                              aria-controls="PaymentcollapseOne"
                            >
                              1.What modes of payments are accepted?
                            </button>
                          </h2>
                          <div
                            id="PaymentcollapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="PaymentOne"
                            data-bs-parent="#accordionExample3"
                          >
                            <div className="accordion-body">
                              The Gamehunt accepts all payment methods,
                              including debit cards, credit cards, net banking,
                              and UPI.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="PaymentTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#PaymentcollapseTwo"
                              aria-expanded="false"
                              aria-controls="PaymentcollapseTwo"
                            >
                              2. In how many days will I receive my refund?
                            </button>
                          </h2>
                          <div
                            id="PaymentcollapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="PaymentTwo"
                            data-bs-parent="#accordionExample3"
                          >
                            <div className="accordion-body">
                              All refunds will reflect in your account in 6-7
                              business days.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="PaymentThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#PaymentcollapseThree"
                              aria-expanded="false"
                              aria-controls="PaymentcollapseThree"
                            >
                              3. How do I identify a transaction made with
                              Gamehunt in my debit or credit statement?
                            </button>
                          </h2>
                          <div
                            id="PaymentcollapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="PaymentThree"
                            data-bs-parent="#accordionExample3"
                          >
                            <div className="accordion-body">
                              You will see a statement such as “amount
                              debited/credited to Gamehunt”. (Is this question
                              required?) (
                              <i>
                                We only need this question if our trade company
                                is not Gamehunt. We don’t need this otherwise.
                              </i>
                              )
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Payment4">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Paymentcollapse4"
                              aria-expanded="false"
                              aria-controls="Paymentcollapse4"
                            >
                              4. How to remove the previously saved card details
                              on the website?
                            </button>
                          </h2>
                          <div
                            id="Paymentcollapse4"
                            className="accordion-collapse collapse"
                            aria-labelledby="Payment4"
                            data-bs-parent="#accordionExample3"
                          >
                            <div className="accordion-body">
                              We have asked this query to Razor pay, awaiting
                              reply.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Payment5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Paymentcollapse5"
                              aria-expanded="false"
                              aria-controls="Paymentcollapse5"
                            >
                              5. How to add more than one payment method on the
                              website?
                            </button>
                          </h2>
                          <div
                            id="Paymentcollapse5"
                            className="accordion-collapse collapse"
                            aria-labelledby="Payment5"
                            data-bs-parent="#accordionExample3"
                          >
                            <div className="accordion-body">
                              We have asked this query to Razor pay, awaiting
                              reply.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Payment6">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Paymentcollapse6"
                              aria-expanded="false"
                              aria-controls="Paymentcollapse6"
                            >
                              6. What modes of payment are accepted?
                            </button>
                          </h2>
                          <div
                            id="Paymentcollapse6"
                            className="accordion-collapse collapse"
                            aria-labelledby="Payment6"
                            data-bs-parent="#accordionExample3"
                          >
                            <div className="accordion-body">
                              We accept all Debit cards, Credit cards, and Net
                              banking and UPI payments. We also accept
                              international credit cards
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Payment7">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Paymentcollapse7"
                              aria-expanded="false"
                              aria-controls="Paymentcollapse7"
                            >
                              7.Is there a registration fee for a new user to
                              create a profile on Gamehunt?
                            </button>
                          </h2>
                          <div
                            id="Paymentcollapse7"
                            className="accordion-collapse collapse"
                            aria-labelledby="Payment7"
                            data-bs-parent="#accordionExample3"
                          >
                            <div className="accordion-body">
                              There are no registration charges for a new user.
                              You can simply go to The Gamehunt website, create
                              an account and immediately start benefiting from
                              our services.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-4">
                <div className="bg-white p-3 outerSide">
                  <button
                    type="button"
                    className={
                      show && faqTabFor === "Rescheduling"
                        ? "mainTitile iconflip"
                        : "mainTitile"
                    }
                    onClick={() => {
                      // setFaqTabFor("about");
                      // toggle();
                      handleToggle("Rescheduling", !show);
                    }}
                  >
                    Rescheduling, cancellations & refunds
                  </button>
                  <div
                    className={
                      show && faqTabFor === "Rescheduling"
                        ? "collapse multi-collapse show"
                        : "collapse multi-collapse"
                    }
                  >
                    <div className="card card-body border-0">
                      <div className="accordion" id="accordionExample4">
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="ReschedulingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ReschedulingcollapseOne"
                              aria-expanded="true"
                              aria-controls="ReschedulingcollapseOne"
                            >
                              1. How can I cancel my bookings?
                            </button>
                          </h2>
                          <div
                            id="ReschedulingcollapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="ReschedulingOne"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              Visit The Gamehunt website and log in to your
                              account. Go to “My Bookings” wherein you will find
                              all your reservations. Select the one you want to
                              cancel.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="ReschedulingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ReschedulingcollapseTwo"
                              aria-expanded="false"
                              aria-controls="ReschedulingcollapseTwo"
                            >
                              2. How can I reschedule my booking?
                            </button>
                          </h2>
                          <div
                            id="ReschedulingcollapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="ReschedulingTwo"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              Instead of rescheduling, The Gamehunt encourages
                              users to cancel and rebook their reservations.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2
                            className="accordion-header"
                            id="ReschedulingThree"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ReschedulingcollapseThree"
                              aria-expanded="false"
                              aria-controls="ReschedulingcollapseThree"
                            >
                              3. How many times can a booking be rescheduled?
                            </button>
                          </h2>
                          <div
                            id="ReschedulingcollapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="ReschedulingThree"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              Instead of rescheduling, The Gamehunt encourages
                              users to cancel and rebook their reservations.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Rescheduling4">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Reschedulingcollapse4"
                              aria-expanded="false"
                              aria-controls="Reschedulingcollapse4"
                            >
                              4. Can I transfer a booking from one venue to
                              another?
                            </button>
                          </h2>
                          <div
                            id="Reschedulingcollapse4"
                            className="accordion-collapse collapse"
                            aria-labelledby="Rescheduling4"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              Currently, The Gamehunt does not facilitate
                              transfer of venues from one to another. However,
                              users can cancel and rebook another venue
                              according to their preference
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Rescheduling5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Reschedulingcollapse5"
                              aria-expanded="false"
                              aria-controls="Reschedulingcollapse5"
                            >
                              5. What conditions prohibit the cancellation of a
                              booking?
                            </button>
                          </h2>
                          <div
                            id="Reschedulingcollapse5"
                            className="accordion-collapse collapse"
                            aria-labelledby="Rescheduling5"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              Cancellation of any booking is permitted up to one
                              hour before the start of the booking/event.
                              Cancellation is not possible after that.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Rescheduling6">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Reschedulingcollapse6"
                              aria-expanded="false"
                              aria-controls="Reschedulingcollapse6"
                            >
                              6. What is the cancellation policy for a booking,
                              and what are the cancellation charges?
                            </button>
                          </h2>
                          <div
                            id="Reschedulingcollapse6"
                            className="accordion-collapse collapse"
                            aria-labelledby="Rescheduling6"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              You will receive a full refund without incurring
                              any cancellation fees up to one hour prior to the
                              event or booking.
                              <br />
                              However, there will be no refund for cancellations
                              made within an hour of the booking/event.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Rescheduling7">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Reschedulingcollapse7"
                              aria-expanded="false"
                              aria-controls="Reschedulingcollapse7"
                            >
                              7. I am not able to cancel my booking 1 hour prior
                              to a slot.
                            </button>
                          </h2>
                          <div
                            id="Reschedulingcollapse7"
                            className="accordion-collapse collapse"
                            aria-labelledby="Rescheduling7"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              The system will allow you to cancel the booking
                              one hour before the scheduled time. Cancellation
                              after that point is not possible.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Rescheduling8">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Reschedulingcollapse8"
                              aria-expanded="false"
                              aria-controls="Reschedulingcollapse8"
                            >
                              8. I cancelled my booking, when will I get my
                              refund back?
                            </button>
                          </h2>
                          <div
                            id="Reschedulingcollapse8"
                            className="accordion-collapse collapse"
                            aria-labelledby="Rescheduling8"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              All refunds will reflect in your account in 6-7
                              business days.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Rescheduling9">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Reschedulingcollapse9"
                              aria-expanded="false"
                              aria-controls="Reschedulingcollapse9"
                            >
                              9. How will I receive my refund?
                            </button>
                          </h2>
                          <div
                            id="Reschedulingcollapse9"
                            className="accordion-collapse collapse"
                            aria-labelledby="Rescheduling9"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              You will receive the refund in the same account
                              you made the payment.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="Rescheduling10">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#Reschedulingcollapse10"
                              aria-expanded="false"
                              aria-controls="Reschedulingcollapse10"
                            >
                              10. Why haven’t I received the entire cancellation
                              amount?
                            </button>
                          </h2>
                          <div
                            id="Reschedulingcollapse10"
                            className="accordion-collapse collapse"
                            aria-labelledby="Rescheduling10"
                            data-bs-parent="#accordionExample4"
                          >
                            <div className="accordion-body">
                              Users will receive a full refund if they cancel
                              more than one hour before their booking/event. If
                              you have not received the amount after 7 days,
                              please send an email to{" "}
                              <a href="mailto:play@thegamehunt.com">
                                play@thegamehunt.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-4">
                <div className="bg-white p-3 outerSide">
                  <button
                    type="button"
                    className={
                      show && faqTabFor === "Others"
                        ? "mainTitile iconflip"
                        : "mainTitile"
                    }
                    onClick={() => {
                      // setFaqTabFor("Others");
                      // toggle();
                      handleToggle("Others", !show);
                    }}
                  >
                    Others
                  </button>
                  <div
                    className={
                      show && faqTabFor === "Others"
                        ? "collapse multi-collapse show"
                        : "collapse multi-collapse"
                    }
                  >
                    <div className="card card-body border-0">
                      <div className="accordion" id="accordionExample5">
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="OthersOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#OthersQuestioncollapseOne"
                              aria-expanded="true"
                              aria-controls="OthersQuestioncollapseOne"
                            >
                              1. How can a user provide feedback or ratings for
                              a venue they just visited?
                            </button>
                          </h2>
                          <div
                            id="OthersQuestioncollapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="OthersOne"
                            data-bs-parent="#accordionExample5"
                          >
                            <div className="accordion-body">
                              Once you log in on The Gamehunt website, go to My
                              bookings{" "}
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>{" "}
                              Completed booking session. Therein, you will find
                              an option to give your reviews/feedbacks for
                              venues, coaches and other players.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item innerSide">
                          <h2 className="accordion-header" id="OthersTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#OthersQuestioncollapseTwo"
                              aria-expanded="false"
                              aria-controls="OthersQuestioncollapseTwo"
                            >
                              2. Do we plan on having any loyalty
                              programs/discounts/offers for our recurring
                              clients?
                            </button>
                          </h2>
                          <div
                            id="OthersQuestioncollapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="OthersTwo"
                            data-bs-parent="#accordionExample5"
                          >
                            <div className="accordion-body">
                              The Gamehunt encourages repeat and loyal
                              customers. As a result, we periodically announce
                              our offers/discounts on the home page for our
                              recurring users.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default FAQS;
