import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BookTrialSessionReducer,
  CoachingCenterDetailReducer,
  CoachingSlotReducer,
} from "../slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

function BookTrialSession() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setDate] = useState(new Date());
  const detḁilSport = localStorage.getItem("detailSport");

  const SportName = localStorage.getItem("SportName");
  const detailSportTrial = JSON.parse(localStorage.getItem("detailSportTrial"));

  const detail = useSelector((state) => state.main.CoachingCenterDetail);

  const initialValues = {
    sport: detailSportTrial,
    slot: "",
  };

  const getDetail = useCallback(async () => {
    dispatch(
      CoachingCenterDetailReducer({
        coachesId: id,
      })
    );
  }, [dispatch, id]);

  const timeSloteList = useSelector((state) => state.main.CoachingSlotList);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      //sport: Yup.string().typeError("Required").required("Required"),
      slot: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      dispatch(
        BookTrialSessionReducer({
          coachId: id,
          time: values.slot,
          date: moment(value).format("DD/MM/YYYY"),
          sport: values.sport?.sport,
          isTrail: true,
        })
      ).then((data) => {
        if (data?.payload?.code === 1) {
          toast.success(data.payload.message);
          navigate("/coach-booking", {
            replace: true,
          });
        } else {
          toast.error(data.payload.message);
        }
      });
    },
  });

  const getTimeSlotList = useCallback(async () => {
    dispatch(
      CoachingSlotReducer({
        coachId: id,
        date: moment(value).format("YYYY-MM-DD"),
        sportId: formik.values?.sport?._id,
      })
    );
  }, [dispatch, id, value, formik.values?.sport?._id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getDetail();
      getTimeSlotList();
    }
    return () => {
      mounted = false;
    };
  }, [getDetail, getTimeSlotList]);

  const colourStyles = {
    // control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        textTransform: "capitalize",
      };
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center position-relative">
                <span
                  onClick={() => navigate(-1)}
                  className="theme-color backIconPosi"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </span>
                <h3 className="my-5">Book a Trial Session</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-4 mb-3 coachLineVer position-relative">
                <h5>Coach</h5>
                <h6 className="mb-0 coacFlex justify-content-start gap-2 text-capitalize">
                  <img
                    src={
                      detail?.image?.includes("http")
                        ? detail?.image
                        : "assets/img/employe.png"
                    }
                    className="coachImgCenetr"
                    alt=""
                  />
                  {detail?.name || "NA"}
                </h6>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 mb-3 coachLineVer position-relative">
                <div className="position-relative ps-4">
                  <img
                    src="assets/img/sports.png"
                    className="createEventInputIcon"
                    alt=""
                  />
                  <p className="mb-0 "> Sports</p>
                  <p className="mb-0 text-capitalize">
                    <b>
                      {detail?.sports
                        ?.map((item) => {
                          return `${item?.sport} ${
                            item?.sessionName ? `- ${item?.sessionName}` : ""
                          }`;
                        })
                        ?.join(", ")}
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 mb-3 coachLineVer position-relative">
                <div className="position-relative ps-4">
                  <img
                    src="assets/img/address.png"
                    alt=""
                    className="createEventInputIcon"
                  />
                  <p className="mb-0">Address</p>
                  <p className="mb-0">
                    <b>{detail?.address || "NA"}</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="FormBoxShadow rad">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="calendar">
                        <Calendar
                          onChange={(val) => {
                            setDate(val);
                            let arr = "";
                            formik.setFieldValue("slot", arr);
                          }}
                          value={value}
                          //selectRange={true}
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="row custom-radio ps-0">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <h5 className="">Sports</h5>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <Select
                    className="bg-white border-0 p-1"
                    value={formik.values.sport || undefined}
                    styles={colourStyles}
                    onChange={(val) => {
                      formik.setFieldValue("sport", val);
                    }}
                    options={detail?.sports || ""}
                    getOptionLabel={(option) => {
                      return `${
                        option?.sport?.charAt(0)?.toUpperCase() +
                        option?.sport?.slice(1, option?.sport.length)
                      } ${
                        option?.sessionName ? `- ${option?.sessionName}` : ""
                      }`;
                    }}
                    getOptionValue={(option) => option?._id}
                  />
                  {/* <select
                    className="bg-white border-0 p-4 form-select"
                    name="sport"
                    value={formik.values.sport}
                    onChange={(e) => {
                      setSport(e.target.value);
                      console.log(e.target.value?.name);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    {detail?.sports?.map((item, i) => (
                      <option value={item?._id} key={i}>
                        {item?.sport}{" "}
                        {item?.sessionName ? `- ${item?.sessionName}` : ""}
                      </option>
                    ))}
                  </select> */}
                  {formik.errors.sport && formik.touched.sport && (
                    <div className="text-red"> {formik.errors.sport}</div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <h5 className="">Time</h5>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                  <ul className="slotTimeList">
                    {timeSloteList?.length > 0
                      ? timeSloteList?.map((item, i) => (
                          <li key={i}>
                            <div className="radio-item h-100">
                              <input
                                type="radio"
                                value={item?.timeSlotStart}
                                name="slot"
                                id={`timeslot${i}`}
                                onChange={formik.handleChange("slot")}
                                onBlur={formik.handleBlur("radio")}
                                checked={
                                  formik.values.slot === item?.timeSlotStart
                                }
                              />
                              <label
                                className="label-icon timeSlotAdd"
                                htmlFor={`timeslot${i}`}
                              >
                                <p>
                                  {moment(item?.timeSlotStart, "hh:mm").format(
                                    "hh:mm a"
                                  )}
                                </p>
                              </label>
                            </div>
                          </li>
                        ))
                      : "No Slot Found"}
                  </ul>
                  {formik.errors.slot && formik.touched.slot && (
                    <div className="text-red"> {formik.errors.slot}</div>
                  )}
                </div>
              </div>
              <div className={timeSloteList?.length > 0 ? "mt-5" : "d-none "}>
                <div className="view-btns">
                  <button className="btn-view btn-accept" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
}

export default BookTrialSession;
