import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ChangeouterLogin,
  ChangeurlId,
  CoachingCenterDetailReducer,
  CoachingCenterDetailWithTokenReducer,
} from "../slice";
import { useState } from "react";
import LoginModal from "../../Components/Modals/LoginModal";
import LoginWithEmail from "../../Components/Modals/LoginWithEmail";
import SignUpModal from "../../Components/Modals/SignUpModal";
import SignUpWithMobileModal from "../../Components/Modals/SignUpWithMobileModal";
import ForgotPasswordModal from "../../Components/Modals/ForgotPasswordModal";
import OTPVerification from "../../Components/Modals/OTPVerification";
import ResetPassword from "../../Components/Modals/ResetPassword";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { mapApiKey } from "../../Utils";
import { UpdateFavCoachAPI } from "../../api/setting";
import { toast } from "react-toastify";

function CoachingDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");

  const handleShow = () => {
    setShow(!show);
  };

  const detail = useSelector((state) => state.main.CoachingCenterDetail);
  const outerLogin = useSelector((state) => state.main.outerLogin);
  const urlId = useSelector((state) => state.main.urlId);

  const token = localStorage.getItem("token");
  const detailSport = localStorage.getItem("detailSport");

  const liveLat = useSelector((state) => state.main.liveCoords?.lat);
  const liveLan = useSelector((state) => state.main.liveCoords?.lng);

  const [pricePlan, setPricePlan] = useState("");
  const [planTime, setPlanTime] = useState("3");
  const [AvailableSport, setAvailableSport] = useState("");

  localStorage.setItem("PlanDuration", planTime);
  localStorage.setItem("PlanPrice", pricePlan);

  const getDetail = useCallback(async () => {
    if (token) {
      dispatch(
        CoachingCenterDetailWithTokenReducer({
          coachesId: id,
          sportId: detailSport,
          longitude: liveLan,
          latitude: liveLat,
        })
      );
    } else {
      dispatch(
        CoachingCenterDetailReducer({
          coachesId: id,
          sportId: detailSport,
          longitude: liveLan,
          latitude: liveLat,
        })
      );
    }
  }, [dispatch, id, detailSport, token, liveLan, liveLat]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getDetail]);

  useEffect(() => {
    setAvailableSport(detail?.sportId);
    setPricePlan(detail?.threeMonths);
    localStorage.setItem(
      "detailSportTrial",
      JSON.stringify(detail?.sports?.[0])
    );
    localStorage.setItem("SportName", detail?.sport);
    localStorage.setItem(
      "SessionName",
      detail?.sessionName ? detail?.sessionName : ""
    );
  }, [
    detail?.sportId,
    detail?.threeMonths,
    detail?.sport,
    detail?.sessionName,
    detail?.sports,
  ]);

  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (detail) {
      setMarkerPosition({
        lat: Number(detail?.latitude),
        lng: Number(detail?.longitude),
      });
    }
  }, [detail]);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap defaultZoom={15} defaultCenter={props?.markerPosition}>
        <Marker position={props?.markerPosition} />
      </GoogleMap>
    ))
  );

  const UpdateFav = (data) => {
    //console.log(data);
    UpdateFavCoachAPI(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getDetail();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="mainbody" className="pt-0">
        <section className="py-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4 position-relative">
                <span
                  onClick={() => navigate(-1)}
                  className="theme-color backIconPosi"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </span>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 mb-2 position-relative">
                <img
                  src={
                    detail?.coverImage?.includes("http")
                      ? detail?.coverImage
                      : "assets/img/dummyImage.png"
                  }
                  className="img-fluid Detailgounds"
                  alt=""
                />
                <img
                  src={
                    detail?.isFev === true
                      ? "assets/img/Favoriteselected.png"
                      : "assets/img/Favoriteunselected.png"
                  }
                  className={
                    token ? "Favoriteselected cursor-pointer" : "d-none"
                  }
                  alt=""
                  onClick={() => {
                    UpdateFav({
                      coachId: detail?._id,
                      isfev: detail?.isFev === true ? false : true,
                    });
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                <div className={markerPosition?.lat ? "mapView" : "NomapView"}>
                  {markerPosition?.lat ? (
                    <MapWithAMarker
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      markerPosition={markerPosition}
                    />
                  ) : (
                    <div className="NoMapFound">No Map Found</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8 position-relative">
                <div className="detailTitle align-items-baseline">
                  <span>
                    <div className="d-flex align-items-center gap-3">
                      <h5 className="mb-0 text-capitalize">
                        {detail?.coachingCenterName
                          ? detail?.coachingCenterName
                          : detail?.name}
                      </h5>
                      <a
                        href={`https://www.google.com/maps/place/${detail?.address}`}
                        target="_blank"
                        className="clubKMS box-shadow"
                      >
                        {detail?.dist?.calculated
                          ? parseFloat(detail?.dist?.calculated).toFixed(2)
                          : "0"}{" "}
                        Km
                      </a>
                    </div>
                    <p className="text-capitalize">
                      {detail?.newSport
                        ?.map((item) => {
                          return item?.sport;
                        })
                        .join(", ")}
                      {/* <img
                        src={
                          detail?.sport === "cricket"
                            ? "assets/img/cricket_ball_icon.png"
                            : detail?.sport === "football"
                            ? "assets/img/Footall.png"
                            : detail?.sport === "basketball"
                            ? "assets/img/Basketball.png"
                            : detail?.sport === "table tennis"
                            ? "assets/img/table_tennis.png"
                            : detail?.sport === "badminton"
                            ? "assets/img/badminton.png"
                            : null
                        }
                        alt=""
                        className="venueSportIcon"
                        width="18px"
                      />{" "}
                      {detail?.sport || "NA"} */}
                    </p>
                    <p className="mb-0">
                      <img src="assets/img/address.png" alt="" />{" "}
                      {detail?.address || "NA"}
                    </p>
                  </span>
                  <span className="text-end">
                    <span className="votesRatingBg">
                      {detail?.rating
                        ? parseFloat(detail?.rating).toFixed(1)
                        : "0"}{" "}
                      <img src="assets/img/star.png" alt="" />
                    </span>
                    <p>
                      {detail?.review || "0"}{" "}
                      {detail?.review <= 1 ? "vote" : "votes"}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div className="row pt-2 align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h5>Coach</h5>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                <h6 className="mb-0 coacFlex text-capitalize justify-content-start gap-2">
                  <img
                    src={
                      detail?.image?.includes("http")
                        ? detail?.image
                        : "assets/img/employe.png"
                    }
                    className="coachImgCenetr"
                    alt=""
                  />
                  {detail?.name || "NA"}
                </h6>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                <div className="">
                  <img src="assets/img/briefcaseofficedetail.png" alt="" />
                  <p className="mb-0">Experience</p>
                  <p className="mb-0">
                    <b>
                      {detail?.exprience || "NA"}{" "}
                      {detail?.exprience <= "1" ? "year" : "years"}
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                <div className="">
                  <img src="assets/img/cartificates.png" alt="" />
                  <p className="mb-0">Certificates & Achievement</p>
                  <p className="mb-0">
                    <b>{detail?.achievements || "NA"}</b>
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                <h5>
                  <span className="fw-bold">
                    <i className="fa fa-inr"></i> {detail?.threeMonths || "0"} /
                  </span>
                  <span> 3 Months</span>
                </h5>
              </div>
            </div>
            <hr />
            <div className="row  pt-2">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h5>Available Sports</h5>
              </div>
              {detail?.sports?.map((item, i) => (
                <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3" key={i}>
                  <div
                    className={
                      AvailableSport === item?._id
                        ? "AvailableSportsName bgCOlorAmi"
                        : AvailableSport === item?._id
                        ? "AvailableSportsName bgCOlorAmi"
                        : AvailableSport === item?._id
                        ? "AvailableSportsName bgCOlorAmi"
                        : AvailableSport === item?._id
                        ? "AvailableSportsName bgCOlorAmi"
                        : AvailableSport === item?._id
                        ? "AvailableSportsName bgCOlorAmi"
                        : "AvailableSportsName"
                    }
                    onClick={() => {
                      setAvailableSport(item?._id);
                      localStorage.setItem("detailSport", item?._id);
                      localStorage.setItem(
                        "detailSportTrial",
                        JSON.stringify(item)
                      );
                      localStorage.setItem("SportName", item?.sport);
                      localStorage.setItem(
                        "SessionName",
                        item?.sessionName ? item?.sessionName : ""
                      );
                    }}
                  >
                    <img
                      src={
                        item?.sport === "cricket"
                          ? "assets/img/cricket_ball_icon.png"
                          : item?.sport === "football"
                          ? "assets/img/Footall.png"
                          : item?.sport === "basketball"
                          ? "assets/img/Basketball.png"
                          : item?.sport === "table tennis"
                          ? "assets/img/table_tennis.png"
                          : item?.sport === "badminton"
                          ? "assets/img/badminton.png"
                          : item?.sport === "swimming" ? "assets/img/swimming.png" : item?.sport === "lawn tennis" ? "assets/img/lawntennis.png" :  null
                      }
                      alt=""
                      className="unselectImg"
                    />{" "}
                    <img
                      src={
                        item?.sport === "cricket"
                          ? "assets/img/cricket_ball_icon_white.png"
                          : item?.sport === "football"
                          ? "assets/img/Footall_white.png"
                          : item?.sport === "basketball"
                          ? "assets/img/Basketball_white.png"
                          : item?.sport === "table tennis"
                          ? "assets/img/table_tennis_white.png"
                          : item?.sport === "badminton"
                          ? "assets/img/badminton_white.png"
                          :  item?.sport === "swimming" ? "assets/img/swimming_white.png" : item?.sport === "lawn tennis" ? "assets/img/lawntennis_white.png" : null
                      }
                      alt=""
                      className="selectImg"
                    />{" "}
                    {item?.sport}{" "}
                    {item?.sessionName ? `- ${item?.sessionName}` : ""}
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <div className="row pt-2">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h5>Amenities</h5>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                <ul className="AmenitiesList">
                  {detail?.amenities?.map((item, i) => (
                    <li
                      className={item?.amenities ? "text-capitalize" : "d-none"}
                      key={i}
                    >
                      <img
                        src={
                          item?.amenities === "cctv"
                            ? "assets/img/cctv.png"
                            : item?.amenities === "parking"
                            ? "assets/img/parking.png"
                            : item?.amenities === "air-conditioned"
                            ? "assets/img/air_conditioning.png"
                            : item?.amenities === "changing rooms"
                            ? "assets/img/ChangingRoom.png"
                            : item?.amenities === "washroom"
                            ? "assets/img/washroom.png"
                            : item?.amenities === "first aid"
                            ? "assets/img/firstaid_healtn.png"
                            : null
                        }
                        alt=""
                        width="16px"
                      />{" "}
                      {item?.amenities ? item?.amenities : ""}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <hr />
            <div className="row pt-2">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h5>Rules</h5>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <div
                  className="rulePTage"
                  dangerouslySetInnerHTML={{ __html: detail?.rules }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="row pt-2">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <h5>Price Plan</h5>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="position-relative">
                  <div className="row custom-radio ps-0">
                    <div className="col-sm-6 col-md-4 col-lg-4 mt-3">
                      <div className="radio-item">
                        <input
                          type="radio"
                          value={detail?.threeMonths}
                          name="pricePlan"
                          id="price1"
                          onClick={() => {
                            setPricePlan(detail?.threeMonths);
                            setPlanTime("3");
                          }}
                          checked={
                            pricePlan === detail?.threeMonths &&
                            planTime === "3"
                          }
                        />
                        <label
                          className="label-icon text-center pricePlan"
                          htmlFor="price1"
                        >
                          <h6>3 Months</h6>
                          <h3 className="priceP">
                            <i className="fa fa-inr"></i>

                            {detail?.threeMonths || "0"}
                          </h3>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mt-3">
                      <div className="radio-item">
                        <input
                          type="radio"
                          value={detail?.sixMonths}
                          name="pricePlan"
                          id="price2"
                          onClick={() => {
                            setPricePlan(detail?.sixMonths);
                            setPlanTime("6");
                          }}
                          checked={
                            pricePlan === detail?.sixMonths && planTime === "6"
                          }
                        />
                        <label
                          className="label-icon text-center pricePlan"
                          htmlFor="price2"
                        >
                          <h6>6 Months</h6>
                          <h3 className="priceP">
                            <i className="fa fa-inr"></i>

                            {detail?.sixMonths || "0"}
                          </h3>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mt-3">
                      <div className="radio-item">
                        <input
                          type="radio"
                          value={detail?.twelveMonths}
                          name="pricePlan"
                          id="price3"
                          checked={
                            pricePlan === detail?.twelveMonths &&
                            planTime === "12"
                          }
                          onClick={() => {
                            setPricePlan(detail?.twelveMonths);
                            setPlanTime("12");
                          }}
                        />
                        <label
                          className="label-icon text-center pricePlan"
                          htmlFor="price3"
                        >
                          <h6>12 Months</h6>
                          <h3 className="priceP">
                            <i className="fa fa-inr"></i>

                            {detail?.twelveMonths || "0"}
                          </h3>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="view-btns">
                {token ? (
                  <Link
                    to={`/coaching/detail/${id}/book-trial-session`}
                    className="btn-view btn-accept"
                  >
                    Book a Trial Session
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      dispatch(ChangeouterLogin(true));
                      dispatch(
                        ChangeurlId(`/coaching/detail/${id}/book-trial-session`)
                      );
                      setModalName("LoginModal");
                      handleShow();
                    }}
                    className="btn-view btn-accept cursor-pointer"
                  >
                    Book a Trial Session
                  </span>
                )}
                {token ? (
                  <Link
                    to={`/coaching/detail/${id}/book-coach`}
                    className="btn-view btn-reject cursor-pointer"
                  >
                    Book the Coach
                  </Link>
                ) : (
                  <span
                    className="btn-view btn-reject cursor-pointer"
                    onClick={() => {
                      dispatch(ChangeouterLogin(true));
                      dispatch(
                        ChangeurlId(`/coaching/detail/${id}/book-coach`)
                      );
                      setModalName("LoginModal");
                      handleShow();
                      handleShow();
                    }}
                  >
                    Book the Coach
                  </span>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      {modalName === "LoginModal" && (
        <LoginModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "LoginWithEmail" && (
        <LoginWithEmail
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "SignUpModal" && (
        <SignUpModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "SignUpWithMobile" && (
        <SignUpWithMobileModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "ForgotPassword" && (
        <ForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
      {modalName === "OTPVerification" && (
        <OTPVerification
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
          outerLogin={outerLogin}
          urlId={urlId}
        />
      )}
      {modalName === "ResetPassword" && (
        <ResetPassword
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
    </>
  );
}

export default CoachingDetail;
