import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Paginations from "../../../Components/Paginations";
import { VenueOwnerReviewRatingListReducer } from "../../CoachVenueslice";

function VenueReviewRating() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const handlePage = (value) => {
    setPage(value);
  };
  const list = useSelector((state) => state.CoachVenueMain.VenueReviewRating);

  const getRatingReviewList = useCallback(async () => {
    setLoading(true);
    dispatch(
      VenueOwnerReviewRatingListReducer({
        isReceived: true,
        page: page,
        limit: 10,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getRatingReviewList();
    }
    return () => {
      mounted = false;
    };
  }, [getRatingReviewList]);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-3 fw-bold">Review & Rating</h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-end">
                <div className="theme-color text-center d-inline-block">
                  <h3>{parseFloat(list?.totalRating).toFixed(1) || "0"}</h3>
                  <div className="ratingIcon mb-1">
                    {list?.totalRating > 0.4 && list?.totalRating < 0.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 0.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 1.4 && list?.totalRating < 1.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 1.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 2.4 && list?.totalRating < 2.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 2.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 3.4 && list?.totalRating < 3.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 3.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                    {list?.totalRating > 4.4 && list?.totalRating < 4.9 ? (
                      <img src="assets/img/star_half.png" alt="" />
                    ) : list?.totalRating > 4.9 ? (
                      <img src="assets/img/star_full.png" alt="" />
                    ) : (
                      <img src="assets/img/Page-2.png" alt="" />
                    )}
                  </div>
                  <p>{list?.totalReview || "0"} (reviews)</p>
                </div>
              </div>
              {loading ? (
                <Loader colorChange="colorChange" />
              ) : (
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="w-100">
                    <div className="">
                      {list?.eventbooking?.length > 0 ? (
                        list?.eventbooking?.map((item, i) => (
                          <div className="position-relative botmLine" key={i}>
                            <div className="d-md-flex justify-content-between gap-3">
                              <span>
                                <p className="text-muted text-capitalize">
                                  <img
                                    src={
                                      item?.normaluser?.picture?.includes(
                                        "http"
                                      )
                                        ? item?.normaluser?.picture
                                        : "assets/img/employe.png"
                                    }
                                    className="coachImgCenetr"
                                    alt=""
                                  />{" "}
                                  {item?.normaluser?.firstName
                                    ? `${item?.normaluser?.firstName} ${item?.normaluser?.lastName}`
                                    : "NA"}{" "}
                                  {moment(item?.time).format("ll")}
                                </p>
                              </span>
                              <small>
                                <img src="assets/img/star_full.png" alt="" />
                                <img src="assets/img/star_full.png" alt="" />
                                <img src="assets/img/star_full.png" alt="" />
                                <img src="assets/img/star_full.png" alt="" />
                                <img src="assets/img/star_full.png" alt="" />
                              </small>
                            </div>
                            <h6>{item?.review || "NA"}</h6>
                          </div>
                        ))
                      ) : (
                        <div className="text-center">No Records Found</div>
                      )}
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    {list?.eventbooking?.length > 0 && (
                      <Paginations
                        handlePage={handlePage}
                        page={page}
                        total={
                          list?.total_venueappointment &&
                          list?.total_venueappointment
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default VenueReviewRating;
