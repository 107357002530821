import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
function Phone({ className, onChange, value, name, onBlur, isValid }) {
  return (
    <PhoneInput
      name={name}
      defaultCountry="IN"
      className={className}
      placeholder="Enter phone number"
      onBlur={(e) => {
        console.log(e);
        // onBlur(e.target.value.replace(" ", ""));
        isValid(e.target.value.replace(" ", ""));
      }}
      countryCallingCodeEditable
      withcountryCallingCode
      international={true}
      limitMaxLength
      value={value}
      onChange={(val) => {
        onChange(val);
      }}
      //   onBlur={onBlur}
    />
  );
}
export default Phone;
