import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateFavCoachAPI } from "../../api/setting";
import { MyFavouriteCoachListReducer } from "../slice";

function FavouriteCoach() {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.main.FavouriteCoachList);

  const getFavouriteList = useCallback(async () => {
    dispatch(MyFavouriteCoachListReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getFavouriteList();
    }
    return () => {
      mounted = false;
    };
  }, [getFavouriteList]);

  const UpdateFav = (data) => {
    //console.log(data);
    UpdateFavCoachAPI(data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getFavouriteList();
      } else {
        toast?.error(res?.message);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
                <h3 className="my-5">Favourite</h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul
                  className="nav nav-pills mb-3 justify-content-center tabsnew"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/my-favourite"
                      className="nav-link text-capitalize"
                    >
                      venue
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/favourite-coach"
                      className="nav-link active text-capitalize"
                    >
                      coach
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row FavouriteVenuesList">
              {list?.length > 0 ? (
                list?.map((item, i) => (
                  <div className="col-sm-6 col-md-6 col-lg-4 mb-5" key={i}>
                    <div className="bg-white p-2 text-capitalize vanuesNearMeBox">
                      <div className="position-relative">
                        <img
                          src={
                            item?.sports[0]?.coverImage?.includes("http")
                              ? item?.sports[0]?.coverImage
                              : "assets/img/dummyImage.png"
                          }
                          className="img-fluid Coachbigimg"
                          alt=""
                        />
                        <div className="venueBoxIcons">
                          <span className="votesRatingBg">
                            {item?.rating || "0"}{" "}
                            <img
                              src="assets/img/star.png"
                              alt=""
                              className="start"
                            />
                          </span>{" "}
                          {item?.review || "0"} votes
                        </div>
                        <img
                          src={
                            item?.isFev === true
                              ? "assets/img/Favoriteselected.png"
                              : "assets/img/Favoriteunselected.png"
                          }
                          alt=""
                          className="homeFav"
                          onClick={() => {
                            UpdateFav({
                              coachId: item?._id,
                              isfev: item?.isFev === true ? false : true,
                            });
                          }}
                        />
                      </div>
                      <Link
                        to={`/coaching/detail/${item._id}`}
                        className="text-dark"
                        onClick={() => {
                          localStorage.setItem(
                            "detailSport",
                            item?.sports[0]?.sport
                          );
                        }}
                      >
                        <div className="text-start mt-3">
                          <h5
                            className="ellips1Line w-100 text-dark fw-bold"
                            title={
                              item?.coachingCenterName
                                ? item?.coachingCenterName
                                : item?.name
                            }
                          >
                            {item?.coachingCenterName
                              ? item?.coachingCenterName
                              : item?.name}
                          </h5>
                          <p className="text-dark">
                            {item?.sports
                              ?.map((item) => {
                                return item?.sport || "NA";
                              })
                              ?.join(", ")}
                          </p>
                          <div className="eventCreater">
                            <img
                              src={
                                item?.image?.includes("http")
                                  ? item?.image
                                  : "assets/img/employe.png"
                              }
                              className="rounded-circle  eventCreaterIcon"
                              alt=""
                            />
                            <span>
                              <p className="mb-0 fw-bold ellips1Line w-100 text-dark">
                                {item?.name || "NA"}
                              </p>
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
                            <span className="ellips1Line w-100 text-dark">
                              {item?.city
                                ? `${item?.doorNo ? item?.doorNo : ""} ${
                                    item?.street ? item?.street : ""
                                  } ${item?.city ? item?.city : ""} ${
                                    item?.state ? item?.state : ""
                                  } ${item?.zipCode ? item?.zipCode : ""}  ${
                                    item?.country ? item?.country : ""
                                  }`
                                : "NA"}
                            </span>
                            <span className="clubKMS box-shadow">
                              <img
                                src="assets/img/map_pins_icon.png"
                                alt=""
                                className="map_pins_icon"
                              />
                              7Km
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default FavouriteCoach;
