import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AllReadyCreatedEventdetailReducer,
  CompletedBookingGiveRatingReviewbyUserReducer,
} from "../slice";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import BlockedUserModal from "../../Components/Modals/BlockedUserModal";
import JoinAnExistingEventModal from "../../Components/Modals/JoinAnExistingEventModal";

function CompleteBookingDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState("");
  const [isBlocked, setisBlocked] = useState("");
  const [newDate, setNewDate] = useState(new Date());
  const [modalName, setModalName] = useState("");

  const handleShow = () => {
    setShow(!show);
  };

  const loginDetail = useSelector((state) => state.main.userDetail);
  const Cancelled = localStorage.getItem("isCancelled");
  const time = localStorage.getItem("time");

  const detail = useSelector((state) => state.main.AllReadyCreatedEventdetail);

  const getAllReadyCreatedEvent = useCallback(async () => {
    dispatch(
      AllReadyCreatedEventdetailReducer({
        eventbookingId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getAllReadyCreatedEvent();
    }
    return () => {
      mounted = false;
    };
  }, [getAllReadyCreatedEvent]);

  const initialValues = {
    review: "",
    feedback: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      // review: Yup.string().typeError("Required").required("Required"),
      // feedback: Yup.string().typeError("Required").required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      const payload = {
        rating: values.review,
        review: values.feedback,
        eventbookingId: id,
      };

      dispatch(CompletedBookingGiveRatingReviewbyUserReducer(payload)).then(
        (data) => {
          if (data?.payload?.code === 1) {
            toast.success(data.payload.message);
            getAllReadyCreatedEvent();
            // navigate("/my-booking", {
            //   replace: true,
            // });
          } else {
            toast.error(data.payload.message);
          }
        }
      );
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center position-relative">
                <span
                  onClick={() => navigate(-1)}
                  className="theme-color backIconPosi"
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </span>
                <h3 className="my-3 fw-bold text-capitalize">
                  {Cancelled === "true" ? "Cancelled" : detail?.progress}{" "}
                  Booking Detail
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="bookingBox bg-white rad p-3">
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-6 col-lg-6 CompletedBooking">
                      <div className="AlreadyEventBox justify-content-start gap-3">
                        <img
                          src={
                            detail?.venueId?.image?.[0]?.image?.includes("http")
                              ? detail?.venueId?.image?.[0]?.image
                              : "assets/img/dummyImage.png"
                          }
                          alt=""
                          className="eventImg"
                        />
                        <span>
                          <h5 className="mb-0">
                            {detail?.venueId?.name || "NA"}
                          </h5>
                          <p className="">
                            {detail?.venueId?.address
                              ? `${detail?.venueId?.address}`
                              : "NA"}
                          </p>
                          <div className="eventCreater">
                            <span>
                              <p className="mb-0 fw-bold">
                                <img
                                  src={
                                    detail?.createdBy?.picture?.includes("http")
                                      ? detail?.createdBy?.picture
                                      : "assets/img/employe.png"
                                  }
                                  className="rounded-circle eventCreaterIcon"
                                  alt=""
                                />{" "}
                                {detail?.createdBy?.firstName
                                  ? `${detail?.createdBy?.firstName} ${detail?.createdBy?.lastName}`
                                  : "NA"}
                              </p>
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 text-end">
                      <h5 className="mb-0 theme-color fw-bold">
                        <i className="fa fa-inr"></i>{" "}
                        {detail?.userTotal
                          ? parseFloat(detail?.userTotal).toFixed(2)
                          : "0"}
                        /-
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-3">
                      <div className="detailData">
                        <p className="mb-0">Booking No.</p>
                        <p className="fw-bold">{detail?.bookingId}</p>
                        <img
                          src="assets/img/BookingID.png"
                          alt=""
                          className="detailIcon"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-3">
                      <div className="detailData">
                        <p className="mb-0">Booking Date</p>
                        <p className="fw-bold">
                          {moment(detail?.date).format("ll")}
                        </p>
                        <img
                          src="assets/img/calender.png"
                          alt=""
                          className="detailIcon"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-3">
                      <div className="detailData">
                        <p className="mb-0">Sports</p>
                        <p className="fw-bold text-capitalize">
                          {detail?.sport}
                        </p>
                        <img
                          src="assets/img/sports.png"
                          alt=""
                          className="detailIcon"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-3">
                      <div className="detailData">
                        <p className="mb-2">Status</p>
                        <span
                          className={
                            Cancelled === "true"
                              ? "StatusBtn rejectedStatus text-capitalize"
                              : "StatusBtn approvedStatus text-capitalize"
                          }
                        >
                          {Cancelled === "true"
                            ? "cancelled"
                            : detail?.progress}<br/>
                            {Cancelled === "true" ? moment(time).format("lll") : null}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                      <div className="detailData">
                        <p className="mb-0">Booking Time</p>
                        <img
                          src="assets/img/Schedule.png"
                          alt=""
                          className="detailIcon"
                        />
                        {detail?.slot?.map((item) => {
                          return (
                            <span className="mb-0 fw-bold commasLast">
                              {moment(item?.timeSlotStart, "hh:mm:ss").format(
                                "hh:mm a"
                              )}{" "}
                              -{" "}
                              {moment(item?.timeSlotEnd, "hh:mm:ss").format(
                                "hh:mm a"
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {detail?.progress === "completed" ? (
              <div className={Cancelled === "true" ? "d-none" : ""}>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <h4 className="fw-bold">Venue Rating</h4>
                  </div>

                  {detail?.eventRatings?.length > 0 ? (
                    detail?.eventRatings
                      ?.filter((el) => el?.rateBy === "venue")
                      ?.map((item, i) => (
                        <div
                          className="col-sm-12 col-md-12 col-lg-12 mb-3"
                          key={i}
                        >
                          <p className="coacFlex  justify-content-start gap-2 fs-5">
                            {detail?.venueId?.name || "NA"}
                          </p>
                          <div className="ratingIcon my-3">
                            {item?.rating === 1 ? (
                              <img
                                src="assets/img/icons.png"
                                alt=""
                                width="20px"
                              />
                            ) : item?.rating === 2 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : item?.rating === 3 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : item?.rating === 4 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : item?.rating === 5 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="bg-white border-0 w-100 p-3">
                            {item?.review}
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      No Data Found
                    </div>
                  )}
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="coacFlex justify-content-start gap-2 fs-6">
                        <img
                          src={
                            loginDetail?.picture?.includes("http")
                              ? loginDetail?.picture
                              : "assets/img/employe.png"
                          }
                          className="coachImgCenetr"
                          alt=""
                        />
                        <div>
                          <p className="mb-0 text-capitalize">
                            {loginDetail?.firstName
                              ? `${loginDetail?.firstName} ${loginDetail?.lastName}`
                              : "NA"}
                          </p>
                        </div>
                      </div>
                      <div className="ratingIcon my-3">
                        <Rating
                          onClick={(val) => {
                            formik.setFieldValue("review", val);
                          }}
                          ratingValue={formik.values.review * 20}
                          initialValue={formik.values.review}
                          //showTooltip
                          size={25}
                          iconsCount={5}
                          label
                          transition
                          fillColor="#f3b20f"
                          emptyColor="gray"
                          className="foo"
                        />
                      </div>
                      <textarea
                        className="bg-white border-0 w-100 p-3"
                        rows="4"
                        placeholder="Comments..."
                        name="feedback"
                        value={formik.values.feedback}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      <div className="mt-4">
                        <div className="view-btns justify-content-start">
                          <button type="submit" className="btn-view btn-accept">
                            Give Review
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <h4 className="fw-bold">Other Players</h4>
                  </div>
                  {detail?.eventRatings?.length > 0 ? (
                    detail?.eventRatings
                      ?.filter((el) => el?.rateBy === "user")
                      ?.map((item, i) => (
                        <div
                          className="col-sm-12 col-md-12 col-lg-12 mb-3"
                          key={i}
                        >
                          <div className="coacFlex  justify-content-start gap-2 fs-6">
                            <img
                              src={
                                item?.user?.picture?.includes("http")
                                  ? item?.user?.picture
                                  : "assets/img/employe.png"
                              }
                              className="coachImgCenetr"
                              alt=""
                            />
                            <div>
                              <p className="mb-0  text-capitalize">
                                {item?.user?.firstName
                                  ? `${item?.user?.firstName} ${item?.user?.lastName}`
                                  : item?.user?.mobile
                                  ? `${item?.user?.countryCode} ${item?.user?.mobile}`
                                  : ""}
                              </p>
                              <h6
                                className={
                                  loginDetail?._id === item?.user?._id
                                    ? "d-none"
                                    : "theme-color text-decoration-underline cursor-pointer"
                                }
                                onClick={() => {
                                  setUserId(item?.user?._id);
                                  setisBlocked(item?.isBlocked);
                                  handleShow();
                                }}
                              >
                                {item?.isBlocked === true ? "Unblock" : "Block"}
                              </h6>
                            </div>
                          </div>
                          <div className="ratingIcon my-3">
                            {item?.rating === 1 ? (
                              <img
                                src="assets/img/icons.png"
                                alt=""
                                width="20px"
                              />
                            ) : item?.rating === 2 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : item?.rating === 3 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : item?.rating === 4 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : item?.rating === 5 ? (
                              <>
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                                <img
                                  src="assets/img/icons.png"
                                  alt=""
                                  width="20px"
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="bg-white border-0 w-100 p-3">
                            {item?.review}
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      No Data Found
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={
                  Cancelled === "true" || detail?.progress === "completed" || detail?.fullBooking === true
                    ? "d-none"
                    : "mt-5"
                }
              >
                <div className="mb-3 text-center">
                  <span className="theme-color fw-bold text-decoration-underline cursor-pointer">
                    Invite Other Users
                  </span>
                </div>
                <div className="view-btns">
                  <span
                    onClick={() => {
                      setModalName("JoinAnExistingEvent");
                      handleShow();
                    }}
                    className={
                      detail?.max === 1 ||
                      detail?.fullBooking === true ||
                      detail?.max === detail?.addPeople
                        ? "d-none"
                        : "btn-view btn-accept  cursor-pointer"
                    }
                  >
                    Book my slot
                  </span>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      {userId && show && (
        <BlockedUserModal
          handleShow={handleShow}
          show={show}
          id={userId}
          getdetail={getAllReadyCreatedEvent}
          isBlocked={isBlocked}
        />
      )}
      {modalName === "JoinAnExistingEvent" && id && (
        <JoinAnExistingEventModal
          show={show}
          handleShow={handleShow}
          createdEventId={id}
          minCapacity={detail?.min}
        />
      )}
    </>
  );
}

export default CompleteBookingDetail;
