import React from "react";
import { config } from "../../Utils";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { Buffer } from "buffer";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
window.Buffer = Buffer;

function DocumentUpload() {
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [uploadDocument, setUploadDocument] = useState("");
  const [uploadDocumentName, setUploadDocumentName] = useState("");
  const [uploadDocumentDate, setUploadDocumentDate] = useState("");

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center position-relative">
                <h3 className="my-3 fw-bold">Documents Upload</h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-1 col-lg-1"></div>
              <div className="col-sm-12 col-md-10 col-lg-10">
                <div className="bg-white rad p-4">
                  <h5>Attach Documents</h5>
                  <div className="shadow p-3 my-3">
                    <img src="assets/img/fileName.png" alt="" /> File Name
                  </div>
                  <div className="">
                    <div className="upload-btn-wrapper imageUpoad p-0 rounded-0 d-block">
                      <span className="btn">
                        <img
                          src="assets/img/uploadImg.png"
                          alt=""
                          className="uploadImageicon"
                        />

                        <div>
                          <p className="mb-0">Drag and drop here</p>
                          <p className="mb-0">or</p>
                          <p className="theme-color">Browse files</p>
                          <small>File Size should be less then 1 MB</small>
                        </div>
                      </span>
                      <input
                        type="file"
                        accept="image/*"
                        name="imageName"
                        onChange={(event) => {
                          setIsActive(true);
                          S3FileUpload.uploadFile(event.target.files[0], config)
                            .then((data) => {
                              //   setFormikState((prevState) => {
                              //     return {
                              //       ...prevState,
                              //       values: {
                              //         ...prevState.values,
                              //         imageName: data.location,
                              //       },
                              //     };
                              //   });
                              setUploadDocument(data.location);
                              setUploadDocumentName(event.target.files[0].name);
                              setUploadDocumentDate(
                                event.target.files[0].lastModifiedDate
                              );
                              setTime(100);
                              setIsActive(true);
                              setTimeout(() => {
                                setTime(0);
                                setIsActive(false);
                              }, 3000);
                              console.log(event.target.files[0]);
                            })
                            .catch((err) => console.error(err));
                        }}
                      />
                    </div>
                    Accepted file Types: pdf, jpej, png, doc, excel only
                  </div>
                  <div>
                    <div className="uploadDocumentStyle">
                      <img
                        src="assets/img/docIcon.png"
                        alt=""
                        className="docIcon"
                      />
                      <div>
                        <div className="d-flex justify-content-between mb-2">
                          <h6 className="mb-0">
                            {uploadDocumentName || "Filename.doc"}
                          </h6>
                          <span>
                            <img
                              src={
                                uploadDocument
                                  ? "assets/img/trash_icon.png"
                                  : "assets/img/trash_icon.png"
                              }
                              alt=""
                              className=""
                            />
                          </span>
                        </div>
                        {time > 0 && isActive && (
                          <Progress type="line" width={100} percent={time} />
                        )}
                        <p className="mb-0">
                          Completed at{" "}
                          {uploadDocumentDate
                            ? moment(uploadDocumentDate).format("LT")
                            : "11:00am"}
                          ,{" "}
                          {uploadDocumentDate
                            ? moment(uploadDocumentDate).format("DD MMM YYYY")
                            : "13 Aug 2022"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 text-end">
                    <button className="btn btn-submit w-25">Add Files</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default DocumentUpload;
