import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { VenueOwnerBookingDetailReducer } from "../features/CoachVenueslice";

function VenueBookingDetail({ show, handleShow, id, eventStatus }) {
  const dispatch = useDispatch();
  const detail = useSelector(
    (state) => state.CoachVenueMain.VenueOwnerBookingDetail
  );

  const getDetail = useCallback(async () => {
    dispatch(
      VenueOwnerBookingDetailReducer({
        eventbookingId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getDetail]);
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="lg">
        <Modal.Body className="p-0">
          <div>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleShow}
                ></button>
                <div className="form-style bg-white p-3 mb-3">
                  <h4 className="mb-4 text-center">Booking Detail</h4>
                  <div className="row  text-capitalize">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="d-flex align-items-center gap-3 text-capitalize">
                        <img
                          src={
                            detail?.createdBy?.picture?.includes("http")
                              ? detail?.createdBy?.picture
                              : "assets/img/employe.png"
                          }
                          className="CurriculumImges"
                          alt=""
                        />
                        <span>
                          <p className="mb-0">user name</p>
                          <b>
                            {detail?.createdBy?.firstName
                              ? `${detail?.createdBy?.firstName} ${detail?.createdBy?.lastName}`
                              : "NA"}
                          </b>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/BookingID.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Booking ID</p>
                        <h6 className="mb-1">{detail?.bookingId || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/social-gender-agender_black.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">gender</p>
                        <h6 className="mb-1">{detail?.gender || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/mobile.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Type Of Booking</p>
                        <h6 className="mb-1">
                          {detail?.fullBooking === false
                            ? detail?.eventType
                            : "Full Booking"}
                        </h6>
                      </div>
                    </div>
                    <div
                      className={
                        detail?.fullBooking === true
                          ? "col-sm-12 col-md-12 col-lg-6"
                          : "d-none"
                      }
                    >
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/capacity.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Booked With Coach</p>
                        <h6 className="mb-1">
                          {detail?.isCoach === false ? "No" : "yes"}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/TypeOfCoaching.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">sports</p>
                        <h6 className="mb-1">{detail?.sport || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/courtTrufNumber.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">court/turf</p>
                        <h6 className="mb-1">{detail?.court?.name || "NA"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/calendar_date.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">Start Date</p>
                        <h6 className="mb-1">
                          {moment(detail?.date).format("DD/MM/YYYY")}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/timeIcon.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">time</p>
                        <h6 className="mb-1">
                          {detail?.slot
                            ?.map((slt) => {
                              return `${moment(
                                slt?.timeSlotStart,
                                "hh:mm"
                              ).format("hh:mm A")}
                              -
                              ${moment(slt?.timeSlotEnd, "hh:mm").format(
                                "hh:mm A"
                              )}`;
                            })
                            ?.join(", ")}
                        </h6>
                      </div>
                    </div>
                    <div
                      className={
                        detail?.fullBooking === false
                          ? "col-sm-12 col-md-12 col-lg-12"
                          : "d-none"
                      }
                    >
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/maxCapacity.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">No. Of People Booked The Slot</p>
                        <h6 className="mb-1">{detail?.addPeople || "0"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/minCapacity.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">
                          Min No. Of People Required For The Slot
                        </p>
                        <h6 className="mb-1">{detail?.min || "0"}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mt-3 position-relative ps-4">
                        <img
                          src="assets/img/minCapacity.png"
                          className="detailIconBooking"
                          alt=""
                        />
                        <p className="mb-1">
                          Max No. Of People Required For The Slot
                        </p>
                        <h6 className="mb-1">{detail?.max || "0"}</h6>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <p className="mb-1">Amount</p>
                        <h6 className="mb-1">
                          <i className="fa fa-inr"></i>{" "}
                          {detail?.total
                            ? parseFloat(detail?.total).toFixed(2)
                            : "0"}
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <div className="mt-3 position-relative ps-4">
                        <p className="mb-1">status</p>
                        <h6 className="mb-1">
                          <span
                            className={
                              eventStatus === "refunded"
                                ? "StatusBtn approvedStatus"
                                : eventStatus === "pending"
                                ? "StatusBtn pendingStatus"
                                : "StatusBtn approvedStatus"
                            }
                          >
                            {eventStatus}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VenueBookingDetail;
