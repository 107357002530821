import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CoachVenueChangePasswordReducer } from "../../CoachVenueslice";
import Loader from "../../../Components/Loader";
import CoachVenueForgotPasswordModal from "../../../Components/Modals/CoachVenueForgotPasswordModal";

function CoachChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");

  const handleShow = () => {
    setShow(!show);
    localStorage.removeItem("loginImg");
  };

  return (
    <>
      <main id="mainbody">
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="form-style mt-5">
                  <Formik
                    initialValues={{
                      oldPassword: "",
                      newPassword: "",
                      changePassword: "",
                      toggle: false,
                      toggle1: false,
                      toggle2: false,
                      error: "",
                    }}
                    validationSchema={Yup.object({
                      oldPassword: Yup.string().required("Required"),
                      newPassword: Yup.string().required("Required"),
                      changePassword: Yup.string()
                        .required("Required")
                        .oneOf(
                          [Yup.ref("newPassword"), null],
                          "Passwords must match"
                        ),
                    })}
                    onSubmit={(values) => {
                      setLoading(true);
                      dispatch(
                        CoachVenueChangePasswordReducer({
                          current_password: values.oldPassword,
                          new_password: values.newPassword,
                          user_type: "coach",
                        })
                      ).then((data) => {
                        setLoading(false);
                        if (data?.payload?.code === 1) {
                          toast.success(data.payload.message);
                          localStorage.clear();
                          navigate("/", {
                            replace: true,
                          });
                        } else {
                          setLoading(false);
                          toast.error(data.payload.message);
                        }
                      });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setFormikState,
                    }) => (
                      <form className="row" onSubmit={handleSubmit}>
                        <div className="form-group col-sm-12 col-md-12 text-center mb-3">
                          <div className="">
                            <img
                              src="assets/img/change-password.png"
                              className="iconIMg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                          <h2 className="mdl-ttl">Change Password</h2>
                          <p>Please change your password here</p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                type={values.toggle ? "text" : "password"}
                                className="form-control ps-5"
                                placeholder="Password"
                                name="oldPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.oldPassword}
                              />
                              <img
                                src="assets/img/password.png"
                                className="input-img"
                                alt=""
                              />
                              <span
                                className={
                                  values.toggle
                                    ? "pass-hide field-icon toggle-password"
                                    : "pass-view field-icon toggle-password"
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFormikState((prevState) => {
                                    return {
                                      ...prevState,
                                      values: {
                                        ...prevState.values,
                                        toggle: !prevState.values.toggle,
                                      },
                                    };
                                  });
                                }}
                              ></span>
                            </div>
                            {errors.oldPassword && touched.oldPassword && (
                              <div className="text-red">
                                {" "}
                                {errors.oldPassword}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 text-end mb-4">
                          <span
                            className="forgot-pass cursor-pointer"
                            onClick={() => {
                              setModalName("CoachVenueForgotPassword");
                              handleShow();
                              localStorage.setItem("loginImg", "coachLogin");
                            }}
                          >
                            Forgot Password?
                          </span>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                type={values.toggle2 ? "text" : "password"}
                                className="form-control ps-5"
                                placeholder="Set New password"
                                name="newPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.newPassword}
                              />
                              <img
                                src="assets/img/password.png"
                                className="input-img"
                                alt=""
                              />
                              <span
                                className={
                                  values.toggle2
                                    ? "pass-hide field-icon toggle-password"
                                    : "pass-view field-icon toggle-password"
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFormikState((prevState) => {
                                    return {
                                      ...prevState,
                                      values: {
                                        ...prevState.values,
                                        toggle2: !prevState.values.toggle2,
                                      },
                                    };
                                  });
                                }}
                              ></span>
                            </div>
                            {errors.newPassword && touched.newPassword && (
                              <div className="text-red">
                                {" "}
                                {errors.newPassword}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                id="toggle-password1"
                                type={values.toggle1 ? "text" : "password"}
                                className="form-control ps-5"
                                placeholder="Set Confirm New password"
                                name="changePassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.changePassword}
                              />
                              <img
                                src="assets/img/password.png"
                                className="input-img"
                                alt=""
                              />
                              <span
                                className={
                                  values.toggle1
                                    ? "pass-hide field-icon toggle-password1"
                                    : "pass-view field-icon toggle-password1"
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFormikState((prevState) => {
                                    return {
                                      ...prevState,
                                      values: {
                                        ...prevState.values,
                                        toggle1: !prevState.values.toggle1,
                                      },
                                    };
                                  });
                                }}
                              ></span>
                            </div>
                            {errors.changePassword &&
                              touched.changePassword && (
                                <div className="text-red">
                                  {" "}
                                  {errors.changePassword}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="form-group col-sm-12 col-md-12 text-center">
                          <button className="btn btn-submit">
                            {" "}
                            {loading ? <Loader /> : "Submit"}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {modalName === "CoachVenueForgotPassword" && (
        <CoachVenueForgotPasswordModal
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}
    </>
  );
}

export default CoachChangePassword;
