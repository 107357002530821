import React, { useCallback, useEffect, useState } from "react";
import { Drawer } from "antd";
import "antd/dist/antd.css";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { Buffer } from "buffer";
import { config } from "../../../Utils";
import Loader from "../../../Components/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { valHooks } from "jquery";
import { CourtsAndTrufDetailReducer } from "../../CoachVenueslice";
window.Buffer = Buffer;

function EditCourtTurf({
  onClose,
  visible,
  getlist,
  showDrawer,
  setModalName,
  setinitialData,
  id,
}) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const detail = useSelector((state) => state.CoachVenueMain.CourtsTrufsDetail);

  const getDetail = useCallback(async () => {
    dispatch(
      CourtsAndTrufDetailReducer({
        courtandtrufId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  const initialValues = {
    description: detail?.description ? detail?.description : "",
    maxCapacity: detail?.maxCapacity ? detail?.maxCapacity : "",
    sports: detail?.sport ? detail?.sport : "",
    courtTrufNumber: detail?.name ? detail?.name : "",
    minCapacity: detail?.minCapacity ? detail?.minCapacity : "",
    rulesandregulations: detail?.rules ? detail?.rules : "",
    imageName: detail?.image?.[0]?.image.includes("http")
      ? detail?.image?.[0]?.image
      : "",
    imageName1: detail?.image?.[1]?.image.includes("http")
      ? detail?.image?.[1]?.image
      : "",
    imageName2: detail?.image?.[2]?.image.includes("http")
      ? detail?.image?.[2]?.image
      : "",
    days: detail?.days,
    costPerSlot: detail?.costPerSlot ? detail?.costPerSlot : "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      description: Yup.string().required("Required"),
      maxCapacity: Yup.string().required("Required"),
      sports: Yup.string().required("Required"),
      courtTrufNumber: Yup.string().required("Required"),
      minCapacity: Yup.string().required("Required"),
      //imageName: Yup.string().required("Required"),
      rulesandregulations: Yup.string().required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      //console.log(values);
      setLoading(true);
      onClose = { onClose };
      setModalName("EditSlot");
      showDrawer();

      setinitialData({
        description: values.description,
        maxCapacity: values.maxCapacity,
        sports: values.sports,
        courtTrufNumber: values.courtTrufNumber,
        minCapacity: values.minCapacity,
        rulesandregulations: values.rulesandregulations,
        imageName: values.imageName,
        imageName1: values.imageName1,
        imageName2: values.imageName2,
        days: values?.days,
        costPerSlot: values?.costPerSlot,
      });

      const payload = {
        sports: values.sports,
        maxCapacity_name: values.maxCapacity,
        minCapacity: values.minCapacity,
        description: values.description,
        courtTrufNumber: values.courtTrufNumber,
        rulesandregulations: values.rulesandregulations,
        picture: JSON.stringify([
          {
            image: values.imageName,
          },
          {
            image: values.imageName1,
          },
          {
            image: values.imageName2,
          },
        ]),
      };
      //console.log(payload);
      // dispatch(
      //   AddBatchReducer(payload)
      // ).then((data) => {
      //   setLoading(false);
      //   if (data?.payload?.code === 1) {
      //     toast.success(data.payload.message);
      //     getlist();
      //   } else {
      //     setLoading(false);
      //     toast.error(data.payload.message);
      //   }
      // });
    },
  });
  return (
    <>
      <Drawer placement="right" onClose={onClose} visible={visible}>
        <div className="">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3 fw-bold">Edit Court/Turfs</h2>
          </div>
          <form className="form-style formCavas" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container">
                    <select
                      className="form-control ps-5 form-select"
                      placeholder="Sports"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sports}
                      name="sports"
                    >
                      <option value="">Select Sport</option>
                      <option value="badminton">Badminton</option>
                      <option value="table tennis">Table Tennis</option>
                      <option value="football">Football</option>
                      <option value="cricket">Cricket</option>
                      <option value="basketball">BasketBall</option>
                      <option value="lawn tennis">Lawn tennis</option>
                      <option value="swimming">Swimming</option>
                    </select>
                    <img
                      src="assets/img/sports.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.sports && formik.touched.sports && (
                  <div className="text-red"> {formik.errors.sports}</div>
                )}
              </div>
              <div className="col-sm-12 col-md-12 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-5"
                      placeholder="Court/Turfs Number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.courtTrufNumber}
                      name="courtTrufNumber"
                    />
                    <img
                      src="assets/img/courtTrufNumber.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.courtTrufNumber &&
                  formik.touched.courtTrufNumber && (
                    <div className="text-red">
                      {" "}
                      {formik.errors.courtTrufNumber}
                    </div>
                  )}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 mb-4 position-relative">
                <div className="form-group text-center">
                  <div className="upload-btn-wrapper imageUpoad p-0 rounded-0">
                    <span
                      className={
                        formik.values.imageName?.includes("http")
                          ? "btn p-0"
                          : "btn"
                      }
                    >
                      {formik.values.imageName ? (
                        <img
                          src={formik.values.imageName}
                          alt=""
                          className="EditImages"
                        />
                      ) : (
                        <img
                          src="assets/img/uploadImg.png"
                          alt=""
                          className="uploadImageicon"
                        />
                      )}

                      <span
                        className={
                          formik.values.imageName?.includes("http")
                            ? "d-none"
                            : "theme-color fs-15"
                        }
                      >
                        Upload Court image
                      </span>
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      name="imageName"
                      onChange={(event) => {
                        setIsActive(true);
                        S3FileUpload.uploadFile(event.target.files[0], config)
                          .then((data) => {
                            formik.setFormikState((prevState) => {
                              return {
                                ...prevState,
                                values: {
                                  ...prevState.values,
                                  imageName: data.location,
                                },
                              };
                            });
                            //setUploadImage(data.location);
                            setTime(100);
                            setIsActive(true);
                            setTimeout(() => {
                              setTime(0);
                              setIsActive(false);
                            }, 3000);
                            //console.log(data.location);
                          })
                          .catch((err) => console.error(err));
                      }}
                    />
                  </div>
                  {/* {time > 0 && isActive && formik.values.uploadImage && (
                <Progress type="line" width={100} percent={time} />
              )} */}
                </div>
                {formik.errors.imageName && formik.touched.imageName && (
                  <div className="text-red"> {formik.errors.imageName}</div>
                )}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 mb-4 position-relative">
                <div className="form-group text-center">
                  <div className="upload-btn-wrapper imageUpoad p-0 rounded-0">
                    <span
                      className={
                        formik.values.imageName1?.includes("http")
                          ? "btn p-0"
                          : "btn"
                      }
                    >
                      {formik.values.imageName1 ? (
                        <img
                          src={formik.values.imageName1}
                          alt=""
                          className="EditImages"
                        />
                      ) : (
                        <img
                          src="assets/img/uploadImg.png"
                          alt=""
                          className="uploadImageicon"
                        />
                      )}

                      <span
                        className={
                          formik.values.imageName1?.includes("http")
                            ? "d-none"
                            : "theme-color fs-15"
                        }
                      >
                        Upload Court image
                      </span>
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      name="imageName1"
                      onChange={(event) => {
                        setIsActive(true);
                        S3FileUpload.uploadFile(event.target.files[0], config)
                          .then((data) => {
                            formik.setFormikState((prevState) => {
                              return {
                                ...prevState,
                                values: {
                                  ...prevState.values,
                                  imageName1: data.location,
                                },
                              };
                            });
                            //setUploadImage1(data.location);
                            setTime(100);
                            setIsActive(true);
                            setTimeout(() => {
                              setTime(0);
                              setIsActive(false);
                            }, 3000);
                            //console.log(data.location);
                          })
                          .catch((err) => console.error(err));
                      }}
                    />
                  </div>
                  {/* {time > 0 && isActive && formik.values.uploadImage1 && (
                <Progress type="line" width={100} percent={time} />
              )} */}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 mb-4 position-relative">
                <div className="form-group text-center">
                  <div className="upload-btn-wrapper imageUpoad p-0 rounded-0">
                    <span
                      className={
                        formik.values.imageName2?.includes("http")
                          ? "btn p-0"
                          : "btn"
                      }
                    >
                      {formik.values.imageName2 ? (
                        <img
                          src={formik.values.imageName2}
                          alt=""
                          className="EditImages"
                        />
                      ) : (
                        <img
                          src="assets/img/uploadImg.png"
                          alt=""
                          className="uploadImageicon"
                        />
                      )}

                      <span
                        className={
                          formik.values.imageName2?.includes("http")
                            ? "d-none"
                            : "theme-color fs-15"
                        }
                      >
                        Upload Court image
                      </span>
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      name="imageName2"
                      onChange={(event) => {
                        setIsActive(true);
                        S3FileUpload.uploadFile(event.target.files[0], config)
                          .then((data) => {
                            formik.setFormikState((prevState) => {
                              return {
                                ...prevState,
                                values: {
                                  ...prevState.values,
                                  imageName2: data.location,
                                },
                              };
                            });
                            //setUploadImage2(data.location);
                            setTime(100);
                            setIsActive(true);
                            setTimeout(() => {
                              setTime(0);
                              setIsActive(false);
                            }, 3000);
                            //console.log(data.location);
                          })
                          .catch((err) => console.error(err));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 mb-4 position-relative">
                {time > 0 && isActive && (
                  <Progress type="line" width={100} percent={time} />
                )}
              </div>
              <div className="col-sm-12 col-md-12 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container">
                    <textarea
                      className="textarea-control ps-5 py-3"
                      placeholder="Description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      name="description"
                      rows="5"
                    ></textarea>
                    <img
                      src="assets/img/Description.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.description && formik.touched.description && (
                  <div className="text-red"> {formik.errors.description}</div>
                )}
              </div>
              <div className="col-sm-12 col-md-6 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-5"
                      placeholder="Min Capacity"
                      name="minCapacity"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.minCapacity}
                    />
                    <img
                      src="assets/img/minCapacity.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.minCapacity && formik.touched.minCapacity && (
                  <div className="text-red"> {formik.errors.minCapacity}</div>
                )}
              </div>
              <div className="col-sm-12 col-md-6 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-5"
                      placeholder="Max Capacity"
                      name="maxCapacity"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.maxCapacity}
                    />
                    <img
                      src="assets/img/maxCapacity.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.maxCapacity && formik.touched.maxCapacity && (
                  <div className="text-red"> {formik.errors.maxCapacity}</div>
                )}
              </div>

              <div className="col-sm-12 col-md-12 mb-4 position-relative">
                <div className="form-group">
                  <div className="input-container">
                    <ReactQuill
                      theme="snow"
                      className="textarea-control ps-5 py-3"
                      value={formik.values.rulesandregulations}
                      onChange={(content, data, source, editor) => {
                        formik.setFieldValue("rulesandregulations", content);
                      }}
                      name="rulesandregulations"
                      placeholder="Rules and regulations"
                      modules={{ toolbar: false }}
                    />
                    {/* <textarea
                  className="textarea-control ps-5 py-3"
                  placeholder="Rules and regulations"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rulesandregulations}
                  name="rulesandregulations"
                  rows="5"
                ></textarea> */}
                    <img
                      src="assets/img/rulesandregulations.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                </div>
                {formik.errors.rulesandregulations &&
                  formik.touched.rulesandregulations && (
                    <div className="text-red">
                      {" "}
                      {formik.errors.rulesandregulations}
                    </div>
                  )}
              </div>

              <div className="col-sm-12 col-md-12 text-center">
                <button
                  type="submit"
                  className="btn btn-submit w-50"
                  // onClose={onClose}
                  // onClick={() => {
                  //   setModalName("AddSlot");
                  //   showDrawer();
                  // }}
                >
                  {loading ? <Loader /> : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
}

export default EditCourtTurf;
